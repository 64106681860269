import { connect } from 'react-redux';
import App from './Component';

const mapStateToProps = state => {
  return ({ dark: state.theme.dark,  
            path: window.location.pathname,
 });
}

const AppContainer = connect(mapStateToProps)(App);

export default AppContainer;
