import React from 'react';
import styled from 'styled-components/macro';

var md = require('markdown-it')();
var mk = require('markdown-it-katex');

md.use(mk);

const Wrapper = styled.div`
  display: flex;

  * {
    font-size: 16px;
    line-height: 20px;
    //font-weight: 500;
    //text-decoration: none;
    //color: ${props => props.theme.normalText};
    //${props => props.full && 'white-space: unset'};
  }

  & > * {
    margin-right: 4px;
  }
  
  
  & > span {
    color: ${props => props.theme.mutedText};
  }
`;

const Wrapper2 = styled.div`
  display: flex;

  * {
    font-size: 18px;
    line-height: 20px;
    //font-weight: 500;
    //text-decoration: none;
    color: ${props => props.theme.normalText};
    //${props => props.full && 'white-space: unset'};
  }
`;

const renderTitle = props => {

  switch (props.type) {

    case 'deleted':

      return (
        <div dangerouslySetInnerHTML={{__html: md.render('This post has been deleted.') }}/>
       );     

    case 'general':

      switch (props.subtype) {
        case 'link':
          return (
           <a href={props.url} style={{"textDecoration": "none"}} >
             <div dangerouslySetInnerHTML={{__html: md.render(props.title) }}/>
           </a>
          );

        case 'discussion':
          if (props.full) return (
            <div dangerouslySetInnerHTML={{__html: md.render(props.title) }}/>
          );
          return (
           <div dangerouslySetInnerHTML={{__html: md.render(props.title) }}/>
          );

        default:
          break;
        };
      break;
        
    case 'library':
      if (props.full) return (
        <div dangerouslySetInnerHTML={{__html: md.render(props.title) }}/>
      );

      return (
        <div dangerouslySetInnerHTML={{__html: md.render(props.title) }}/>
      );
      
    case 'career':
      switch (props.subtype) {
        case 'interview':
          if (props.full) return (
            <div dangerouslySetInnerHTML={{__html: md.render("Interview&mdash;"+props.title) }}/>
          );
          return (
            <div dangerouslySetInnerHTML={{__html: md.render("Interview&mdash;"+props.title) }}/>
          );

        default:
          if (props.full) return (
            <div dangerouslySetInnerHTML={{__html: md.render(props.title) }}/>
          );
          return (
            <div dangerouslySetInnerHTML={{__html: md.render(props.title) }}/>
          );
        };

    case 'alpha':
      if (props.full) return (
        <div dangerouslySetInnerHTML={{__html: md.render(props.title) }}/>
      );
      return (
        <div dangerouslySetInnerHTML={{__html: md.render(props.title) }}/>
      );

    default:
      break;
  }
};

const PostContentTitle = props => {
  if (props.full) {
    return (<Wrapper2 full={props.full}>{renderTitle(props)}</Wrapper2>)
  } else {
    return (<Wrapper full={props.full}>{renderTitle(props)}</Wrapper>)
  }
};

export default PostContentTitle;
