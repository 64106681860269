import React from 'react';
import styled from 'styled-components/macro';
import { transition } from '../../helpers';
import FormWrapper from './Wrapper';

const StyledForm = styled.form`
  ${transition('filter')};
  
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  flex-direction: column;
  //align-items: flex-start;
  margin: 0px;
  //border-left: 1px solid ${props => props.theme.border};
  //border-right: 1px solid ${props => props.theme.border};
  min-width: 0;
  
  ${props =>
    props.loading &&
    'filter: grayscale(0.5) blur(5px) opacity(0.6); pointer-events: none'};
`;

const Form = ({ className, wide, ...props }) => {

return(
  <FormWrapper className={className} wide={wide} ref={props.myInput}>
    <StyledForm {...props} />
    {props.loading}
  </FormWrapper>
);
};

export default Form;
