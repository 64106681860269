export const regionOptions = [
  { value: 'Canada',   label: 'Canada'},  
  { value: 'China',    label: 'China'},  
  { value: 'Emerging', label: 'Emerging'},  
  { value: 'Europe',   label: 'Europe'},  
  { value: 'Global',   label: 'Global'},  
  { value: 'India',    label: 'India'},  
  { value: 'Japan',    label: 'Japan'},
  { value: 'None',     label: 'None'},  
  { value: 'United Kingdom', label: 'United Kingdom'},
  { value: 'United States', label: 'United States'}
]; 

