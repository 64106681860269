import { Auth } from 'aws-amplify';

import { checkProfile, checkUser, sendContact } from '../util/api';

// LOGIN                            attemptLogin
// CREATE COGNITO USER              attemptCreateUser
// VERIFY COGNITO USER (SIGNUP)     attemptSignup
// LOGOUT                           logout

// CHANGE COGNITO EMAIL             attemptChangeEmail
// RESEND AUTHENTICATION            attemptResendConfirm
// VERIFY COGNITO EMAIL             attemptVerifyAttribute

// RESEND AUTHENTICATION            attemptResendSignup
// CONFIRM COGNITO USER             attemptConfirmSignup

// FORGOT PASSWORD                  attemptForgotPassword
// RESET PASSWORD                   attemptResetPassword
// CHANGE PASSWORD                  attemptChangePassword

// DELETE USER                      attemptDeleteUser

// SEND EMAIL CONTACT US            attemptSendContact

// CLEAR SIGNUP FORM                attemptClearSignupForm
// CLEAR PROFILE FORM               attemptClearProfileAuth

// LOGIN
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR   = 'LOGIN_ERROR';

const loginRequest =                            { type: LOGIN_REQUEST        };
const loginSuccess = (token, emailVerified) => ({ type: LOGIN_SUCCESS, token, emailVerified });
const loginError   = error                  => ({ type: LOGIN_ERROR,   error });

export const attemptLogin = (username, password) => async dispatch => {
  dispatch(loginRequest);
  try {
    // SIGN IN
    const user = await Auth.signIn(username, password);

    // GET AWS TOKEN
    const awsInfo = await Auth.currentSession();

    const accessToken   = awsInfo.getAccessToken()
    const jwtToken      = accessToken.getJwtToken()
    const emailVerified = user.attributes.email_verified
    
    // CHECK IF PROFILE EXISTS IN RDS (OTHERWISE CREATE IT) 
    if (user.attributes['custom:profile'] === 0) {
      await checkProfile(user.username, user.attributes.email);
      await Auth.updateUserAttributes(user, { 'custom:profile': '1' });
    }
    
    // PRINT OBJECTS
    //console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
    //console.log(`myJwt: ${jwtToken}`)
    //console.log('user: ', jwtDecode(jwtToken))

    dispatch(loginSuccess(jwtToken, emailVerified));
    
  } catch (error) {

    if (error.code === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
    } else if (error.code === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
    } else if (error.code === 'NotAuthorizedException') {
      // The error happens when the incorrect password is provided
    } else if (error.code === 'UserNotFoundException') {
      // The error happens when the supplied username/email does not exist in the Cognito user pool
    } else {
      console.log(error);
    }

    dispatch(loginError(error));
  }
};

// CREATE USER IN COGNITO
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR   = 'CREATE_USER_ERROR';

const createUserRequest =           { type: CREATE_USER_REQUEST       };
const createUserSuccess =          ({ type: CREATE_USER_SUCCESS       });
const createUserError   = error => ({ type: CREATE_USER_ERROR,  error });

export const attemptCreateUser = (username, password, email) => async dispatch => {
  dispatch(createUserRequest);

  //var type = '';
  try {

    // CHECK IF USERNAME IS AVALIABLE
    const exists = await checkUser(username, email);

    if (exists.existU) {
      throw new Error('Username already exists');

    } else if (exists.existE) {
      throw new Error('Email already exists');

    } else {

      // IF USERNAME DOES NOT EXIST THEN YOU CAN CREATE THIS USER. OTHERWISE THROW ERROR.
      await Auth.signUp({
        username,
        password,
        attributes: {
            'email': email,
            'custom:profile': '0',
        },
        validationData: []  //optional
      })

      dispatch(createUserSuccess);
    }

  } catch (error) {
    console.log(error)
    dispatch(createUserError(error));

  }
  
};

// SIGNUP
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR   = 'SIGNUP_ERROR';
 
const signupRequest =                            { type: SIGNUP_REQUEST                       };
const signupSuccess = (token, emailVerified) => ({ type: SIGNUP_SUCCESS, token, emailVerified });
const signupError   = error                  => ({ type: SIGNUP_ERROR,   error                });

export const attemptSignup = (username, password, email, authenticationCode) => async dispatch => {
  dispatch(signupRequest);

  try {
    // CONFIRM
    await Auth.confirmSignUp(username, authenticationCode);

    // SIGN IN
    const user    = await Auth.signIn(username, password);

    // GET AWS TOKEN
    const awsInfo = await Auth.currentSession();

    const accessToken   = awsInfo.getAccessToken()
    const jwtToken      = accessToken.getJwtToken()
    const emailVerified = user.attributes.email_verified
    
    // PRINT OBJECTS
    //console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
    //console.log(`myJwt: ${jwtToken}`)
    //console.log('user: ', jwtDecode(jwtToken))

    dispatch(signupSuccess(jwtToken, emailVerified));

  } catch (error) {
    dispatch(signupError(error));
  }
};

// LOGOUT
export const LOGOUT = 'LOGOUT';

const logoutRequest = { type: LOGOUT };

export const logout = () => async dispatch => {
  try {
    // CONFIRM
    await Auth.signOut()
  
  } catch (error) {
  
  }
  dispatch(logoutRequest)
};

// CHECK TOKEN
export const checkToken = () => async (dispatch, getState) => {
  const { token } = getState().auth;
  //console.log("************** CHECKING TOKEN HERE", token)
  if ( (token !== null) && (token !== undefined) ) {
    try {
      // CONFIRM USER IS ACTIVE
      await Auth.currentAuthenticatedUser()
    } catch (error) {
      //console.log("************** LOGING OUT")
      dispatch(logoutRequest)
    }
  }
};

// CHANGE EMAIL ATTRIBUTE
export const CHANGE_ATTRIBUTE_REQUEST = 'CHANGE_ATTRIBUTE_REQUEST';
export const CHANGE_ATTRIBUTE_SUCCESS = 'CHANGE_ATTRIBUTE_SUCCESS';
export const CHANGE_ATTRIBUTE_ERROR   = 'CHANGE_ATTRIBUTE_ERROR';

const changeAttributeRequest =             { type: CHANGE_ATTRIBUTE_REQUEST        };
const changeAttributeSuccess = profile => ({ type: CHANGE_ATTRIBUTE_SUCCESS, profile });
const changeAttributeError   = error   => ({ type: CHANGE_ATTRIBUTE_ERROR,   error });

export const attemptChangeEmail = (profile) => async dispatch => {
  dispatch(changeAttributeRequest);
  
  try {
    // CONFIRM
    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, { email: profile.email })
              
    dispatch(changeAttributeSuccess(profile));

  } catch (error) {
    dispatch(changeAttributeError(error));

  }
};

// VERIFY EMAIL ATTRIBUTE CHANGE
export const VERIFY_ATTRIBUTE_REQUEST = 'VERIFY_ATTRIBUTE_REQUEST';
export const VERIFY_ATTRIBUTE_SUCCESS = 'VERIFY_ATTRIBUTE_SUCCESS';
export const VERIFY_ATTRIBUTE_ERROR   = 'VERIFY_ATTRIBUTE_ERROR';

const verifyAttributeRequest =                   { type: VERIFY_ATTRIBUTE_REQUEST                };
const verifyAttributeSuccess = emailVerified => ({ type: VERIFY_ATTRIBUTE_SUCCESS, emailVerified });
const verifyAttributeError   = error         => ({ type: VERIFY_ATTRIBUTE_ERROR,   error         });

export const attemptVerifyAttribute = (confirm) => async dispatch => {
  dispatch(verifyAttributeRequest);
  
  try {
    // CONFIRM
    await Auth.verifyCurrentUserAttributeSubmit("email", confirm);
    
    const user = await Auth.currentAuthenticatedUser();

    const emailVerified = user.attributes.email_verified

    dispatch(verifyAttributeSuccess(emailVerified));

  } catch (error) {
    dispatch(verifyAttributeError(error));
    
  }
};

// VERIFY EMAIL SIGNUP CHANGE
export const CONFIRM_SIGNUP_REQUEST = 'CONFIRM_SIGNUP_REQUEST';
export const CONFIRM_SIGNUP_SUCCESS = 'CONFIRM_SIGNUP_SUCCESS';
export const CONFIRM_SIGNUP_ERROR   = 'CONFIRM_SIGNUP_ERROR';

const verifyConfirmSignupRequest =           { type: CONFIRM_SIGNUP_REQUEST        };
const verifyConfirmSignupSuccess =          ({ type: CONFIRM_SIGNUP_SUCCESS});
const verifyConfirmSignupError   = error => ({ type: CONFIRM_SIGNUP_ERROR,   error });

export const attemptConfirmSignup = (username, confirm) => async dispatch => {
  dispatch(verifyConfirmSignupRequest);
  
  try {
    // CONFIRM
    await Auth.confirmSignUp(username, confirm);

    dispatch(verifyConfirmSignupSuccess);

  } catch (error) {
    dispatch(verifyConfirmSignupError(error));
    
  }
};


// RESEND EMAIL ATTRIBUTE CODE
export const RESEND_CONFIRM_REQUEST = 'RESEND_CONFIRM_REQUEST';
export const RESEND_CONFIRM_SUCCESS = 'RESEND_CONFIRM_SUCCESS';
export const RESEND_CONFIRM_ERROR   = 'RESEND_CONFIRM_ERROR';

const resendConfirmRequest =           { type: RESEND_CONFIRM_REQUEST        };
const resendConfirmSuccess =          ({ type: RESEND_CONFIRM_SUCCESS        });
const resendConfirmError   = error => ({ type: RESEND_CONFIRM_ERROR,   error });

export const attemptResendConfirm = () => async dispatch => {
  dispatch(resendConfirmRequest);

  try {
    // CONFIRM
    await Auth.verifyCurrentUserAttribute("email").then(() => {
      }).catch(e => {

      });

    dispatch(resendConfirmSuccess);

  } catch (error) {
    dispatch(resendConfirmError(error));

  }
};


// RESEND SIGNUP CODE
export const RESEND_SIGNUP_REQUEST = 'RESEND_SIGNUP_REQUEST';
export const RESEND_SIGNUP_SUCCESS = 'RESEND_SIGNUP_SUCCESS';
export const RESEND_SIGNUP_ERROR   = 'RESEND_SIGNUP_ERROR';

const resendSignupRequest =           { type: RESEND_SIGNUP_REQUEST        };
const resendSignupSuccess =          ({ type: RESEND_SIGNUP_SUCCESS        });
const resendSignupError   = error => ({ type: RESEND_SIGNUP_ERROR,   error });

export const attemptResendSignup = (username) => async dispatch => {
  dispatch(resendSignupRequest);

  try {
    // CONFIRM
    await Auth.resendSignUp(username).then(() => {
      }).catch(e => {

      });

    dispatch(resendSignupSuccess);

  } catch (error) {
    dispatch(resendSignupError(error));

  }
};

// CHANGE PASSWORD
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR   = 'CHANGE_PASSWORD_ERROR';

const changePasswordRequest =           { type: CHANGE_PASSWORD_REQUEST       };
const changePasswordSuccess =          ({ type: CHANGE_PASSWORD_SUCCESS       });
const changePasswordError   = error => ({ type: CHANGE_PASSWORD_ERROR,  error });

export const attemptChangePassword = (oldpassword, newpassword) => async dispatch => {
  dispatch(changePasswordRequest);

  try {
    // CONFIRM
    const user = await Auth.currentAuthenticatedUser();
    
    await Auth.changePassword(user, oldpassword, newpassword).then(() => {
    }).catch(e => {
    
    });
   
    dispatch(changePasswordSuccess);

  } catch (error) {
    dispatch(changePasswordError(error));

  }
};

// DELETE USER
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR   = 'DELETE_USER_ERROR';

const deleteUserRequest =           { type: DELETE_USER_REQUEST       };
const deleteUserError   = error => ({ type: DELETE_USER_ERROR,  error });

export const attemptDeleteUser = () => async dispatch => {
  dispatch(deleteUserRequest);

  try {

    Auth
    .currentAuthenticatedUser()
    .then((user: CognitoUser) => new Promise((resolve, reject) => {
        user.deleteUser(error => {
            if (error) {

            }
            resolve();
        });
    }));

    dispatch(logoutRequest);

  } catch (error) {
    dispatch(deleteUserError(error));

  }
};


// FORGOT PASSWORD
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR   = 'FORGOT_PASSWORD_ERROR';

const forgotPasswordRequest =           { type: FORGOT_PASSWORD_REQUEST       };
const forgotPasswordSuccess =          ({ type: FORGOT_PASSWORD_SUCCESS       });
const forgotPasswordError   = error => ({ type: FORGOT_PASSWORD_ERROR,  error });

export const attemptForgotPassword = (username) => async dispatch => {
  dispatch(forgotPasswordRequest);

  try {
   
    // CHECK IF USERNAME IS AVALIABLE
    const exists = await checkUser(username, '');

    if (!exists.existU) {

      await Auth.forgotPassword(username)
             
    } else {
      dispatch(forgotPasswordError("Username not found"));
    }

    dispatch(forgotPasswordSuccess);
    
  } catch (error) {
    dispatch(forgotPasswordError(error));

  }
};

// RESET PASSWORD
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR   = 'RESET_PASSWORD_ERROR';

const resetPasswordRequest =           { type: RESET_PASSWORD_REQUEST       };
const resetPasswordSuccess =          ({ type: RESET_PASSWORD_SUCCESS       });
const resetPasswordError   = error => ({ type: RESET_PASSWORD_ERROR,  error });

export const attemptResetPassword = (username, authenticationCode, newpassword) => async dispatch => {
  dispatch(resetPasswordRequest);

  try {
    await Auth.forgotPasswordSubmit(username, authenticationCode, newpassword)
       
    dispatch(resetPasswordSuccess);
    
  } catch (error) {
    dispatch(resetPasswordError(error));

  }
};


// CONTACT EMAIL
export const SEND_CONTACT_REQUEST = 'SEND_CONTACT_REQUEST';
export const SEND_CONTACT_SUCCESS = 'SEND_CONTACT_SUCCESS';
export const SEND_CONTACT_ERROR   = 'SEND_CONTACT_ERROR';

const sendContactRequest =           { type: SEND_CONTACT_REQUEST       };
const sendContactSuccess =          ({ type: SEND_CONTACT_SUCCESS       });
const sendContactError   = error => ({ type: SEND_CONTACT_ERROR,  error });

export const attemptSendContact = (props) => async (dispatch, getState) => {
  dispatch(sendContactRequest);

  try {
    const json = await sendContact(props);

    //console.log("CONTACT: ", props, json)
    dispatch(sendContactSuccess(json));

  } catch (error) {
    dispatch(sendContactError(error));

  }
};


// CLEAR SIGNUP FORM
export const CLEAR_SIGNUP_FORM = 'CLEAR_SIGNUP_FORM';

const clearSignupForm = { type: CLEAR_SIGNUP_FORM };

export const attemptClearSignupForm = () => async dispatch => {
  dispatch(clearSignupForm)
};


// CLEAR SIGNUP FORM
export const CLEAR_VERIFY_SIGNUP_FORM = 'CLEAR_VERIFY_SIGNUP_FORM';

const clearVerifySignupForm = { type: CLEAR_VERIFY_SIGNUP_FORM };

export const attemptClearVerifySignupForm = () => async dispatch => {
  dispatch(clearVerifySignupForm)
};


// CLEAR SIGNUP FORM
export const CLEAR_PROFILE_FORM = 'CLEAR_PROFILE_FORM';

const clearProfileForm = { type: CLEAR_PROFILE_FORM };

export const attemptClearProfileAuth = () => async dispatch => {
  dispatch(clearProfileForm)
};


export const CLEAR_LOGIN_FORM = 'CLEAR_LOGIN_FORM';

const clearLoginForm = { type: CLEAR_LOGIN_FORM };

export const attemptClearLoginForm = () => async dispatch => {
  dispatch(clearLoginForm)
};