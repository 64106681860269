import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // CHANGES BACKGROUND COLOR

// HEADER
import theme from '../../theme';

import history from '../../util/history';
import GlobalStyle from '../../globalStyle';

//import FooterContainer from '../Footer/Container';
import HeaderContainer from '../Header/Container';
import ErrorNotificationContainer from '../ErrorNotification/Container';
import Home from '../Home';

import './ScrollUp.css';

// AWS 
import Amplify from 'aws-amplify';
import awsconfig from '../../aws-exports';
import {Helmet} from "react-helmet";

import loadable from '@loadable/component'

const ScrollUpButton = loadable(() => import('react-scroll-up-button'))

Amplify.configure(awsconfig);

const LoginFormContainer = loadable(() => import('../LoginForm/Container'))
const SignupFormContainer = loadable(() => import('../SignupForm/Container'))
const VerifySignupFormContainer = loadable(() => import('../VerifySignupForm/Container'))
const VerifyEmailFormContainer = loadable(() => import('../VerifyEmailForm/Container'))

const CreatePostFormContainer = loadable(() => import('../CreatePostForm/Container'))
const UpdateProfileFormContainer = loadable(() => import('../UpdateProfileForm/Container'))

const NavigateContainer = loadable(() => import('../Navigate/Container'))

const AboutUsContainer = loadable(() => import('../AboutUs/Container'))
const HelpContainer = loadable(() => import('../Help/Container'))
const TermsContainer = loadable(() => import('../Terms/Container'))
const PrivacyContainer = loadable(() => import('../Privacy/Container'))
const ContentContainer = loadable(() => import('../Content/Container'))
const ContactContainer = loadable(() => import('../Contact/Container'))
const PreferencesContainer = loadable(() => import('../Preferences/Container'))
const ForgotPasswordContainer = loadable(() => import('../ForgotPassword/Container'))
const ForgotUsernameContainer = loadable(() => import('../ForgotUsername/Container'))
const DisclaimerContainer = loadable(() => import('../Disclaimer/Container'))

const App = (props) => {
 return(
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>QuantifyAlpha | A Quantitative Finance Community</title>
      <meta name="Description" content="QuantifyAlpha is home to a collection of communities focusing on the field of finance. A forum to discuss quantitative trading, statistical methods, econometrics, programming, implementation, automated strategies, and bounce ideas off each other for constructive criticism. Feel free to submit papers/discussions/links of things you find interesting." />
      <meta name="Keywords" content="quantitative trading, statistical methods, econometrics, programming, implementation, automated strategies" />
      <link rel="canonical" href="http://quantifyalpha.com/" />
    </Helmet>
   <ThemeProvider theme={theme(props.dark)}>

    <Router history={history}>

      <>

        <GlobalStyle />

        <Route component={() => <HeaderContainer theme={props.theme} pathname={props.path}/> } />
        
        <Route component={ErrorNotificationContainer} />   

        <Switch>

          {/* No Search Bar */}
          <Route path='/login' component={LoginFormContainer} />
          <Route path='/signup' component={SignupFormContainer} />
          <Route path='/verifysignup' component={VerifySignupFormContainer} />
          <Route path='/verifyemail' component={VerifyEmailFormContainer} />

          <Route path='/createpost' component={CreatePostFormContainer} />
          <Route path='/updateprofile' component={UpdateProfileFormContainer} />

          <Route path='/navigate' component={NavigateContainer} />

          <Route path='/aboutus' component={AboutUsContainer} />
          <Route path='/help' component={HelpContainer} />
          <Route path='/contactus' component={ContactContainer} />
          <Route path='/preferences' component={PreferencesContainer} />
          <Route path='/forgotpassword' component={ForgotPasswordContainer} />
          <Route path='/forgotusername' component={ForgotUsernameContainer} />
          <Route path='/disclaimer' component={DisclaimerContainer} />
          <Route path='/termsconditions' component={TermsContainer} />
          <Route path='/privacypolicy' component={PrivacyContainer} />
          <Route path='/contentpolicy' component={ContentContainer} />

          {/* Search Bar */}
          <Route path='/' render={() => <Home history={history} pathname={props.path}/>} /> 

        </Switch>

      </>

    </Router>

    {/*
    <Router history={history}>
      <FooterContainer/>
    </Router>
    */}

    <ScrollUpButton
        ContainerClassName="AnyClassForContainer"
        TransitionClassName="AnyClassForTransition"
        EasingType="easeInCubic"
        ShowAtPosition={145}
        AnimationDuration={10}
      >
      <span>BACK TO TOP</span>
    </ScrollUpButton>

   </ThemeProvider>

  </>
 );
}

export default App;
