import {
  //RESET_POSTS_REQUEST,
  //RESET_POSTS_SUCCESS,
  //RESET_POSTS_ERROR,

  CLEAR_POSTS_REQUEST,
  CLEAR_POSTS_SUCCESS,
  CLEAR_POSTS_ERROR,

  FETCH_POSTS_REQUEST,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_ERROR,
  
  FETCH_POST_REQUEST,
  FETCH_POST_SUCCESS,
  FETCH_POST_ERROR,

  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  CREATE_POST_ERROR,
  
  DELETE_POST_REQUEST,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERROR,
  
  SAVED_POST_REQUEST,
  SAVED_POST_SUCCESS,
  SAVED_POST_ERROR,

  SAVE_POST_REQUEST,
  SAVE_POST_SUCCESS,
  SAVE_POST_ERROR,

  UNSAVE_POST_REQUEST,
  UNSAVE_POST_SUCCESS,
  UNSAVE_POST_ERROR,

  GETKEY_POST_REQUEST,
  GETKEY_POST_SUCCESS,
  GETKEY_POST_ERROR,
  
  VOTE_POST_REQUEST,
  VOTE_POST_SUCCESS,
  VOTE_POST_ERROR,
  
  VIEW_POST_REQUEST,
  VIEW_POST_SUCCESS,
  VIEW_POST_ERROR,

  CLEAR_POST_DETAIL,
  
} from '../actions/posts';

const initialState = { isFetching: false, items: [] };

export default (state = initialState, action) => {
  switch (action.type) {
 
    case CLEAR_POSTS_REQUEST:
      return { ...state, prvitems: null, items: null, postsearchfields: null };
    case CLEAR_POSTS_SUCCESS:
      return { ...state, prvitems: null, items: null, postsearchfields: null };
    case CLEAR_POSTS_ERROR:
      return { ...state };
   
    case FETCH_POSTS_REQUEST:
      return { ...state, isFetching: true, post: null, newPost: null, newpid: null, prvitems: action.posts.fetchmore, postsearchfields: action.posts.postsearchfields };
    case FETCH_POSTS_SUCCESS:
      return { ...state, isFetching: false, items: action.posts.posts, prvitems: action.posts.fetchmore, postsearchfields: action.posts.postsearchfields };
    case FETCH_POSTS_ERROR:
      return { ...state, isFetching: false };

    case FETCH_POST_REQUEST:
      return { ...state, isPostFetching: true, newPost: null, newpid: null };
    case FETCH_POST_SUCCESS:
      return { ...state, isPostFetching: false, post: action.post.post, items: action.post.posts, updateViewPost: true };
    case FETCH_POST_ERROR:
      return { ...state, isPostFetching: false };

    case CLEAR_POST_DETAIL:
      return { ...state, isPostFetching:  true };       

    case CREATE_POST_REQUEST:
      return { ...state, isFetching: true };
    case CREATE_POST_SUCCESS:
      return { ...state, isFetching: false, newpid: action.post.maxpid };
    case CREATE_POST_ERROR:
      return { ...state, isFetching: false, error: action.error };

    case DELETE_POST_REQUEST:
      return { ...state, isDeleting: true };
    case DELETE_POST_SUCCESS:
      var itemsDelete = state.items.filter(i => i.pid !== action.post);
      return { ...state, isDeleting: false, post: action.post, items: itemsDelete };
    case DELETE_POST_ERROR:
      return { ...state, isDeleting: false };

    case SAVED_POST_REQUEST:
      return { ...state, isSaving: true };
    case SAVED_POST_SUCCESS:
      return { ...state, isSaving: false, postsaved: action.saved };
    case SAVED_POST_ERROR:
      return { ...state, isSaving: false };

    case SAVE_POST_REQUEST:
      return { ...state, isSaving: true };
    case SAVE_POST_SUCCESS:
      return { ...state, isSaving: false };
    case SAVE_POST_ERROR:
      return { ...state, isSaving: false };

    case UNSAVE_POST_REQUEST:
      return { ...state, isDeleting: true };
    case UNSAVE_POST_SUCCESS:
      return { ...state, isDeleting: false };
    case UNSAVE_POST_ERROR:
      return { ...state, isDeleting: false };

    case VOTE_POST_REQUEST:
      return { ...state, isVoting: true };
    case VOTE_POST_SUCCESS:
      return { ...state, isVoting: false, items: action.post.posts };
    case VOTE_POST_ERROR:
      return { ...state, isVoting: false };

    case VIEW_POST_REQUEST:
      return { ...state, isViewing: true };
    case VIEW_POST_SUCCESS:
      return { ...state, isViewing: false, postviews: action.post, updateViewPost: false };
    case VIEW_POST_ERROR:
      return { ...state, isViewing: false };

    case GETKEY_POST_REQUEST:
      return { ...state, isFetchingKeys: true };
    case GETKEY_POST_SUCCESS:
      return { ...state, userkeywords: action.post, isFetchingKeys: false };
    case GETKEY_POST_ERROR:
      return { ...state, isFetchingKeys: false };

    default:
      return state;
  }
};
