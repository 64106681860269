export const subtypeGeneralOptions = [
  { value: 'discussion',  label: 'Discussion'},
  { value: 'link',        label: 'URL'},
];

export const subtypeLibraryOptions = [
  { value: 'article',     label: 'Article'},
  { value: 'textbook',    label: 'Textbook'},
];

export const subtypeCareerOptions = [
  { value: 'school',     label: 'School'},
  { value: 'interview',  label: 'Interview'},
  { value: 'firm',       label: 'Firm'},
  //{ value: 'charter',    label: 'Charter'},
];

export const subtypeAlphaOptions = [
  { value: 'strategy',     label: 'Strategy'},
  { value: 'data',         label: 'Data'},
  //{ value: 'software',     label: 'Software'},
  //{ value: 'operation',    label: 'Operation'},
];

