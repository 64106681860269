export const jArXivOptions = [
{ value: 'econ.EM', label: 'econ.EM'},
{ value: 'econ.GN', label: 'econ.GN'},
{ value: 'econ.TH', label: 'econ.TH'},
{ value: 'q-fin.CP', label: 'q-fin.CP'},
{ value: 'q-fin.EC', label: 'q-fin.EC'},
{ value: 'q-fin.GN', label: 'q-fin.GN'},
{ value: 'q-fin.MF', label: 'q-fin.MF'},
{ value: 'q-fin.PM', label: 'q-fin.PM'},
{ value: 'q-fin.PR', label: 'q-fin.PR'},
{ value: 'q-fin.RM', label: 'q-fin.RM'},
{ value: 'q-fin.ST', label: 'q-fin.ST'},
{ value: 'q-fin.TR', label: 'q-fin.TR'},
];
export const jSpringerOptions = [
{ value: 'Finance and Stochastics', label: 'Finance and Stochastics'},
{ value: 'Mathematics and Financial Economics', label: 'Mathematics and Financial Economics'},
{ value: 'Review of Quantitative Finance and Accounting', label: 'Review of Quantitative Finance and Accounting'},
];
export const jSSRNOptions = [
{ value: 'Financial Economics', label: 'Financial Economics'},
{ value: 'Mathematical and Quantitative Methods', label: 'Mathematical and Quantitative Methods'},
];
export const jPMROptions = [
{ value: 'Journal of Financial Data Science', label: 'Journal of Financial Data Science'},
{ value: 'Journal of Investing', label: 'Journal of Investing'},
{ value: 'Journal of Portfolio Management', label: 'Journal of Portfolio Management'},
];
export const jRiskOptions = [
{ value: 'Journal of Computational Finance', label: 'Journal of Computational Finance'},
{ value: 'Journal of Investment Strategies', label: 'Journal of Investment Strategies'},
{ value: 'Journal of Risk', label: 'Journal of Risk'},
];
export const jTFOptions = [
{ value: 'Applied Mathematical Finance', label: 'Applied Mathematical Finance'},
{ value: 'Journal of Behavioral Finance', label: 'Journal of Behavioral Finance'},
{ value: 'Quantitative Finance', label: 'Quantitative Finance'},
];
export const jCambridgeOptions = [
{ value: 'Journal of Financial and Quantitative Analysis', label: 'Journal of Financial and Quantitative Analysis'},
];
export const jWileyOptions = [
{ value: 'Econometrica', label: 'Econometrica'},
{ value: 'Mathematical Finance', label: 'Mathematical Finance'},
{ value: 'The Journal of Finance', label: 'The Journal of Finance'},
];
export const journalOptions = [
{ value: 'econ.EM', label: '(ArXiV) econ.EM'},
{ value: 'econ.GN', label: '(ArXiV) econ.GN'},
{ value: 'econ.TH', label: '(ArXiV) econ.TH'},
{ value: 'q-fin.CP', label: '(ArXiV) q-fin.CP'},
{ value: 'q-fin.EC', label: '(ArXiV) q-fin.EC'},
{ value: 'q-fin.GN', label: '(ArXiV) q-fin.GN'},
{ value: 'q-fin.MF', label: '(ArXiV) q-fin.MF'},
{ value: 'q-fin.PM', label: '(ArXiV) q-fin.PM'},
{ value: 'q-fin.PR', label: '(ArXiV) q-fin.PR'},
{ value: 'q-fin.RM', label: '(ArXiV) q-fin.RM'},
{ value: 'q-fin.ST', label: '(ArXiV) q-fin.ST'},
{ value: 'q-fin.TR', label: '(ArXiV) q-fin.TR'},
{ value: 'Finance and Stochastics', label: '(Springer) Finance and Stochastics'},
{ value: 'Mathematics and Financial Economics', label: '(Springer) Mathematics and Financial Economics'},
{ value: 'Review of Quantitative Finance and Accounting', label: '(Springer) Review of Quantitative Finance and Accounting'},
{ value: 'Financial Economics', label: '(SSRN) Financial Economics'},
{ value: 'Mathematical and Quantitative Methods', label: '(SSRN) Mathematical and Quantitative Methods'},
{ value: 'Journal of Financial Data Science', label: '(PMR) Journal of Financial Data Science'},
{ value: 'Journal of Investing', label: '(PMR) Journal of Investing'},
{ value: 'Journal of Portfolio Management', label: '(PMR) Journal of Portfolio Management'},
{ value: 'Journal of Computational Finance', label: '(Risk) Journal of Computational Finance'},
{ value: 'Journal of Investment Strategies', label: '(Risk) Journal of Investment Strategies'},
{ value: 'Journal of Risk', label: '(Risk) Journal of Risk'},
{ value: 'Applied Mathematical Finance', label: '(T & F) Applied Mathematical Finance'},
{ value: 'Journal of Behavioral Finance', label: '(T & F) Journal of Behavioral Finance'},
{ value: 'Quantitative Finance', label: '(T & F) Quantitative Finance'},
{ value: 'Journal of Financial and Quantitative Analysis', label: '(Cambridge) Journal of Financial and Quantitative Analysis'},
{ value: 'Econometrica', label: '(Wiley) Econometrica'},
{ value: 'Mathematical Finance', label: '(Wiley) Mathematical Finance'},
{ value: 'The Journal of Finance', label: '(Wiley) The Journal of Finance'},
];
export const textbookOptions = [
{ value: 'econ.EM', label: '(ArXiV) econ.EM'},
{ value: 'econ.GN', label: '(ArXiV) econ.GN'},
{ value: 'econ.TH', label: '(ArXiV) econ.TH'},
{ value: 'q-fin.CP', label: '(ArXiV) q-fin.CP'},
{ value: 'q-fin.EC', label: '(ArXiV) q-fin.EC'},
{ value: 'q-fin.GN', label: '(ArXiV) q-fin.GN'},
{ value: 'q-fin.MF', label: '(ArXiV) q-fin.MF'},
{ value: 'q-fin.PM', label: '(ArXiV) q-fin.PM'},
{ value: 'q-fin.PR', label: '(ArXiV) q-fin.PR'},
{ value: 'q-fin.RM', label: '(ArXiV) q-fin.RM'},
{ value: 'q-fin.ST', label: '(ArXiV) q-fin.ST'},
{ value: 'q-fin.TR', label: '(ArXiV) q-fin.TR'},
{ value: 'Finance and Stochastics', label: '(Springer) Finance and Stochastics'},
{ value: 'Mathematics and Financial Economics', label: '(Springer) Mathematics and Financial Economics'},
{ value: 'Review of Quantitative Finance and Accounting', label: '(Springer) Review of Quantitative Finance and Accounting'},
{ value: 'Financial Economics', label: '(SSRN) Financial Economics'},
{ value: 'Mathematical and Quantitative Methods', label: '(SSRN) Mathematical and Quantitative Methods'},
{ value: 'Journal of Financial Data Science', label: '(PMR) Journal of Financial Data Science'},
{ value: 'Journal of Investing', label: '(PMR) Journal of Investing'},
{ value: 'Journal of Portfolio Management', label: '(PMR) Journal of Portfolio Management'},
{ value: 'Journal of Computational Finance', label: '(Risk) Journal of Computational Finance'},
{ value: 'Journal of Investment Strategies', label: '(Risk) Journal of Investment Strategies'},
{ value: 'Journal of Risk', label: '(Risk) Journal of Risk'},
{ value: 'Applied Mathematical Finance', label: '(T & F) Applied Mathematical Finance'},
{ value: 'Journal of Behavioral Finance', label: '(T & F) Journal of Behavioral Finance'},
{ value: 'Quantitative Finance', label: '(T & F) Quantitative Finance'},
{ value: 'Journal of Financial and Quantitative Analysis', label: '(Cambridge) Journal of Financial and Quantitative Analysis'},
{ value: 'Econometrica', label: '(Wiley) Econometrica'},
{ value: 'Mathematical Finance', label: '(Wiley) Mathematical Finance'},
{ value: 'The Journal of Finance', label: '(Wiley) The Journal of Finance'},
];