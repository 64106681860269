import styled from 'styled-components/macro';

const FormWrapper = styled.div`
  position: relative;
  //overflow: hidden;

  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  //margin: 0px;

  align-items: center;
  justify-content: center;

  align-items: stretch;

  //margin: 0 auto;
  //margin: 0 auto;
  //border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  //max-width: ${props => (props.wide ? '600px' : '375px')};
  //padding: 8px;
  background-color: ${props => props.theme.foreground};

`;

export default FormWrapper;
