export const keywordFirmOptions = [
    { value: 'Culture', label: 'Culture'},
    { value: 'Diversity', label: 'Diversity'},
{ value: 'Investment', label: 'Investment'},
{ value: 'Philosophy', label: 'Philosophy'},
{ value: 'Performance', label: 'Performance'},
{ value: 'Office', label: 'Office'},
{ value: 'Location', label: 'Location'},
{ value: 'Community', label: 'Community'},
{ value: 'Retirement', label: 'Retirement'},
{ value: 'Perks', label: 'Perks'},
{ value: 'Benefits', label: 'Benefits'},
{ value: 'Retention', label: 'Retention'},
{ value: 'Career Path', label: 'Career Path'},
{ value: 'Work Life Balance', label: 'Work Life Balance'}
];