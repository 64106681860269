import React from 'react';
import styled from 'styled-components/macro';
//import { transition } from '../../helpers';
import UserWrapper from './Wrapper';

const StyledUser = styled.div`
  list-style: none;
  //border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  padding-bottom: 4px;
`;

const User = ({ className, wide, loading, ...props }) => {
  if (loading) {
    return (
      <UserWrapper className={className} wide={wide}>
        <StyledUser {...props} />
      </UserWrapper>
    )
  } else {
    return(
      <UserWrapper className={className} wide={wide}>
        <StyledUser {...props} />
      </UserWrapper>
    )
  }

};

export default User;
