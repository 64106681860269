import { connect } from 'react-redux';
import { compose } from 'redux';
import withAuth from '../../../util/withAuth';

import { attemptFetchPostsByUser, attemptClearPosts } from '../../../actions/posts';
import { searchRequest, pageRequest } from '../../../actions/searchrequest';

import ProfilePosts from './Component';

export const mapStateToProps = state => {
  return({
    isFetching: state.posts.isFetching,
    posts:      state.posts.items,
    prvposts:   state.posts.prvitems,
    postsearchvalues:   state.posts.postsearchfields,

    searchvalues: state.searchrequest.searchvalues,
    pagevalues:   state.searchrequest.pagevalues,
    
    pathname:     state.router.location.pathname,
  })
};

const mapDispatchToProps = { attemptFetchPostsByUser, attemptClearPosts,
                             searchRequest, pageRequest 
                            };

const ProfilePostsContainer = compose(

  withAuth,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProfilePosts);

export default ProfilePostsContainer;