import { connect } from 'react-redux';
import { compose } from 'redux';
import withAuth from '../../../util/withAuth';
import { attemptVotePost } from '../../../actions/posts';
import PostVote from './Component';

const mapDispatchToProps = { attemptVotePost };

const mapStateToProps = (state) => {
  return {
      posts:        state.posts.items
  }

}

const enhance = compose(
  withAuth,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

const PostVoteContainer = enhance(PostVote);

export default PostVoteContainer;
