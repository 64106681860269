import React from 'react';
import styled from 'styled-components/macro';
//import { transition } from '../../helpers';
import FormWrapper from './Wrapper';

const StyledForm = styled.form`

  display: flex;
  flex-grow: 1;
  flex-shrink: 1;

  margin: 0px;
    border-left: 0px solid ${props => props.theme.border};
    border-right: 0px solid ${props => props.theme.border};
  min-width: 0;
  z-index: 2;

  //flex-direction: 'column';
  //border-color: blue;

  ${props =>
    props.loading &&
    'filter: grayscale(0.5) blur(5px) opacity(0.6); pointer-events: none'};
`;

const SearchForm = ({ className, wide, ...props }) => (
  <FormWrapper className={className} wide={wide}>
    <StyledForm {...props} />
     {props.loading}
  </FormWrapper>
);

export default SearchForm;
