import styled from 'styled-components/macro';
import NavLink from '../../shared/NavLink';
import { link } from '../../shared/helpers';

const PostNavLinkNew = styled(NavLink)`
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  //white-space: nowrap;

  background-color: ${props => props.theme.foregroundNew};

  display: flex;
  flex: 1;
  //max-height: 75px;
  flex-direction: column;
  border-left: 1px solid ${props => props.theme.border};
  padding: 8px;
  min-width: 0;
  align-items: "center";

  outline: 0;
  
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }

  &:focus, &:hover {
    color: ${props => props.theme.accent};
  }
  
  & > * {
    margin-right: 4px;
  }

  & > a {
    ${link};
  }

  & > span {
    color: ${props => props.theme.normalText};
  }
`;

export default PostNavLinkNew;
