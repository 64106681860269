export const keywordDataOptions = [
{ value: 'Adjusted'   , label: 'Adjusted'}, 
{ value: 'Alternative'   , label: 'Alternative'},  
{ value: 'Analytics'   , label: 'Analytics'},  

{ value: 'Balance Sheet'   , label: 'Balance Sheet'}, 
{ value: 'Bulk Download', label: 'Bulk Download'},
 
{ value: 'Calendar'   , label: 'Calendar'}, 
{ value: 'Cash Flow Statement'   , label: 'Cash Flow Statement'},
{ value: 'Classification'    , label: 'Classification'},  
{ value: 'Credit'    , label: 'Credit'},  
{ value: 'Commodities'    , label: 'Commodities'},  
{ value: 'Company'   , label: 'Company'},  
{ value: 'Composite'   , label: 'Composite'},  
 
{ value: 'Currencies'   , label: 'Currencies'}, 

{ value: 'Debt', label: 'Debt'},
{ value: 'Delayed', label: 'Delayed'},
{ value: 'Dividends'   , label: 'Dividends'},

{ value: 'Earnings'   , label: 'Earnings'},
{ value: 'Economic', label: 'Economic'},
{ value: 'End of Day', label: 'End of Day'},
{ value: 'Estimates'   , label: 'Estimates'}, 
{ value: 'Events'   , label: 'Events'}, 
{ value: 'Exchange'       , label: 'Exchange'},  
{ value: 'Extended Hours' , label: 'Extended Hours'},  
 
{ value: 'Factors', label: 'Factors'},
{ value: 'Financials', label: 'Financials'},
{ value: 'Fundamentals', label: 'Fundamentals'},
{ value: 'Futures'     , label: 'Futures'}, 

{ value: 'Historical', label: 'Historical'},

  { value: 'Insider Transactions'   , label: 'Insider Transactions'},
  { value: 'Intraday', label: 'Intraday'},
  { value: 'Income Statement'   , label: 'Income Statement'}, 
  
  { value: 'Legal'   , label: 'Legal'},

  { value: 'Macroeconomics'   , label: 'Macroeconomics'}, 
  { value: 'Market Data', label: 'Market Data'},
  { value: 'Mergers and Acquisitions'   , label: 'Mergers and Acquisitions'}, 
  { value: 'Meta', label: 'Meta'},
  { value: 'Metrics', label: 'Metrics'},
  
  { value: 'News', label: 'News'},

  { value: 'OHLC'           , label: 'OHLC'},  
  { value: 'Options'     , label: 'Options'},  
  { value: 'Order Book'     , label: 'Order Book'}, 
  { value: 'Ownership'   , label: 'Ownership'}, 
  
  { value: 'Peers'   , label: 'Peers'},  
  
  { value: 'Quotes'   , label: 'Quotes'},  

  { value: 'Rates', label: 'Rates'},
  { value: 'Ratings', label: 'Ratings'},
  { value: 'Real Time', label: 'Real Time'},
  { value: 'Recommendations'   , label: 'Recommendations'}, 
  { value: 'Reference', label: 'Reference'},
  { value: 'Reports'   , label: 'Reports'}, 
  { value: 'REST Endpoint', label: 'REST Endpoint'},
  { value: 'Returns'   , label: 'Returns'}, 
  { value: 'Rights Issue'   , label: 'Rights Issue'}, 
 
  { value: 'Sentiment'      , label: 'Sentiment'}, 
  { value: 'Short Interest' , label: 'Short Interest'},  
  { value: 'Spinoffs'   , label: 'Spinoffs'}, 
  { value: 'Splits'   , label: 'Splits'}, 
  { value: 'Spread'         , label: 'Spread'}, 
  { value: 'Strategies'         , label: 'Strategies'},  
  { value: 'Supply Chain'         , label: 'Supply Chain'},  
  { value: 'Symbology', label: 'Symbology'},
   
  { value: 'Targets'   , label: 'Targets'},
  { value: 'TAQ'            , label: 'TAQ'},  
  { value: 'Technicals'   , label: 'Technicals'},  
  { value: 'Terminal', label: 'Terminal'},
 
  { value: 'Unadjusted', label: 'Unadjusted'},
  
  { value: 'Volume'         , label: 'Volume'}, 
  { value: 'Volatility'         , label: 'Volatility'}, 
  
  { value: 'Websockets', label: 'Websockets'},
];