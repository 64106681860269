import { LOGIN_SUCCESS, SIGNUP_SUCCESS, LOGOUT } from '../actions/auth';
import { CHANGE_ATTRIBUTE_SUCCESS, VERIFY_ATTRIBUTE_SUCCESS } from '../actions/auth';

export default () => next => action => {
  
  if (action.type === LOGIN_SUCCESS || action.type === SIGNUP_SUCCESS) {
    localStorage.setItem('token', action.token);
    localStorage.setItem('emailVerified', action.emailVerified);
  
  } else if (action.type === LOGOUT) {
    localStorage.removeItem('token');
    localStorage.removeItem('emailVerified');
  
  } else if (action.type === CHANGE_ATTRIBUTE_SUCCESS) {
    localStorage.removeItem('emailVerified');
  
  } else if (action.type === VERIFY_ATTRIBUTE_SUCCESS) {
    localStorage.setItem('emailVerified', action.emailVerified);
  
  }
  
  next(action);
};
