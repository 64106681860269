import React from 'react';
import { Field } from 'redux-form';

import renderAdvanced from '../../../shared/form/renderAdvanced';

class SelectBool2 extends React.Component {

    render() {

        return(
        <>
           <div style={{margin: '0%', float: 'right'}}> 
                <Field 
                    name='bool2'
                    type='radiogroup3'
                    component={renderAdvanced}
                    options={this.props.postAttach}
                />
            </div>
        </>
        );
    };
};

export default SelectBool2;