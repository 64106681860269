export const instrumentOptions = [
  { value: 'Bonds',        label: 'Bonds'},
  { value: 'Crypto',       label: 'Crypto'},
  { value: 'ETFs',         label: 'ETFs'},
  { value: 'Fowards',      label: 'Fowards'},
  { value: 'Funds',        label: 'Funds'},
  { value: 'Futures',      label: 'Futures'},
  { value: 'Options',      label: 'Options'},
  { value: 'Stocks',       label: 'Stocks'}, 
  { value: 'Swaps',        label: 'Swaps'}, 
];

