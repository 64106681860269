import Amplify from '@aws-amplify/core';
import Storage from '@aws-amplify/storage';
import awsconfig from './aws-exports';

// AWS
export function configureAmplify() {
    Amplify.configure({
        Auth: {
            region: awsconfig.aws_cognito_region,
            userPoolId: awsconfig.aws_user_pools_id,
            identityPoolId: awsconfig.aws_cognito_identity_pool_id,
            userPoolWebClientId: awsconfig.aws_user_pools_web_client_id
        },

        API: {
            endpoints: [
                {
                    name: awsconfig.aws_cloud_logic_custom.name,
                    endpoint: awsconfig.aws_cloud_logic_custom.endpoint,
                    region: awsconfig.aws_cloud_logic_custom.region
                }
            ]
        },

        Storage: {
            bucket: awsconfig.aws_user_files_s3_bucket,
            region: awsconfig.aws_user_files_s3_bucket_region,
            identityPoolId: awsconfig.aws_cognito_identity_pool_id
        }
    });
}

// Configure Storage with S3 bucket information
export function SetS3Config(level){
   Storage.configure({ 
          bucket: awsconfig.aws_user_files_s3_bucket,
          level: level,
          region: 'us-east-1',  
          identityPoolId: awsconfig.aws_user_pools_web_client_id
       });
}