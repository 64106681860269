import styled from 'styled-components/macro';
import { transition } from '../helpers';

import 'react-datepicker/dist/react-datepicker.css';
//import 'bootstrap/dist/css/bootstrap.min.css';

//https://github.com/Hacker0x01/react-datepicker/blob/master/src/stylesheets/datepicker.scss#L7

const DateWrapper = styled.div`

  ${transition('border', 'box-shadow')};

  --border: ${props => props.error ? props.theme.error : props.theme.accent};
  --shadow: ${props =>
    props.error ? props.theme.error + '4d' : props.theme.accent + '4d'};
 
  height: 33px;
  padding: 0px;
  margin: 0px;
  margin-bottom: 6px;

// EXTERNAL BOX
.react-datepicker-wrapper {
  display: flex;
  font-size: 1em;
  padding: 0px;
  margin: 0px;
}

// DAYS IN THE CALENDAR
.react-datepicker-wrapper,
.react-datepicker {
  font-size: 15px;
  background-color: ${props => props.theme.inputBackground};
  color: ${props => props.theme.inputBackground};
  border-radius: 0px;
  display: flex;
  position: relative;
  font-size: 15px;
}

.react-datepicker__header {
  font-size: 15px;
  background-color: ${props => props.theme.shadow};
  color: ${props => props.theme.normalText};
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    color: ${props => props.theme.normalText};
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: ${props => props.theme.normalText};
  font-size: 15px;
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected {
  background-color: ${props => props.theme.accent};
}

.react-datepicker__month {
  margin: 0.4em 1em;
}

.react-datepicker__navigation--previous {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent; 
  border-bottom: 10px solid transparent;
  border-right: 10px solid ${props => props.theme.normalText};
}

.react-datepicker__navigation--next {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent; 
  border-bottom: 10px solid transparent;
  border-left: 10px solid ${props => props.theme.normalText};
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: ${props => props.theme.normalText};
  font-size: 1.2em;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  color: ${props => props.theme.normalText};
  background-color: ${props => props.theme.inputBackground};

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {

  .react-datepicker__navigation--years-upcoming {
    border-bottom-color: ${props => props.theme.normalText};
  }

  .react-datepicker__navigation--years-previous {
    border-top-color: ${props => props.theme.normalText};
  }
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  &--down-arrow {
    @extend %triangle-arrow-down;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent; 
    border-right: 10px solid transparent;
    border-top: 10px solid ${props => props.theme.normalText};
  }
  
  .react-datepicker__year-read-view--selected-year {
    margin-left: 10px;
  }
}

.react-datepicker__input-container input {
  padding: 8px;
  display: flex;
  width: 100%;
  color: ${props => props.theme.normalText};
  height: 38px;
  font-size: 15px;
  border-radius: 2px;
  background-color: ${props => props.theme.inputBackground};

  ${props => props.error ? `
    border: 1px solid var(--border)
    ` : `
    border: 1px solid ${props.theme.border}
  `};

  :hover, :focus, :active {
    border: 1px solid var(--border);
  }
  
  outline-color: ${props => props.theme.accent}
}


.react-datepicker__triangle {
  position: relative;
  left: 50%;
}

`

export default DateWrapper;
