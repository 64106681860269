import React from "react";
//import cx from "classnames";

import styled from 'styled-components/macro';
import { withTheme, useTheme } from 'styled-components';

const OptionDIV = styled.div`
  color: ${props => props.isFocused ? props.theme.normalText : props.theme.mutedText};
  
  padding: 0px;
  margin: 0px;
  align-items: center;

  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;

  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: auto;
  
  background-color: ${props => props.isDisabled ? props.theme.activeBackground : props.isSelected ? props.theme.inputBackground : props.isFocused ? props.theme.inputBackground : props.theme.activeBackground };
  
  cursor: ${(props) => props.isDisabled ? 'not-allowed' : 'default'};
`;

const Option = ({
  children,
  innerProps,
  isSelected,
  isFocused,
  isDisabled
}) => {
  return(
    <OptionDIV
      //className={cx("react-select__option", {
      //  "react-select__option_selected": isSelected
      //})}
      id={innerProps.id}
      tabIndex={innerProps.tabIndex}
      onClick={innerProps.onClick}
      isSelected={isSelected}
      isFocused={isFocused}
      isDisabled={isDisabled}
      theme={useTheme()}
    >
      {children}
    </OptionDIV>
  );
  }

export default withTheme(Option);
