import React from 'react';
import styled from 'styled-components/macro';
import PostVoteContainer from './Vote/Container';
import PostContent from './Content';

const Wrapper = styled.div`
  display: flex;
  height: auto;
  background-color: ${props => props.theme.foreground};
  border: 1px solid transparent;
  
  :hover, :focus {
    border: 1px solid ${props => props.theme.accent};
  }  
`;

const Post = ({ token, history, pid, type, subtype, vote, score, comments, full, author, ...content }) => {
 
  return (
    <Wrapper>
      
      {(type !== 'deleted') && (subtype !== 'deleted') && (
        <PostVoteContainer 
          pid={pid} 
          vote={vote} 
          score={score} 
          author={author}
          history={history}
          {...content}
        />
      )}
    
      <PostContent
        token={token}
        history={history}
        showFullPost={full}
        pid={pid}
        type={type}
        subtype={subtype}
        commentCount={comments}
        author={author}
        {...content}
      />
    </Wrapper>
  );
}

export default Post;