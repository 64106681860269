import styled from 'styled-components/macro';
import { transition } from '../helpers';

const Input = styled.input`
  ${transition('border', 'box-shadow')};
  
  --border: ${props => props.error ? props.theme.error : props.theme.accent};
  //--shadow: ${props => props.error ? props.theme.error + '4d' : props.theme.accent + '4d'};
  
  display: flex;

  flex-direction: 'column';
  ${props => props.error ? `
    border: 1px solid var(--border)
    ` : `
    border: 1px solid ${props.theme.border}
  `};
  //border-radius: 2px;

  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;

  width: 100%;//width: auto;//200px;//${props => (props.wide ? '1000px' : '500px')}
  min-width: 0px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  background-color: ${props => props.theme.inputBackground};
  font-size: 16px;
  //height: 33px;
  color: ${props => props.theme.normalText};
  appearance: none;
  outline: none;
  resize: vertical;

  :hover, :focus {
    border: 1px solid var(--border);
  }
  
  :focus {
    box-shadow: 0 0 0 2px var(--shadow);
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.theme.normalText};
  }
  :-ms-input-placeholder {
     color: ${props => props.theme.normalText};
  }

`;

export default Input;
