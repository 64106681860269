import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, getFormValues, getFormInitialValues } from 'redux-form';

import { searchRequest, pageRequest } from '../../../actions/searchrequest';

import withAuth from '../../../util/withAuth';

import moment from "moment";

import searchBar from './Component';

const mapStateToProps = state => {

    if ( (getFormInitialValues('searchFields')(state) !== null) && (getFormInitialValues('searchFields')(state) !== undefined) ) {
		return ({
			form: getFormValues('searchFields')(state),
			iform: getFormInitialValues('searchFields')(state),
			initialValues: getFormInitialValues('searchFields')(state),
			pageInitialValues: { currentpage: 1, sortby: 'Best', typeby: 'Posted' },
			searchvalues: state.searchrequest.searchvalues,
			pagevalues:   state.searchrequest.pagevalues,
			pathname:     state.router.location.pathname,
		})
    } else {
		return ({
			form: getFormValues('searchFields')(state),
			iform: getFormInitialValues('searchFields')(state),
			initialValues: { pathname: '/', query: null,  table1: 'posts', table2: null, 
						formdates: {date1: null, date2: moment((new Date()).toDateString())},
						type: null,
						formsubtype: { subtype: null },
						formvalue1: { value1: null },
						formvalue2: { value2: null },
						formvalue3: { value3: null },
						formvalue4: { value4: null },
						formvalue5: { value5: null },
						formvalue6: { value6: null },
						array1: null, array2: null, 
						bool1: 'OR',  
						formbool2: { bool2: false },
						bool3: false,
						keywords: null, 
					},
			pageInitialValues: { currentpage: 1, sortby: 'Best', typeby: 'Posted' },
			searchvalues: state.searchrequest.searchvalues,
			pagevalues:   state.searchrequest.pagevalues,
			pathname:     state.router.location.pathname,
		})
	}

};

const mapDispatchToProps = { searchRequest, pageRequest };

const enhance = compose(
  reduxForm({
    form: 'searchFields',
    enableReinitialize: true,
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    //validate
  }),
  withAuth,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

const SearchBarContainer = enhance(searchBar);

export default SearchBarContainer; 
