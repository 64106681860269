const constants = {
  error: '#f5222d',
  vote: '#b6b6b6',
  upvote: '#f9920b',
  downvote: '#2e70ff',

  w1m1: '479px',
  w1:   '480px',
  w1p1: '481px',
  w2m1: '829px',
  w2:   '830px',
  w2p1: '831px',
  w2B:  '1100px',
  w2Bp1:'1101px',
  w3:   '2048px',

  
  w1date:   '640px',
  w1p1date: '641px',
};

const dark = {
  ...constants,
  normalText: '#ffffff',
  mutedText: '#b0b8bf',
  border: '#333333',
  accent: '#33a0ff',
  pageBackground: '#1b1b1b',
  voteButtonHover: '#383838',
  foreground: '#262626',
  foregroundNew: '#293e51',
  foregroundHover: '#2B5071',
  activeBackground: '#333333',
  inputBackground: '#212121',
  shadow: 'rgba(0, 0, 0, 0.4)',
  commentbar: '#b0b8bf',
  captcha: 'dark',
};

const light = {
  ...constants,
  normalText: '#454f5b',
  mutedText: '#818e99',
  border: '#ebedf0',
  accent: '#1890ff',
  pageBackground: '#f4f6f8',
  voteButtonHover: '#f2f2f2',
  foreground: '#ffffff',
  foregroundNew: '#c5e3ff',
  foregroundHover: '#A6D4FF',
  activeBackground: '#fafafa',
  inputBackground: '#fcfcfc',
  shadow: 'rgba(0, 0, 0, 0.05)',
  commentbar: '#454f5b',
  captcha: 'light',
};

const theme = isDark => (isDark ? dark : light);

export default theme;


// 868px
// 905px
// 906px
