import React from 'react';
import styled from 'styled-components/macro';
import { Route, Router, Switch } from 'react-router-dom';
import history from '../../util/history';
import HomeMainSection from './MainSection';
import {Helmet} from "react-helmet";

import loadable from '@loadable/component'

import ProfilePageContainer from '../Profile/Container'; // this causes the post list syntax to mess up

const CommentDetailContainer = loadable(() => import('../CommentDetail/Container'))
const SidebarContainer = loadable(() => import('../Sidebar/Container'))
const JournalMenuContainer = loadable(() => import('../JournalMenu/Container'))
const PostDetailContainer = loadable(() => import('../PostDetail/Container'))
const PostListContainer = loadable(() => import('../PostList/Container'))


const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 4vw;

  @media (max-width: ${props => props.theme.w3}) {
    margin: 0 2vw;
  }

  @media (max-width: ${props => props.theme.w2}) {
    display: block;
    margin: 0;
  }
`;

const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Home = (props) => {
  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>QuantifyAlpha | A Quantitative Finance Community</title>
        <meta name="Description" content="QuantifyAlpha is home to a collection of communities focusing on the field of finance. A forum to discuss quantitative trading, statistical methods, econometrics, programming, implementation, automated strategies, and bounce ideas off each other for constructive criticism. Feel free to submit papers/discussions/links of things you find interesting." />
        <meta name="Keywords" content="quantitative trading, statistical methods, econometrics, programming, implementation, automated strategies" />
        <link rel="canonical" href="http://quantifyalpha.com/" />
      </Helmet>
      <HomeMainSection>
        <Router history={history}>
          <>

            <Route component={JournalMenuContainer} />

            <Switch>
              
              <Route 
                exact 
                path="/s=:psortby" 
                render={({match}) => <PostListContainer
                psortby={Capitalize(match.params.psortby)} history={history}  pathname={props.path}/> } 
              />

              <Route 
                exact 
                path="/" 
                render={() => <PostListContainer psortby={null} history={history}  pathname={props.path}/> } 
              />

            <Route 
              exact 
              path="/search/s=:psortby" 
              render={({match}) => <PostListContainer 
              psortby={Capitalize(match.params.psortby)} history={history}  pathname={props.path}/> } 
            />

            <Route 
              exact 
              path="/search" 
              render={() => <PostListContainer psortby={null} history={history}  pathname={props.path}/> } 
            />
            
            <Route
              exact
              path='/q/:type/s=:psortby'
              render={({ match }) => (
                <PostListContainer type={match.params.type} psortby={Capitalize(match.params.psortby)} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/q/:type'
              render={({ match }) => (
                <PostListContainer type={match.params.type} psortby={null} history={history}  pathname={props.path}/>
              )}
            />  
           
            <Route
              exact
              path='/q/:type/:subtype/s=:psortby'
              render={({ match }) => (
                <PostListContainer type={match.params.type} subtype={match.params.subtype} psortby={Capitalize(match.params.psortby)} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/q/:type/:subtype'
              render={({ match }) => (
                <PostListContainer type={match.params.type} subtype={match.params.subtype} psortby={null} history={history}  pathname={props.path}/>
              )}
            />   

            <Route
              exact
              path='/c/:type/:subtype/:pid/:cid'
              render={({ match }) => (
                <CommentDetailContainer pid={match.params.pid} cid={match.params.cid} history={history}  pathname={props.path}/> 
              )}
            />   
            
            <Route
              exact
              path='/q/:type/:subtype/:pid'
              render={({ match }) => (
                <PostDetailContainer pid={match.params.pid} history={history} pathname={props.path}/>
              )}
            />
            
            <Route
              exact
              path='/u/:username/s=:psortby'
              render={({ match }) => (
                <ProfilePageContainer username={match.params.username} psortby={Capitalize(match.params.psortby)} history={history}  pathname={props.path} />
              )}
            />
            
            <Route
              exact
              path='/u/:username'
              render={({ match }) => (
                <ProfilePageContainer username={match.params.username} psortby={null} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/a/:author/s=:psortby'
              render={({ match }) => (
                <PostListContainer author={match.params.author} psortby={Capitalize(match.params.psortby)} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/a/:author/'
              render={({ match }) => (
                <PostListContainer author={match.params.author} psortby={null} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/f/:firm/s=:psortby'
              render={({ match }) => (
                <PostListContainer firm={match.params.firm} psortby={Capitalize(match.params.psortby)} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/f/:firm/'
              render={({ match }) => (
                <PostListContainer firm={match.params.firm} psortby={null} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/j/:journal/s=:psortby'
              render={({ match }) => (
                <PostListContainer journal={match.params.journal} psortby={Capitalize(match.params.psortby)} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/j/:journal/'
              render={({ match }) => (
                <PostListContainer journal={match.params.journal} psortby={null} history={history}  pathname={props.path} />
              )}
            />

            <Route
              exact
              path='/k/:keyword/s=:psortby'
              render={({ match }) => (
                <PostListContainer keyword={match.params.keyword} psortby={Capitalize(match.params.psortby)} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/k/:keyword/'
              render={({ match }) => (
                <PostListContainer keyword={match.params.keyword} psortby={null} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/p/:publisher/s=:psortby'
              render={({ match }) => (
                <PostListContainer publisher={match.params.publisher} psortby={Capitalize(match.params.psortby)} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/p/:publisher/'
              render={({ match }) => (
                <PostListContainer publisher={match.params.publisher} psortby={null} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/s/:school/s=:psortby'
              render={({ match }) => (
                <PostListContainer school={match.params.school} psortby={Capitalize(match.params.psortby)} history={history}  pathname={props.path}/>
              )}
            />

            <Route
              exact
              path='/s/:school/'
              render={({ match }) => (
                <PostListContainer school={match.params.school} psortby={null} history={history}  pathname={props.path}/>
              )}
            />

            </Switch>
          
            {/*
            
              a: a/:author
            d: d/data *** (ALL DATA)
            f: f/:firm
            f: f/:firm/data *** (DATA FROM FIRM)
            i: i/interviews *** (ALL INTERVIEWS)
              j: j/:journal
              k: j/:keyword
            p: p/:publisher
            s: s/:school
            f: f/:firm/interviews *** (INTERVIEWS FROM FIRM)

            c: c/careers
            e: e/events
            a: r/alpha

            */}

          </>

        </Router>

      </HomeMainSection>

      <Route path="/" render={({ match }) => (<SidebarContainer/>)}/>
      
    </Wrapper>
  );
};

export default Home;