import './style.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import AppContainer from './components/App/Container';
import * as serviceWorker from './serviceWorker';

import history from './util/history'
import { ConnectedRouter } from 'connected-react-router'

// AWS
import { configureAmplify } from "./aws-services";
configureAmplify();

ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppContainer/>    
      </ConnectedRouter>
    </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
