export const indTypeOptions = [
  { value: 'i1', label: 'i1'},
  { value: 'i2', label: 'i2' },
  { value: 'i3', label: 'i3' },
  { value: 'i4', label: 'i4' },
  { value: 'i5', label: 'i5' },
  { value: 'i6', label: 'i6' },
  { value: 'i7', label: 'i7' },
];

