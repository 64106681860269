import styled from 'styled-components/macro';

const InputWrapper = styled.div`
  position: relative;

  flex-direction: 'column';
  //border-color: red;

  display: 'flex';
  //flex-grow: 1;
  //flex-shrink: 1;
  font-size: '15px';
  float: 'left';
  height: '38px';
  //resize: vertical;
  
  width: 79px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
`;

export default InputWrapper;
