import styled from 'styled-components/macro';

const FormWrapper = styled.div`
  position: relative;

  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  z-index: 2;

  //margin: 0px;

  align-items: center;
  justify-content: center;

  align-items: stretch;

  margin: 0 auto;
  
  //border: 1px solid red;//${props => props.theme.border};
  border-radius: 0px;

  background-color: ${props => props.theme.foreground};
  
  @media (max-width: 868px) {
    position: sticky;
    //top: 0;
    display: flex;
    align-items: stretch;
    margin-bottom: 0px;
    //box-shadow: 0 4px 12px ${props => props.theme.shadow};
    border-bottom: 1px solid ${props => props.theme.border};
    height: auto;
    z-index: 2;

    padding: 0px;
    background-color: ${props => props.theme.foreground};
    user-select: none;

    z-index:  null;
  }
`;

export default FormWrapper;
