import styled from 'styled-components/macro';
import PostVoteButton from './Button';

const PostVoteUpvote = styled(PostVoteButton)`
  --iconColor: white;//${props => props.didVote ? props.theme.upvote : props.theme.vote};

  ::after {
    border-top: 2px solid var(--iconColor);
    border-right: 2px solid var(--iconColor);
    top: 1.5px;
    radius: 0px;
    outline: none;
  }
`;

export default PostVoteUpvote;
