export const experienceOptions = [
  { value: 'No Preference',         label: 'No Preference'}, 
  { value: 'Bachelor',    label: 'Bachelor'},  
  { value: 'Master',      label: 'Master'},  
  { value: 'MBA',         label: 'MBA'},  
  { value: 'Graduate',    label: 'Graduate'},    
  { value: 'Experience1', label: 'Experience < 3'},   
  { value: 'Experience2', label: 'Experience < 7'},    
  { value: 'Experience3', label: 'Experience > 7'},    
];

