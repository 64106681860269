import React from 'react';
import { Field } from 'redux-form';
import Media from 'react-media';

import renderAdvanced from '../../../shared/form/renderAdvanced';

class SelectDates extends React.Component {

    render() {
        return(
            <>
                {(this.props.version === "two") && (
                    <>
                        <Media queries={{ large: "(min-width: "+this.props.theme.w1p1date+")" }}>
                            <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginBottom: '8px', marginRight: '100px'}}> 
                                <div style={{flex: '1', flexGrow: '1', flexShrink: '1', marginRight: '8px'}}> 
                                    <Field 
                                    name='date1'
                                    type='startcreatedate'
                                    placeholder='Created Before'
                                    placement="top"
                                    date={this.props.date1}
                                    date2={this.props.date2}
                                    handleStartCreateDateChange={this.props.handleStartCreateDateChange.bind(this)}
                                    component={renderAdvanced}
                                    />
                                </div>
                                <div style={{flex: '1', flexGrow: '1', flexShrink: '1', }}> 
                                    <Field 
                                    name='date2'
                                    type='endcreatedate'
                                    placeholder='Created After'
                                    placement="top"
                                    date1={this.props.date1}
                                    date={this.props.date2}
                                    handleEndCreateDateChange={this.props.handleEndCreateDateChange.bind(this)}
                                    component={renderAdvanced}
                                    />
                                </div>
                            </div> 
                        </Media>   
                        <Media queries={{ large: "(max-width: "+this.props.theme.w1date+")" }}>
                            <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginBottom: '8px', marginRight: '100px'}}> 
                                <div style={{flex: '1', flexGrow: '1', flexShrink: '1', marginRight: '8px'}}> 
                                    <Field 
                                    name='date1'
                                    type='startcreatedate'
                                    placeholder='Created Before'
                                    placement="top-start"
                                    date={this.props.date1}
                                    date2={this.props.date2}
                                    handleStartCreateDateChange={this.props.handleStartCreateDateChange.bind(this)}
                                    component={renderAdvanced}
                                    />
                                </div>
                                <div style={{flex: '1', flexGrow: '1', flexShrink: '1', }}> 
                                    <Field 
                                    name='date2'
                                    type='endcreatedate'
                                    placeholder='Created After'
                                    placement="top"
                                    date1={this.props.date1}
                                    date={this.props.date2}
                                    handleEndCreateDateChange={this.props.handleEndCreateDateChange.bind(this)}
                                    component={renderAdvanced}
                                    />
                                </div>
                            </div> 
                        </Media>  
                    </>          
                )}

            {(this.props.version === "one") && (
                <div style={{flex: '1', flexGrow: '1', flexShrink: '1', }}> 
                    <Field 
                    name='date1'
                    type='startcreatedate'
                    placeholder='Created Before'
                    placement="top"
                    date={this.props.date1}
                    date2={this.props.date2}
                    handleStartCreateDateChange={this.props.handleStartCreateDateChange.bind(this)}
                    component={renderAdvanced}
                    />
                </div> 
            )}
            </>
        );
    };
};

export default SelectDates;