import {
  CHANGE_ATTRIBUTE_ERROR,
  CREATE_USER_ERROR,
  VERIFY_ATTRIBUTE_ERROR,
  RESEND_CONFIRM_ERROR
} from '../actions/auth';

import {
  FETCH_POSTS_ERROR,
  FETCH_POST_ERROR,
  CREATE_POST_ERROR,
  DELETE_POST_ERROR,
  VOTE_POST_ERROR
} from '../actions/posts';

import {
  FETCH_COMMENTS_ERROR,
  CREATE_COMMENT_ERROR,
  VOTE_COMMENT_ERROR
} from '../actions/comments';

//import {
//  FETCH_PROFILE_ERROR
//} from '../actions/profiles';

import { LOGIN_ERROR, SIGNUP_ERROR } from '../actions/auth';

import { HIDE_ERROR } from '../actions/error';

const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POSTS_ERROR:
    case FETCH_POST_ERROR:
    case CREATE_POST_ERROR:
    case DELETE_POST_ERROR:
    case VOTE_POST_ERROR:
    case FETCH_COMMENTS_ERROR:
    case CREATE_COMMENT_ERROR:
    case VOTE_COMMENT_ERROR:
    case CHANGE_ATTRIBUTE_ERROR:
    case VERIFY_ATTRIBUTE_ERROR:
    case CREATE_USER_ERROR:
    case RESEND_CONFIRM_ERROR:
    case LOGIN_ERROR:
    case SIGNUP_ERROR:
      return action.error;
    case HIDE_ERROR:
      return null;
    default:
      return state;
  }
};
