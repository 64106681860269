import styled from 'styled-components/macro';
import { link,transition } from '../../shared/helpers';
import { Link } from 'react-router-dom';

const PostNavLink = styled(Link)` 
  ${link};
  
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;

  display: flex;
  flex: 1;
  //max-height: 75px;
  flex-direction: column;
  border-left: 1px solid ${props => props.theme.border};
  padding: 8px;
  min-width: 0;
  align-items: "center";
  
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
  
  & > * {
    margin-right: 4px;
  }

  & > span {
    color: ${props => props.theme.normalText};
  }

  ::after {
    ${transition('opacity')};
    
    content: '';
    position: absolute;
    opacity: 0;
  }
  
  &.active {
    ::after {
      opacity: 1;
    }
  }
`;

export default PostNavLink;
