import React from 'react';
import styled from 'styled-components/macro';

import './styles.css';

/*
const LinkIcon = () => {
  return(
<Span>
     <svg width="100px" height="24px" xmlns="http://www.w3.org/2000/svg"> 
      <path class="text-red-600 group-hover:text-red-900 change-color-fast print:text-red-900 fill-current" d="M19.48 13.03A4 4 0 0 1 16 19h-4a4 4 0 1 1 0-8h1a1 1 0 0 0 0-2h-1a6 6 0 1 0 0 12h4a6 6 0 0 0 5.21-8.98L21.2 12a1 1 0 1 0-1.72 1.03zM4.52 10.97A4 4 0 0 1 8 5h4a4 4 0 1 1 0 8h-1a1 1 0 0 0 0 2h1a6 6 0 1 0 0-12H8a6 6 0 0 0-5.21 8.98l.01.02a1 1 0 1 0 1.72-1.03z"/>
     </svg>
</Span>
  )
}
*/

const TwitterIcon = () => {
  return(
<Span>
  <svg width="24" height="24"  viewBox="0 0 34 34">
    <path class="text-red-600 group-hover:text-red-900 change-color-fast print:text-red-900 fill-current"  d="M2 4 C6 8 10 12 15 11 A6 6 0 0 1 22 4 A6 6 0 0 1 26 6 A8 8 0 0 0 31 4 A8 8 0 0 1 28 8 A8 8 0 0 0 32 7 A8 8 0 0 1 28 11 A18 18 0 0 1 10 30 A18 18 0 0 1 0 27 A12 12 0 0 0 8 24 A8 8 0 0 1 3 20 A8 8 0 0 0 6 19.5 A8 8 0 0 1 0 12 A8 8 0 0 0 3 13 A8 8 0 0 1 2 4"></path>
  </svg>
</Span>
  )
}

const LinkedInIcon = () => {
  return(
<Span>
  <svg width="24px" height="24px"  viewBox="0 0 16 16">
  <path class="text-red-600 group-hover:text-red-900 change-color-fast print:text-red-900 fill-current" d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
  </svg>
</Span>
  )
}

const GitHubIcon = () => {
  return(
<Span>
  <svg width="24px" height="24px"  viewBox="0 0 16 16">
  <path class="text-red-600 group-hover:text-red-900 change-color-fast print:text-red-900 fill-current" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
  </svg>
</Span>
  )
}

const HomeIcon = () => {
  return(
<Span>
  <svg width="24px" height="24px"  viewBox="0 0 16 16">
    <path class="text-red-600 group-hover:text-red-900 change-color-fast print:text-red-900 fill-current" d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
  </svg>
</Span>
  )
}

const EmailIcon = () => {
  return(
<Span>
     <svg width="100px" height="24px" xmlns="http://www.w3.org/2000/svg">
      <path class="text-red-600 group-hover:text-red-900 change-color-fast print:text-red-900 fill-current" d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z"/>
     </svg>
    </Span>
  )
}

const Username = styled.div`
  display: flex;
  font-size: 25px;
  color: ${props => props.theme.mutedText};
`;


const Span = styled.span`
  padding-top: 4px;
  width: 30px;
  text-align: center;
  items-align: center;
  color: ${props => props.theme.accent};
`

const LinkSpan = styled.a`
  display: table-cell;

  display: flex;
  font-size: 15px;
  outline: 0;

  background-color: ${props => props.theme.foreground};
  color: ${props => props.theme.normalText}

  height: 100%;

  padding-top: 4px;
  margin: 0px;

  text-align: center;
  items-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;

  &:focus, &:hover {
    color: ${props => props.theme.accent};
  }
`;


const EmailSpan = styled.div`
  display: flex;
  font-size: 15px;
  outline: 0;

  background-color: ${props => props.theme.foreground};
  color: ${props => props.theme.normalText}

  height: 100%;

  padding-top: 4px;
  margin: 0px;

  text-align: center;
  items-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
`;

const AboutMe = styled.div`
  display: flex;
  margin-top: 10px; // mb-9

  font-size: 15px; // text-2xl
  color: ${props => props.theme.mutedText};
`;


class Page extends React.Component {
  
  render() {
    return (
      <>
        <Username>{this.props.username}</Username> {/* TWITTER (HANDLE 1, HANDLE 2, ...), KARMA (POST, COMMENT, VOTE, CONTRIBUTOR, ...), MEMBER SINCE, FOLLOWERS, FOLLOWING */}
 
        {/* EMAIL */}
        {(this.props.email!=="") && (this.props.email!==null) && (this.props.bool1) && (
          <div class="flex" style={{height:'32px', margin: '0px', padding: '0px', marginLeft: '10px', marginBottom: '0px', }}>
          <EmailIcon/> 
            <EmailSpan>{this.props.email}</EmailSpan> 
          </div> 
        )}

        {/* TWITTER (HANDLE 1)*/}
        {(this.props.var1!=="") && (this.props.var1!==null) && (
        <a href={this.props.var1} class="flex" style={{height:'32px', margin: '0px', padding: '0px', marginLeft: '10px', marginBottom: '0px'}}>
          <HomeIcon/> 
          <LinkSpan href={this.props.var1} target="_blank"> 
            {this.props.var1}
          </LinkSpan> 
        </a>
        )}

        {/* LINKEDIN */}
        {(this.props.var2!=="") && (this.props.var2!==null) && (
        <a href={this.props.var2} class="flex" style={{height:'32px', margin: '0px', padding: '0px', marginLeft: '10px', marginBottom: '0px'}}>
          <LinkedInIcon/> 
          <LinkSpan href={this.props.var2} target="_blank"> 
            {this.props.var2}
          </LinkSpan> 
        </a>
        )}

        {/* GITHUB */}
        {(this.props.var3!=="") && (this.props.var3!==null) && (
        <a href={this.props.var3} class="flex" style={{height:'32px', margin: '0px', padding: '0px', marginLeft: '10px', marginBottom: '0px', }}>
          <GitHubIcon/> 
          <LinkSpan href={this.props.var3} target="_blank">  
            {this.props.var3}
          </LinkSpan> 
        </a>
        )}

        {/* TWITTER */}
        {(this.props.var4!=="") && (this.props.var4!==null) && (
        <a href={this.props.var4} class="flex" style={{height:'32px', margin: '0px', padding: '0px', marginLeft: '10px', marginBottom: '0px'}}>
          <TwitterIcon/> 
          <LinkSpan href={this.props.var4} target="_blank"> 
            {this.props.var4}
          </LinkSpan> 
        </a>
        )}

        <AboutMe> {this.props.var0} </AboutMe>

      </>
    );
  }
}

export default Page;