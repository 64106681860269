import jwtDecode from 'jwt-decode';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,

  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,

  LOGOUT,

  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,

  CHANGE_ATTRIBUTE_REQUEST,
  CHANGE_ATTRIBUTE_SUCCESS,
  CHANGE_ATTRIBUTE_ERROR,

  VERIFY_ATTRIBUTE_REQUEST,
  VERIFY_ATTRIBUTE_SUCCESS,
  VERIFY_ATTRIBUTE_ERROR,

  RESEND_CONFIRM_REQUEST,
  RESEND_CONFIRM_SUCCESS,
  RESEND_CONFIRM_ERROR,

  RESEND_SIGNUP_REQUEST,
  RESEND_SIGNUP_SUCCESS,
  RESEND_SIGNUP_ERROR,

  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,

  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,

  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,

  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,

  CONFIRM_SIGNUP_REQUEST,
  CONFIRM_SIGNUP_SUCCESS,
  CONFIRM_SIGNUP_ERROR,

  CLEAR_SIGNUP_FORM,
  CLEAR_VERIFY_SIGNUP_FORM,
  CLEAR_PROFILE_FORM,
  CLEAR_LOGIN_FORM,

} from '../actions/auth';

const token         = localStorage.getItem('token');
const emailVerified = localStorage.getItem('emailVerified');
const user          = token && jwtDecode(token);

const initialState = {
  ...(token && { token }),
  ...(emailVerified && { emailVerified }),
  ...(user && { user })
};

export default (state = initialState, action) => {

  switch (action.type) {
    case SIGNUP_REQUEST:
    case LOGIN_REQUEST:
      return { ...state, loading: true };
    case LOGIN_SUCCESS:
      return { ...state, loading: false, token: action.token, emailVerified: action.emailVerified, user: jwtDecode(action.token) };
    
    case SIGNUP_SUCCESS:
      return { ...state, loading: false, token: action.token, emailVerified: action.emailVerified, user: jwtDecode(action.token), verifiedUser: true };
    case SIGNUP_ERROR:
    case LOGIN_ERROR:
      return { ...state, loading: false, userError: action.error };

    case LOGOUT:
      return { ...state, token: null, emailVerified: null, user: null };

    case CREATE_USER_REQUEST:
      return { ...state, createUser: false };
    case CREATE_USER_SUCCESS:
      return { ...state, createUser: true  };
    case CREATE_USER_ERROR:
      return { ...state, createUser: false };

    case CHANGE_ATTRIBUTE_REQUEST:
      return { ...state, codeSent: false };
    case CHANGE_ATTRIBUTE_SUCCESS:
      return { ...state, codeSent: true, emailVerified: null };
    case CHANGE_ATTRIBUTE_ERROR:
      return { ...state, codeSent: false };

    case VERIFY_ATTRIBUTE_REQUEST:
      return { ...state, codeVerified: false };
    case VERIFY_ATTRIBUTE_SUCCESS:
      return { ...state, codeVerified: true, emailVerified: action.emailVerified };
    case VERIFY_ATTRIBUTE_ERROR:
      return { ...state, codeVerified: false };

    case RESEND_CONFIRM_REQUEST:
      return { ...state, codeSent: false  };
    case RESEND_CONFIRM_SUCCESS:
      return { ...state, codeSent: true  };
    case RESEND_CONFIRM_ERROR:
      return { ...state, codeSent: false };

    case RESEND_SIGNUP_REQUEST:
      return { ...state, codeSent: false  };
    case RESEND_SIGNUP_SUCCESS:
      return { ...state, codeSent: true  };
    case RESEND_SIGNUP_ERROR:
      return { ...state, codeSent: false };

    case CHANGE_PASSWORD_REQUEST:
      return { ...state, passwordChanged: false  };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, passwordChanged: true  };
    case CHANGE_PASSWORD_ERROR:
      return { ...state, passwordChanged: false };
      
    case CONFIRM_SIGNUP_REQUEST:
      return { ...state, signupConfirmed: false  };
    case CONFIRM_SIGNUP_SUCCESS:
      return { ...state, signupConfirmed: true  };
    case CONFIRM_SIGNUP_ERROR:
      return { ...state, signupConfirmed: false };

    case DELETE_USER_REQUEST:
      return { ...state, userDeleted: false  };
    case DELETE_USER_SUCCESS:
      return { ...state, token: null, emailVerified: null, user: null };
    case DELETE_USER_ERROR:
      return { ...state, userDeleted: false };

    case FORGOT_PASSWORD_REQUEST:
      return { ...state, codeSent: false  };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, codeSent: true  };
    case FORGOT_PASSWORD_ERROR:
      return { ...state, codeSent: false };

    case RESET_PASSWORD_REQUEST:
      return { ...state, passwordReseted: false  };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, passwordReseted: true  };
    case RESET_PASSWORD_ERROR:
      return { ...state, passwordReseted: false };

    case CLEAR_SIGNUP_FORM:
      return { ...state, loading: undefined, createUser: undefined, verifiedUser: undefined };

    case CLEAR_VERIFY_SIGNUP_FORM:
      return { ...state, codeSent: undefined };

    case CLEAR_PROFILE_FORM:
      return { ...state, codeVerified: undefined, codeSent: undefined };

    case CLEAR_LOGIN_FORM:
      return { ...state, userError:  undefined };

    default:
      return state;
  }
};
