import {

  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,

  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_ERROR,

  FORGOT_USERNAME_REQUEST,
  FORGOT_USERNAME_SUCCESS,
  FORGOT_USERNAME_ERROR,

  CLEAR_PROFILE,

} from '../actions/profiles';

const initialState = { items: [] };

export default (state = initialState, action) => {

  switch (action.type) {

    case UPDATE_PROFILE_REQUEST:
      return { ...state, isUpdatingProfile: true,  updatedProfile: false };
    case UPDATE_PROFILE_SUCCESS:
      return { ...state, isUpdatingProfile: false, updatedProfile: true, };
    case UPDATE_PROFILE_ERROR:
      return { ...state, isUpdatingProfile: false, updatedProfile: false, error: action.error };

    case FETCH_PROFILE_REQUEST:
      return { ...state, isFetchingProfile: true };
    case FETCH_PROFILE_SUCCESS:
      return { ...state, isFetchingProfile: false, fetchProfile: action.profile };
    case FETCH_PROFILE_ERROR:
      return { ...state, isFetchingProfile: false, errorUnknownProfile: action.error };

    case FORGOT_USERNAME_REQUEST:
      return { ...state, usernameSent: false };
    case FORGOT_USERNAME_SUCCESS:
      return { ...state, usernameSent: true  };
    case FORGOT_USERNAME_ERROR:
      return { ...state, usernameSent: false };

    case CLEAR_PROFILE:
      return { ...state, updatedProfile: undefined };

    default:
      return state;
  }
};
