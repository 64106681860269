import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { headerItem } from '../shared/helpers';

//import moment from "moment";

const Logo = styled(Link)`
  ${headerItem};
   
  padding-top:3px;
  padding-bottom:0px;
  height:100%;
  font-family: 'Cinzel';
  font-display: swap;

  border-color:red;
  align-item: left;

  margin-right: auto;
  font-size: 32px;
  font-weight: 500;
  color: ${props => props.theme.normalText};
  
  
  @media (max-width: ${props => props.theme.w1}) {
    padding: 10px 8px 0 16px;
    font-size: 24px;
    margin-right: 0px;  
  }

  :hover {
    color: ${props => props.theme.accent};
    text-decoration: none;
  }

  outline: 0 !important;
`;

const Alpha = styled.h1`
  font-size: 56px;
  font-weight: thin;
  margin-top: -19px;
  margin-bottom: 0px;
  margin-left: 2px;
  //font-style: italic;
  font-family: 'EB Garamond';
  font-display: swap;
  color: ${props => props.theme.accent};

  -webkit-transform: scale(1.25,1);
  -moz-transform: scale(1.25,1);
  -ms-transform: scale(1.25,1);
  -o-transform: scale(1.25,1);
  transform: scale(1.25,1);

  @media (max-width: ${props => props.theme.w1}) {
    font-size: 48px;
    margin-top: -20px;
  }
`

const HeaderLogo = ({reset, pagereset}) => {

  return (
   <Logo to='/' onClick={() => {reset('reset'); pagereset('reset')}}> 
    {/*QuantifyAlpha*/}
    Quantify <Alpha>&#945;</Alpha>
   </Logo>
 );
}

export default HeaderLogo;
