import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';

const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080/api'
    : `https://${window.location.hostname}/api`;

const methods = {

  // GET ----
  get: async function (endpoint, token = null) {
    const options = {
      method: 'GET',
      headers: {
        'Cache-Control': 'no-cache',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    };

    const response = await fetch(`${baseUrl}/${endpoint}`, options);
    const json = await response.json();

    if (!response.ok) throw Error(json.message);

    return json;
  },

  // POST ----
  post: async function (endpoint, body, token = null) {

    if (body.authors) {
      body.authors.map(function(author, index){
         body.authors[index] = {...body.authors[index], name: (author.firstName + author.lastName).toLowerCase()}
      })
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        ...(token && { Authorization: `Bearer ${token}` })
      },
      body: JSON.stringify(body)
    };

    const response = await fetch(`${baseUrl}/${endpoint}`, options);
    const json = await response.json();

    if (!response.ok) {
      if (response.status === 422) {
        json.errors.forEach(error => {
          throw Error(`${error.param} ${error.msg}`);
        });
      }

      throw Error(json.message);
    }

    return json;
  },

  // DELETE ----
  delete: async function (endpoint, token = null) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        ...(token && { Authorization: `Bearer ${token}` })
      }
    };

    const response = await fetch(`${baseUrl}/${endpoint}`, options);
    const json = await response.json();

    if (!response.ok) {
      if (response.status === 401) throw Error('unauthorized');
      throw Error(json.message);
    }

    return json;
  }
};

// SEARCH FUNCTIONS 
export async function fetchPosts (searchfields, currentpage, numpids, sortby, token) {
  const body = {searchfields: searchfields, currentpage: currentpage, numpids: numpids, sortby: sortby}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };

  try {
    await Auth.currentAuthenticatedUser();
    return await API.post('quantifyalpha8restapipost', '/fetchposts', myinit);
  } catch (e) {
    return await API.post('quantifyalpha8restapipublic', '/fetchposts', myinit);
  }
}

// SEARCH FUNCTIONS 
export async function fetchMorePosts (searchfields, currentpage, numpids, sortby, token) {
  const body = {searchfields: searchfields, currentpage: currentpage, numpids: numpids, sortby: sortby}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };

  try {
    await Auth.currentAuthenticatedUser();
    return await API.post('quantifyalpha8restapipost', '/fetchposts', myinit);
  } catch (e) {
    return await API.post('quantifyalpha8restapipublic', '/fetchposts', myinit);
  }
}

export async function fetchPostsByUser (searchfields, username, currentpage, numpids, sortby, typeby, token) {
  const body = { searchfields: searchfields, username: username, currentpage: currentpage, numpids: numpids, sortby: sortby, typeby: typeby } 

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };

  try {
    await Auth.currentAuthenticatedUser();
    return await API.post('quantifyalpha8restapipost', '/fetchpostsbyuser', myinit);
  } catch (e) {
    return await API.post('quantifyalpha8restapipublic', '/fetchpostsbyuser', myinit);
  }
}

// PROFILE FUNCTIONS
export async function createProfile (username, email) {
  const body = { username: username, email: email }
  
  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };

  return await API.post('quantifyalpha8restapiprofile', '/createprofile', myinit);
}

export async function checkProfile (username, email) {
  const body = { username: username, email: email }

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };

  return await API.post('quantifyalpha8restapiprofile', '/checkprofile', myinit);
}

export async function checkUser (username, email) {   
  const body = { username: username, email: email }

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };

  return await API.post('quantifyalpha8restapipublic', '/checkuser', myinit);
}

export async function fetchProfile (username, token) {                           
  const body = {username: username}
  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };

  try {
    await Auth.currentAuthenticatedUser();
    return await API.post('quantifyalpha8restapiprofile', '/fetchprofile', myinit);
  } catch (e) {
    return await API.post('quantifyalpha8restapipublic', '/fetchprofile', myinit);
  }
}

export async function updateProfile (props) {
  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: props
  };
  
  return await API.post('quantifyalpha8restapiprofile', '/updateprofile', myinit);
}

export async function forgotUsername (email) {
  const props = { email: email };

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: props
  };
  
  return await API.post('quantifyalpha8restapipublic', '/forgotusername', myinit);
}

// SAVE(D) FUNCTIONS
export async function savedPost (pid) {
  const body = {pid: pid}
  
  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapipost', '/savedpost', myinit);
}

export async function savePost (pid) {
  const body = {pid: pid}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapipost', '/savepost', myinit);
}

export async function unsavePost (pid) {
  const body = {pid: pid}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapipost', '/unsavepost', myinit);
}

export async function savedComment (pid, cid) {
  const body = {pid: pid, cid: cid}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapicomment', '/savedcomment', myinit);
}

export async function saveComment (pid, cid) {
  const body = {pid: pid, cid: cid}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapicomment', '/savecomment', myinit);
}

export async function unsaveComment (pid, cid) {
  const body = {pid: pid, cid: cid}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapicomment', '/unsavecomment', myinit);
}

// POST FUNCTIONS
export async function createPost (body, token) {
  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };

  return await API.post('quantifyalpha8restapipost', '/createpost', myinit);
}

export async function fetchPost (pid, token) {
  const body = {pid: pid}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };

  try {
    await Auth.currentAuthenticatedUser();
    return await API.post('quantifyalpha8restapipost', '/fetchpost', myinit);
  } catch (e) {
    return await API.post('quantifyalpha8restapipublic', '/fetchpost', myinit);
  }
}

export async function votePost (pid, vote) {
  //const voteTypes = {
  //  '1': 'upvote',
  //  '0': 'unvote',
  //  '-1': 'downvote'
  //};

  const body = { pid: pid, vote: vote };

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapipost', '/votepost', myinit); // NO TOKEN REQUIRED
}

export async function viewPost () {
  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: null
  };
  
  return await API.post('quantifyalpha8restapipost', '/viewpost', myinit);
}

export async function getkeyPost (pid) {
  const body = {pid: pid}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapipost', '/getkeypost', myinit);
}

export async function setkeyPost (pid, keys) {
  const body = { pid: pid, keywords: keys };

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapipost', '/setkeypost', myinit); // NO TOKEN REQUIRED
}

export async function deletePost (pid) {
  const body = { pid: pid }

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapipost', '/deletepost', myinit);
}

export async function reportPost (pid) {
  const body = { pid: pid }

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapipost', '/reportpost', myinit); // NO TOKEN REQUIRED
}

// COMMENT FUNCTIONS
export async function fetchComments ( pid, token ) {
  const body = { pid: pid }

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  try {
    await Auth.currentAuthenticatedUser();
    return await API.post('quantifyalpha8restapicomment', '/fetchcomments', myinit);
  } catch (e) {
    return await API.post('quantifyalpha8restapipublic', '/fetchcomments', myinit);
  }
}

export async function fetchComment ( pid, cid, token ) {
  const body = { pid: pid, cid: cid }

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };

  try {
    await Auth.currentAuthenticatedUser();
    return await API.post('quantifyalpha8restapicomment', '/fetchcomment', myinit);
  } catch (e) {
    return await API.post('quantifyalpha8restapipublic', '/fetchcomment', myinit);
  }
}

export async function createComment (body) {

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapicomment', '/createcomment', myinit);
}

export async function createReply (body) {
  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapicomment', '/createreply', myinit);
}

export async function deleteComment (pid, cid) {
  const body = { pid: pid, cid: cid}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapicomment', '/deletecomment', myinit);
}

export async function reportComment (pid, cid) {
  const body = { pid: pid, cid: cid}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapicomment', '/reportcomment', myinit);
}

export async function voteComment (pid, cid, vote) {
  //const voteTypes = {
  //  '1': 'upvote',
  //  '0': 'unvote',
  //  '-1': 'downvote'
  //};

  const body = { pid: pid, cid: cid, vote: vote}

  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  };
  
  return await API.post('quantifyalpha8restapicomment', '/votecomment', myinit);
}


// EMAIL FUNCTIONS
export async function sendContact (props) {

  if ((props.subtype === 'keyword') && (props.type === 'request')) {
    props.subject = 'keyword'
  }
  if ((props.subtype === 'school') && (props.type === 'request')) {
    props.subject = 'school'
  }
  if ((props.subtype === 'firm') && (props.type === 'request')) {
    props.subject = 'firm'
  } 
  if ((props.type === 'bug')) {
    props.subject = 'bug'
  }
  
  const myinit = {
    headers: {
      'Content-Type': 'application/json'
    },
    body: props
  };

  return await API.post('quantifyalpha8restapiprofile', '/sendcontact', myinit);
}

export async function sendEmailConfirm (props, token) {
  return await methods.post('confirmemail/', props, token);
}

export async function emailConfirm (username, emailid, token) {
  const body = {username: username, emailid: emailid}
  return await methods.post('emailconfirmed/', body, token);
}

export async function sendEmailReset (props, token) {
  return await methods.post(`profile/resetpassword`, props, token);
}

export async function emailConfirmReset (props, token) {
  return await methods.post(`profile/confirmingreset`, props, token);
}

// DELETE PROFILE
export async function deleteProfile (user, token) {
  return await methods.post('profile/deactivate', user, token);
}