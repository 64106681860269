import React from 'react';
import styled from 'styled-components';
import RadioGroupOption from './Option';

const RadioGroupWrapper = styled.div`
  display: flex;
  //align-items: left;
  //flex-wrap: nowrap;

  padding: 0px;
  width: 100%;
  margin: 0px;
  margin-right: 0px;
  
  input[type=radio] {
    display: none;
  }
`;

function handleClick (e, value, flag, fn) {
  e.preventDefault();
  if (flag) {
    //console.log("FUCKING WORK:                       87213872183721382173872183721", flag)
    fn('done');
  } else {
    //console.log("FUCKING WORK:                       87213872183721382173872183721", flag)
    fn(value);
  }
}

const renderOptions = field => {
  return field.options.map((option, key) => (
    <RadioGroupOption
      {...option}
      active={field.input.value === option.value}
      onClick={e => handleClick(e, option.value, field.input.value === option.value, field.input.onChange)}
      key={key}
    />
  ));
};

const RadioGroup = ({ field }) => (
  <RadioGroupWrapper>{renderOptions(field)}</RadioGroupWrapper>
);

export default RadioGroup;
