import React from 'react';
import styled from 'styled-components/macro';
import UserPageItem from './Item';
//import { transition } from '../../shared/helpers';
import Select from 'react-select';
//import localeInfo from '../../shared/en_US';
import {Helmet} from "react-helmet";

import { withRouter } from 'react-router-dom';

import { withTheme } from 'styled-components';

import Media from 'react-media';

import Empty from '../../shared/Empty';
//import User from '../../shared/form/User';

import 'react-dropdown/style.css'

//import PageWrapper from '../../shared/PageWrapper';
import InfiniteScroll from "react-infinite-scroll-component";
//import Pagination from 'rc-pagination';

import ContentLoader from "react-content-loader"
import {LayoutTextSidebarReverse} from '@styled-icons/bootstrap/LayoutTextSidebarReverse'

import cloneDeep from 'lodash/cloneDeep'
var deepEqual = require('deep-equal')

const SideBarWrapper = styled.button`
display: flex;
justify-items: center;
align-items: center;

  position: relative;
  height: 26px;
  width: 26px;
  ${props => props.flex && 'flex: 1'};
  margin-left: 6px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  color: ${props => props.theme.foreground}
  background-color: ${props => props.theme.foreground}
  outline: 0px;
  
  :hover,:focus {
    text-underline-position: under;
    text-decoration: none;
    outline: 0;

    color: ${props => props.theme.accent};
    //border-color: ${props => props.theme.accent};
    outline: 0;
  }

  @media (min-width: ${props => props.theme.w2}) {
    display: none;
  }
`;

const DataBar = styled(LayoutTextSidebarReverse)`
  width: 26px;
  height: 26px;
  color: ${props => props.theme.normalText};
  background-color: ${props => props.theme.foreground}
  border-radius: 2px;
  border: none;
  
  :hover,:focus {
    text-underline-position: under;
    text-decoration: none;
    outline: 0;

    color: ${props => props.theme.accent};
    outline: 0;
  }
`;

const MyLoaderPostList = (props) => (
  <>
    <ContentLoader 
    speed={2}
    height={87} width={"100%"} 
    backgroundColor={props.theme.foregroundNew}
    foregroundColor={props.theme.inputBackground}
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="125" height="26" />
    <rect x="131" y="0" rx="2" ry="2" width="100" height="26" />
    <rect x="0" y="30" rx="2" ry="2" width="100%" height="56" />
  </ContentLoader>
  <ContentLoader 
    speed={2}
    height={57} width={"100%"} 
    backgroundColor={props.theme.foregroundNew}
    foregroundColor={props.theme.inputBackground}
    opacity={0.8}
    {...props}
    >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="56" />
  </ContentLoader>
  <ContentLoader 
    speed={2}
    height={57} width={"100%"} 
    backgroundColor={props.theme.foregroundNew}
    foregroundColor={props.theme.inputBackground}
    opacity={0.6}
    {...props}
    >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="56" />
  </ContentLoader>
  <ContentLoader 
    speed={2}
    height={57} width={"100%"} 
    backgroundColor={props.theme.foregroundNew}
    foregroundColor={props.theme.inputBackground}
    opacity={0.4}
    {...props}
    >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="56" />
  </ContentLoader>
  <ContentLoader 
    speed={2}
    height={57} width={"100%"} 
    backgroundColor={props.theme.foregroundNew}
    foregroundColor={props.theme.inputBackground}
    opacity={0.2}
    {...props}
    >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="56" />
  </ContentLoader>
  <ContentLoader 
    speed={2}
    height={57} width={"100%"} 
    backgroundColor={props.theme.foregroundNew}
    foregroundColor={props.theme.inputBackground}
    opacity={0.1}
    {...props}
    >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="56" />
  </ContentLoader>
</>
)

const MyLoaderBar = (props) => (
  <>
  <ContentLoader 
    speed={2}
    height={57} width={"100%"} 
    backgroundColor={props.theme.foregroundNew}
    foregroundColor={props.theme.inputBackground}
    opacity={1.0}
    {...props}
    >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="56" />
  </ContentLoader>
  <ContentLoader 
    speed={2}
    height={57} width={"100%"} 
    backgroundColor={props.theme.foregroundNew}
    foregroundColor={props.theme.inputBackground}
    opacity={0.667}
    {...props}
    >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="56" />
  </ContentLoader>
  <ContentLoader 
    speed={2}
    height={57} width={"100%"} 
    backgroundColor={props.theme.foregroundNew}
    foregroundColor={props.theme.inputBackground}
    opacity={0.334}
    {...props}
    >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="56" />
  </ContentLoader>
</>
)

const customStylesSingle = (theme) => {
  return({
    container: (base) => ({
      ...base,
      display:'inline-block',
      maxHeight: '26px',
      width: '185px',
      borderRadius: '3px',
      backgroundColor: theme.inputBackground,
      borderColor: theme.border,
    }),

    control: (provided, {isDisabled, isFocused, isSelected }) => ({
      ...provided,
      color: isFocused ? theme.normalText : theme.mutedText,
      //height: "26px",
      width: '185px',
      minHeight: 'fit-content',
      borderRadius: '3px',
      fontSize: '15px',
      paddingLeft: '8px',
      borderColor: theme.border,

      boxShadow: '0px',

      cursor: isDisabled ? 'not-allowed' : 'default',
      backgroundColor: isDisabled
          ? null
          : isSelected
          ? theme.inputBackground
          : isFocused
          ? theme.inputBackground
          : null,

      ':hover': {
        border: "1px solid",
        borderColor: theme.accent
      },
    }),

    option: (styles, {isDisabled, isFocused, isSelected }) => {
      return {
      color: isFocused ? theme.normalText : theme.mutedText,
      padding: '0px',
      margin: '0px',
      paddingTop: '2px',
      paddingBottom: '2px',
      paddingLeft: '8px',
      //color: theme.normalText,
      fontSize: '15px',
      backgroundColor: isDisabled
          ? null
          : isSelected
          ? theme.inputBackground
          : isFocused
          ? theme.inputBackground
          : null,
      height: 'auto',
      cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },

  menuList: (provided, state) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: '15px',
    maxHeight: '250px',
    height: 'auto',
    backgroundColor: theme.activeBackground,
    color: theme.normalText,
    zIndex: 2,
    border: '1px solid',
    borderColor: theme.border,
   }),

  valueContainer: base => ({
      ...base,
      padding: '0px 0px',
      height: '24px',
  }),

  singleValue: base => ({
      ...base,
      maxHeight: '26px',
      color: theme.normalText,
      fontSize: '15px',
      paddingBottom: '0.5px',
  }),

  input: base => ({
      ...base,
      display: 'inline-block',
      fontSize: '15px',
      color: theme.normalText,
      backgroundColor: theme.activeBackground,
  }),
});
}

const customStylesSingleSort = (theme) => {
  return({
    container: (base) => ({
      ...base,
      display:'inline-block',
      maxHeight: '26px',
      borderRadius: '3px',
      backgroundColor: theme.inputBackground,
      borderColor: theme.border,
    }),

  control: (provided, {isDisabled, isFocused, isSelected }) => ({
    ...provided,
    color: isFocused ? theme.normalText : theme.mutedText,
    //height: "26px",
    width: '120px',
    minHeight: 'fit-content',
    borderRadius: '3px',
    fontSize: '15px',
    paddingLeft: '8px',
    borderColor: theme.border,

    boxShadow: '0px',

    cursor: isDisabled ? 'not-allowed' : 'default',
    backgroundColor: isDisabled
        ? null
        : isSelected
        ? theme.inputBackground
        : isFocused
        ? theme.inputBackground
        : null,

    ':hover': {
      border: "1px solid",
      borderColor: theme.accent
    },
  }),

  option: (styles, {isDisabled, isFocused, isSelected }) => {
    return {
    color: isFocused ? theme.normalText : theme.mutedText,
    padding: '0px',
    margin: '0px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '8px',
    fontSize: '15px',
    backgroundColor: isDisabled
        ? null
        : isSelected
        ? theme.inputBackground
        : isFocused
        ? theme.inputBackground
        : null,
     height: 'auto',
     cursor: isDisabled ? 'not-allowed' : 'default',
    };
   },

  menuList: (provided, state) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: '15px',
    maxHeight: '250px',
    height: 'auto',
    backgroundColor: theme.activeBackground,
    color: theme.normalText,
    zIndex: 2,
    border: '1px solid',
    borderColor: theme.border,
   }),

  valueContainer: base => ({
      ...base,
      padding: '0px 0px',
      height: '24px',
  }),

  singleValue: base => ({
      ...base,
      maxHeight: '26px',
      color: theme.normalText,
      fontSize: '15px',
      paddingBottom: '0.5px',
  }),

  input: base => ({
      ...base,
      display: 'inline-block',
      fontSize: '15px',
      color: theme.normalText,
      backgroundColor: theme.activeBackground,
  }),
});
}

const SelectWrapper = styled.div`
  position: relative;
  ${props => props.flex && 'flex: 1'};
  //padding-top: 2px;
  
  ::after {
    content: '';
    position: absolute;
    top: 13px;
    right: 0;
    transform: translate(-150%, calc(-50% - 2px)) rotate(45deg);
    border-bottom: 2px solid ${props => props.theme.normalText};
    border-right: 2px solid ${props => props.theme.normalText};
    width: 8px;
    height: 8px;
    pointer-events: none;
  }
`;

const SelectWrapperSort = styled.div`
  position: relative;
  ${props => props.flex && 'flex: 1'};
  margin-left: 4px;
  
  ::after {
    content: '';
    position: absolute;
    top: 13px;
    right: 0;
    transform: translate(-150%, calc(-50% - 2px)) rotate(45deg);
    border-bottom: 2px solid ${props => props.theme.normalText};
    border-right: 2px solid ${props => props.theme.normalText};
    width: 8px;
    height: 8px;
    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  outline: 0px;
  width: 100%;
  font-size: 15px;

  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  
  margin: 0px;

  background-color: "#fff";
  color: ${props => props.theme.mutedText};
`;

const WrapperEmpty = styled.div`
  padding-top: 4px;
`;

const WrapperEmpty5 = styled.div`
  padding-top: 5px;
`;

const Wrapper2 = styled.div`
  display: flex;
  flex-direction: row;

  width: 10%;
  font-size: 15px;

  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;

  margin: 0px;

  background-color: "#fff";
  color: ${props => props.theme.mutedText};
`;

const sorts = [
  { value: 'Best', label: 'Best'},
  { value: 'Hot', label: 'Hot'},
  { value: 'New', label: 'New'},
  //{ value: 'Controversial', label: 'Controversial'},
  { value: 'Top', label: 'Top'},
  //{ value: 'Rising', label: 'Rising'},
  //{ value: 'Missed', label: 'Missed'}
  ];

const types = [
{ value: 'Posted', label: 'Posted'},
{ value: 'Commented', label: 'Commented'},
{ value: 'Post Saved', label: 'Post Saved'},
{ value: 'Comment Saved', label: 'Comment Saved'},
{ value: 'Post Upvoted', label: 'Post Upvoted'},
{ value: 'Post Downvoted', label: 'Post Downvoted'},
{ value: 'Comment Upvoted', label: 'Comment Upvoted'},
{ value: 'Comment Downvoted', label: 'Comment Downvoted'}
];

const typesR = [
{ value: 'Posted', label: 'Posted'},
{ value: 'Commented', label: 'Commented'}
];

/*
const sortBy = [
  'Best',
  'Hot',
  'New',
  'Controversial',
  'Top',
  'Rising',
  'Missed'
];

const typeBy = [
  'Posted',
  'Commented',
  'Post Saved',
  'Comment Saved',
  'Post Upvoted',
  'Post Downvoted',
  'Comment Upvoted',
  'Comment Downvoted',
];

const typeByRestrict = [
  'Posted',
  'Commented',
];
*/

const List = styled.ul`
  list-style: none;
  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;

  margin-top: 4px;
  margin-bottom: 0px;

  @media (max-width: 868px) {
    //border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

/*
function arraysNotEqual(a, b) {
  if (a === b) return false;
  if (a == null || b == null) return true;
  if (a.length !== b.length) return true;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return true;
  }
  return false;
}
*/

class ProfilePosts extends React.Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.state = {  
      numpids: 20,
      items: Array.from({ length: 20 }),
      theposition: window.pageYOffset,
      getMore: false
    };
  }

  fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      this.setState({
        theposition: window.pageYOffset,
        getMore: true
      });
    }, 3000);
  };

  loadPosts = (prvposts) => {
    if (prvposts === null) {
      this.setState({theposition: 0})
    }
    this.props.attemptFetchPostsByUser(this.props.searchvalues, this.props.pagevalues, this.props.username, this.state.numpids, prvposts)
  };
  
  loadMorePosts = () => {
    var pagevalues_copy   = cloneDeep(this.props.pagevalues);
    pagevalues_copy.currentpage = pagevalues_copy.currentpage + 1
    this.props.pageRequest(pagevalues_copy)
  };

  componentWillUnmount(prevProps, prevState) {
    if ( (this.props.history.location.pathname === '/') ) {
      // maybe previous pathname /u/:username?
      this.props.searchRequest('reset')
      this.props.pageRequest('reset')
      this.props.attemptClearPosts()
    }
  }

  componentDidMount() {
    // DOES PAGE VALUES EXIST?
    if ((this.props.pagevalues === null) || (this.props.pagevalues === undefined)) {
      this.props.pageRequest('reset')
    }
    
    // DO POSTS EXIST?
    if ( (this.props.posts === null) || (this.props.posts === undefined) ) {
      this.loadPosts(null)
    }
    
    // ARE THERE ANY POSTS?
    if ( (this.props.posts !== null) && (this.props.posts !== undefined) ) {
      if ( this.props.posts.length === 0 ) {
        this.loadPosts(null)
      }
    }
    
    window.scrollTo(0, this.state.theposition);
  }

  componentDidUpdate(prevProps, prevState) {

    
    if (this.props.theme.accent !== prevProps.theme.accent) {
      this.setState({theposition: 0})
    }

    // CHECK IF SEARCH VALUES CHANGED
    if (!this.props.isFetching) {
      // compare postsearchvalues === searchvalues
      if ( !deepEqual(this.props.searchvalues, this.props.postsearchvalues) ) {
        this.loadPosts(null);
      } else if ( (this.props.posts === null) || (this.props.posts === undefined) ) {
        // DO POSTS EXIST?
        this.loadPosts(null)
      }
      
    }

     // CHECK IF PAGEVALUES CHANGE
     if ( (this.props.pagevalues!==null) && (this.props.pagevalues!==undefined) ) {

      if ( !deepEqual(this.props.pagevalues, prevProps.pagevalues) ) {

        // PAGE VALUES CHANGED
      
        // IF SORT CHANGES THEN LOAD NEW ELSE LOAD MORE      
        if ( (prevProps.pagevalues !== null) && (prevProps.pagevalues !== undefined)) {
          
          if ( !deepEqual(this.props.pagevalues.currentpage, prevProps.pagevalues.currentpage) ) {

            this.loadPosts(this.props.posts);

          }

        } else {

          this.loadPosts(null);
             
        }
      }
    }

    
    // ELSE LOAD MORE
    if ( this.state.getMore === true ) {
      
      this.setState({getMore: false}, () => {this.loadMorePosts()});

    }

  }
  
  handleSortChange = (event) => {
    this.props.attemptClearPosts()
    
    var pagevalues_copy = cloneDeep(this.props.pagevalues);

    pagevalues_copy.currentpage = 1;
    pagevalues_copy.sortby = event.value;

    var newPathname = this.props.history.location.pathname.split('/s=')[0]
    
    if ( (newPathname[newPathname.length - 1] === '/') && (newPathname.length > 1) ) {
        newPathname.pop();
    }

    if (newPathname.length === 1) {
      newPathname = newPathname.concat('s='+event.value);
    } else {
      newPathname = newPathname.concat('/s='+event.value);  
    }

    this.setState({theposition: 0})
    this.props.pageRequest(pagevalues_copy)
    this.props.history.push(newPathname);
  };

  // LIST OPTIONS
  handleTypeChange = (event) => {
    var pagevalues_copy = cloneDeep(this.props.pagevalues);
    pagevalues_copy.typeby = event.value;

    this.setState({theposition: 0})
    this.props.pageRequest(pagevalues_copy)
  };

  refreshPosts = (event) => {
    this.setState({theposition: 0})
    this.loadPosts()
  };

  sideBar = (event) => {
    this.props.history.push('/navigate');
  };

  render() {
    if ((!this.props.posts || this.props.posts.length === 0 || this.props.prvposts === null) && (this.props.isFetching) ) return MyLoaderPostList(this.props);
    if (!this.props.posts || this.props.posts.length === 0) return <Empty text={"Sorry, no posts found."}/>;
  
    return (
     <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>QuantifyAlpha | {this.props.username}</title>
        <meta name="Description" content={"QuantifyAlpha Profile" + this.props.username}/>
        <meta name="keywords" content="profile" />
        <link rel="canonical" href={"http://quantifyalpha.com/u/" + this.props.username} />
      </Helmet>
      
       {!this.props.posts || this.props.posts.length === 0 ? (

        <div className="commentBox">
         <Wrapper>

          <Wrapper2>


            {(this.props.user) && (
                <>
              {(this.props.user.username === this.props.username) && (
              <SelectWrapper>

                <Select
                  options={types}
                  styles={customStylesSingle(this.props.theme)}
                  onChange={this.handleTypeChange}
                  value={types.find(op => {return op.value === this.props.pagevalues.typeby})}
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  isSearchable={false}
                />  

              </SelectWrapper>
              )}
              </>
            )}

            {(this.props.user) && (
              <>
              {(this.props.user.username !== this.props.username) && (
              <SelectWrapper>

                <Select
                  options={typesR}
                  styles={customStylesSingle(this.props.theme)}
                  onChange={this.handleTypeChange} 
                  value={typesR.find(op => {return op.value === this.props.pagevalues.typeby})}
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  isSearchable={false}
                />  

              </SelectWrapper>
              )}
              </>
            )}

            {!(this.props.user) && (
              <SelectWrapper>
                
                <Select
                  options={typesR}
                  styles={customStylesSingle(this.props.theme)}
                  onChange={this.handleTypeChange} 
                  value={typesR.find(op => {return op.value === this.props.pagevalues.typeby})}
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  isSearchable={false}
                />  

              </SelectWrapper>
            )}

          </Wrapper2>
  
          <SideBarWrapper onClick={this.sideBar}>
            <DataBar/>
          </SideBarWrapper >

         </Wrapper>


         <Media queries={{ large: "(min-width: "+this.props.theme.w2p1+")" }}>
          <WrapperEmpty>
            <Empty text={"Sorry, no posts found."} />
          </WrapperEmpty>
         </Media>
         
         <Media queries={{ large: "(max-width: "+this.props.theme.w2+")" }}>
          <WrapperEmpty5>
            <Empty text={"Sorry, no posts found."} />
          </WrapperEmpty5>
         </Media>

        </div>

         
         ) : (

        <div className="commentBox">
         <Wrapper>

          <Wrapper2>

            {(this.props.user) && (
              <>
              {(this.props.user.username === this.props.username) && (
              <SelectWrapper>

                <Select
                  options={types}
                  styles={customStylesSingle(this.props.theme)}
                  onChange={this.handleTypeChange} 
                  value={types.find(op => {return op.value === this.props.pagevalues.typeby})}
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  isSearchable={false}
                />  

              </SelectWrapper>
              )}
              </>
            )}

            {(this.props.user) && (
              <>
              {(this.props.user.username !== this.props.username) && (
              <SelectWrapper>

                <Select
                  options={typesR}
                  styles={customStylesSingle(this.props.theme)}
                  onChange={this.handleTypeChange} 
                  value={typesR.find(op => {return op.value === this.props.pagevalues.typeby})}
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  isSearchable={false}
                />  

              </SelectWrapper>
              )}
              </>
            )}

            {!(this.props.user) && (
              <SelectWrapper>
                
                <Select
                  options={typesR}
                  styles={customStylesSingle(this.props.theme)}
                  onChange={this.handleTypeChange} 
                  value={typesR.find(op => {return op.value === this.props.pagevalues.typeby})}
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  isSearchable={false}
                />  

              </SelectWrapper>
            )}

            <SelectWrapperSort>

              <Select
                  options={sorts}
                  styles={customStylesSingleSort(this.props.theme)}
                  onChange={this.handleSortChange} 
                  value={sorts.find(op => {return op.value === this.props.pagevalues.sortby})}
                  components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                  isSearchable={false}
                />  

            </SelectWrapperSort>

            
          </Wrapper2>

          <SideBarWrapper onClick={this.sideBar}>
            <DataBar/>
          </SideBarWrapper >
  
         </Wrapper>

          <InfiniteScroll
            dataLength={this.props.posts.length}
            next={this.fetchMoreData}
            hasMore={(this.props.posts.length >= this.props.posts[this.props.posts.length-1].fullcount) ? false : true}
            scrollThreshold={1}
            loader={ MyLoaderBar(this.props)}
          >
            {/*this.state.items.map((i, index) => (
              <div style={style} key={index}>
                div - #{index}
              </div>
            ))*/}
            {<List>{
              this.props.posts.map((post, index) => (
                <UserPageItem key={index} token={this.props.token} history={this.props.history} posts={this.props.posts} {...post} />
              ))
            }</List>}
          </InfiniteScroll>
     
        </div>
 
       )}

     </>

    );
  }
}

export default withRouter(withTheme(ProfilePosts));