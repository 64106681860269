import styled from 'styled-components/macro';
import Button from '../../shared/SmallButton';

const ResetButton = styled(Button)`

  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;

  height: 36px;
  width: 68px;

  align-items: center;
  justify-content: center;
  text-align: center;

  outline: none;
  border: none;

`;

export default ResetButton;
