export const periodOptions = [
  { value: 'Seconds',      label: 'Seconds'},  
  { value: 'Minutes',      label: 'Minutes'},  
  { value: 'Hourly',       label: 'Hourly'},   
  { value: 'Daily',        label: 'Daily'}, 
  { value: 'Weekly',       label: 'Weekly'},    
  { value: 'Monthly',      label: 'Monthly'},   
  { value: 'Yearly',       label: 'Yearly'},
];

