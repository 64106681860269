import React from 'react';
import styled from 'styled-components/macro';
import { transition } from '../shared/helpers';
import {Helmet} from "react-helmet";

import { withRouter } from 'react-router-dom';

import { withTheme } from 'styled-components';

import User from '../shared/form/User';

import Page from './ProfilePage';
import 'react-dropdown/style.css'

import ProfilePostsContainer from './PostList/Container';

import ContentLoader from "react-content-loader"

const MyLoaderProfile = (props) => (
  <>
    <ContentLoader 
    speed={2}
    height={242} width={"100%"} 
    backgroundColor={props.theme.foregroundNew}
    foregroundColor={props.theme.inputBackground}
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="100%" height="150" />
    <rect x="0" y="154" rx="2" ry="2" width="185" height="26" />
    <rect x="189" y="154" rx="2" ry="2" width="130" height="26" />
    <rect x="0" y="185" rx="2" ry="2" width="100%" height="56" />
  </ContentLoader>
</>
)

const MissingWrapper = styled.div`
  text-align: center;
  item-align: center;
  justify-content: center;

  align-items: left;
  font-size: 26px;
  font-weight: 300;
  color: ${props => props.theme.normalText};

  margin-Top: 48px;
  flex: 1;
  flexGrow: 1;
  flexShrink: 1;
  
  align-items: flex-start;
  margin: 0 8vw;
  border: 0px solid ${props => props.theme.border};
    
  @media (max-width: ${props => props.theme.w2}) {
    display: block;
    margin: 0;
  }
`;


const Alpha = styled.h1`
  font-size: 100px;
  font-weight: thin;
  margin-top: -19px;
  margin-bottom: 0px;
  margin-left: 2px;
  //font-style: italic;
  font-family: 'EB Garamond';
  font-display: swap;
  color: ${props => props.theme.accent};

  -webkit-transform: scale(1.25,1);
  -moz-transform: scale(1.25,1);
  -ms-transform: scale(1.25,1);
  -o-transform: scale(1.25,1);
  transform: scale(1.25,1);

  @media (max-width: ${props => props.theme.w1}) {
    font-size: 48px;
    margin-top: -20px;
  }
`

const Profile = styled.div`
  font-size: 15px;
  color: ${props => props.theme.normalText};
  appearance: none;
  outline: none;
  resize: vertical;  

  border: 1px solid ${props => props.theme.border};
  border-radius: 2px;
  padding: 15px;
  padding-top: 5px;
  background-color: ${props => props.theme.foreground};

  // bottom margin comes form Field > RenderField > Input Wrapper

  ${transition('filter')};
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${props =>
    props.loading &&
    'filter: grayscale(0.5) blur(5px) opacity(0.6); pointer-events: none'};
`;

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.listRef = React.createRef();
  }

  componentDidMount() {
    if ( (this.props.profiles === null) || (this.props.profile === undefined) ) {
      this.props.attemptFetchProfile(this.props.username)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //if (!this.props.isFetchingProfile) {
    
      if (this.props.theme.accent !== prevProps.theme.accent) {
        this.setState({theposition: 0})
      }
      
      //const { history } = this.props;
      if (this.props.username !== prevProps.username) {
        this.props.attemptFetchProfile(this.props.username)
        window.scrollTo(0, 0);
      } 
  }

  render() {
    if (this.props.isFetchingProfile) return MyLoaderProfile(this.props);
  
    if ((this.props.profile === undefined) && (!this.props.isFetchingProfile)) {
      return (
        <MissingWrapper>
          <div> 
          <Alpha>&#945;</Alpha> 
          </div>
          Sorry, nobody on QuantifyAlpha goes by that name.
        </MissingWrapper>
        )
    }

    return (
     <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>QuantifyAlpha | {this.props.username}</title>
        <meta name="Description" content={"QuantifyAlpha Profile" + this.props.username}/>
        <meta name="keywords" content="profile" />
        <link rel="canonical" href={"http://quantifyalpha.com/u/" + this.props.username} />
      </Helmet>

      <User loading={this.props.isFetching} >

       <Profile>

        <Page 
          username={this.props.username} 
          email={this.props.profile.email}
          bool1={this.props.profile.bool1}
          var0={this.props.profile.var0}
          var1={this.props.profile.var1}
          var2={this.props.profile.var2}
          var3={this.props.profile.var3}
          var4={this.props.profile.var4}
        />

      </Profile>
      
      </User>
      
      <ProfilePostsContainer username = {this.props.username} psortby={this.props.psortby}/>

     </>

    );
  }
}

export default withRouter(withTheme(ProfilePage));