export const keywordStrategyOptions = [
    { value: 'Accruals Effects', label: 'Accruals Effect'},
    { value: 'Alternative Data', label: 'Alternative'},
    { value: 'Arbitrage', label: 'Arbitrage'},
    { value: 'Asset Class Picking', label: 'Asset Class Picking'},
    { value: 'Beta Factor', label: 'Beta Factor'},
    { value: 'Bond Timing', label: 'Bond Timing'},
    { value: 'Carry Trade', label: 'Carry Trade'},
    { value: 'Country Picking', label: 'Country Picking'},
    { value: 'Earnings Announcement', label: 'Earnings Announcement'},
    { value: 'Equity Long Short', label: 'Equity Long Short'},
    { value: 'ESG Investing', label: 'ESG Investing'},
    { value: 'Factor Allocation', label: 'Factor Allocation'},
    { value: 'Factor Investing', label: 'Factor Investing'},
    { value: 'Forex System', label: 'Forex System'},
    { value: 'Fund Picking', label: 'Fund Picking'},
    { value: 'Fundamental Analysis', label: 'Fundamental Analysis'},
    { value: 'FX Anomaly', label: 'FX Anomaly'},
    { value: 'Growth Stocks', label: 'Growth Stocks'},
    { value: 'Indication of Interest', label: 'Indication of Interest'},
    { value: 'Insiders Trading Effect', label: 'Insiders Trading Effect'},
    { value: 'Liquidity Risk Premium', label: 'Liquidity Risk Premium'},
    { value: 'Machine Learning', label: 'Machine Learning'},
    { value: 'Market Timing', label: 'Market Timing'},
    { value: 'Momentum', label: 'Momentum'},
    { value: 'Pairs Trading', label: 'Pairs Trading'},
    { value: 'Recommendations', label: 'Recommendations'},
    { value: 'Reversal', label: 'Reversal'},
    { value: 'Rotational System', label: 'Rotational System'},
    { value: 'Seasonality', label: 'Seasonality'},
    { value: 'Sector Picking', label: 'Sector Picking'},
    { value: 'Sentiment Trading', label: 'Sentiment Trading'},
    { value: 'Short Interest', label: 'Short Interest'},
    { value: 'Small Cap', label: 'Small Cap'},
    { value: 'Smart Beta', label: 'Smart Beta'},
    { value: 'Spread Trading', label: 'Spread Trading'},
    { value: 'Stock Picking', label: 'Stock Picking'},
    { value: 'Supply Chain', label: 'Supply Chain'},
    { value: 'Term Spread', label: 'Term Spread'},
    { value: 'Trading Earnings', label: 'Trading Earnings'},
    { value: 'Ttrend Following', label: 'Trend Following'},
    { value: 'Value', label: 'Value'},
    { value: 'Volatility Effect', label: 'Volatility Effect'},
    { value: 'Volatility Premium', label: 'Volatility Premium'},
    { value: 'Volume Effect', label: 'Volume Effect'},
];