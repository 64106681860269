import {

  fetchPosts,       // BY SEARCH

  fetchPostsByUser, // BY USER

  createPost,       // CREATE

  fetchPost,
  deletePost,
   
  savedPost,
  savePost,
  unsavePost,

  getkeyPost, 
  setkeyPost,

  votePost,

  viewPost,

  reportPost

 // getPostsUserSaved,
 // getPostsKeyword,
 // getPostsAuthor,
 // getPostsJournal,

} from '../util/api';

// FETCH POSTS              attemptFetchPosts
// FETCH POSTS BY USER      attemptFetchPostsByUser
// FETCH POST               attemptFetchPost

// CREATE POST              attemptCreatePost

// SAVED POST               attemptSavedPost
// SAVE POST                attemptSavePost
// UNSAVE POST              attemptUnsavePost

// GET KEY POST             attemptGetkeyPost
// SET KEY POST             attemptSetkeyPost

// DELETE POST              attemptDeletePost


/*
// RESET
export const RESET_POSTS_REQUEST = 'RESET_POSTS_REQUEST';
export const RESET_POSTS_SUCCESS = 'RESET_POSTS_SUCCESS';
export const RESET_POSTS_ERROR   = 'RESET_POSTS_ERROR';

const resetPostsRequest =           { type: RESET_POSTS_REQUEST        };
const resetPostsSuccess = posts => ({ type: RESET_POSTS_SUCCESS, posts });
const resetPostsError   = error => ({ type: RESET_POSTS_ERROR,   error });

// BY FIELDS, BY ORDER, BY COUNT, BY USER, BY CATEGORY

export const attemptResetPosts = () => async (dispatch, getState) => {  
  // BY       SEARCH FIELDS & ORDER
  dispatch(resetPostsRequest);
  try {
    const { token } = getState().auth;
    
    var searchfields = { pathname: '/', query: null,  table1: 'posts', table2: null, 
			date1: null,  date2: new Date().setHours(0, 0, 0, 0), 
			type: null,   subtype: null, 
			value1: null, value2: null, value3: null, value4: null, value5: null, 
			array1: null, array2: null, 
			bool1: 'OR',  bool2: false, bool3: false,
			keywords: null, 
		}

    const posts = await fetchPosts(searchfields, 1, 20, 'New', token);
    dispatch(resetPostsSuccess({searchfields, posts}));
  } catch (error) {
    dispatch(resetPostsError(error));
  }
};

export const attemptResetPostsByUser = (username) => async (dispatch, getState) => {  
  // BY       SEARCH FIELDS & ORDER
  dispatch(resetPostsRequest);
  try {
    const { token } = getState().auth;
    
    var searchfields = { pathname: '/', query: null,  table1: 'posts', table2: null, 
			date1: null,  date2: new Date().setHours(0, 0, 0, 0), 
			type: null,   subtype: null, 
			value1: null, value2: null, value3: null, value4: null, value5: null, 
			array1: null, array2: null, 
			bool1: 'OR',  bool2: false, bool3: false,
			keywords: null, 
		}

    const posts = await fetchPostsByUser(searchfields, username, 1, 20, 'New', 'Posted', token);
    dispatch(resetPostsSuccess({searchfields, posts}));
  } catch (error) {
    dispatch(resetPostsError(error));
  }
};
*/


// SEARCH
export const CLEAR_POSTS_REQUEST = 'CLEAR_POSTS_REQUEST';
export const CLEAR_POSTS_SUCCESS = 'CLEAR_POSTS_SUCCESS';
export const CLEAR_POSTS_ERROR   = 'CLEAR_POSTS_ERROR';

const clearPostsRequest =          ({ type: CLEAR_POSTS_REQUEST        });
const clearPostsSuccess =          ({ type: CLEAR_POSTS_SUCCESS        });
const clearPostsError   = error => ({ type: CLEAR_POSTS_ERROR,   error });

export const attemptClearPosts = () => async (dispatch, getState) => {  
  dispatch(clearPostsRequest);
  try {
    dispatch(clearPostsSuccess);
  } catch (error) {
    dispatch(clearPostsError(error));
  }
};


// SEARCH
export const FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_ERROR   = 'FETCH_POSTS_ERROR';

const fetchPostsRequest = posts => ({ type: FETCH_POSTS_REQUEST, posts });
const fetchPostsSuccess = posts => ({ type: FETCH_POSTS_SUCCESS, posts });
const fetchPostsError   = error => ({ type: FETCH_POSTS_ERROR,   error });

export const attemptFetchPosts = (postsearchfields, pagefields, numpids, prvposts, source) => async (dispatch, getState) => {  
  // BY SEARCH FIELDS & ORDER
  var fetchmore  

  if ( (prvposts !== null) && (prvposts !== undefined) ) {
    if ( (prvposts.length > 0) ) {
      fetchmore = true 
      dispatch(fetchPostsRequest({fetchmore}));
    } else { 
      fetchmore = false  
      dispatch(fetchPostsRequest({fetchmore}));
    }
  } else {
    fetchmore = false  
    dispatch(fetchPostsRequest({fetchmore}));
  }

  try {
    const { token } = getState().auth;
    
    var currentpage = 1;
    var sortby = "New";
    var search = postsearchfields;

    if ((postsearchfields !== null) && (postsearchfields !== undefined)) {

      const { array1, array2, bool1, bool3, formbool2, formdates, formsubtype, formvalue1, formvalue2, 
        formvalue3, formvalue4, formvalue5, keywords, pathname, query, table1, table2, type } = postsearchfields;
      
      search = { pathname: pathname, query: query,  table1: table1, table2: table2, 
                  date1: formdates.date1,  date2: formdates.date2+86400000, 
                  type: type,   subtype: formsubtype.subtype, 
                  value1: formvalue1.value1, value2: formvalue2.value2, value3: formvalue3.value3, 
                  value4: formvalue4.value4, value5: formvalue5.value5, 
                  array1: array1, array2: array2, 
                  bool1: bool1,  bool2: formbool2.bool2, bool3: bool3,
                  keywords: keywords, 
      }

    }
    
    if ((pagefields !== null) && (pagefields !== undefined)) {
      var { currentpage, sortby, position, theposition } = pagefields; 
    }

    var posts = await fetchPosts(search, currentpage, numpids, sortby, token);

    if ( (prvposts !== null) && (prvposts !== undefined) ) {
      if ( (prvposts.length > 0) ) {
        posts = prvposts.concat(posts)
        fetchmore = true  
        dispatch(fetchPostsSuccess({postsearchfields, posts, fetchmore}));
      } else { 
        fetchmore = false  
        dispatch(fetchPostsSuccess({postsearchfields, posts, fetchmore}));
      }
    } else {
      fetchmore = false  
      dispatch(fetchPostsSuccess({postsearchfields, posts, fetchmore}));
    }
  } catch (error) {
    fetchmore = false  
    dispatch(fetchPostsError({error, fetchmore}));
  }
};


export const attemptFetchPostsByUser = (postsearchfields, pagefields, username, numpids, prvposts) => async (dispatch, getState) => {                 
  // BY USER & SEARCH FIELDS & ORDER
  
  var fetchmore

  if ( (prvposts !== null) && (prvposts !== undefined) ) {
    if ( (prvposts.length > 0) ) {
      fetchmore = true  
      dispatch(fetchPostsRequest({fetchmore}));
    } else { 
      fetchmore = false  
      dispatch(fetchPostsRequest({fetchmore}));
    }
  } else {
    fetchmore = false  
    dispatch(fetchPostsRequest({fetchmore}));
  }

  dispatch(fetchPostsRequest);
  try {
    const { token } = getState().auth;
    
    var currentpage = 1;
    var sortby = "New";
    var typeby = "Posted";
    var search = postsearchfields;

    if ((postsearchfields !== null) && (postsearchfields !== undefined)) {

      const { array1, array2, bool1, bool3, formbool2, formdates, formsubtype, formvalue1, formvalue2, 
        formvalue3, formvalue4, formvalue5, keywords, pathname, query, table1, table2, type } = postsearchfields;
      
      search = { pathname: pathname, query: query,  table1: table1, table2: table2, 
                  date1: formdates.date1,  date2: formdates.date2+86400000, 
                  type: type,   subtype: formsubtype.subtype, 
                  value1: formvalue1.value1, value2: formvalue2.value2, value3: formvalue3.value3, 
                  value4: formvalue4.value4, value5: formvalue5.value5, 
                  array1: array1, array2: array2, 
                  bool1: bool1,  bool2: formbool2.bool2, bool3: bool3,
                  keywords: keywords, 
      }
    }

    if ((pagefields !== null) && (pagefields !== undefined)) {
      var { currentpage, sortby, typeby } = pagefields; 
    }

    var posts = await fetchPostsByUser(search, username, currentpage, numpids, sortby, typeby, token);

    if ( (prvposts !== null) && (prvposts !== undefined) ) {
      if ( (prvposts.length > 0) ) {
        posts = prvposts.concat(posts)
        fetchmore = true  
        dispatch(fetchPostsSuccess({postsearchfields, posts, fetchmore}));
      } else { 
        fetchmore = false  
        dispatch(fetchPostsSuccess({postsearchfields, posts, fetchmore}));
      }
    } else {
      fetchmore = false  
      dispatch(fetchPostsSuccess({postsearchfields, posts, fetchmore}));
    }
  } catch (error) {
    dispatch(fetchPostsError(error));
  }
};

// CREATE
export const CREATE_POST_REQUEST = 'CREATE_POST_REQUEST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_ERROR   = 'CREATE_POST_ERROR';

const createPostRequest =           { type: CREATE_POST_REQUEST        }; 
const createPostSuccess = post  => ({ type: CREATE_POST_SUCCESS, post  });
const createPostError   = error => ({ type: CREATE_POST_ERROR,   error });

export const attemptCreatePost = (post, username, attach) => async (dispatch, getState) => {

  dispatch(createPostRequest);
  try {
    const { token } = getState().auth;

    var universialpost = {
      username:  null,
      type:      null, 
      subtype:   null, 
      keywords:  null, 
      title:     null, 
      reference: null, 
      text:      null, 
      url:       null, 
      date:      null, 
      value1:    null, 
      value2:    null, 
      value3:    null, 
      value4:    null, 
      value5:    null, 
      value6:    null, 
      bool1:  null, 
      bool2:  null, 
     
      authors:   null,
    }
    
    universialpost.username    = username
    universialpost.type        = post.type
    universialpost.subtype     = post.subtype
    universialpost.keywords    = post.keywords
    universialpost.title       = post.title
    universialpost.reference   = post.reference

    universialpost.authors     = post.authors.map(function(author, index) {return({'firstName': author.firstName.trim().replace(/\s/g,'_'), 
                                                                                   'lastName': author.lastName.trim().replace(/\s/g,'_')
                                 })});

    universialpost.attach      = attach
    
    if (post.type === 'general' && post.subtype === 'discussion') {
      universialpost.text      = post.text
      universialpost.url       = null
      universialpost.date      = null
      universialpost.value1    = null
      universialpost.value2    = null
      universialpost.value3    = null
      universialpost.value4    = null
      universialpost.value5    = null
      universialpost.value6    = null
      universialpost.bool1  = null
      universialpost.bool2  = null
      
    } else if (post.type === 'general' && post.subtype === 'link') {
      universialpost.text      = null
      universialpost.url       = post.url
      universialpost.date      = null
      universialpost.value1    = null
      universialpost.value2    = null
      universialpost.value3    = null
      universialpost.value4    = null
      universialpost.value5    = null
      universialpost.value6    = null
      universialpost.bool1  = null
      universialpost.bool2  = null
      
    } else if (post.type === 'library' && post.subtype === 'article') {
      universialpost.text      = post.text
      universialpost.url       = post.url
      universialpost.date      = post.date
      universialpost.value1    = post.jpublisher.value
      universialpost.value2    = post.journal.value
      universialpost.value3    = null
      universialpost.value4    = null
      universialpost.value5    = null
      universialpost.value6    = null
      universialpost.bool1  = post.implement
      universialpost.bool2  = null
      
    } else if (post.type === 'library' && post.subtype === 'textbook') {
      universialpost.text      = post.text
      universialpost.url       = null
      universialpost.date      = post.date
      universialpost.value1    = post.tpublisher.value
      universialpost.value2    = post.isbn.replace(' ','_')
      universialpost.value3    = null
      universialpost.value4    = null
      universialpost.value5    = null
      universialpost.value6    = null
      universialpost.bool1  = null
      universialpost.bool2  = null
      
    } else if (post.type === 'career' && post.subtype === 'school') {
      universialpost.text      = post.text
      universialpost.url       = post.url
      universialpost.date      = null
      universialpost.value1    = post.school.value
      universialpost.value2    = post.department
      universialpost.value3    = null
      universialpost.value4    = null
      universialpost.value5    = null
      universialpost.value6    = null
      universialpost.bool1  = null
      universialpost.bool2  = null
      
    } else if (post.type === 'career' && post.subtype === 'interview') {

      universialpost.title     = post.firm.value

      universialpost.text      = post.text
      universialpost.url       = null
      universialpost.date      = post.date
      universialpost.value1    = post.position
      universialpost.value2    = post.experience.value
      universialpost.value3    = null
      universialpost.value4    = null
      universialpost.value5    = null
      universialpost.value6    = null
      universialpost.bool1  = post.anonymous
      universialpost.bool2  = null
      
    } else if (post.type === 'career' && post.subtype === 'firm') {

      universialpost.text      = post.text
      universialpost.url       = post.url
      universialpost.date      = null
      universialpost.value1    = post.firm.value
      universialpost.value2    = null
      universialpost.value3    = null
      universialpost.value4    = null
      universialpost.value5    = null
      universialpost.value6    = null
      universialpost.bool1  = null
      universialpost.bool2  = null
      
    } else if (post.type === 'career' && post.subtype === 'charter') {
      universialpost.text      = post.text
      universialpost.url       = post.url
      universialpost.date      = null
      universialpost.value1    = post.cert.value
      universialpost.value2    = null
      universialpost.value3    = null
      universialpost.value4    = null
      universialpost.value5    = null
      universialpost.value6    = null
      universialpost.bool1  = null
      universialpost.bool2  = null
      
    } else if (post.type === 'alpha' && post.subtype === 'strategy') {

      universialpost.text      = post.text
      universialpost.date      = null
      universialpost.value1    = post.market.value
      universialpost.value2    = post.instrument.value
      universialpost.value3    = post.region.value
      universialpost.value4    = post.value.value
      universialpost.value5    = post.number.value
      universialpost.value6    = post.period.value
      universialpost.bool1  = post.implement
      universialpost.bool2  = post.simulation

    } else if (post.type === 'alpha' && post.subtype === 'data') {
      universialpost.text      = post.text
      universialpost.url       = post.url
      universialpost.date      = null
      universialpost.value1    = post.market.value
      universialpost.value2    = post.instrument.value
      universialpost.value3    = post.region.value
      universialpost.value4    = post.price.value
      universialpost.value5    = post.provider.value
      universialpost.value6    = null
      universialpost.bool1  = post.sample
      universialpost.bool2  = null
      
    } else if (post.type === 'alpha' && post.subtype === 'operation') {
      universialpost.text      = post.text
      universialpost.date      = null
      universialpost.value1    = post.market.value
      universialpost.value2    = post.instrument.value
      universialpost.value3    = post.region.value
      universialpost.value4    = post.category.value
      universialpost.value5    = null
      universialpost.value6    = null
      universialpost.bool1  = post.implement
      universialpost.bool2  = null
      
    } else if (post.type === 'alpha' && post.subtype === 'software') {
      universialpost.text      = post.text
      universialpost.date      = null
      universialpost.value1    = post.market.value
      universialpost.value2    = post.instrument.value
      universialpost.value3    = post.region.value
      universialpost.value4    = null
      universialpost.value5    = null
      universialpost.value6    = null
      universialpost.bool1  = null
      universialpost.bool2  = null
      
    }

    const newPost = await createPost(universialpost, token);
    dispatch(createPostSuccess(newPost));
  } catch (error) {
    //error.message = 'confirm that the reference is indeed valid. If error persists please contact us. '
    dispatch(createPostError(error));
  }
};


export const FETCH_POST_REQUEST = 'FETCH_POST_REQUEST';
export const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS';
export const FETCH_POST_ERROR   = 'FETCH_POST_ERROR';

const fetchPostRequest =           { type: FETCH_POST_REQUEST        };
const fetchPostSuccess = post  => ({ type: FETCH_POST_SUCCESS, post  });
const fetchPostError   = error => ({ type: FETCH_POST_ERROR,   error });

export const attemptFetchPost = (pid, posts) => async (dispatch, getState) => {
  dispatch(fetchPostRequest);
  try {
    const { token } = getState().auth;
    
    const post = await fetchPost(pid, token);

    if ( (posts !== null) && (posts !== undefined) ) {
      for (var i = 0; i < posts.length; i++) {
        if (parseInt(posts[i].pid) === parseInt(pid)) {
          posts[i].uid = 1;
          //Do something
        }
      }
    }

    dispatch(fetchPostSuccess({post, posts}));
   
  } catch (error) {
    dispatch(fetchPostError(error));
  }
};


export const SAVED_POST_REQUEST = 'SAVED_POST_REQUEST';
export const SAVED_POST_SUCCESS = 'SAVED_POST_SUCCESS';
export const SAVED_POST_ERROR   = 'SAVED_POST_ERROR';

const savedPostRequest =           { type: SAVED_POST_REQUEST        };
const savedPostSuccess = saved => ({ type: SAVED_POST_SUCCESS, saved });
const savedPostError   = error => ({ type: SAVED_POST_ERROR,   error });

export const attemptSavedPost = (pid) => async (dispatch, getState) => {
  dispatch(savedPostRequest);
  try {
    const { token } = getState().auth;

    const json = await savedPost(pid, token);
    dispatch(savedPostSuccess(json));
  } catch (error) {
    dispatch(savedPostError(error));
  }
};


export const SAVE_POST_REQUEST = 'SAVE_POST_REQUEST';
export const SAVE_POST_SUCCESS = 'SAVE_POST_SUCCESS';
export const SAVE_POST_ERROR   = 'SAVE_POST_ERROR';

const savePostRequest =           { type: SAVE_POST_REQUEST       };
const savePostSuccess =          ({ type: SAVE_POST_SUCCESS       });
const savePostError   = error => ({ type: SAVE_POST_ERROR,  error });

export const attemptSavePost = (pid) => async (dispatch, getState) => {
  dispatch(savePostRequest);
  try {
    const { token } = getState().auth;
    
    const json = await savePost(pid, token);
    dispatch(savePostSuccess(json));
  } catch (error) {
    dispatch(savePostError(error));
  }
};


export const VOTE_POST_REQUEST = 'VOTE_POST_REQUEST';
export const VOTE_POST_SUCCESS = 'VOTE_POST_SUCCESS';
export const VOTE_POST_ERROR   = 'VOTE_POST_ERROR';

const votePostRequest =           { type: VOTE_POST_REQUEST        };
const votePostSuccess = post  => ({ type: VOTE_POST_SUCCESS, post  });
const votePostError   = error => ({ type: VOTE_POST_ERROR,   error });

export const attemptVotePost = (pid, vote, posts) => async (dispatch, getState) => {
  dispatch(votePostRequest);
  try {
    const { token } = getState().auth;
    const post = await votePost(pid, vote, token);
    
    if ( (posts !== null) && (posts !== undefined) ) {
      for (var i = 0; i < posts.length; i++) {
        if (parseInt(posts[i].pid) === parseInt(pid)) {
        
          if (token) {
            posts[i].vote = vote
            if (vote == 1) {
              posts[i].score = posts[i].score + 1
            } else {
              posts[i].score = posts[i].score - 1
              if (posts[i].score < 0) {
                posts[i].score = 0
              }
            }
          }
          //posts[i].uid = 1;
          //Do something
        }
      }
    }

    dispatch(votePostSuccess({post, posts}));
  } catch (error) {
    dispatch(votePostError(error));
  }
};


export const VIEW_POST_REQUEST = 'VIEW_POST_REQUEST';
export const VIEW_POST_SUCCESS = 'VIEW_POST_SUCCESS';
export const VIEW_POST_ERROR   = 'VIEW_POST_ERROR';

const viewPostRequest =           { type: VIEW_POST_REQUEST        };
const viewPostSuccess = post  => ({ type: VIEW_POST_SUCCESS, post  });
const viewPostError   = error => ({ type: VIEW_POST_ERROR,   error });

export const attemptViewPost = () => async (dispatch, getState) => {
  dispatch(viewPostRequest);
  try {
    const { token } = getState().auth;
    const post = await viewPost(token);
    
    dispatch(viewPostSuccess(post));
  } catch (error) {
    dispatch(viewPostError(error));
  }
};


export const UNSAVE_POST_REQUEST = 'UNSAVE_POST_REQUEST';
export const UNSAVE_POST_SUCCESS = 'UNSAVE_POST_SUCCESS';
export const UNSAVE_POST_ERROR   = 'UNSAVE_POST_ERROR';

const unsavePostRequest =           { type: UNSAVE_POST_REQUEST       };
const unsavePostSuccess =          ({ type: UNSAVE_POST_SUCCESS       });
const unsavePostError   = error => ({ type: UNSAVE_POST_ERROR,  error });

export const attemptUnsavePost = (pid) => async (dispatch, getState) => {
  dispatch(unsavePostRequest);
  try {
    const { token } = getState().auth;
    
    const json = await unsavePost(pid, token);
    dispatch(unsavePostSuccess(json));
  } catch (error) {
    dispatch(unsavePostError(error));
  }
};


// TO DO
export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_ERROR   = 'DELETE_POST_ERROR';

const deletePostRequest =           { type: DELETE_POST_REQUEST        };
const deletePostSuccess = post  => ({ type: DELETE_POST_SUCCESS, post  });
const deletePostError   = error => ({ type: DELETE_POST_ERROR,   error });

export const attemptDeletePost = (pid) => async (dispatch, getState) => {
  dispatch(deletePostRequest);
  try {
    const { token } = getState().auth;
    await deletePost(pid, token);
    const post = await fetchPost(pid, token);
    dispatch(deletePostSuccess(post));
  } catch (error) {
    dispatch(deletePostError(error));
  }
};


export const GETKEY_POST_REQUEST = 'GETKEY_POST_REQUEST';
export const GETKEY_POST_SUCCESS = 'GETKEY_POST_SUCCESS';
export const GETKEY_POST_ERROR   = 'GETKEY_POST_ERROR';

const getkeyPostRequest =           { type: GETKEY_POST_REQUEST        }; 
const getkeyPostSuccess = post  => ({ type: GETKEY_POST_SUCCESS, post  });
const getkeyPostError   = error => ({ type: GETKEY_POST_ERROR,   error });

export const attemptGetkeyPost = (pid) => async (dispatch, getState) => {
  dispatch(getkeyPostRequest);
  try {
    const { token } = getState().auth;
    const newPost = await getkeyPost(pid, token);
    dispatch(getkeyPostSuccess(newPost));
  } catch (error) {
    dispatch(getkeyPostError(error));
  }
};


export const SETKEY_POST_REQUEST = 'SETKEY_POST_REQUEST';
export const SETKEY_POST_SUCCESS = 'SETKEY_POST_SUCCESS';
export const SETKEY_POST_ERROR   = 'SETKEY_POST_ERROR';

const setkeyPostRequest =           { type: SETKEY_POST_REQUEST        }; 
const setkeyPostSuccess = post  => ({ type: SETKEY_POST_SUCCESS, post  });
const setkeyPostError   = error => ({ type: SETKEY_POST_ERROR,   error });

export const attemptSetkeyPost = (pid, keys) => async (dispatch, getState) => {
  dispatch(setkeyPostRequest);
  try {
    const { token } = getState().auth;
    const newPost = await setkeyPost(pid, keys, token);
    dispatch(setkeyPostSuccess(newPost));
  } catch (error) {
    dispatch(setkeyPostError(error));
  }
};



export const REPORT_POST_REQUEST = 'REPORT_POST_REQUEST';
export const REPORT_POST_SUCCESS = 'REPORT_POST_SUCCESS';
export const REPORT_POST_ERROR   = 'REPORT_POST_ERROR';

const reportPostRequest =           { type: REPORT_POST_REQUEST        }; 
const reportPostSuccess = post  => ({ type: REPORT_POST_SUCCESS, post  });
const reportPostError   = error => ({ type: REPORT_POST_ERROR,   error });

export const attemptReportPost = (pid) => async (dispatch, getState) => {
  dispatch(reportPostRequest);
  try {
    const { token } = getState().auth;
    const newPost = await reportPost(pid, token);
    dispatch(reportPostSuccess(newPost));
  } catch (error) {
    dispatch(reportPostError(error));
  }
};


// CLEAR SIGNUP FORM
export const CLEAR_POST_DETAIL = 'CLEAR_POST_DETAIL';

const clearPostDetail = { type: CLEAR_POST_DETAIL };

export const attemptClearPostDetail = () => async dispatch => {
  dispatch(clearPostDetail)
};



// REPAIR ???
/*
export const fetchPostsKeyword = (keyword = '') => async dispatch => {
  dispatch(fetchPostsRequest);
  try {
    const posts = await getPostsKeyword(keyword);
    dispatch(fetchPostsSuccess(posts));
  } catch (error) {
    dispatch(fetchPostsError(error));
  }
};

export const fetchPostsAuthor = (author = '') => async dispatch => {
  dispatch(fetchPostsRequest);
  try {
    const posts = await getPostsAuthor(author);
    dispatch(fetchPostsSuccess(posts));
  } catch (error) {
    dispatch(fetchPostsError(error));
  }
};

export const fetchPostsJournal = journal => async dispatch => {
  dispatch(fetchPostsRequest);
  try {
    const posts = await getPostsJournal(journal);
    dispatch(fetchPostsSuccess(posts));
  } catch (error) {
    dispatch(fetchPostsError(error));
  }
};

export const fetchPostsSaved = username => async (dispatch, getState) => {
  dispatch(fetchPostsRequest);
  try {
    const { token } = getState().auth;
    const posts = await getPostsUserSaved(username, token);
    dispatch(fetchPostsSuccess(posts));
  } catch (error) {
    dispatch(fetchPostsError(error));
  }
};
*/






