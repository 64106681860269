export const firmOptions = [
{ value: '3Red Partners', label: '3Red Partners'},
{ value: 'ABN AMRO', label: 'ABN AMRO'},
{ value: 'ABRY Partners', label: 'ABRY Partners'},
{ value: 'ACON Investments', label: 'ACON Investments'},
{ value: 'AE Industrial Partners', label: 'AE Industrial Partners'},
{ value: 'AEA Investors', label: 'AEA Investors'},
{ value: 'AEW Capital', label: 'AEW Capital'},
{ value: 'AEW Europe', label: 'AEW Europe'},
{ value: 'AGF Management', label: 'AGF Management'},
{ value: 'AMP Capital Investors', label: 'AMP Capital Investors'},
{ value: 'APG', label: 'APG'},
{ value: 'AQR Capital Management', label: 'AQR Capital Management'},
{ value: 'ARC Financial Corporation', label: 'ARC Financial Corporation'},
{ value: 'ASR Vermogensbeheer', label: 'ASR Vermogensbeheer'},
{ value: 'AXA Investment Managers', label: 'AXA Investment Managers'},
{ value: 'Abbott Capital Management', label: 'Abbott Capital Management'},
{ value: 'Aberdeen Standard Investments', label: 'Aberdeen Standard Investments'},
{ value: 'Abrams Capital', label: 'Abrams Capital'},
{ value: 'Abris Capital Partners', label: 'Abris Capital Partners'},
{ value: 'Acadian Asset Management', label: 'Acadian Asset Management'},
{ value: 'Accel', label: 'Accel'},
{ value: 'Accel-KKR', label: 'Accel-KKR'},
{ value: 'Access Capital Partners', label: 'Access Capital Partners'},
{ value: 'Achmea Investment Management', label: 'Achmea Investment Management'},
{ value: 'Actiam', label: 'Actiam'},
{ value: 'Adage Capital Management', label: 'Adage Capital Management'},
{ value: 'Adam & Company', label: 'Adam & Company'},
{ value: 'Adams Street Partners', label: 'Adams Street Partners'},
{ value: 'Adrian Lee & Partners', label: 'Adrian Lee & Partners'},
{ value: 'Advent International', label: 'Advent International'},
{ value: 'Aegon Asset Management', label: 'Aegon Asset Management'},
{ value: 'Affiliated Managers Group', label: 'Affiliated Managers Group'},
{ value: 'Affinity Equity Partners', label: 'Affinity Equity Partners'},
{ value: 'Agricultural Bank of China', label: 'Agricultural Bank of China'},
{ value: 'Aktia Bank', label: 'Aktia Bank'},
{ value: 'Akuna Capital', label: 'Akuna Capital'},
{ value: 'Alcentra', label: 'Alcentra'},
{ value: 'Alfred Berg', label: 'Alfred Berg'},
{ value: 'Algebris', label: 'Algebris'},
{ value: 'Allahabad Bank', label: 'Allahabad Bank'},
{ value: 'Allen & Company', label: 'Allen & Company'},
{ value: 'AllianceBernstein', label: 'AllianceBernstein'},
{ value: 'Allianz Capital Partners', label: 'Allianz Capital Partners'},
{ value: 'Allianz Global Investors', label: 'Allianz Global Investors'},
{ value: 'Allianz Popular Pensiones', label: 'Allianz Popular Pensiones'},
{ value: 'Allston Trading', label: 'Allston Trading'},
{ value: 'Alphadyne Asset Management', label: 'Alphadyne Asset Management'},
{ value: 'Altamont Capital Partners', label: 'Altamont Capital Partners'},
{ value: 'Altor Equity Partners', label: 'Altor Equity Partners'},
{ value: 'Alvarez & Marsal Capital Partners', label: 'Alvarez & Marsal Capital Partners'},
{ value: 'Alyeska Investment Group', label: 'Alyeska Investment Group'},
{ value: 'Ambienta', label: 'Ambienta'},
{ value: 'American Century Investments', label: 'American Century Investments'},
{ value: 'American Industrial Partners', label: 'American Industrial Partners'},
{ value: 'American Securities', label: 'American Securities'},
{ value: 'Amia Capital', label: 'Amia Capital'},
{ value: 'Amplify Trading', label: 'Amplify Trading'},
{ value: 'Amundi', label: 'Amundi'},
{ value: 'Anchorage Capital Group', label: 'Anchorage Capital Group'},
{ value: 'Andera Partners', label: 'Andera Partners'},
{ value: 'Andreessen Horowitz', label: 'Andreessen Horowitz'},
{ value: 'Angelo Gordon & Co.', label: 'Angelo Gordon & Co.'},
{ value: 'Anima SGR', label: 'Anima SGR'},
{ value: 'Apax Partners', label: 'Apax Partners'},
{ value: 'Apax Partners SAS', label: 'Apax Partners SAS'},
{ value: 'Apollo Capital', label: 'Apollo Capital'},
{ value: 'Apollo Global Management', label: 'Apollo Global Management'},
{ value: 'Appaloosa Management', label: 'Appaloosa Management'},
{ value: 'Apple Bank for Savings', label: 'Apple Bank for Savings'},
{ value: 'Aquiline Capital Partners', label: 'Aquiline Capital Partners'},
{ value: 'Arbuthnot', label: 'Arbuthnot'},
{ value: 'Arbuthnot Latham', label: 'Arbuthnot Latham'},
{ value: 'ArcLight Capital Partners', label: 'ArcLight Capital Partners'},
{ value: 'Arca Fondi', label: 'Arca Fondi'},
{ value: 'ArchiMed', label: 'ArchiMed'},
{ value: 'Ardian', label: 'Ardian'},
{ value: 'Ardour Capital', label: 'Ardour Capital'},
{ value: 'Ardour Capital Investment Research', label: 'Ardour Capital Investment Research'},
{ value: 'Ares Management', label: 'Ares Management'},
{ value: 'Arion Bank', label: 'Arion Bank'},
{ value: 'Arrowgrass Capital', label: 'Arrowgrass Capital'},
{ value: 'Arrowstreet Capital', label: 'Arrowstreet Capital'},
{ value: 'Arsenal Capital Partners', label: 'Arsenal Capital Partners'},
{ value: 'Artemis Investment Management', label: 'Artemis Investment Management'},
{ value: 'Artisan Partners', label: 'Artisan Partners'},
{ value: 'Arvest Bank', label: 'Arvest Bank'},
{ value: 'Ashmore Group', label: 'Ashmore Group'},
{ value: 'Aspiriant', label: 'Aspiriant'},
{ value: 'Assenagon Asset Management', label: 'Assenagon Asset Management'},
{ value: 'Asset Management One International', label: 'Asset Management One International'},
{ value: 'Astignes Capital Asia', label: 'Astignes Capital Asia'},
{ value: 'Astorg Partners', label: 'Astorg Partners'},
{ value: 'Atlas Holdings', label: 'Atlas Holdings'},
{ value: 'Audax Group', label: 'Audax Group'},
{ value: 'Aurora Capital Partners', label: 'Aurora Capital Partners'},
{ value: 'Autonomy Capital', label: 'Autonomy Capital'},
{ value: 'Avanda Investment', label: 'Avanda Investment'},
{ value: 'Avatar Securities', label: 'Avatar Securities'},
{ value: 'Avenue Capital', label: 'Avenue Capital'},
{ value: 'Aviva Investors', label: 'Aviva Investors'},
{ value: 'Axeltis', label: 'Axeltis'},
{ value: 'Axonic Capital', label: 'Axonic Capital'},
{ value: 'B&K Securities', label: 'B&K Securities'},
{ value: 'B. Riley Wealth Management', label: 'B. Riley Wealth Management'},
{ value: 'BB&T Capital Markets', label: 'BB&T Capital Markets'},
{ value: 'BBH Investment Management', label: 'BBH Investment Management'},
{ value: 'BBR Partners', label: 'BBR Partners'},
{ value: 'BBVA', label: 'BBVA'},
{ value: 'BBVA Asset Management', label: 'BBVA Asset Management'},
{ value: 'BBY Ltd.', label: 'BBY Ltd.'},
{ value: 'BC Partners', label: 'BC Partners'},
{ value: 'BDT Capital Partners', label: 'BDT Capital Partners'},
{ value: 'BFAM Partners', label: 'BFAM Partners'},
{ value: 'BG Capital', label: 'BG Capital'},
{ value: 'BGC Partners', label: 'BGC Partners'},
{ value: 'BGH Capital', label: 'BGH Capital'},
{ value: 'BMO Capital Markets Corp', label: 'BMO Capital Markets Corp'},
{ value: 'BMO Global Asset Management', label: 'BMO Global Asset Management'},
{ value: 'BNP Exane Securities', label: 'BNP Exane Securities'},
{ value: 'BNP Paribas', label: 'BNP Paribas'},
{ value: 'BNP Paribas Asset Management', label: 'BNP Paribas Asset Management'},
{ value: 'BNP Paribas Wealth Management', label: 'BNP Paribas Wealth Management'},
{ value: 'BNY', label: 'BNY'},
{ value: 'BNY Mellon', label: 'BNY Mellon'},
{ value: 'BNY Mellon Asset Management', label: 'BNY Mellon Asset Management'},
{ value: 'BNY Mellon Cash Investment Strategies', label: 'BNY Mellon Cash Investment Strategies'},
{ value: 'BNY Mellon Investment Mangement', label: 'BNY Mellon Investment Mangement'},
{ value: 'BPI Gestao de Activos', label: 'BPI Gestao de Activos'},
{ value: 'BTG Pactual', label: 'BTG Pactual'},
{ value: 'BV Investment Partners', label: 'BV Investment Partners'},
{ value: 'Baillie Gifford', label: 'Baillie Gifford'},
{ value: 'Baillie Gifford & Company', label: 'Baillie Gifford & Company'},
{ value: 'Bain Capital', label: 'Bain Capital'},
{ value: 'Baird', label: 'Baird'},
{ value: 'Baker Brothers Investments', label: 'Baker Brothers Investments'},
{ value: 'Bakers Investment Group', label: 'Bakers Investment Group'},
{ value: 'Baloise Asset Management', label: 'Baloise Asset Management'},
{ value: 'Balyasny Asset Management', label: 'Balyasny Asset Management'},
{ value: 'Banco Bilbao Vizcaya Argentaria', label: 'Banco Bilbao Vizcaya Argentaria'},
{ value: 'Banco Portugues de Investimento', label: 'Banco Portugues de Investimento'},
{ value: 'Banco Sabadell', label: 'Banco Sabadell'},
{ value: 'Banco do Brasil', label: 'Banco do Brasil'},
{ value: 'Bank Alpinum', label: 'Bank Alpinum'},
{ value: 'Bank Frick & Co', label: 'Bank Frick & Co'},
{ value: 'Bank J. Safra Sarasin', label: 'Bank J. Safra Sarasin'},
{ value: 'Bank Vontobel AG', label: 'Bank Vontobel AG'},
{ value: 'Bank of America', label: 'Bank of America'},
{ value: 'Bank of America Merrill Lynch', label: 'Bank of America Merrill Lynch'},
{ value: 'Bank of China International', label: 'Bank of China International'},
{ value: 'Bank of New York Mellon Corp', label: 'Bank of New York Mellon Corp'},
{ value: 'BankInvest', label: 'BankInvest'},
{ value: 'Bankhaus C. L. Seeliger', label: 'Bankhaus C. L. Seeliger'},
{ value: 'Bankhaus Lampe', label: 'Bankhaus Lampe'},
{ value: 'Bankia Fondos', label: 'Bankia Fondos'},
{ value: 'Bankia Pensiones', label: 'Bankia Pensiones'},
{ value: 'Bankinter', label: 'Bankinter'},
{ value: 'Banque Neuflize OBC', label: 'Banque Neuflize OBC'},
{ value: 'Banque Syz & Co', label: 'Banque Syz & Co'},
{ value: 'Banque de Gestion Privee Indosuez', label: 'Banque de Gestion Privee Indosuez'},
{ value: 'Bantleon Bank', label: 'Bantleon Bank'},
{ value: 'Barclays Capital', label: 'Barclays Capital'},
{ value: 'Barclays Investment Bank', label: 'Barclays Investment Bank'},
{ value: 'Baring Private Equity Asia', label: 'Baring Private Equity Asia'},
{ value: 'Barings', label: 'Barings'},
{ value: 'Barrow', label: 'Barrow'},
{ value: 'Battery Ventures', label: 'Battery Ventures'},
{ value: 'Baupost Group', label: 'Baupost Group'},
{ value: 'BayernInvest', label: 'BayernInvest'},
{ value: 'Bayview Asset Management', label: 'Bayview Asset Management'},
{ value: 'Beach Point Capital Management', label: 'Beach Point Capital Management'},
{ value: 'Belvedere Trading', label: 'Belvedere Trading'},
{ value: 'Benefit Street Partners', label: 'Benefit Street Partners'},
{ value: 'Bentall Kennedy', label: 'Bentall Kennedy'},
{ value: 'Bentley Reid & Co', label: 'Bentley Reid & Co'},
{ value: 'Berenberg Bank', label: 'Berenberg Bank'},
{ value: 'Berkery', label: 'Berkery'},
{ value: 'Berkshire Partners', label: 'Berkshire Partners'},
{ value: 'Bernhard Capital Partners', label: 'Bernhard Capital Partners'},
{ value: 'Bessemer Trust Company', label: 'Bessemer Trust Company'},
{ value: 'Bessemer Venture Partners', label: 'Bessemer Venture Partners'},
{ value: 'Beutel Goodman & Company', label: 'Beutel Goodman & Company'},
{ value: 'BlackRock', label: 'BlackRock'},
{ value: 'Blackstone', label: 'Blackstone'},
{ value: 'Blue Ridge Capital', label: 'Blue Ridge Capital'},
{ value: 'BlueBay Asset Management', label: 'BlueBay Asset Management'},
{ value: 'BlueCrest Capital Management', label: 'BlueCrest Capital Management'},
{ value: 'BlueMountain Capital Management', label: 'BlueMountain Capital Management'},
{ value: 'BlueRun Ventures', label: 'BlueRun Ventures'},
{ value: 'Bluefin Companies', label: 'Bluefin Companies'},
{ value: 'BoCom International', label: 'BoCom International'},
{ value: 'BofA Merrill Lynch', label: 'BofA Merrill Lynch'},
{ value: 'Bordier & Cie', label: 'Bordier & Cie'},
{ value: 'Boston Partners Global Investors', label: 'Boston Partners Global Investors'},
{ value: 'Boussard & Gavaudan Investment', label: 'Boussard & Gavaudan Investment'},
{ value: 'Bouwinvest', label: 'Bouwinvest'},
{ value: 'Bowmark Capital', label: 'Bowmark Capital'},
{ value: 'Boyu Capital', label: 'Boyu Capital'},
{ value: 'Bracebridge Capital', label: 'Bracebridge Capital'},
{ value: 'Bradesco BBI', label: 'Bradesco BBI'},
{ value: 'Bram - Bradesco Asset Management', label: 'Bram - Bradesco Asset Management'},
{ value: 'Brandes Investment Partners', label: 'Brandes Investment Partners'},
{ value: 'Brandywine Global Investment Management', label: 'Brandywine Global Investment Management'},
{ value: 'Breakwater Trading', label: 'Breakwater Trading'},
{ value: 'Brentwood Associates', label: 'Brentwood Associates'},
{ value: 'Brevan Howard Asset Management', label: 'Brevan Howard Asset Management'},
{ value: 'Brewin Dolphin', label: 'Brewin Dolphin'},
{ value: 'Bridgepoint', label: 'Bridgepoint'},
{ value: 'Bridgewater Associates', label: 'Bridgewater Associates'},
{ value: 'Brigade Capital Management', label: 'Brigade Capital Management'},
{ value: 'Bright Trading', label: 'Bright Trading'},
{ value: 'Brookfield AM', label: 'Brookfield AM'},
{ value: 'Brookfield Asset Management', label: 'Brookfield Asset Management'},
{ value: 'Brown Advisory', label: 'Brown Advisory'},
{ value: 'Brown Brothers Harriman', label: 'Brown Brothers Harriman'},
{ value: 'Brummer & Partners', label: 'Brummer & Partners'},
{ value: 'Bryan Garnier', label: 'Bryan Garnier'},
{ value: 'Butterfield Private Bank', label: 'Butterfield Private Bank'},
{ value: 'C Worldwide Asset Management', label: 'C Worldwide Asset Management'},
{ value: 'C. Hoare & Co', label: 'C. Hoare & Co'},
{ value: 'CBC Group', label: 'CBC Group'},
{ value: 'CBRE Global Investors', label: 'CBRE Global Investors'},
{ value: 'CCMP Capital', label: 'CCMP Capital'},
{ value: 'CCSZF Management', label: 'CCSZF Management'},
{ value: 'CDH Investments', label: 'CDH Investments'},
{ value: 'CI Financial Corporation', label: 'CI Financial Corporation'},
{ value: 'CIBC Asset Management', label: 'CIBC Asset Management'},
{ value: 'CIBC World Markets', label: 'CIBC World Markets'},
{ value: 'CIMB Investment Bank', label: 'CIMB Investment Bank'},
{ value: 'CITIC Capital', label: 'CITIC Capital'},
{ value: 'CITIC Securities International', label: 'CITIC Securities International'},
{ value: 'CITICPE', label: 'CITICPE'},
{ value: 'CLSA', label: 'CLSA'},
{ value: 'CLSA Asia-Pacific Markets', label: 'CLSA Asia-Pacific Markets'},
{ value: 'CM-CIC Asset Management', label: 'CM-CIC Asset Management'},
{ value: 'CM-CIC Securities', label: 'CM-CIC Securities'},
{ value: 'CPR Asset Management', label: 'CPR Asset Management'},
{ value: 'CQS', label: 'CQS'},
{ value: 'CSG Partners', label: 'CSG Partners'},
{ value: 'CTI Private Bank', label: 'CTI Private Bank'},
{ value: 'CVC Capital Partners', label: 'CVC Capital Partners'},
{ value: 'Caisse de depot et placement du Quebec', label: 'Caisse de depot et placement du Quebec'},
{ value: 'CaixaBank Asset Management', label: 'CaixaBank Asset Management'},
{ value: 'Calamos Advisors', label: 'Calamos Advisors'},
{ value: 'Canaccord Financial Group', label: 'Canaccord Financial Group'},
{ value: 'Canaccord Genuity', label: 'Canaccord Genuity'},
{ value: 'Candriam', label: 'Candriam'},
{ value: 'Candriam Investors Group', label: 'Candriam Investors Group'},
{ value: 'Cantor Fitzgerald', label: 'Cantor Fitzgerald'},
{ value: 'Canyon Capital', label: 'Canyon Capital'},
{ value: 'CapitaLand', label: 'CapitaLand'},
{ value: 'Capital Dynamics', label: 'Capital Dynamics'},
{ value: 'Capital Four', label: 'Capital Four'},
{ value: 'Capital Fund Management', label: 'Capital Fund Management'},
{ value: 'Capital Group', label: 'Capital Group'},
{ value: 'Capital One Securities', label: 'Capital One Securities'},
{ value: 'Capstone Investment', label: 'Capstone Investment'},
{ value: 'Capstone Partners', label: 'Capstone Partners'},
{ value: 'Capula Investment Management', label: 'Capula Investment Management'},
{ value: 'Capvis Equity Partners', label: 'Capvis Equity Partners'},
{ value: 'CarVal Investors', label: 'CarVal Investors'},
{ value: 'Cardano', label: 'Cardano'},
{ value: 'Carlson Capital', label: 'Carlson Capital'},
{ value: 'Carmignac Gestion', label: 'Carmignac Gestion'},
{ value: 'Carnegie', label: 'Carnegie'},
{ value: 'Castlelake', label: 'Castlelake'},
{ value: 'Cater Allen', label: 'Cater Allen'},
{ value: 'Cathay Capital Private Equity', label: 'Cathay Capital Private Equity'},
{ value: 'Caxton Associates', label: 'Caxton Associates'},
{ value: 'CenterSquare Investment Management', label: 'CenterSquare Investment Management'},
{ value: 'Centerbridge Partners', label: 'Centerbridge Partners'},
{ value: 'Centerview Partners', label: 'Centerview Partners'},
{ value: 'Cerberus Capital Management', label: 'Cerberus Capital Management'},
{ value: 'Cevian Capital', label: 'Cevian Capital'},
{ value: 'Charles Schwab Corporation', label: 'Charles Schwab Corporation'},
{ value: 'Charles Schwab Investment Management', label: 'Charles Schwab Investment Management'},
{ value: 'Charlesbank Capital Partners', label: 'Charlesbank Capital Partners'},
{ value: 'Charterhouse Capital Partners', label: 'Charterhouse Capital Partners'},
{ value: 'Chequers Capital', label: 'Chequers Capital'},
{ value: 'Cheyne Capital', label: 'Cheyne Capital'},
{ value: 'Chicago Trading Company', label: 'Chicago Trading Company'},
{ value: 'China Asset Management Company', label: 'China Asset Management Company'},
{ value: 'China Construction Bank Corporation', label: 'China Construction Bank Corporation'},
{ value: 'China Everbright Limited', label: 'China Everbright Limited'},
{ value: 'China International Capital Corporation', label: 'China International Capital Corporation'},
{ value: 'China Merchant Bank', label: 'China Merchant Bank'},
{ value: 'China Renaissance Group', label: 'China Renaissance Group'},
{ value: 'Chopper Trading', label: 'Chopper Trading'},
{ value: 'ChrysCapital', label: 'ChrysCapital'},
{ value: 'Church House', label: 'Church House'},
{ value: 'Cinven', label: 'Cinven'},
{ value: 'Citadel', label: 'Citadel'},
{ value: 'Citi Investment Research', label: 'Citi Investment Research'},
{ value: 'Citibank', label: 'Citibank'},
{ value: 'Citigroup', label: 'Citigroup'},
{ value: 'Clarion Partners', label: 'Clarion Partners'},
{ value: 'Clarus Ventures', label: 'Clarus Ventures'},
{ value: 'Clayton', label: 'Clayton'},
{ value: 'Clayton Dubilier & Rice', label: 'Clayton Dubilier & Rice'},
{ value: 'ClearBridge Investments', label: 'ClearBridge Investments'},
{ value: 'Clearlake Capital Group', label: 'Clearlake Capital Group'},
{ value: 'Close Brothers Private Banking', label: 'Close Brothers Private Banking'},
{ value: 'Coast Asset Management', label: 'Coast Asset Management'},
{ value: 'Coatue Management', label: 'Coatue Management'},
{ value: 'Cohen & Steers Capital Management', label: 'Cohen & Steers Capital Management'},
{ value: 'Colchester Global Investors', label: 'Colchester Global Investors'},
{ value: 'Collins Stewart', label: 'Collins Stewart'},
{ value: 'Colony NorthStar', label: 'Colony NorthStar'},
{ value: 'Columbia Threadneedle Investments', label: 'Columbia Threadneedle Investments'},
{ value: 'Comac Capital', label: 'Comac Capital'},
{ value: 'Comgest', label: 'Comgest'},
{ value: 'Commerzbank', label: 'Commerzbank'},
{ value: 'Compagnie Financiere Edmond de Rothschild', label: 'Compagnie Financiere Edmond de Rothschild'},
{ value: 'Conning', label: 'Conning'},
{ value: 'Connor', label: 'Connor'},
{ value: 'Copper Street Capital', label: 'Copper Street Capital'},
{ value: 'Cormark Securities', label: 'Cormark Securities'},
{ value: 'Cornell Capital', label: 'Cornell Capital'},
{ value: 'Coronation Fund Managers', label: 'Coronation Fund Managers'},
{ value: 'Corporate Finance Associates', label: 'Corporate Finance Associates'},
{ value: 'Coutts & Co', label: 'Coutts & Co'},
{ value: 'Covea Finance', label: 'Covea Finance'},
{ value: 'Cowen & Company', label: 'Cowen & Company'},
{ value: 'Cowen Inc', label: 'Cowen Inc'},
{ value: 'Credit Agricole CIB', label: 'Credit Agricole CIB'},
{ value: 'Credit Agricole Corporate and Investment Bank', label: 'Credit Agricole Corporate and Investment Bank'},
{ value: 'Credit Agricole Private Bank', label: 'Credit Agricole Private Bank'},
{ value: 'Credit Industriel et Commercial', label: 'Credit Industriel et Commercial'},
{ value: 'Credit Suisse', label: 'Credit Suisse'},
{ value: 'Credit Suisse Asset Management', label: 'Credit Suisse Asset Management'},
{ value: 'Cressey & Company', label: 'Cressey & Company'},
{ value: 'Crestview Partners', label: 'Crestview Partners'},
{ value: 'Cromwell Property Group', label: 'Cromwell Property Group'},
{ value: 'D.E. Shaw', label: 'D.E. Shaw'},
{ value: 'D1 Capital', label: 'D1 Capital'},
{ value: 'DBS Bank', label: 'DBS Bank'},
{ value: 'DBS Private Bank', label: 'DBS Private Bank'},
{ value: 'DDJ Capital', label: 'DDJ Capital'},
{ value: 'DJE Kapital', label: 'DJE Kapital'},
{ value: 'DNB Asset Management AS', label: 'DNB Asset Management AS'},
{ value: 'DNB Investment Bank', label: 'DNB Investment Bank'},
{ value: 'DNCA Investments', label: 'DNCA Investments'},
{ value: 'DRW Trading Group', label: 'DRW Trading Group'},
{ value: 'DTZ Investors', label: 'DTZ Investors'},
{ value: 'DV Trading', label: 'DV Trading'},
{ value: 'DWS', label: 'DWS'},
{ value: 'DZ BANK Investment Research', label: 'DZ BANK Investment Research'},
{ value: 'Daewoo Securities', label: 'Daewoo Securities'},
{ value: 'Daiwa Capital Markets', label: 'Daiwa Capital Markets'},
{ value: 'Daiwa SB Investments', label: 'Daiwa SB Investments'},
{ value: 'Danske Bank Asset Management', label: 'Danske Bank Asset Management'},
{ value: 'Danske Capital', label: 'Danske Capital'},
{ value: 'Davidson Kempner Capital Management', label: 'Davidson Kempner Capital Management'},
{ value: 'Davis Advisors', label: 'Davis Advisors'},
{ value: 'Davy Group', label: 'Davy Group'},
{ value: 'Deerfield Management', label: 'Deerfield Management'},
{ value: 'Degroof Petercam Asset Management', label: 'Degroof Petercam Asset Management'},
{ value: 'Deka Bank', label: 'Deka Bank'},
{ value: 'DekaBank', label: 'DekaBank'},
{ value: 'Denham Capital Management', label: 'Denham Capital Management'},
{ value: 'Desjardins Group', label: 'Desjardins Group'},
{ value: 'Deutsche Bank', label: 'Deutsche Bank'},
{ value: 'Deutsche Beteiligungs AG', label: 'Deutsche Beteiligungs AG'},
{ value: 'Digital Sky Technologies', label: 'Digital Sky Technologies'},
{ value: 'Dimensional Fund Advisors', label: 'Dimensional Fund Advisors'},
{ value: 'Discovery Capital Management', label: 'Discovery Capital Management'},
{ value: 'Dodge & Cox', label: 'Dodge & Cox'},
{ value: 'Donner & Reuschel', label: 'Donner & Reuschel'},
{ value: 'Driehaus Capital Management', label: 'Driehaus Capital Management'},
{ value: 'Du Pasquier Asset Management', label: 'Du Pasquier Asset Management'},
{ value: 'Duff & Phelps', label: 'Duff & Phelps'},
{ value: 'E Fund Management Company', label: 'E Fund Management Company'},
{ value: 'ECI Partners', label: 'ECI Partners'},
{ value: 'EFG Asset Management', label: 'EFG Asset Management'},
{ value: 'EFG Bank von Ernst', label: 'EFG Bank von Ernst'},
{ value: 'EIG Global Energy Partners', label: 'EIG Global Energy Partners'},
{ value: 'EMR Capital', label: 'EMR Capital'},
{ value: 'EQT', label: 'EQT'},
{ value: 'Eagle Asset Management', label: 'Eagle Asset Management'},
{ value: 'Eagletree Capital', label: 'Eagletree Capital'},
{ value: 'Eastspring Investments', label: 'Eastspring Investments'},
{ value: 'Eaton Vance', label: 'Eaton Vance'},
{ value: 'Ecofi Investments', label: 'Ecofi Investments'},
{ value: 'Edinburgh Partners', label: 'Edinburgh Partners'},
{ value: 'Edison Investment Research', label: 'Edison Investment Research'},
{ value: 'Edmond de Rothschild', label: 'Edmond de Rothschild'},
{ value: 'Effissimo Capital', label: 'Effissimo Capital'},
{ value: 'Egerton Capital', label: 'Egerton Capital'},
{ value: 'Eight Capital', label: 'Eight Capital'},
{ value: 'Eisler Capital', label: 'Eisler Capital'},
{ value: 'Elan Capital', label: 'Elan Capital'},
{ value: 'Element Capital Management', label: 'Element Capital Management'},
{ value: 'Ellington Management', label: 'Ellington Management'},
{ value: 'Elliott Management', label: 'Elliott Management'},
{ value: 'Eminence Capital', label: 'Eminence Capital'},
{ value: 'Empyrean Capital', label: 'Empyrean Capital'},
{ value: 'EnCap Investments', label: 'EnCap Investments'},
{ value: 'EnTrust Permal', label: 'EnTrust Permal'},
{ value: 'Energy Spectrum Capital', label: 'Energy Spectrum Capital'},
{ value: 'Epoch Investment Partners', label: 'Epoch Investment Partners'},
{ value: 'Equistone Partners Europe', label: 'Equistone Partners Europe'},
{ value: 'Erste Asset Management', label: 'Erste Asset Management'},
{ value: 'Erste Bank', label: 'Erste Bank'},
{ value: 'Espirito Santo Investment Bank', label: 'Espirito Santo Investment Bank'},
{ value: 'Espirito Santo Investment Research', label: 'Espirito Santo Investment Research'},
{ value: 'Eurazeo', label: 'Eurazeo'},
{ value: 'Eurizon Asset Management', label: 'Eurizon Asset Management'},
{ value: 'Europa Partners', label: 'Europa Partners'},
{ value: 'Evercore', label: 'Evercore'},
{ value: 'Evercore Partners', label: 'Evercore Partners'},
{ value: 'Evli Bank', label: 'Evli Bank'},
{ value: 'Evolution', label: 'Evolution'},
{ value: 'Exane BNP Paribas', label: 'Exane BNP Paribas'},
{ value: 'ExodusPoint Capital', label: 'ExodusPoint Capital'},
{ value: 'Exponent Private Equity', label: 'Exponent Private Equity'},
{ value: 'FBR Capital', label: 'FBR Capital'},
{ value: 'FFL Partners', label: 'FFL Partners'},
{ value: 'FIM Varainhoito Oy', label: 'FIM Varainhoito Oy'},
{ value: 'FSN Capital', label: 'FSN Capital'},
{ value: 'FTV Capital', label: 'FTV Capital'},
{ value: 'Falcon Private Bank', label: 'Falcon Private Bank'},
{ value: 'Farallon Capital', label: 'Farallon Capital'},
{ value: 'Federated Investors', label: 'Federated Investors'},
{ value: 'Fidelity International', label: 'Fidelity International'},
{ value: 'Fidelity Investments', label: 'Fidelity Investments'},
{ value: 'Fiera Capital', label: 'Fiera Capital'},
{ value: 'Financo', label: 'Financo'},
{ value: 'Fir Tree Partners', label: 'Fir Tree Partners'},
{ value: 'First Eagle Investment Management', label: 'First Eagle Investment Management'},
{ value: 'First New York', label: 'First New York'},
{ value: 'First Quadrant', label: 'First Quadrant'},
{ value: 'First Reserve', label: 'First Reserve'},
{ value: 'First State Investments', label: 'First State Investments'},
{ value: 'Fisch Asset Management', label: 'Fisch Asset Management'},
{ value: 'Fisher Investments', label: 'Fisher Investments'},
{ value: 'Five Point Energy', label: 'Five Point Energy'},
{ value: 'Flagship Pioneering', label: 'Flagship Pioneering'},
{ value: 'Flossbach & von Storch', label: 'Flossbach & von Storch'},
{ value: 'Flow Traders', label: 'Flow Traders'},
{ value: 'Fondo FSI', label: 'Fondo FSI'},
{ value: 'Foresite Capital Management', label: 'Foresite Capital Management'},
{ value: 'Fortress Investment Group', label: 'Fortress Investment Group'},
{ value: 'Founders Fund', label: 'Founders Fund'},
{ value: 'Foundry Group', label: 'Foundry Group'},
{ value: 'FountainVest Partners', label: 'FountainVest Partners'},
{ value: 'Foyston', label: 'Foyston'},
{ value: 'Francisco Partners', label: 'Francisco Partners'},
{ value: 'Franklin Templeton Investments', label: 'Franklin Templeton Investments'},
{ value: 'Frazier Healthcare Partners', label: 'Frazier Healthcare Partners'},
{ value: 'Freeman Spogli & Co', label: 'Freeman Spogli & Co'},
{ value: 'Frontier Capital Management', label: 'Frontier Capital Management'},
{ value: 'GAM', label: 'GAM'},
{ value: 'GAM Holding', label: 'GAM Holding'},
{ value: 'GCM Grosvenor', label: 'GCM Grosvenor'},
{ value: 'GGV Capital', label: 'GGV Capital'},
{ value: 'GI Partners', label: 'GI Partners'},
{ value: 'GLP', label: 'GLP'},
{ value: 'GMO', label: 'GMO'},
{ value: 'GMT Capital', label: 'GMT Capital'},
{ value: 'GNB Gestao de Ativos', label: 'GNB Gestao de Ativos'},
{ value: 'GSA Capital', label: 'GSA Capital'},
{ value: 'GSR Ventures', label: 'GSR Ventures'},
{ value: 'GTCR', label: 'GTCR'},
{ value: 'GW&K Investment Management', label: 'GW&K Investment Management'},
{ value: 'Galliard Capital Management', label: 'Galliard Capital Management'},
{ value: 'Gaorong Capital', label: 'Gaorong Capital'},
{ value: 'Garda Capital', label: 'Garda Capital'},
{ value: 'Gateway Investment Advisers', label: 'Gateway Investment Advisers'},
{ value: 'Gelber Group', label: 'Gelber Group'},
{ value: 'General Atlantic', label: 'General Atlantic'},
{ value: 'General Catalyst Partners', label: 'General Catalyst Partners'},
{ value: 'Generali Investments', label: 'Generali Investments'},
{ value: 'Generation Investment Management', label: 'Generation Investment Management'},
{ value: 'Genesis Asset Managers', label: 'Genesis Asset Managers'},
{ value: 'Geneva Trading', label: 'Geneva Trading'},
{ value: 'Genstar Capital', label: 'Genstar Capital'},
{ value: 'Geode Capital Management', label: 'Geode Capital Management'},
{ value: 'Gilde Buy Out Partners', label: 'Gilde Buy Out Partners'},
{ value: 'Gleacher & Company', label: 'Gleacher & Company'},
{ value: 'Glenview Capital Management', label: 'Glenview Capital Management'},
{ value: 'GoldPoint Partners', label: 'GoldPoint Partners'},
{ value: 'GoldenTree Asset Management', label: 'GoldenTree Asset Management'},
{ value: 'Goldman Sachs', label: 'Goldman Sachs'},
{ value: 'Goldman Sachs Asset Management', label: 'Goldman Sachs Asset Management'},
{ value: 'Goldman Sachs Capital Partners', label: 'Goldman Sachs Capital Partners'},
{ value: 'Goldman Sachs Merchant Banking Division', label: 'Goldman Sachs Merchant Banking Division'},
{ value: 'Golub Capital', label: 'Golub Capital'},
{ value: 'Gothaer Asset Management', label: 'Gothaer Asset Management'},
{ value: 'Grace Hall Trading', label: 'Grace Hall Trading'},
{ value: 'Graham Capital Management', label: 'Graham Capital Management'},
{ value: 'Grantham Mayo Van Otterloo', label: 'Grantham Mayo Van Otterloo'},
{ value: 'Graticule Asia Macro Advisors', label: 'Graticule Asia Macro Advisors'},
{ value: 'Great Hill Partners', label: 'Great Hill Partners'},
{ value: 'Great Point Capital', label: 'Great Point Capital'},
{ value: 'Greenbriar Equity Group', label: 'Greenbriar Equity Group'},
{ value: 'Greenhill & Company', label: 'Greenhill & Company'},
{ value: 'Group One Trading', label: 'Group One Trading'},
{ value: 'Groupama Asset Management', label: 'Groupama Asset Management'},
{ value: 'Gryphon Investors', label: 'Gryphon Investors'},
{ value: 'Guggenheim Investments', label: 'Guggenheim Investments'},
{ value: 'Guggenheim Partners', label: 'Guggenheim Partners'},
{ value: 'Guggenheim Securities', label: 'Guggenheim Securities'},
{ value: 'Guosen Partners', label: 'Guosen Partners'},
{ value: 'H.I.G. Capital', label: 'H.I.G. Capital'},
{ value: 'H/2 Capital', label: 'H/2 Capital'},
{ value: 'H2O Asset Management', label: 'H2O Asset Management'},
{ value: 'HBK Capital Management', label: 'HBK Capital Management'},
{ value: 'HFT Investment Management', label: 'HFT Investment Management'},
{ value: 'HG Vora Capital', label: 'HG Vora Capital'},
{ value: 'HGGC', label: 'HGGC'},
{ value: 'HPS Investment Partners', label: 'HPS Investment Partners'},
{ value: 'HQ Capital', label: 'HQ Capital'},
{ value: 'HSBC', label: 'HSBC'},
{ value: 'HSBC Global Asset Management', label: 'HSBC Global Asset Management'},
{ value: 'HSBC Holdings PLC', label: 'HSBC Holdings PLC'},
{ value: 'HSBC Private Bank', label: 'HSBC Private Bank'},
{ value: 'HTC Corporation', label: 'HTC Corporation'},
{ value: 'Hahn & Company', label: 'Hahn & Company'},
{ value: 'Haidar Capital', label: 'Haidar Capital'},
{ value: 'Haitong Bank', label: 'Haitong Bank'},
{ value: 'Hamilton Lane', label: 'Hamilton Lane'},
{ value: 'Hamilton Lane Advisors', label: 'Hamilton Lane Advisors'},
{ value: 'Handelsbanken', label: 'Handelsbanken'},
{ value: 'HarbourVest Partners', label: 'HarbourVest Partners'},
{ value: 'Hard Eight Trading', label: 'Hard Eight Trading'},
{ value: 'Harding Loevner', label: 'Harding Loevner'},
{ value: 'Hardman & Co', label: 'Hardman & Co'},
{ value: 'Harris Associates', label: 'Harris Associates'},
{ value: 'Hartford Investment Managment', label: 'Hartford Investment Managment'},
{ value: 'Harvest Global Investments', label: 'Harvest Global Investments'},
{ value: 'Harvest Partners', label: 'Harvest Partners'},
{ value: 'Hauck & Aufhauser', label: 'Hauck & Aufhauser'},
{ value: 'Hauck & Aufhauser Privatbankiers', label: 'Hauck & Aufhauser Privatbankiers'},
{ value: 'Hayfin Capital Management', label: 'Hayfin Capital Management'},
{ value: 'Haywood Securities', label: 'Haywood Securities'},
{ value: 'Hehmeyer Trading & Investments', label: 'Hehmeyer Trading & Investments'},
{ value: 'Heikkenen Energy', label: 'Heikkenen Energy'},
{ value: 'Heitman', label: 'Heitman'},
{ value: 'Helaba Invest', label: 'Helaba Invest'},
{ value: 'Hellman & Friedman', label: 'Hellman & Friedman'},
{ value: 'Henderson Global Investors', label: 'Henderson Global Investors'},
{ value: 'Hermes GPE', label: 'Hermes GPE'},
{ value: 'Hermes Investment Management', label: 'Hermes Investment Management'},
{ value: 'Hg', label: 'Hg'},
{ value: 'Highfields Capital Management', label: 'Highfields Capital Management'},
{ value: 'Highland Capital Management', label: 'Highland Capital Management'},
{ value: 'Highland Capital Partners Europe', label: 'Highland Capital Partners Europe'},
{ value: 'Hillhouse Capital Group', label: 'Hillhouse Capital Group'},
{ value: 'Hillhouse Capital Management', label: 'Hillhouse Capital Management'},
{ value: 'Himalaya Capital', label: 'Himalaya Capital'},
{ value: 'Hines', label: 'Hines'},
{ value: 'HitecVision', label: 'HitecVision'},
{ value: 'Hodge Bank', label: 'Hodge Bank'},
{ value: 'Hold Brothers', label: 'Hold Brothers'},
{ value: 'Holocene Advisors', label: 'Holocene Advisors'},
{ value: 'Hony Capital', label: 'Hony Capital'},
{ value: 'Hopu Investment Management', label: 'Hopu Investment Management'},
{ value: 'Horsley Bridge Partners', label: 'Horsley Bridge Partners'},
{ value: 'Houlihan Lokey', label: 'Houlihan Lokey'},
{ value: 'Hudson Bay Capital', label: 'Hudson Bay Capital'},
{ value: 'Hudson River Trading', label: 'Hudson River Trading'},
{ value: 'Hyposwiss Private Bank', label: 'Hyposwiss Private Bank'},
{ value: 'ICBC', label: 'ICBC'},
{ value: 'ICICI Bank', label: 'ICICI Bank'},
{ value: 'ICONIQ Capital', label: 'ICONIQ Capital'},
{ value: 'IDG Capital', label: 'IDG Capital'},
{ value: 'IFC Asset Management Company', label: 'IFC Asset Management Company'},
{ value: 'IFM Investors', label: 'IFM Investors'},
{ value: 'IGM Financial', label: 'IGM Financial'},
{ value: 'III Capital', label: 'III Capital'},
{ value: 'IK Investment Partners', label: 'IK Investment Partners'},
{ value: 'IKB Deutsche Industriebank', label: 'IKB Deutsche Industriebank'},
{ value: 'IMC Financial Markets', label: 'IMC Financial Markets'},
{ value: 'IMM Private Equity', label: 'IMM Private Equity'},
{ value: 'ING Group', label: 'ING Group'},
{ value: 'IPM Informed Portfolio Management', label: 'IPM Informed Portfolio Management'},
{ value: 'IVP', label: 'IVP'},
{ value: 'Ibercaja Pension', label: 'Ibercaja Pension'},
{ value: 'Impax Asset Management', label: 'Impax Asset Management'},
{ value: 'Imperial Capital', label: 'Imperial Capital'},
{ value: 'Index Ventures', label: 'Index Ventures'},
{ value: 'Indian Bank', label: 'Indian Bank'},
{ value: 'Industrial and Commercial Bank of China Limited', label: 'Industrial and Commercial Bank of China Limited'},
{ value: 'Inflexion Private Equity', label: 'Inflexion Private Equity'},
{ value: 'Insight Partners', label: 'Insight Partners'},
{ value: 'Integra Capital', label: 'Integra Capital'},
{ value: 'Intermediate Capital Group', label: 'Intermediate Capital Group'},
{ value: 'Intesa Sanpaolo', label: 'Intesa Sanpaolo'},
{ value: 'Investa Property Group', label: 'Investa Property Group'},
{ value: 'Investec Asset Management', label: 'Investec Asset Management'},
{ value: 'Investindustrial', label: 'Investindustrial'},
{ value: 'Irish Life Investment Managers', label: 'Irish Life Investment Managers'},
{ value: 'J O Hambro Capital Management', label: 'J O Hambro Capital Management'},
{ value: 'J.P. Morgan', label: 'J.P. Morgan'},
{ value: 'J.P. Morgan Asset Management', label: 'J.P. Morgan Asset Management'},
{ value: 'JAFCO', label: 'JAFCO'},
{ value: 'JLL Partners', label: 'JLL Partners'},
{ value: 'JMI Equity', label: 'JMI Equity'},
{ value: 'JP Morgan Asset Management', label: 'JP Morgan Asset Management'},
{ value: 'Jacob Securities Inc', label: 'Jacob Securities Inc'},
{ value: 'Jacobs Levy Equity Management', label: 'Jacobs Levy Equity Management'},
{ value: 'Jane Street', label: 'Jane Street'},
{ value: 'Janney Investment Research', label: 'Janney Investment Research'},
{ value: 'Jefferies & Co.', label: 'Jefferies & Co.'},
{ value: 'Jefferies & Company', label: 'Jefferies & Company'},
{ value: 'Jefferies Group', label: 'Jefferies Group'},
{ value: 'Jefferies Investment Research', label: 'Jefferies Investment Research'},
{ value: 'Joh. Berenberg', label: 'Joh. Berenberg'},
{ value: 'John Hancock Investments', label: 'John Hancock Investments'},
{ value: 'Johnson Rice & Company', label: 'Johnson Rice & Company'},
{ value: 'Julius Baer', label: 'Julius Baer'},
{ value: 'Jump Trading', label: 'Jump Trading'},
{ value: 'Juniper Capital', label: 'Juniper Capital'},
{ value: 'Jupiter Asset Management', label: 'Jupiter Asset Management'},
{ value: 'Jyske Capital', label: 'Jyske Capital'},
{ value: 'KBC Asset Management', label: 'KBC Asset Management'},
{ value: 'KBC Bank', label: 'KBC Bank'},
{ value: 'KBI Global Investors', label: 'KBI Global Investors'},
{ value: 'KGAL Group', label: 'KGAL Group'},
{ value: 'KKR', label: 'KKR'},
{ value: 'KLP Kapitalforvaltning', label: 'KLP Kapitalforvaltning'},
{ value: 'KLS Diversified Asset Management', label: 'KLS Diversified Asset Management'},
{ value: 'KSL Capital Partners', label: 'KSL Capital Partners'},
{ value: 'Kairos Partners', label: 'Kairos Partners'},
{ value: 'Kaiser Partner Privatbank', label: 'Kaiser Partner Privatbank'},
{ value: 'Kames Capital', label: 'Kames Capital'},
{ value: 'Kayne Anderson Capital Advisors', label: 'Kayne Anderson Capital Advisors'},
{ value: 'Keefe', label: 'Keefe'},
{ value: 'Keefe Bruyette & Woods', label: 'Keefe Bruyette & Woods'},
{ value: 'Kelso & Company', label: 'Kelso & Company'},
{ value: 'Kempen Capital Management', label: 'Kempen Capital Management'},
{ value: 'Kensico Capital', label: 'Kensico Capital'},
{ value: 'Kepler Capital Markets', label: 'Kepler Capital Markets'},
{ value: 'Kepler Cheuvreux', label: 'Kepler Cheuvreux'},
{ value: 'Kepler-Fonds', label: 'Kepler-Fonds'},
{ value: 'Kershner Trading Group', label: 'Kershner Trading Group'},
{ value: 'KeyBanc Capital Markets', label: 'KeyBanc Capital Markets'},
{ value: 'KeyCorp', label: 'KeyCorp'},
{ value: 'Killik & Co', label: 'Killik & Co'},
{ value: 'Kinderhook Industries', label: 'Kinderhook Industries'},
{ value: 'King Street Capital Management', label: 'King Street Capital Management'},
{ value: 'Kleiner Perkins Caufield & Byers', label: 'Kleiner Perkins Caufield & Byers'},
{ value: 'Kleinwort Benson Private Bank Ltd', label: 'Kleinwort Benson Private Bank Ltd'},
{ value: 'Kohlberg & Company', label: 'Kohlberg & Company'},
{ value: 'Kutxabank Gestion', label: 'Kutxabank Gestion'},
{ value: 'L Catterton', label: 'L Catterton'},
{ value: 'LBBW - Investment Research', label: 'LBBW - Investment Research'},
{ value: 'LBBW Asset Management', label: 'LBBW Asset Management'},
{ value: 'LGT Bank', label: 'LGT Bank'},
{ value: 'LGT Capital Partners', label: 'LGT Capital Partners'},
{ value: 'LHV Bank', label: 'LHV Bank'},
{ value: 'LLR Partners', label: 'LLR Partners'},
{ value: 'LSV Asset Management', label: 'LSV Asset Management'},
{ value: 'La Banque Postale Asset Management', label: 'La Banque Postale Asset Management'},
{ value: 'La Financiere de lEchiquier', label: 'La Financiere de lEchiquier'},
{ value: 'La Francaise', label: 'La Francaise'},
{ value: 'La Roche & Co', label: 'La Roche & Co'},
{ value: 'LaSalle Investment Management', label: 'LaSalle Investment Management'},
{ value: 'Ladenburg Thalmann', label: 'Ladenburg Thalmann'},
{ value: 'Lakewood Capital', label: 'Lakewood Capital'},
{ value: 'Lancaster Pollard', label: 'Lancaster Pollard'},
{ value: 'Landmark Partners', label: 'Landmark Partners'},
{ value: 'Landolt & Cie', label: 'Landolt & Cie'},
{ value: 'Lansdowne Partners', label: 'Lansdowne Partners'},
{ value: 'Laurion Capital', label: 'Laurion Capital'},
{ value: 'Lazard', label: 'Lazard'},
{ value: 'Lazard Alternatives', label: 'Lazard Alternatives'},
{ value: 'Lazard Asset Management', label: 'Lazard Asset Management'},
{ value: 'Lead Edge Capital', label: 'Lead Edge Capital'},
{ value: 'League Trading', label: 'League Trading'},
{ value: 'Legae Securities', label: 'Legae Securities'},
{ value: 'Legal & General Investment Management', label: 'Legal & General Investment Management'},
{ value: 'Legend Capital', label: 'Legend Capital'},
{ value: 'Legg Mason', label: 'Legg Mason'},
{ value: 'Lendlease Investment Management', label: 'Lendlease Investment Management'},
{ value: 'Lenovo Group', label: 'Lenovo Group'},
{ value: 'Leonard Green & Partners', label: 'Leonard Green & Partners'},
{ value: 'Leopold Johnson & Sons', label: 'Leopold Johnson & Sons'},
{ value: 'Liberum Capital', label: 'Liberum Capital'},
{ value: 'Liechtenstein Landesbank', label: 'Liechtenstein Landesbank'},
{ value: 'Life Sciences Partners', label: 'Life Sciences Partners'},
{ value: 'Lighthouse Investment Partners', label: 'Lighthouse Investment Partners'},
{ value: 'Lightspeed Venture Partners', label: 'Lightspeed Venture Partners'},
{ value: 'Lime Rock Partners', label: 'Lime Rock Partners'},
{ value: 'Lincoln International', label: 'Lincoln International'},
{ value: 'Linden Advisors', label: 'Linden Advisors'},
{ value: 'Linden Capital Partners', label: 'Linden Capital Partners'},
{ value: 'Lindsay Goldberg', label: 'Lindsay Goldberg'},
{ value: 'Lindsell Train Limited', label: 'Lindsell Train Limited'},
{ value: 'Liontrust Asset Management', label: 'Liontrust Asset Management'},
{ value: 'Littlejohn & Co', label: 'Littlejohn & Co'},
{ value: 'Littlejohn & Co.', label: 'Littlejohn & Co.'},
{ value: 'Livingbridge', label: 'Livingbridge'},
{ value: 'Lloyds Banking Group Plc', label: 'Lloyds Banking Group Plc'},
{ value: 'Lobnek Wealth Management', label: 'Lobnek Wealth Management'},
{ value: 'LocalTapiola Asset Management', label: 'LocalTapiola Asset Management'},
{ value: 'Logan Circle Partners', label: 'Logan Circle Partners'},
{ value: 'Lombard Odier Darier Hentsch & Cie', label: 'Lombard Odier Darier Hentsch & Cie'},
{ value: 'Lombard Odier Investment Managers', label: 'Lombard Odier Investment Managers'},
{ value: 'Lone Pine Capital', label: 'Lone Pine Capital'},
{ value: 'Loomis Sayles & Company', label: 'Loomis Sayles & Company'},
{ value: 'Lord Abbett & Company', label: 'Lord Abbett & Company'},
{ value: 'Los Angeles CM and Equity Research', label: 'Los Angeles CM and Equity Research'},
{ value: 'Lovell Minnick Partners', label: 'Lovell Minnick Partners'},
{ value: 'Luminus Management', label: 'Luminus Management'},
{ value: 'Lupus alpha Asset Management', label: 'Lupus alpha Asset Management'},
{ value: 'Luxor Capital', label: 'Luxor Capital'},
{ value: 'Lyxor Asset Management', label: 'Lyxor Asset Management'},
{ value: 'M Square Investimentos', label: 'M Square Investimentos'},
{ value: 'M&G Investments', label: 'M&G Investments'},
{ value: 'M&G Prudential', label: 'M&G Prudential'},
{ value: 'M&T Bank', label: 'M&T Bank'},
{ value: 'M.M. Warburg & Company', label: 'M.M. Warburg & Company'},
{ value: 'MBK Partners', label: 'MBK Partners'},
{ value: 'MEAG', label: 'MEAG'},
{ value: 'MFG Asset Management', label: 'MFG Asset Management'},
{ value: 'MFS Investment Management', label: 'MFS Investment Management'},
{ value: 'MKM Partners', label: 'MKM Partners'},
{ value: 'MKP Capital Management', label: 'MKP Capital Management'},
{ value: 'MLP Finanzdienstleistungen AG', label: 'MLP Finanzdienstleistungen AG'},
{ value: 'MN', label: 'MN'},
{ value: 'MacKay Shields', label: 'MacKay Shields'},
{ value: 'Macquarie Asset Management', label: 'Macquarie Asset Management'},
{ value: 'Macquarie Group', label: 'Macquarie Group'},
{ value: 'Macquarie Securities', label: 'Macquarie Securities'},
{ value: 'Madison Dearborn Partners', label: 'Madison Dearborn Partners'},
{ value: 'Maerki Baumann & Co', label: 'Maerki Baumann & Co'},
{ value: 'Magnetar Capital', label: 'Magnetar Capital'},
{ value: 'Maj Invest', label: 'Maj Invest'},
{ value: 'Mako Group', label: 'Mako Group'},
{ value: 'Man Group', label: 'Man Group'},
{ value: 'Managed Portfolio Advisors', label: 'Managed Portfolio Advisors'},
{ value: 'Manulife Asset Management', label: 'Manulife Asset Management'},
{ value: 'Maple Capital Advisors', label: 'Maple Capital Advisors'},
{ value: 'Maple-Brown Abbott', label: 'Maple-Brown Abbott'},
{ value: 'Marathon Asset Management', label: 'Marathon Asset Management'},
{ value: 'Marathon Capital', label: 'Marathon Capital'},
{ value: 'March Asset Management', label: 'March Asset Management'},
{ value: 'Mariner Investment', label: 'Mariner Investment'},
{ value: 'Marlin Equity Partners', label: 'Marlin Equity Partners'},
{ value: 'Marquette Partners', label: 'Marquette Partners'},
{ value: 'Marshall Wace', label: 'Marshall Wace'},
{ value: 'Marunouchi Capital', label: 'Marunouchi Capital'},
{ value: 'Matrix Partners', label: 'Matrix Partners'},
{ value: 'Maverick Capital', label: 'Maverick Capital'},
{ value: 'Maybank', label: 'Maybank'},
{ value: 'Maybank Kim Eng', label: 'Maybank Kim Eng'},
{ value: 'Mayfair Equity Partners', label: 'Mayfair Equity Partners'},
{ value: 'McColl Partners', label: 'McColl Partners'},
{ value: 'McDonnell Investment Management', label: 'McDonnell Investment Management'},
{ value: 'Mediobanca', label: 'Mediobanca'},
{ value: 'Mediolanum Group', label: 'Mediolanum Group'},
{ value: 'Mellon Capital', label: 'Mellon Capital'},
{ value: 'Melvin & Co', label: 'Melvin & Co'},
{ value: 'Melvin Capital', label: 'Melvin Capital'},
{ value: 'Menlo Ventures', label: 'Menlo Ventures'},
{ value: 'Merck Finck & Co', label: 'Merck Finck & Co'},
{ value: 'Meritech Capital Partners', label: 'Meritech Capital Partners'},
{ value: 'Merrill Lynch', label: 'Merrill Lynch'},
{ value: 'Merriman Equity Research', label: 'Merriman Equity Research'},
{ value: 'Mesirow Financial Currency Management', label: 'Mesirow Financial Currency Management'},
{ value: 'MetLife Investment Management', label: 'MetLife Investment Management'},
{ value: 'Metacapital Management LP', label: 'Metacapital Management LP'},
{ value: 'Metro Banking', label: 'Metro Banking'},
{ value: 'Metzler Asset Management', label: 'Metzler Asset Management'},
{ value: 'Metzler Bank', label: 'Metzler Bank'},
{ value: 'Mid Europa Partners', label: 'Mid Europa Partners'},
{ value: 'MidOcean Partners', label: 'MidOcean Partners'},
{ value: 'Millennium Global Investments', label: 'Millennium Global Investments'},
{ value: 'Millennium Management', label: 'Millennium Management'},
{ value: 'Miller Buckfire & Co.', label: 'Miller Buckfire & Co.'},
{ value: 'Mirabaud Group', label: 'Mirabaud Group'},
{ value: 'Mirae Asset Global Investments', label: 'Mirae Asset Global Investments'},
{ value: 'Mirae Asset Group', label: 'Mirae Asset Group'},
{ value: 'Mirova', label: 'Mirova'},
{ value: 'Mitsubishi UFJ Financial Group', label: 'Mitsubishi UFJ Financial Group'},
{ value: 'Mitsubishi UFJ Trust and Banking', label: 'Mitsubishi UFJ Trust and Banking'},
{ value: 'Mizuho Financial Group', label: 'Mizuho Financial Group'},
{ value: 'Moelis & Company', label: 'Moelis & Company'},
{ value: 'Monarch Alternative Capital', label: 'Monarch Alternative Capital'},
{ value: 'Mondrian Investment Partners', label: 'Mondrian Investment Partners'},
{ value: 'Montagu Private Equity', label: 'Montagu Private Equity'},
{ value: 'Montgomery  & Co.', label: 'Montgomery  & Co.'},
{ value: 'Moore Capital Management', label: 'Moore Capital Management'},
{ value: 'Morgan Keegan & Co.', label: 'Morgan Keegan & Co.'},
{ value: 'Morgan Stanley', label: 'Morgan Stanley'},
{ value: 'Morgan Stanley Investment Management', label: 'Morgan Stanley Investment Management'},
{ value: 'Morningside Venture Capital', label: 'Morningside Venture Capital'},
{ value: 'Motilal Oswal Securities', label: 'Motilal Oswal Securities'},
{ value: 'Multi-Bank Securities Inc.', label: 'Multi-Bank Securities Inc.'},
{ value: 'Multiples Alternate Asset Management', label: 'Multiples Alternate Asset Management'},
{ value: 'Muzinich & Company', label: 'Muzinich & Company'},
{ value: 'Myriad Asset Management', label: 'Myriad Asset Management'},
{ value: 'NBF', label: 'NBF'},
{ value: 'NGP Energy Capital Management', label: 'NGP Energy Capital Management'},
{ value: 'NN Investment Partners', label: 'NN Investment Partners'},
{ value: 'NWI Management', label: 'NWI Management'},
{ value: 'NatWest Markets', label: 'NatWest Markets'},
{ value: 'National Bank Financial Wealth Management', label: 'National Bank Financial Wealth Management'},
{ value: 'Natixis', label: 'Natixis'},
{ value: 'Natixis Investment Managers', label: 'Natixis Investment Managers'},
{ value: 'Natixis Securities', label: 'Natixis Securities'},
{ value: 'Nautic Partners', label: 'Nautic Partners'},
{ value: 'Navis Capital Partners', label: 'Navis Capital Partners'},
{ value: 'Needham & Co.', label: 'Needham & Co.'},
{ value: 'Nephila Capital', label: 'Nephila Capital'},
{ value: 'Neuberger Berman Group', label: 'Neuberger Berman Group'},
{ value: 'Neue Bank', label: 'Neue Bank'},
{ value: 'New England Asset Management Limited', label: 'New England Asset Management Limited'},
{ value: 'New Enterprise Associates', label: 'New Enterprise Associates'},
{ value: 'New Mountain Capital', label: 'New Mountain Capital'},
{ value: 'New View Capital', label: 'New View Capital'},
{ value: 'New York Life', label: 'New York Life'},
{ value: 'New York Life Investment Management', label: 'New York Life Investment Management'},
{ value: 'Newton investment Management', label: 'Newton investment Management'},
{ value: 'Nikko Asset Management', label: 'Nikko Asset Management'},
{ value: 'Nomura Asset Management', label: 'Nomura Asset Management'},
{ value: 'Nomura Global Research', label: 'Nomura Global Research'},
{ value: 'Nomura Holdings', label: 'Nomura Holdings'},
{ value: 'Nomura Securities', label: 'Nomura Securities'},
{ value: 'Norbolsa', label: 'Norbolsa'},
{ value: 'Nord/LB Private Banking', label: 'Nord/LB Private Banking'},
{ value: 'Nordea', label: 'Nordea'},
{ value: 'Nordea Investment Management', label: 'Nordea Investment Management'},
{ value: 'Nordea Markets', label: 'Nordea Markets'},
{ value: 'Nordic Capital', label: 'Nordic Capital'},
{ value: 'Northern Trust', label: 'Northern Trust'},
{ value: 'Northern Trust Asset Management', label: 'Northern Trust Asset Management'},
{ value: 'Northill Capital', label: 'Northill Capital'},
{ value: 'Northland Securities', label: 'Northland Securities'},
{ value: 'Norwest Equity Partners', label: 'Norwest Equity Partners'},
{ value: 'Norwest Venture Partners', label: 'Norwest Venture Partners'},
{ value: 'NovaQuest Capital Management', label: 'NovaQuest Capital Management'},
{ value: 'Numis Securities', label: 'Numis Securities'},
{ value: 'Nuveen', label: 'Nuveen'},
{ value: 'Nykredit Asset Management', label: 'Nykredit Asset Management'},
{ value: 'OFI Asset Management', label: 'OFI Asset Management'},
{ value: 'OP Financial Group', label: 'OP Financial Group'},
{ value: 'Oak Hill Advisors', label: 'Oak Hill Advisors'},
{ value: 'Oak Hill Capital Partners', label: 'Oak Hill Capital Partners'},
{ value: 'Oakley Capital Private Equity', label: 'Oakley Capital Private Equity'},
{ value: 'Oaktree Capital Management', label: 'Oaktree Capital Management'},
{ value: 'Oceanwood Capital', label: 'Oceanwood Capital'},
{ value: 'Och-Ziff Capital Management', label: 'Och-Ziff Capital Management'},
{ value: 'Oddo BHF Asset Management SAS', label: 'Oddo BHF Asset Management SAS'},
{ value: 'Oddo Securities', label: 'Oddo Securities'},
{ value: 'Odyssey Investment Partners', label: 'Odyssey Investment Partners'},
{ value: 'Old Ironsides Energy', label: 'Old Ironsides Energy'},
{ value: 'Old Mutual Investment Group', label: 'Old Mutual Investment Group'},
{ value: 'Olympus Partners', label: 'Olympus Partners'},
{ value: 'One Equity Partners', label: 'One Equity Partners'},
{ value: 'One Rock Capital Partners', label: 'One Rock Capital Partners'},
{ value: 'One William Street Capital', label: 'One William Street Capital'},
{ value: 'Onex', label: 'Onex'},
{ value: 'Oppenheimer & Co.', label: 'Oppenheimer & Co.'},
{ value: 'Oppenheimer & Company', label: 'Oppenheimer & Company'},
{ value: 'Optiver', label: 'Optiver'},
{ value: 'OrbiMed Advisors', label: 'OrbiMed Advisors'},
{ value: 'Orbis Investment Management', label: 'Orbis Investment Management'},
{ value: 'Orchard Global', label: 'Orchard Global'},
{ value: 'Orchid Asia', label: 'Orchid Asia'},
{ value: 'Oriel Securities', label: 'Oriel Securities'},
{ value: 'Ostrum Asset Management', label: 'Ostrum Asset Management'},
{ value: 'Oxford Asset Management', label: 'Oxford Asset Management'},
{ value: 'PAAMCO Prisma Holdings', label: 'PAAMCO Prisma Holdings'},
{ value: 'PAG', label: 'PAG'},
{ value: 'PAI Partners', label: 'PAI Partners'},
{ value: 'PAR Capital', label: 'PAR Capital'},
{ value: 'PDT Partners', label: 'PDT Partners'},
{ value: 'PGGM', label: 'PGGM'},
{ value: 'PGIM', label: 'PGIM'},
{ value: 'PIMCO', label: 'PIMCO'},
{ value: 'PJ', label: 'PJ'},
{ value: 'PNC Financial Services Group', label: 'PNC Financial Services Group'},
{ value: 'Pacific Equity Partners', label: 'Pacific Equity Partners'},
{ value: 'Pai Partners', label: 'Pai Partners'},
{ value: 'Palladium Equity Partners', label: 'Palladium Equity Partners'},
{ value: 'Paloma Partners', label: 'Paloma Partners'},
{ value: 'Pamplona Capital Management', label: 'Pamplona Capital Management'},
{ value: 'PanAgora Asset Management', label: 'PanAgora Asset Management'},
{ value: 'Panagora Asset Management', label: 'Panagora Asset Management'},
{ value: 'Panmure Gordon', label: 'Panmure Gordon'},
{ value: 'Pantheon', label: 'Pantheon'},
{ value: 'Paradigm Capital', label: 'Paradigm Capital'},
{ value: 'Parallax Volatility Advisers', label: 'Parallax Volatility Advisers'},
{ value: 'Partech', label: 'Partech'},
{ value: 'Partner Fund Management', label: 'Partner Fund Management'},
{ value: 'Partners Group', label: 'Partners Group'},
{ value: 'Pathway Capital Management', label: 'Pathway Capital Management'},
{ value: 'Patria Investments', label: 'Patria Investments'},
{ value: 'Patrizia Immobilien', label: 'Patrizia Immobilien'},
{ value: 'Patron Capital Advisors', label: 'Patron Capital Advisors'},
{ value: 'Paulson & Co.', label: 'Paulson & Co.'},
{ value: 'Payden & Rygel Global', label: 'Payden & Rygel Global'},
{ value: 'Peak Rock Capital', label: 'Peak Rock Capital'},
{ value: 'Peak6 Trading', label: 'Peak6 Trading'},
{ value: 'Pearl Energy Investments', label: 'Pearl Energy Investments'},
{ value: 'Peel Hunt', label: 'Peel Hunt'},
{ value: 'Pelham Capital', label: 'Pelham Capital'},
{ value: 'Penn Capital', label: 'Penn Capital'},
{ value: 'Pentwater Capital', label: 'Pentwater Capital'},
{ value: 'Perella Weinberg Partners', label: 'Perella Weinberg Partners'},
{ value: 'Permira Advisers', label: 'Permira Advisers'},
{ value: 'Pershing Square Capital Management', label: 'Pershing Square Capital Management'},
{ value: 'Peters & Company', label: 'Peters & Company'},
{ value: 'Petro Lotus', label: 'Petro Lotus'},
{ value: 'Pharo Management', label: 'Pharo Management'},
{ value: 'Picet & Cie', label: 'Picet & Cie'},
{ value: 'Pictet Alternative Investments', label: 'Pictet Alternative Investments'},
{ value: 'Pictet Asset Management', label: 'Pictet Asset Management'},
{ value: 'Pimco', label: 'Pimco'},
{ value: 'Pine Brook', label: 'Pine Brook'},
{ value: 'Pine River Capital Management', label: 'Pine River Capital Management'},
{ value: 'PineBridge Investments', label: 'PineBridge Investments'},
{ value: 'Piper Jaffray', label: 'Piper Jaffray'},
{ value: 'Platinum Asset Management', label: 'Platinum Asset Management'},
{ value: 'Platinum Equity', label: 'Platinum Equity'},
{ value: 'Point State Capital', label: 'Point State Capital'},
{ value: 'Point72 Asset Management', label: 'Point72 Asset Management'},
{ value: 'PointState Capital', label: 'PointState Capital'},
{ value: 'Polar Asset Management', label: 'Polar Asset Management'},
{ value: 'Polygon Investment Partners', label: 'Polygon Investment Partners'},
{ value: 'Portobello Capital', label: 'Portobello Capital'},
{ value: 'Positive Equity Limited', label: 'Positive Equity Limited'},
{ value: 'Post Oak Energy Capital', label: 'Post Oak Energy Capital'},
{ value: 'Primavera Capital Group', label: 'Primavera Capital Group'},
{ value: 'Prime Trading LLC', label: 'Prime Trading LLC'},
{ value: 'Principal Global Investors', label: 'Principal Global Investors'},
{ value: 'Prologis', label: 'Prologis'},
{ value: 'Providence Equity Partners', label: 'Providence Equity Partners'},
{ value: 'Prudential Financial', label: 'Prudential Financial'},
{ value: 'Punjab National Bank', label: 'Punjab National Bank'},
{ value: 'Punjab National Bank Investment Services Limited', label: 'Punjab National Bank Investment Services Limited'},
{ value: 'Putnam Investments', label: 'Putnam Investments'},
{ value: 'Pyrford International', label: 'Pyrford International'},
{ value: 'QIC', label: 'QIC'},
{ value: 'QS investors', label: 'QS investors'},
{ value: 'Qatalyst Partners', label: 'Qatalyst Partners'},
{ value: 'Qiming Venture Partners', label: 'Qiming Venture Partners'},
{ value: 'Quadrant Private Equity', label: 'Quadrant Private Equity'},
{ value: 'Quantum Energy Partners', label: 'Quantum Energy Partners'},
{ value: 'Quilvest', label: 'Quilvest'},
{ value: 'Quirin Bank', label: 'Quirin Bank'},
{ value: 'Quoniam', label: 'Quoniam'},
{ value: 'R.W. Pressprich & Company', label: 'R.W. Pressprich & Company'},
{ value: 'RBC Capital Markets', label: 'RBC Capital Markets'},
{ value: 'RBC Global Asset Management', label: 'RBC Global Asset Management'},
{ value: 'RBS', label: 'RBS'},
{ value: 'RBS Global Banking & Markets', label: 'RBS Global Banking & Markets'},
{ value: 'RRJ Capital', label: 'RRJ Capital'},
{ value: 'RWC Partners', label: 'RWC Partners'},
{ value: 'Rabobank International', label: 'Rabobank International'},
{ value: 'Raiffeisen Capital Management', label: 'Raiffeisen Capital Management'},
{ value: 'Raiffeisen Privatbank Liechtenstein', label: 'Raiffeisen Privatbank Liechtenstein'},
{ value: 'Raymond James', label: 'Raymond James'},
{ value: 'Raymond James Equity Capital Markets', label: 'Raymond James Equity Capital Markets'},
{ value: 'Record Currency Management', label: 'Record Currency Management'},
{ value: 'Redburn Partners', label: 'Redburn Partners'},
{ value: 'Redpoint China Ventures', label: 'Redpoint China Ventures'},
{ value: 'Redwood Capital', label: 'Redwood Capital'},
{ value: 'ReiseBank AG', label: 'ReiseBank AG'},
{ value: 'Reliance Bank', label: 'Reliance Bank'},
{ value: 'Renaissance Technologies', label: 'Renaissance Technologies'},
{ value: 'Renta 4', label: 'Renta 4'},
{ value: 'Rhone Group', label: 'Rhone Group'},
{ value: 'Ridgemont Equity Partners', label: 'Ridgemont Equity Partners'},
{ value: 'Rimrock Capital', label: 'Rimrock Capital'},
{ value: 'Riverstone Holdings', label: 'Riverstone Holdings'},
{ value: 'Riverwood Capital', label: 'Riverwood Capital'},
{ value: 'Roark Capital Group', label: 'Roark Capital Group'},
{ value: 'Robeco', label: 'Robeco'},
{ value: 'Robert W. Baird', label: 'Robert W. Baird'},
{ value: 'Rokos Capital', label: 'Rokos Capital'},
{ value: 'Ronin Capital', label: 'Ronin Capital'},
{ value: 'Roth Capital Partners', label: 'Roth Capital Partners'},
{ value: 'Rothschild & Co', label: 'Rothschild & Co'},
{ value: 'Rothschild Asset Management', label: 'Rothschild Asset Management'},
{ value: 'Rothschild Merchant Banking', label: 'Rothschild Merchant Banking'},
{ value: 'Rothschild Private Management', label: 'Rothschild Private Management'},
{ value: 'Royal London Asset Management', label: 'Royal London Asset Management'},
{ value: 'Royal Private Banking Canada', label: 'Royal Private Banking Canada'},
{ value: 'Royce & Associates', label: 'Royce & Associates'},
{ value: 'Ruane', label: 'Ruane'},
{ value: 'Rud Blass & Cie', label: 'Rud Blass & Cie'},
{ value: 'Russell Investments', label: 'Russell Investments'},
{ value: 'SBI', label: 'SBI'},
{ value: 'SBI Capital Markets', label: 'SBI Capital Markets'},
{ value: 'SCOR Investment Partners', label: 'SCOR Investment Partners'},
{ value: 'SEB', label: 'SEB'},
{ value: 'SEB AG', label: 'SEB AG'},
{ value: 'SECOR Asset Management', label: 'SECOR Asset Management'},
{ value: 'SEI', label: 'SEI'},
{ value: 'SG Private Banking', label: 'SG Private Banking'},
{ value: 'SMB Capital', label: 'SMB Capital'},
{ value: 'SMBC Nikko Securities', label: 'SMBC Nikko Securities'},
{ value: 'SPF Beheer', label: 'SPF Beheer'},
{ value: 'SRS Investment', label: 'SRS Investment'},
{ value: 'SSG Capital Management', label: 'SSG Capital Management'},
{ value: 'SSGA Funds Management', label: 'SSGA Funds Management'},
{ value: 'STIC Investments', label: 'STIC Investments'},
{ value: 'Sagent Advisors', label: 'Sagent Advisors'},
{ value: 'Samlyn Capital', label: 'Samlyn Capital'},
{ value: 'Sandler ONeill and Partners', label: 'Sandler ONeill and Partners'},
{ value: 'Sanford C. Bernstein', label: 'Sanford C. Bernstein'},
{ value: 'Santander Asset Management', label: 'Santander Asset Management'},
{ value: 'Santander Corporate & Investment Banking', label: 'Santander Corporate & Investment Banking'},
{ value: 'Santander Private Banking', label: 'Santander Private Banking'},
{ value: 'Sarasin & Cie', label: 'Sarasin & Cie'},
{ value: 'Savills Investment Management', label: 'Savills Investment Management'},
{ value: 'Savius LLC', label: 'Savius LLC'},
{ value: 'Sberbank Asset Management', label: 'Sberbank Asset Management'},
{ value: 'Sberbank CIB', label: 'Sberbank CIB'},
{ value: 'Schonfeld Group', label: 'Schonfeld Group'},
{ value: 'Schroder Investment Management', label: 'Schroder Investment Management'},
{ value: 'Schroders', label: 'Schroders'},
{ value: 'Scopia Capital Management', label: 'Scopia Capital Management'},
{ value: 'Scotia Wealth Management', label: 'Scotia Wealth Management'},
{ value: 'Scotiabank', label: 'Scotiabank'},
{ value: 'Scout Energy Partners', label: 'Scout Energy Partners'},
{ value: 'Seaport Global', label: 'Seaport Global'},
{ value: 'Searchlight Capital Partners', label: 'Searchlight Capital Partners'},
{ value: 'Securis Investment Partners', label: 'Securis Investment Partners'},
{ value: 'Seeyond', label: 'Seeyond'},
{ value: 'Segantii Capital', label: 'Segantii Capital'},
{ value: 'Seix Investment Advisors', label: 'Seix Investment Advisors'},
{ value: 'Select Equity Group', label: 'Select Equity Group'},
{ value: 'Semper Constantia Invest', label: 'Semper Constantia Invest'},
{ value: 'Senator Investment Group', label: 'Senator Investment Group'},
{ value: 'Sentinel Capital Partners', label: 'Sentinel Capital Partners'},
{ value: 'Sequoia Capital', label: 'Sequoia Capital'},
{ value: 'Setanta Asset Management', label: 'Setanta Asset Management'},
{ value: 'Seven Points Capital', label: 'Seven Points Capital'},
{ value: 'Shinhan BNP Paribas AMC', label: 'Shinhan BNP Paribas AMC'},
{ value: 'Shunwei Capital Partners', label: 'Shunwei Capital Partners'},
{ value: 'Siemens Fonds Invest', label: 'Siemens Fonds Invest'},
{ value: 'Siguler Guff', label: 'Siguler Guff'},
{ value: 'Silver Lake', label: 'Silver Lake'},
{ value: 'Silver Point Capital', label: 'Silver Point Capital'},
{ value: 'Simplex Investments', label: 'Simplex Investments'},
{ value: 'Siris Capital Group', label: 'Siris Capital Group'},
{ value: 'Skagen Funds', label: 'Skagen Funds'},
{ value: 'Societe Generale', label: 'Societe Generale'},
{ value: 'Solus Alternative Asset Management', label: 'Solus Alternative Asset Management'},
{ value: 'Sonenshine Partners', label: 'Sonenshine Partners'},
{ value: 'Soroban Capital', label: 'Soroban Capital'},
{ value: 'Southeastern Asset Management', label: 'Southeastern Asset Management'},
{ value: 'Sparinvest', label: 'Sparinvest'},
{ value: 'Spark Capital', label: 'Spark Capital'},
{ value: 'Sparx Group', label: 'Sparx Group'},
{ value: 'Spectrum Asset Management', label: 'Spectrum Asset Management'},
{ value: 'Spectrum Equity', label: 'Spectrum Equity'},
{ value: 'Squarepoint Capital', label: 'Squarepoint Capital'},
{ value: 'Standard Bank', label: 'Standard Bank'},
{ value: 'Standard Chartered Bank', label: 'Standard Chartered Bank'},
{ value: 'Standish Mellon Asset Management', label: 'Standish Mellon Asset Management'},
{ value: 'Starwood Capital Group', label: 'Starwood Capital Group'},
{ value: 'State Street Corporation', label: 'State Street Corporation'},
{ value: 'State Street Global Advisors', label: 'State Street Global Advisors'},
{ value: 'Steadfast Capital', label: 'Steadfast Capital'},
{ value: 'Steifel Group', label: 'Steifel Group'},
{ value: 'Stephens', label: 'Stephens'},
{ value: 'Stephens Inc', label: 'Stephens Inc'},
{ value: 'Sterling Waterford Research', label: 'Sterling Waterford Research'},
{ value: 'Stifel Financial Corp', label: 'Stifel Financial Corp'},
{ value: 'Stifel Financial Corporation', label: 'Stifel Financial Corporation'},
{ value: 'Stifel Nicolaus', label: 'Stifel Nicolaus'},
{ value: 'Stone Harbor Investment Partners', label: 'Stone Harbor Investment Partners'},
{ value: 'Stone Point Capital', label: 'Stone Point Capital'},
{ value: 'Storebrand Asset Management', label: 'Storebrand Asset Management'},
{ value: 'Strategic Value Partners', label: 'Strategic Value Partners'},
{ value: 'Sucsy', label: 'Sucsy'},
{ value: 'Sudwestbank AG', label: 'Sudwestbank AG'},
{ value: 'Sumimoto Mitsui Banking Corporation', label: 'Sumimoto Mitsui Banking Corporation'},
{ value: 'Sumitomo Mitsui Asset Management', label: 'Sumitomo Mitsui Asset Management'},
{ value: 'Sumitomo Mitsui Financial Group', label: 'Sumitomo Mitsui Financial Group'},
{ value: 'Sumitomo Mitsui Trust Bank', label: 'Sumitomo Mitsui Trust Bank'},
{ value: 'Summa Equity', label: 'Summa Equity'},
{ value: 'Summit Partners', label: 'Summit Partners'},
{ value: 'Sun Capital Partners', label: 'Sun Capital Partners'},
{ value: 'SunTrust Bank', label: 'SunTrust Bank'},
{ value: 'Sura Asset Management', label: 'Sura Asset Management'},
{ value: 'Susquehanna Financial Group', label: 'Susquehanna Financial Group'},
{ value: 'Susquehanna International Group', label: 'Susquehanna International Group'},
{ value: 'Swedbank Asset Management', label: 'Swedbank Asset Management'},
{ value: 'Swiss Life Asset Managers', label: 'Swiss Life Asset Managers'},
{ value: 'Sycamore Partners', label: 'Sycamore Partners'},
{ value: 'Sycomore Asset Management', label: 'Sycomore Asset Management'},
{ value: 'Sydbank Asset Management', label: 'Sydbank Asset Management'},
{ value: 'Symmetry Investments', label: 'Symmetry Investments'},
{ value: 'Syndicate Bank', label: 'Syndicate Bank'},
{ value: 'Systematica Investments', label: 'Systematica Investments'},
{ value: 'Syz Asset Management', label: 'Syz Asset Management'},
{ value: 'T&D Asset Management', label: 'T&D Asset Management'},
{ value: 'T. Rowe Price', label: 'T. Rowe Price'},
{ value: 'TA Associates', label: 'TA Associates'},
{ value: 'TCI Fund Management', label: 'TCI Fund Management'},
{ value: 'TCV', label: 'TCV'},
{ value: 'TD Securities', label: 'TD Securities'},
{ value: 'TDR Capital', label: 'TDR Capital'},
{ value: 'TFG Asset Management', label: 'TFG Asset Management'},
{ value: 'TKP Investments', label: 'TKP Investments'},
{ value: 'TOBAM', label: 'TOBAM'},
{ value: 'TPG', label: 'TPG'},
{ value: 'TPRV Capital', label: 'TPRV Capital'},
{ value: 'TSG Consumer Partners', label: 'TSG Consumer Partners'},
{ value: 'Taconic Capital', label: 'Taconic Capital'},
{ value: 'Tailwater Capital', label: 'Tailwater Capital'},
{ value: 'Tailwind Capital Partners', label: 'Tailwind Capital Partners'},
{ value: 'Talanx Asset Management', label: 'Talanx Asset Management'},
{ value: 'Tenaron Capital', label: 'Tenaron Capital'},
{ value: 'The Abraaj Group', label: 'The Abraaj Group'},
{ value: 'The Bank of China', label: 'The Bank of China'},
{ value: 'The Baupost Group', label: 'The Baupost Group'},
{ value: 'The Boston Company Asset Management', label: 'The Boston Company Asset Management'},
{ value: 'The Carlyle Group', label: 'The Carlyle Group'},
{ value: 'The D.E. Shaw Group', label: 'The D.E. Shaw Group'},
{ value: 'The Edgewater Funds', label: 'The Edgewater Funds'},
{ value: 'The GPT Group', label: 'The GPT Group'},
{ value: 'The Jordan Company', label: 'The Jordan Company'},
{ value: 'The Raine Group', label: 'The Raine Group'},
{ value: 'The Riverside Company', label: 'The Riverside Company'},
{ value: 'The Sterling Group', label: 'The Sterling Group'},
{ value: 'The TCW Group', label: 'The TCW Group'},
{ value: 'Think Equity', label: 'Think Equity'},
{ value: 'Third Point', label: 'Third Point'},
{ value: 'Thoma Bravo', label: 'Thoma Bravo'},
{ value: 'Thomas H. Lee Partners', label: 'Thomas H. Lee Partners'},
{ value: 'Thompson', label: 'Thompson'},
{ value: 'Thompson Street Capital Partners', label: 'Thompson Street Capital Partners'},
{ value: 'Thornburg Investment Management', label: 'Thornburg Investment Management'},
{ value: 'Threshold Ventures', label: 'Threshold Ventures'},
{ value: 'Thrive Capital', label: 'Thrive Capital'},
{ value: 'Thrivent Financial', label: 'Thrivent Financial'},
{ value: 'Tibra Capital', label: 'Tibra Capital'},
{ value: 'Tiger Global Management', label: 'Tiger Global Management'},
{ value: 'Tilden Park Capital Management', label: 'Tilden Park Capital Management'},
{ value: 'TimesSquare Capital Management', label: 'TimesSquare Capital Management'},
{ value: 'Tishman Speyer', label: 'Tishman Speyer'},
{ value: 'Tokio Marine Asset Management', label: 'Tokio Marine Asset Management'},
{ value: 'TopstepTrader', label: 'TopstepTrader'},
{ value: 'Tower Research Capital', label: 'Tower Research Capital'},
{ value: 'TowerBrook Capital Partners', label: 'TowerBrook Capital Partners'},
{ value: 'Trade Vision Capital', label: 'Trade Vision Capital'},
{ value: 'TradeLink', label: 'TradeLink'},
{ value: 'Transmarket Group', label: 'Transmarket Group'},
{ value: 'Trian Fund Management', label: 'Trian Fund Management'},
{ value: 'Trident Capital', label: 'Trident Capital'},
{ value: 'Trilantic Capital Partners North America', label: 'Trilantic Capital Partners North America'},
{ value: 'Trillium Trading', label: 'Trillium Trading'},
{ value: 'Trilogy Global Advisors', label: 'Trilogy Global Advisors'},
{ value: 'Tristan Capital Partners', label: 'Tristan Capital Partners'},
{ value: 'Triton Partners', label: 'Triton Partners'},
{ value: 'Trive Capital', label: 'Trive Capital'},
{ value: 'True Ventures', label: 'True Ventures'},
{ value: 'Tudor Investment', label: 'Tudor Investment'},
{ value: 'Tudor Pickering & Holt', label: 'Tudor Pickering & Holt'},
{ value: 'Tweedy', label: 'Tweedy'},
{ value: 'TwentyFour Asset Management', label: 'TwentyFour Asset Management'},
{ value: 'Two Sigma Investments', label: 'Two Sigma Investments'},
{ value: 'Tybourne Capital', label: 'Tybourne Capital'},
{ value: 'U.S. Capital Advisors', label: 'U.S. Capital Advisors'},
{ value: 'UBS', label: 'UBS'},
{ value: 'UBS Asset Management', label: 'UBS Asset Management'},
{ value: 'UBS Group AG', label: 'UBS Group AG'},
{ value: 'UBS Hedge Fund Solutions', label: 'UBS Hedge Fund Solutions'},
{ value: 'US Bankcorp', label: 'US Bankcorp'},
{ value: 'UniCredit S.p.A.', label: 'UniCredit S.p.A.'},
{ value: 'Unicaja', label: 'Unicaja'},
{ value: 'Unigestion', label: 'Unigestion'},
{ value: 'Union Bancaire Privee', label: 'Union Bancaire Privee'},
{ value: 'Union Investment', label: 'Union Investment'},
{ value: 'United Bank of India', label: 'United Bank of India'},
{ value: 'Universal-Investment', label: 'Universal-Investment'},
{ value: 'VIG Partners', label: 'VIG Partners'},
{ value: 'VIRTU Financial', label: 'VIRTU Financial'},
{ value: 'VMG Partners', label: 'VMG Partners'},
{ value: 'VTB Capital Investment Management', label: 'VTB Capital Investment Management'},
{ value: 'VZ Vermogenszentrum', label: 'VZ Vermogenszentrum'},
{ value: 'Valor Equity Partners', label: 'Valor Equity Partners'},
{ value: 'ValueAct Capital', label: 'ValueAct Capital'},
{ value: 'Vanguard Asset Management', label: 'Vanguard Asset Management'},
{ value: 'Varde Management', label: 'Varde Management'},
{ value: 'Vaughan Nelson Investment Management', label: 'Vaughan Nelson Investment Management'},
{ value: 'Vector Capital', label: 'Vector Capital'},
{ value: 'Venrock', label: 'Venrock'},
{ value: 'Veritable LP', label: 'Veritable LP'},
{ value: 'Veritas Asset Management', label: 'Veritas Asset Management'},
{ value: 'Veritas Capital', label: 'Veritas Capital'},
{ value: 'Vermillion Partners', label: 'Vermillion Partners'},
{ value: 'Versant Ventures', label: 'Versant Ventures'},
{ value: 'Victory Capital Management', label: 'Victory Capital Management'},
{ value: 'VidaCaixa', label: 'VidaCaixa'},
{ value: 'Vijaya Bank', label: 'Vijaya Bank'},
{ value: 'Viking Global Investors', label: 'Viking Global Investors'},
{ value: 'Vista Equity Partners', label: 'Vista Equity Partners'},
{ value: 'Vistria Group', label: 'Vistria Group'},
{ value: 'Vitruvian Partners', label: 'Vitruvian Partners'},
{ value: 'Vivo Capital', label: 'Vivo Capital'},
{ value: 'Vontobel Asset Management', label: 'Vontobel Asset Management'},
{ value: 'Voya Investment Management', label: 'Voya Investment Management'},
{ value: 'W&W Asset Management', label: 'W&W Asset Management'},
{ value: 'WH Trading', label: 'WH Trading'},
{ value: 'WL Ross & Company', label: 'WL Ross & Company'},
{ value: 'WR Hambrecht & Co.', label: 'WR Hambrecht & Co.'},
{ value: 'Walter Scott & Partners', label: 'Walter Scott & Partners'},
{ value: 'Warburg Pincus', label: 'Warburg Pincus'},
{ value: 'Washington Analysis', label: 'Washington Analysis'},
{ value: 'Waterfall Asset Management', label: 'Waterfall Asset Management'},
{ value: 'Waterland Private Equity Investments', label: 'Waterland Private Equity Investments'},
{ value: 'Waud Capital Partners', label: 'Waud Capital Partners'},
{ value: 'Weatherbys', label: 'Weatherbys'},
{ value: 'Weberbank Actiengesellschaft', label: 'Weberbank Actiengesellschaft'},
{ value: 'Webster Equity Partners', label: 'Webster Equity Partners'},
{ value: 'Wedbush Securities', label: 'Wedbush Securities'},
{ value: 'Wellington Management', label: 'Wellington Management'},
{ value: 'Wellington Management International', label: 'Wellington Management International'},
{ value: 'Wells Fargo', label: 'Wells Fargo'},
{ value: 'Wells Fargo Asset Management', label: 'Wells Fargo Asset Management'},
{ value: 'Wells Fargo Private Bank', label: 'Wells Fargo Private Bank'},
{ value: 'Wells Fargo Securities', label: 'Wells Fargo Securities'},
{ value: 'Wellspring Capital Management', label: 'Wellspring Capital Management'},
{ value: 'Welsh Carson Anderson & Stowe', label: 'Welsh Carson Anderson & Stowe'},
{ value: 'WestLB Markets', label: 'WestLB Markets'},
{ value: 'Western Asset Management Company', label: 'Western Asset Management Company'},
{ value: 'Westwood Management Corporation', label: 'Westwood Management Corporation'},
{ value: 'Whale Rock Capital', label: 'Whale Rock Capital'},
{ value: 'Whitebox Advisors', label: 'Whitebox Advisors'},
{ value: 'William Blair', label: 'William Blair'},
{ value: 'William Blair & Company', label: 'William Blair & Company'},
{ value: 'Williams Capital', label: 'Williams Capital'},
{ value: 'WindRose Health Investors', label: 'WindRose Health Investors'},
{ value: 'Winton Capital Management', label: 'Winton Capital Management'},
{ value: 'Winton Group', label: 'Winton Group'},
{ value: 'Wolfe Research', label: 'Wolfe Research'},
{ value: 'Wolverine Asset Management', label: 'Wolverine Asset Management'},
{ value: 'Wolverine Trading', label: 'Wolverine Trading'},
{ value: 'Wynnchurch Capital', label: 'Wynnchurch Capital'},
{ value: 'XR Trading', label: 'XR Trading'},
{ value: 'Yacktman Asset Management', label: 'Yacktman Asset Management'},
{ value: 'Yes Bank', label: 'Yes Bank'},
{ value: 'York Capital Management', label: 'York Capital Management'},
{ value: 'Yorktown Partners', label: 'Yorktown Partners'},
{ value: 'YunFeng Capital', label: 'YunFeng Capital'},
{ value: 'Zimmer Partners', label: 'Zimmer Partners'},
{ value: 'Zurcher Kantonalbank', label: 'Zurcher Kantonalbank'},
];