import styled from 'styled-components/macro';
import PostVoteButton from './Button';

const PostVoteDownvote = styled(PostVoteButton)`
  --iconColor: ${props => props.didVote ? props.theme.downvote : props.theme.vote};
  --iconHoverColor: ${props => props.theme.downvote};

  ::after {
    border-bottom: 2px solid var(--iconColor);
    border-left: 2px solid var(--iconColor);
    top: -2px;
  }
  :hover ::after{
    background-color: ${props => props.theme.voteButtonHover};
    border-bottom: 2px solid var(--iconHoverColor);
    border-left: 2px solid var(--iconHoverColor);
    top: -2px;
  } 
`;

export default PostVoteDownvote;
