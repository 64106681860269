/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:713cffd3-fb52-4544-be0f-c9795bc72891",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jegwc3TyQ",
    "aws_user_pools_web_client_id": "34r4q8b1m3d1k5ngqedv3pt54h",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "quantifyalpha8restapipublic",
            "endpoint": "https://j3tvnl88mf.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "quantifyalpha8restapiprofile",
            "endpoint": "https://dlmoznxca0.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "quantifyalpha8restapipost",
            "endpoint": "https://i3c0s25stl.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "quantifyalpha8restapicomment",
            "endpoint": "https://x809fefl62.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "quantifyalpha8s3180111-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "quantifyalpha8-20200411171217-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://quantifyalpha8-20200411171217-hostingbucket-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
