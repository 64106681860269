import styled from 'styled-components/macro';
import { transition, wideFont } from '../../shared/helpers';

const PostVoteButton = styled.button`=
  
  ${transition('border', 'box-shadow')};
  --border: ${props => props.error ? props.theme.error : props.theme.accent};
  --shadow: ${props => props.error ? props.theme.error + '4d' : props.theme.accent + '4d'};
  
  ${wideFont};
  
  width:  24px;
  height: 38px;

  border: none;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  
  outline: none;

  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
  background-color: ${props => props.theme.accent};
  cursor: pointer;
  resize: vertical;

  :hover {
    filter: brightness(110%);
  }
  
  :active {
    filter: brightness(90%);
  }
  
  ::after {
    ${transition('border')};
    
    content: '';
    position: relative;
    left: 7px;
    padding-left: 1px;
    padding-right: 1px;
    display: block;
    transform: rotate(-45deg);
    width: 8px;
    height: 8px;
  }
  
  :focus {
    box-shadow: 0 0 0 0px ${props => props.theme.accent + '4d'};
    border: none;
    outline: none;
  }
`;

export default PostVoteButton;
