import React from 'react';
import { Field } from 'redux-form';

import renderAdvanced from '../../../shared/form/renderAdvanced';

class SelectValue6 extends React.Component {
    render() {

        return(
        <>
            {(this.props.version==='col1') && (
                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}> 
                    <div style={{flex: '1', flexGrow: '1', flexShrink: '1', marginBottom: '0px', marginRight: '100px'}}> 
                        <Field 
                        name='value6'
                        type='search_select'
                        placeholder={this.props.placeholder}
                        component={renderAdvanced}
                        theme={this.props.theme}
                        options={this.props.options}
                        defaultValue={this.props.options.includes(this.props.value) ? this.props.value : null}
                        />
                    </div>
                </div>
            )}
        
            {(this.props.version==='col2L') && (
                <div style={{flex: '1', flexGrow: '1', flexShrink: '1', marginRight: '8px'}}> 
                    <Field 
                    name='value6'
                    type='search_select'
                    placeholder={this.props.placeholder}
                    component={renderAdvanced}
                    theme={this.props.theme}
                    options={this.props.options}
                    defaultValue={this.props.options.includes(this.props.value) ? this.props.value : null}
                    />
                </div>
            )}
        
            {(this.props.version==='col2R') && (
                <div style={{flex: '1', flexGrow: '1', flexShrink: '1', marginRight: '0px'}}> 
                    <Field 
                    name='value6'
                    type='search_select'
                    placeholder={this.props.placeholder}
                    component={renderAdvanced}
                    theme={this.props.theme}
                    options={this.props.options}
                    defaultValue={this.props.options.includes(this.props.value) ? this.props.value : null}
                    />
                </div>
            )}
        </>
        );
    };
};

export default SelectValue6;