export const schoolOptions = [
{ value: 'Aalto University', label: 'Aalto University'},
{ value: 'Aarhus University', label: 'Aarhus University'},
{ value: 'Alma Mater Studiorum University of Bologna', label: 'Alma Mater Studiorum University of Bologna'},
{ value: 'American University of Beirut (AUB)', label: 'American University of Beirut (AUB)'},
{ value: 'Arizona State University', label: 'Arizona State University'},
{ value: 'Athens University of Economics and Business', label: 'Athens University of Economics and Business'},
{ value: 'Auckland University of Technology (AUT)', label: 'Auckland University of Technology (AUT)'},
{ value: 'Beihang University (former BUAA)', label: 'Beihang University (former BUAA)'},
{ value: 'Beijing Institute of Technology', label: 'Beijing Institute of Technology'},
{ value: 'Beijing Jiaotong University', label: 'Beijing Jiaotong University'},
{ value: 'Beijing Normal University', label: 'Beijing Normal University'},
{ value: 'Bocconi University', label: 'Bocconi University'},
{ value: 'Boston College', label: 'Boston College'},
{ value: 'Boston University', label: 'Boston University'},
{ value: 'Brigham Young University', label: 'Brigham Young University'},
{ value: 'Brown University', label: 'Brown University'},
{ value: 'Brunel University London', label: 'Brunel University London'},
{ value: 'Ca\' Foscari University of Venice', label: 'Ca\' Foscari University of Venice'},
{ value: 'Cairo University', label: 'Cairo University'},
{ value: 'Cardiff University', label: 'Cardiff University'},
{ value: 'Carnegie Mellon University', label: 'Carnegie Mellon University'},
{ value: 'Case Western Reserve University', label: 'Case Western Reserve University'},
{ value: 'Chalmers University of Technology', label: 'Chalmers University of Technology'},
{ value: 'Chulalongkorn University', label: 'Chulalongkorn University'},
{ value: 'Chung Ang University (CAU)', label: 'Chung Ang University (CAU)'},
{ value: 'City University of Hong Kong', label: 'City University of Hong Kong'},
{ value: 'City University of New York', label: 'City University of New York'},
{ value: 'City, University of London', label: 'City, University of London'},
{ value: 'Colorado State University', label: 'Colorado State University'},
{ value: 'Columbia University', label: 'Columbia University'},
{ value: 'Complutense University of Madrid', label: 'Complutense University of Madrid'},
{ value: 'Concordia University', label: 'Concordia University'},
{ value: 'Cornell University', label: 'Cornell University'},
{ value: 'Cranfield University', label: 'Cranfield University'},
{ value: 'Curtin University', label: 'Curtin University'},
{ value: 'Deakin University', label: 'Deakin University'},
{ value: 'Duke University', label: 'Duke University'},
{ value: 'Durham University', label: 'Durham University'},
{ value: 'ESSEC Business School', label: 'ESSEC Business School'},
{ value: 'ETH Zurich Swiss Federal Institute of Technology', label: 'ETH Zurich Swiss Federal Institute of Technology'},
{ value: 'Eindhoven University of Technology', label: 'Eindhoven University of Technology'},
{ value: 'Emlyon Business School', label: 'Emlyon Business School'},
{ value: 'Emory University', label: 'Emory University'},
{ value: 'Erasmus University Rotterdam', label: 'Erasmus University Rotterdam'},
{ value: 'Essex, University of', label: 'Essex, University of'},
{ value: 'Florida International University', label: 'Florida International University'},
{ value: 'Florida State University', label: 'Florida State University'},
{ value: 'Freie Universitaet Berlin', label: 'Freie Universitaet Berlin'},
{ value: 'Fudan University', label: 'Fudan University'},
{ value: 'George Washington University', label: 'George Washington University'},
{ value: 'Georgetown University', label: 'Georgetown University'},
{ value: 'Georgia Institute of Technology', label: 'Georgia Institute of Technology'},
{ value: 'Georgia State University', label: 'Georgia State University'},
{ value: 'Ghent University', label: 'Ghent University'},
{ value: 'Goethe University Frankfurt am Main', label: 'Goethe University Frankfurt am Main'},
{ value: 'HSE University (National Research University Higher School of Economics)', label: 'HSE University (National Research University Higher School of Economics)'},
{ value: 'Hanyang University', label: 'Hanyang University'},
{ value: 'Harbin Institute of Technology', label: 'Harbin Institute of Technology'},
{ value: 'Harvard University', label: 'Harvard University'},
{ value: 'Heriot Watt University', label: 'Heriot Watt University'},
{ value: 'Hitotsubashi University', label: 'Hitotsubashi University'},
{ value: 'Huazhong University of Science and Technology', label: 'Huazhong University of Science and Technology'},
{ value: 'Humboldt Universitat zu Berlin', label: 'Humboldt Universitat zu Berlin'},
{ value: 'IE University', label: 'IE University'},
{ value: 'Imperial College London', label: 'Imperial College London'},
{ value: 'Indian Institute of Management (IIM) Ahmedabad', label: 'Indian Institute of Management (IIM) Ahmedabad'},
{ value: 'Indian Institute of Technology Bombay (IITB)', label: 'Indian Institute of Technology Bombay (IITB)'},
{ value: 'Indian Institute of Technology Delhi (IITD)', label: 'Indian Institute of Technology Delhi (IITD)'},
{ value: 'Indian Institute of Technology Kanpur (IITK)', label: 'Indian Institute of Technology Kanpur (IITK)'},
{ value: 'Indian Institute of Technology Kharagpur (IIT KGP)', label: 'Indian Institute of Technology Kharagpur (IIT KGP)'},
{ value: 'Indiana University Bloomington', label: 'Indiana University Bloomington'},
{ value: 'Iowa State University', label: 'Iowa State University'},
{ value: 'Johns Hopkins University', label: 'Johns Hopkins University'},
{ value: 'KTH Royal Institute of Technology', label: 'KTH Royal Institute of Technology'},
{ value: 'KU Leuven', label: 'KU Leuven'},
{ value: 'Keio University', label: 'Keio University'},
{ value: 'King Abdulaziz University (KAU)', label: 'King Abdulaziz University (KAU)'},
{ value: 'Kobe University', label: 'Kobe University'},
{ value: 'Korea University', label: 'Korea University'},
{ value: 'Kyung Hee University', label: 'Kyung Hee University'},
{ value: 'Kyushu University', label: 'Kyushu University'},
{ value: 'La Trobe University', label: 'La Trobe University'},
{ value: 'Lahore University of Management Sciences (LUMS)', label: 'Lahore University of Management Sciences (LUMS)'},
{ value: 'Lancaster University', label: 'Lancaster University'},
{ value: 'Leiden University', label: 'Leiden University'},
{ value: 'Lomonosov Moscow State University', label: 'Lomonosov Moscow State University'},
{ value: 'London Business School', label: 'London Business School'},
{ value: 'Loughborough University', label: 'Loughborough University'},
{ value: 'Ludwig Maximilians Universitat Munchen', label: 'Ludwig Maximilians Universitat Munchen'},
{ value: 'Luiss University', label: 'Luiss University'},
{ value: 'Lund University', label: 'Lund University'},
{ value: 'Maastricht University', label: 'Maastricht University'},
{ value: 'Macquarie University', label: 'Macquarie University'},
{ value: 'Massachusetts Institute of Technology (MIT)', label: 'Massachusetts Institute of Technology (MIT)'},
{ value: 'Massey University', label: 'Massey University'},
{ value: 'McGill University', label: 'McGill University'},
{ value: 'McMaster University', label: 'McMaster University'},
{ value: 'Michigan State University', label: 'Michigan State University'},
{ value: 'Middle East Technical University', label: 'Middle East Technical University'},
{ value: 'Monash University', label: 'Monash University'},
{ value: 'Nanjing University', label: 'Nanjing University'},
{ value: 'Nankai University', label: 'Nankai University'},
{ value: 'Nanyang Technological University, Singapore (NTU)', label: 'Nanyang Technological University, Singapore (NTU)'},
{ value: 'National Central University', label: 'National Central University'},
{ value: 'National Cheng Kung University (NCKU)', label: 'National Cheng Kung University (NCKU)'},
{ value: 'National Chengchi University', label: 'National Chengchi University'},
{ value: 'National Chiao Tung University', label: 'National Chiao Tung University'},
{ value: 'National Sun Yat sen University', label: 'National Sun Yat sen University'},
{ value: 'National Taiwan University (NTU)', label: 'National Taiwan University (NTU)'},
{ value: 'National Technical University of Athens', label: 'National Technical University of Athens'},
{ value: 'National Tsing Hua University', label: 'National Tsing Hua University'},
{ value: 'National University of Ireland Galway', label: 'National University of Ireland Galway'},
{ value: 'National University of Singapore (NUS)', label: 'National University of Singapore (NUS)'},
{ value: 'New York University (NYU)', label: 'New York University (NYU)'},
{ value: 'Newcastle University', label: 'Newcastle University'},
{ value: 'North Carolina State University', label: 'North Carolina State University'},
{ value: 'Northwestern University', label: 'Northwestern University'},
{ value: 'Osaka University', label: 'Osaka University'},
{ value: 'Oxford Brookes University', label: 'Oxford Brookes University'},
{ value: 'Peking University', label: 'Peking University'},
{ value: 'Pennsylvania State University', label: 'Pennsylvania State University'},
{ value: 'Pontificia Universidad Catolica de Chile (UC)', label: 'Pontificia Universidad Catolica de Chile (UC)'},
{ value: 'Princeton University', label: 'Princeton University'},
{ value: 'Purdue University', label: 'Purdue University'},
{ value: 'Queen Mary University of London', label: 'Queen Mary University of London'},
{ value: 'Queen\'s University Belfast', label: 'Queen\'s University Belfast'},
{ value: 'Queen\'s University at Kingston', label: 'Queen\'s University at Kingston'},
{ value: 'Queensland University of Technology (QUT)', label: 'Queensland University of Technology (QUT)'},
{ value: 'RMIT University', label: 'RMIT University'},
{ value: 'RWTH Aachen University', label: 'RWTH Aachen University'},
{ value: 'Renmin (People\'s) University of China', label: 'Renmin (People\'s) University of China'},
{ value: 'Rice University', label: 'Rice University'},
{ value: 'Rutgers University New Brunswick', label: 'Rutgers University New Brunswick'},
{ value: 'Sapienza University of Rome', label: 'Sapienza University of Rome'},
{ value: 'Seoul National University', label: 'Seoul National University'},
{ value: 'Shanghai Jiao Tong University', label: 'Shanghai Jiao Tong University'},
{ value: 'Sharif University of Technology', label: 'Sharif University of Technology'},
{ value: 'Singapore Management University', label: 'Singapore Management University'},
{ value: 'Sogang University', label: 'Sogang University'},
{ value: 'Sorbonne University', label: 'Sorbonne University'},
{ value: 'Stanford University', label: 'Stanford University'},
{ value: 'Stellenbosch University', label: 'Stellenbosch University'},
{ value: 'Stockholm School of Economics', label: 'Stockholm School of Economics'},
{ value: 'Stockholm University', label: 'Stockholm University'},
{ value: 'Sun Yat sen University', label: 'Sun Yat sen University'},
{ value: 'Sungkyunkwan University(SKKU)', label: 'Sungkyunkwan University(SKKU)'},
{ value: 'TU Dortmund University', label: 'TU Dortmund University'},
{ value: 'Technische Universitat Berlin (TU Berlin)', label: 'Technische Universitat Berlin (TU Berlin)'},
{ value: 'Technische Universitat Wien', label: 'Technische Universitat Wien'},
{ value: 'Tel Aviv University', label: 'Tel Aviv University'},
{ value: 'Temple University', label: 'Temple University'},
{ value: 'Texas A&M University', label: 'Texas A&M University'},
{ value: 'Thammasat University', label: 'Thammasat University'},
{ value: 'The American University in Cairo', label: 'The American University in Cairo'},
{ value: 'The Australian National University', label: 'The Australian National University'},
{ value: 'The Chinese University of Hong Kong (CUHK)', label: 'The Chinese University of Hong Kong (CUHK)'},
{ value: 'The Hong Kong Polytechnic University', label: 'The Hong Kong Polytechnic University'},
{ value: 'The Hong Kong University of Science and Technology', label: 'The Hong Kong University of Science and Technology'},
{ value: 'The London School of Economics and Political Science (LSE)', label: 'The London School of Economics and Political Science (LSE)'},
{ value: 'The Ohio State University', label: 'The Ohio State University'},
{ value: 'The University of Adelaide', label: 'The University of Adelaide'},
{ value: 'The University of Arizona', label: 'The University of Arizona'},
{ value: 'The University of Auckland', label: 'The University of Auckland'},
{ value: 'The University of Edinburgh', label: 'The University of Edinburgh'},
{ value: 'The University of Exeter', label: 'The University of Exeter'},
{ value: 'The University of Georgia', label: 'The University of Georgia'},
{ value: 'The University of Hong Kong', label: 'The University of Hong Kong'},
{ value: 'The University of Manchester', label: 'The University of Manchester'},
{ value: 'The University of Melbourne', label: 'The University of Melbourne'},
{ value: 'The University of New South Wales (UNSW Sydney)', label: 'The University of New South Wales (UNSW Sydney)'},
{ value: 'The University of Newcastle, Australia (UON)', label: 'The University of Newcastle, Australia (UON)'},
{ value: 'The University of Queensland', label: 'The University of Queensland'},
{ value: 'The University of Sheffield', label: 'The University of Sheffield'},
{ value: 'The University of Sydney', label: 'The University of Sydney'},
{ value: 'The University of Tennessee, Knoxville', label: 'The University of Tennessee, Knoxville'},
{ value: 'The University of Tokyo', label: 'The University of Tokyo'},
{ value: 'The University of Warwick', label: 'The University of Warwick'},
{ value: 'The University of Western Australia', label: 'The University of Western Australia'},
{ value: 'Tilburg University', label: 'Tilburg University'},
{ value: 'Tokyo Institute of Technology (Tokyo Tech)', label: 'Tokyo Institute of Technology (Tokyo Tech)'},
{ value: 'Tongji University', label: 'Tongji University'},
{ value: 'Trinity College Dublin, The University of Dublin', label: 'Trinity College Dublin, The University of Dublin'},
{ value: 'Tsinghua University', label: 'Tsinghua University'},
{ value: 'UCL', label: 'UCL'},
{ value: 'United Arab Emirates University', label: 'United Arab Emirates University'},
{ value: 'Universidad Autonoma de Madrid', label: 'Universidad Autonoma de Madrid'},
{ value: 'Universidad Carlos III de Madrid (UC3M)', label: 'Universidad Carlos III de Madrid (UC3M)'},
{ value: 'Universidad Nacional de Colombia', label: 'Universidad Nacional de Colombia'},
{ value: 'Universidade Estadual de Campinas (Unicamp)', label: 'Universidade Estadual de Campinas (Unicamp)'},
{ value: 'Universidade Federal de Minas Gerais', label: 'Universidade Federal de Minas Gerais'},
{ value: 'Universidade Federal do Rio de Janeiro', label: 'Universidade Federal do Rio de Janeiro'},
{ value: 'Universidade de Sao Paulo', label: 'Universidade de Sao Paulo'},
{ value: 'UniversitA A Cattolica del Sacro Cuore', label: 'UniversitA A Cattolica del Sacro Cuore'},
{ value: 'UniversitA A di Padova', label: 'UniversitA A di Padova'},
{ value: 'Universitat Autonoma de Barcelona', label: 'Universitat Autonoma de Barcelona'},
{ value: 'Universitat Innsbruck', label: 'Universitat Innsbruck'},
{ value: 'Universitat Mannheim', label: 'Universitat Mannheim'},
{ value: 'Universitat Politecnica de Catalunya (UPC Barcelona Tech)', label: 'Universitat Politecnica de Catalunya (UPC Barcelona Tech)'},
{ value: 'Universitat Pompeu Fabra', label: 'Universitat Pompeu Fabra'},
{ value: 'Universitat de Barcelona', label: 'Universitat de Barcelona'},
{ value: 'Universitat de Valencia', label: 'Universitat de Valencia'},
{ value: 'Universite Laval', label: 'Universite Laval'},
{ value: 'Universite PSL', label: 'Universite PSL'},
{ value: 'Universite Paris 1 Pantheon Sorbonne', label: 'Universite Paris 1 Pantheon Sorbonne'},
{ value: 'Universite Paris Saclay', label: 'Universite Paris Saclay'},
{ value: 'Universite catholique de Louvain (UCLouvain)', label: 'Universite catholique de Louvain (UCLouvain)'},
{ value: 'Universite de Montpellier', label: 'Universite de Montpellier'},
{ value: 'Universite de Montreal', label: 'Universite de Montreal'},
{ value: 'Universite de Paris', label: 'Universite de Paris'},
{ value: 'Universite du Quebec', label: 'Universite du Quebec'},
{ value: 'Universiti Kebangsaan Malaysia (UKM)', label: 'Universiti Kebangsaan Malaysia (UKM)'},
{ value: 'Universiti Malaya (UM)', label: 'Universiti Malaya (UM)'},
{ value: 'Universiti Putra Malaysia (UPM)', label: 'Universiti Putra Malaysia (UPM)'},
{ value: 'Universiti Sains Malaysia (USM)', label: 'Universiti Sains Malaysia (USM)'},
{ value: 'Universiti Teknologi MARA UiTM', label: 'Universiti Teknologi MARA UiTM'},
{ value: 'Universiti Utara Malaysia (UUM)', label: 'Universiti Utara Malaysia (UUM)'},
{ value: 'University College Dublin', label: 'University College Dublin'},
{ value: 'University of Aberdeen', label: 'University of Aberdeen'},
{ value: 'University of Alberta', label: 'University of Alberta'},
{ value: 'University of Amsterdam', label: 'University of Amsterdam'},
{ value: 'University of Bath', label: 'University of Bath'},
{ value: 'University of Bern', label: 'University of Bern'},
{ value: 'University of Birmingham', label: 'University of Birmingham'},
{ value: 'University of Bristol', label: 'University of Bristol'},
{ value: 'University of British Columbia', label: 'University of British Columbia'},
{ value: 'University of Calgary', label: 'University of Calgary'},
{ value: 'University of California, Berkeley (UCB)', label: 'University of California, Berkeley (UCB)'},
{ value: 'University of California, Davis', label: 'University of California, Davis'},
{ value: 'University of California, Irvine', label: 'University of California, Irvine'},
{ value: 'University of California, Los Angeles (UCLA)', label: 'University of California, Los Angeles (UCLA)'},
{ value: 'University of California, San Diego (UCSD)', label: 'University of California, San Diego (UCSD)'},
{ value: 'University of California, Santa Barbara (UCSB)', label: 'University of California, Santa Barbara (UCSB)'},
{ value: 'University of Cambridge', label: 'University of Cambridge'},
{ value: 'University of Canterbury', label: 'University of Canterbury'},
{ value: 'University of Cape Town', label: 'University of Cape Town'},
{ value: 'University of Chicago', label: 'University of Chicago'},
{ value: 'University of Cologne', label: 'University of Cologne'},
{ value: 'University of Colorado Boulder', label: 'University of Colorado Boulder'},
{ value: 'University of Copenhagen', label: 'University of Copenhagen'},
{ value: 'University of Delhi', label: 'University of Delhi'},
{ value: 'University of Florida', label: 'University of Florida'},
{ value: 'University of Geneva', label: 'University of Geneva'},
{ value: 'University of Glasgow', label: 'University of Glasgow'},
{ value: 'University of Gothenburg', label: 'University of Gothenburg'},
{ value: 'University of Gottingen', label: 'University of Gottingen'},
{ value: 'University of Groningen', label: 'University of Groningen'},
{ value: 'University of Helsinki', label: 'University of Helsinki'},
{ value: 'University of Illinois at Chicago (UIC)', label: 'University of Illinois at Chicago (UIC)'},
{ value: 'University of Illinois at Urbana Champaign', label: 'University of Illinois at Urbana Champaign'},
{ value: 'University of Iowa', label: 'University of Iowa'},
{ value: 'University of Lausanne', label: 'University of Lausanne'},
{ value: 'University of Leeds', label: 'University of Leeds'},
{ value: 'University of Lisbon', label: 'University of Lisbon'},
{ value: 'University of Liverpool', label: 'University of Liverpool'},
{ value: 'University of Maryland, College Park', label: 'University of Maryland, College Park'},
{ value: 'University of Massachusetts Amherst', label: 'University of Massachusetts Amherst'},
{ value: 'University of Michigan Ann Arbor', label: 'University of Michigan Ann Arbor'},
{ value: 'University of Minnesota Twin Cities', label: 'University of Minnesota Twin Cities'},
{ value: 'University of Missouri, Columbia', label: 'University of Missouri, Columbia'},
{ value: 'University of Naples Federico II', label: 'University of Naples Federico II'},
{ value: 'University of Navarra', label: 'University of Navarra'},
{ value: 'University of North Carolina, Chapel Hill', label: 'University of North Carolina, Chapel Hill'},
{ value: 'University of Notre Dame', label: 'University of Notre Dame'},
{ value: 'University of Nottingham', label: 'University of Nottingham'},
{ value: 'University of Otago', label: 'University of Otago'},
{ value: 'University of Oxford', label: 'University of Oxford'},
{ value: 'University of Pennsylvania', label: 'University of Pennsylvania'},
{ value: 'University of Pisa', label: 'University of Pisa'},
{ value: 'University of Pittsburgh', label: 'University of Pittsburgh'},
{ value: 'University of Porto', label: 'University of Porto'},
{ value: 'University of Reading', label: 'University of Reading'},
{ value: 'University of Rochester', label: 'University of Rochester'},
{ value: 'University of Rome Tor Vergata', label: 'University of Rome Tor Vergata'},
{ value: 'University of South Australia', label: 'University of South Australia'},
{ value: 'University of South Carolina', label: 'University of South Carolina'},
{ value: 'University of Southampton', label: 'University of Southampton'},
{ value: 'University of Southern California', label: 'University of Southern California'},
{ value: 'University of St Andrews', label: 'University of St Andrews'},
{ value: 'University of St.Gallen (HSG)', label: 'University of St.Gallen (HSG)'},
{ value: 'University of Strathclyde', label: 'University of Strathclyde'},
{ value: 'University of Technology Sydney', label: 'University of Technology Sydney'},
{ value: 'University of Texas Dallas', label: 'University of Texas Dallas'},
{ value: 'University of Texas at Austin', label: 'University of Texas at Austin'},
{ value: 'University of Toronto', label: 'University of Toronto'},
{ value: 'University of Twente', label: 'University of Twente'},
{ value: 'University of Utah', label: 'University of Utah'},
{ value: 'University of Vienna', label: 'University of Vienna'},
{ value: 'University of Virginia', label: 'University of Virginia'},
{ value: 'University of Waikato', label: 'University of Waikato'},
{ value: 'University of Washington', label: 'University of Washington'},
{ value: 'University of Waterloo', label: 'University of Waterloo'},
{ value: 'University of Wisconsin Madison', label: 'University of Wisconsin Madison'},
{ value: 'University of Witwatersrand', label: 'University of Witwatersrand'},
{ value: 'University of Wollongong', label: 'University of Wollongong'},
{ value: 'University of York', label: 'University of York'},
{ value: 'University of Zurich', label: 'University of Zurich'},
{ value: 'Uppsala University', label: 'Uppsala University'},
{ value: 'Utrecht University', label: 'Utrecht University'},
{ value: 'Victoria University of Wellington', label: 'Victoria University of Wellington'},
{ value: 'Virginia Polytechnic Institute and State University', label: 'Virginia Polytechnic Institute and State University'},
{ value: 'Vrije Universiteit Amsterdam', label: 'Vrije Universiteit Amsterdam'},
{ value: 'WU (Vienna University of Economics and Business)', label: 'WU (Vienna University of Economics and Business)'},
{ value: 'Waseda University', label: 'Waseda University'},
{ value: 'Washington University in St. Louis', label: 'Washington University in St. Louis'},
{ value: 'Western University', label: 'Western University'},
{ value: 'Xi\'An Jiaotong University', label: 'Xi\'An Jiaotong University'},
{ value: 'Xiamen University', label: 'Xiamen University'},
{ value: 'Yale University', label: 'Yale University'},
{ value: 'Yonsei University', label: 'Yonsei University'},
{ value: 'York University', label: 'York University'},
{ value: 'Zhejiang University', label: 'Zhejiang University'},
];