import {

  createProfile,
  updateProfile,
  fetchProfile,
  deleteProfile,

  forgotUsername,
  checkUser,

//  getProfileUser,
//  getProfileUserAll,

} from '../util/api';

import { Auth } from 'aws-amplify';

// CREATE PROFILE           attemptCreateProfile
// UPDATE PROFILE           attemptUpdateProfile

// FETCH PROFILE            attemptFetchProfile

// FORGOT USERNAME          attemptForgotUsername
// FORGOT USERNAME          attemptForgotUsername

// DELETE PROFILE           attemptDeleteProfile

// CLEAR PROFILE            attemptClearProfile

// CREATE
export const CREATE_PROFILE_REQUEST = 'CREATE_PROFILE_REQUEST';
export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS';
export const CREATE_PROFILE_ERROR   = 'CREATE_PROFILE_ERROR';

const createProfileRequest =             { type: CREATE_PROFILE_REQUEST          };
const createProfileSuccess = profile => ({ type: CREATE_PROFILE_SUCCESS, profile });
const createProfileError   = error   => ({ type: CREATE_PROFILE_ERROR,   error   });

export const attemptCreateProfile = (username) => async (dispatch, getState) => {
  dispatch(createProfileRequest);
 try {
    const { token } = getState().auth; 
    const user   = await Auth.currentAuthenticatedUser();
    
    // CHECK IF USERNAME IS AVALIABLE
    const exists = await checkUser(username, '');

    var newProfile = null
    if (!exists.existU) {
      newProfile = await createProfile(username, user.attributes.email, token);
    }

    await Auth.updateUserAttributes(user, { 'custom:profile': '1' });

    dispatch(createProfileSuccess(newProfile));
    
  } catch (error) {
    dispatch(createProfileError(error));
  }
};


// UPDATE
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR   = 'UPDATE_PROFILE_ERROR';

const updateProfileRequest =             { type: UPDATE_PROFILE_REQUEST          };
const updateProfileSuccess = profile => ({ type: UPDATE_PROFILE_SUCCESS, profile });
const updateProfileError   = error   => ({ type: UPDATE_PROFILE_ERROR,   error   });

export const attemptUpdateProfile = (values) => async (dispatch, getState) => {
  dispatch(updateProfileRequest);
  try {
    const { token } = getState().auth;
    
    const body = {
      email: values.email,
      bool1: values.bool1,
      var0: values.var0,
      var1: values.var1,
      var2: values.var2,
      var3: values.var3,
      var4: values.var4,
    }

    const newProfile = await updateProfile(body, token);
    dispatch(updateProfileSuccess(newProfile));
  } catch (error) {
    dispatch(updateProfileError(error));
  }
  
};

// FETCH
export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_ERROR   = 'FETCH_PROFILE_ERROR';

const fetchProfileRequest =             { type: FETCH_PROFILE_REQUEST                   };
const fetchProfileSuccess = profile => ({ type: FETCH_PROFILE_SUCCESS, profile: profile });
const fetchProfileError   = error   => ({ type: FETCH_PROFILE_ERROR,   error            });

export const attemptFetchProfile = username => async (dispatch, getState) => {
  dispatch(fetchProfileRequest);

  try {
    const { token } = getState().auth;
    
    const json = await fetchProfile(username, token);
    dispatch(fetchProfileSuccess(json));

  } catch (error) {
    error.message = 'UNKOWN USER'
    dispatch(fetchProfileError(error));
  }
};

// FORGOT USERNAME
export const FORGOT_USERNAME_REQUEST = 'FORGOT_USERNAME_REQUEST';
export const FORGOT_USERNAME_SUCCESS = 'FORGOT_USERNAME_SUCCESS';
export const FORGOT_USERNAME_ERROR   = 'FORGOT_USERNAME_ERROR';

const forgotUsernameRequest =             { type: FORGOT_USERNAME_REQUEST          };
const forgotUsernameSuccess =            ({ type: FORGOT_USERNAME_SUCCESS });
const forgotUsernameError   = error   => ({ type: FORGOT_USERNAME_ERROR,   error   });

export const attemptForgotUsername = (email) => async (dispatch, getState) => {
  dispatch(forgotUsernameRequest);
  try {

    // CHECK IF USERNAME IS AVALIABLE
    const exists = await checkUser('', email);

    if (!exists.existE) {
    
      await forgotUsername(email);
    
    } else {
      dispatch(forgotUsernameError("Email not found"));
    }

    dispatch(forgotUsernameSuccess);
  } catch (error) {
    dispatch(forgotUsernameError(error));
  }
};



// TO DO 
/*
export const FETCH_ALLPROFILE_REQUEST = 'FETCH_ALLPROFILE_REQUEST';
export const FETCH_ALLPROFILE_SUCCESS = 'FETCH_ALLPROFILE_SUCCESS';
export const FETCH_ALLPROFILE_ERROR   = 'FETCH_ALLPROFILE_ERROR';

const fetchAllProfileRequest =             { type: FETCH_ALLPROFILE_REQUEST                   };
const fetchAllProfileSuccess = profile => ({ type: FETCH_ALLPROFILE_SUCCESS, profile: profile });
const fetchAllProfileError   = error   => ({ type: FETCH_ALLPROFILE_ERROR,   error            });

export const fetchUserAllProfile = username => async (dispatch, getState) => {
  dispatch(fetchAllProfileRequest);
  try {
    const { token } = getState().auth;
    
    const json = await getProfileUserAll(username, token);
    dispatch(fetchAllProfileSuccess(json));
  } catch (error) {
    dispatch(fetchAllProfileError(error));
  }
};



export const DEL_POST_SAVED_REQUEST = 'DEL_POST_SAVED_REQUEST';
export const DEL_POST_SAVED_SUCCESS = 'DEL_POST_SAVED_SUCCESS';
export const DEL_POST_SAVED_ERROR = 'DEL_POST_SAVED_ERROR';

const delPostSaveRequest = { type: DEL_POST_SAVED_REQUEST };
//const delPostSaveSuccess = ({ type: DEL_POST_SAVED_SUCCESS});
const delPostSaveError = error => ({ type: DEL_POST_SAVED_ERROR, error });

export const attemptDeleteProfile = (pid) => async (dispatch, getState) => {
  dispatch(delPostSaveRequest);
  try {
    const { token } = getState().auth;
    //const { post }  = getState().posts;
    //const data = {uid: iduser, pid: post.id}

    const json = await deleteProfile(pid, token);
    //dispatch(delPostSaveSuccess(json));
  } catch (error) {
    dispatch(delPostSaveError(error));
  }
};
*/




/*
export const GET_COMMENT_SAVED_REQUEST = 'GET_COMMENT_SAVED_REQUEST';
export const GET_COMMENT_SAVED_SUCCESS = 'GET_COMMENT_SAVED_SUCCESS';
export const GET_COMMENT_SAVED_ERROR = 'GET_COMMENT_SAVED_ERROR';

const getCommentSaveRequest = { type: GET_COMMENT_SAVED_REQUEST };
const getCommentSaveSuccess = commented => ({ type: GET_COMMENT_SAVED_SUCCESS, profile: commented });
const getCommentSaveError = error => ({ type: GET_COMMENT_SAVED_ERROR, error });

export const attemptGetCommentSaved = (username, commentid) => async (dispatch, getState) => {
  dispatch(getCommentSaveRequest);
  try {
    const { token } = getState().auth;

    const json = await getProfileCommentSave(username, commentid, token);

    dispatch(getCommentSaveSuccess(json));
  } catch (error) {
    dispatch(getCommentSaveError(error));
  }
};

export const ADD_COMMENT_SAVED_REQUEST = 'ADD_COMMENT_SAVED_REQUEST';
export const ADD_COMMENT_SAVED_SUCCESS = 'ADD_COMMENT_SAVED_SUCCESS';
export const ADD_COMMENT_SAVED_ERROR = 'ADD_COMMENT_SAVED_ERROR';

const addCommentSaveRequest = { type: ADD_COMMENT_SAVED_REQUEST };
const addCommentSaveSuccess = ({ type: ADD_COMMENT_SAVED_SUCCESS });
const addCommentSaveError = error => ({ type: ADD_COMMENT_SAVED_ERROR, error });

export const attemptAddCommentSaved = (username, cid) => async (dispatch, getState) => {
  dispatch(addCommentSaveRequest);
  try {
    const { token } = getState().auth;
    //const { post }  = getState().posts;
    //const data = {uid: iduser, pid: post.id}

    const json = await addProfileCommentSave(username, cid, token);

    //dispatch(addCommentSaveSuccess(json));
  } catch (error) {
    dispatch(addCommentSaveError(error));
  }
};


export const DEL_COMMENT_SAVED_REQUEST = 'DEL_COMMENT_SAVED_REQUEST';
export const DEL_COMMENT_SAVED_SUCCESS = 'DEL_COMMENT_SAVED_SUCCESS';
export const DEL_COMMENT_SAVED_ERROR = 'DEL_COMMENT_SAVED_ERROR';

const delCommentSaveRequest = { type: DEL_COMMENT_SAVED_REQUEST };
const delCommentSaveSuccess = ({ type: DEL_COMMENT_SAVED_SUCCESS });
const delCommentSaveError = error => ({ type: DEL_COMMENT_SAVED_ERROR, error });

export const attemptUnsavePost = (username, cid) => async (dispatch, getState) => {
  dispatch(delCommentSaveRequest);
  try {
    const { token } = getState().auth;
    
    //const { post }  = getState().posts;

    //const data = {uid: iduser, pid: post.id}

    const json = await unsavePost(username, cid, token);
   //dispatch(delCommentSaveSuccess(json));
  } catch (error) {
    dispatch(delCommentSaveError(error));
  }
};

*/





// EMAIL

/*
export const SEND_EMAILCONFIRM_REQUEST = 'SEND_EMAILCONFIRM_REQUEST';
export const SEND_EMAILCONFIRM_SUCCESS = 'SEND_EMAILCONFIRM_SUCCESS';
export const SEND_EMAILCONFIRM_ERROR = 'SEND_EMAILCONFIRM_ERROR';

const sendEmailConfirmRequest = { type: SEND_EMAILCONFIRM_REQUEST };
const sendEmailConfirmSuccess = ({ type: SEND_EMAILCONFIRM_SUCCESS });
const sendEmailConfirmError = error => ({ type: SEND_EMAILCONFIRM_ERROR, error });

export const attemptSendEmailConfirm = (body) => async (dispatch, getState) => {
  dispatch(sendEmailConfirmRequest);
  try {
    const { token } = getState().auth;

    const json = await sendEmailConfirm(body, token);
    dispatch(sendEmailConfirmSuccess(json));
  } catch (error) {
    dispatch(sendEmailConfirmError(error));
  }
};


export const EMAILCONFIRM_REQUEST = 'EMAILCONFIRM_REQUEST';
export const EMAILCONFIRM_SUCCESS = 'EMAILCONFIRM_SUCCESS';
export const EMAILCONFIRM_ERROR = 'EMAILCONFIRM_ERROR';

const emailConfirmRequest = { type: EMAILCONFIRM_REQUEST };
const emailConfirmSuccess = ({ type: EMAILCONFIRM_SUCCESS });
const emailConfirmError = error => ({ type: EMAILCONFIRM_ERROR, error });

export const attemptEmailConfirm = (username, emailid) => async (dispatch, getState) => {
  dispatch(emailConfirmRequest);
  try {
    const { token } = getState().auth;

    const json = await emailConfirm(username, emailid, token);
    dispatch(emailConfirmSuccess(json));
  } catch (error) {
    dispatch(emailConfirmError(error));
  }
};


// EMAIL PASSWORD RESET

export const SEND_EMAILRESET_REQUEST = 'SEND_EMAILRESET_REQUEST';
export const SEND_EMAILRESET_SUCCESS = 'SEND_EMAILRESET_SUCCESS';
export const SEND_EMAILRESET_ERROR = 'SEND_EMAILRESET_ERROR';

const sendEmailResetRequest = { type: SEND_EMAILRESET_REQUEST };
const sendEmailResetSuccess = ({ type: SEND_EMAILRESET_SUCCESS });
const sendEmailResetError = error => ({ type: SEND_EMAILRESET_ERROR, error });

export const attemptEmailReset = (body) => async (dispatch, getState) => {
  dispatch(sendEmailResetRequest);
  try {
    const { token } = getState().auth;

    const json = await sendEmailReset(body, token);
    dispatch(sendEmailResetSuccess(json));
  } catch (error) {
    dispatch(sendEmailResetError(error));
  }
};



export const EMAILRESET_REQUEST = 'EMAILRESET_REQUEST';
export const EMAILRESET_SUCCESS = 'EMAILRESET_SUCCESS';
export const EMAILRESET_ERROR = 'EMAILRESET_ERROR';

const emailConfirmResetRequest = { type: EMAILRESET_REQUEST };
const emailConfirmResetSuccess = ({ type: EMAILRESET_SUCCESS });
const emailConfirmResetError = error => ({ type: EMAILRESET_ERROR, error });

export const attemptEmailConfirmReset = (username, passid, password) => async (dispatch, getState) => {
  dispatch(emailConfirmResetRequest);
  try {
    const { token } = getState().auth;

    const json = await emailConfirmReset(username, passid, password, token);
    dispatch(emailConfirmResetSuccess(json));
  } catch (error) {
    dispatch(emailConfirmResetError(error));
  }
};


// PREFERENCES
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

const resetPasswordRequest = { type: RESET_PASSWORD_REQUEST };
const resetPasswordSuccess = ({ type: RESET_PASSWORD_SUCCESS });
const resetPasswordError = error => ({ type: RESET_PASSWORD_ERROR, error });

export const attemptResetPassword = (password) => async (dispatch, getState) => {
  dispatch(resetPasswordRequest);
  try {
    const { token } = getState().auth;

    const json = await resetPassword(password, token);
    dispatch(resetPasswordSuccess(json));
  } catch (error) {
    dispatch(resetPasswordError(error));
  }
};
*/

export const DELETE_PROFILE_REQUEST = 'DELETE_PROFILE_REQUEST';
export const DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS';
export const DELETE_PROFILE_ERROR   = 'DELETE_PROFILE_ERROR';

const deleteProfileRequest =           { type: DELETE_PROFILE_REQUEST       };
const deleteProfileSuccess =          ({ type: DELETE_PROFILE_SUCCESS       });
const deleteProfileError   = error => ({ type: DELETE_PROFILE_ERROR,  error });

export const attemptDeactivate = (user) => async (dispatch, getState) => {
  dispatch(deleteProfileRequest);
  try {
    const { token } = getState().auth;

    const json = await deleteProfile(user, token);
    dispatch(deleteProfileSuccess(json));
  } catch (error) {
    dispatch(deleteProfileError(error));
  }
};



// CLEAR PROFILE
export const CLEAR_PROFILE = 'CLEAR_PROFILE';

const clearProfile = { type: CLEAR_PROFILE };

export const attemptClearProfile = () => async dispatch => {
  dispatch(clearProfile)
};
