import { SEARCH_SUCCESS } from '../actions/searchrequest';
import { SEARCH_RESET   } from '../actions/searchrequest';

import { PAGE_SUCCESS } from '../actions/searchrequest';
import { PAGE_RESET   } from '../actions/searchrequest';

//import { SAVE_SUCCESS } from '../actions/searchrequest';

const initialState = { items: [] };

export default (state = initialState, action) => {

  switch (action.type) {

    //case SAVE_SUCCESS:
    //  return { ...state, savevalues: action.saverequest, saveAction: action.type};

    case SEARCH_SUCCESS:
      return { ...state, searchvalues: action.searchrequest, searchAction: action.type};

    case SEARCH_RESET:      
      return { ...state, searchvalues: Object.assign(action.searchrequest), searchAction: action.type};
      
    case PAGE_SUCCESS:
      return { ...state, pagevalues: action.pagerequest, pageAction: action.type};

    case PAGE_RESET:      
      return { ...state, pagevalues: action.pagerequest, pageAction: action.type};
      
    default:
      return { ...state}
  }
};
