import {
  fetchComments,
  fetchComment,

  createComment,
  createReply,
  deleteComment,
  
  savedComment,
  saveComment,
  unsaveComment,

  voteComment,

  reportComment,

  // REPORT COMMENT
  
} from '../util/api';

// FETCH COMMENTS         attemptFetchComments
// FETCH COMMENT          attemptFetchComment

// CREATE COMMENT         attemptCreateComment
// CREATE REPLY           attemptCreateReply

// SAVED COMMENT          attemptSavedComment
// SAVE COMMENT           attemptSaveComment
// UNSAVE COMMENT         attemptUnsaveComment

// VOTE COMMENT           attemptVoteComment

// DELETE COMMENT         attemptDeleteComment

export const FETCH_COMMENTS_REQUEST = 'FETCH_COMMENTS_REQUEST';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_ERROR   = 'FETCH_COMMENTS_ERROR';

const fetchCommentsRequest =             { type: FETCH_COMMENTS_REQUEST          };
const fetchCommentsSuccess = comment => ({ type: FETCH_COMMENTS_SUCCESS, comment });
const fetchCommentsError   = error   => ({ type: FETCH_COMMENTS_ERROR,   error   });

export const attemptFetchComments = (pid) => async (dispatch, getState) => {
  dispatch(fetchCommentsRequest);
  try {

    const { token } = getState().auth;
    
    const comment = await fetchComments(pid, token);
    dispatch(fetchCommentsSuccess(comment));
    
  } catch (error) {
    dispatch(fetchCommentsError(error));
  }
};


export const CREATE_COMMENT_REQUEST = 'CREATE_COMMENT_REQUEST';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_ERROR   = 'CREATE_COMMENT_ERROR';

const createCommentRequest =             { type: CREATE_COMMENT_REQUEST          };
const createCommentSuccess = comment => ({ type: CREATE_COMMENT_SUCCESS, comment });
const createCommentError   = error   => ({ type: CREATE_COMMENT_ERROR,   error   });

export const attemptCreateComment = (text, pid, attach) => async (dispatch, getState) => {
  dispatch(createCommentRequest);
  try {

    var universialcomment = {
      pid:      null,
      text:     null, 
      attach:   null, 
    }
    
    universialcomment.pid         = pid
    universialcomment.text        = text.comment
    universialcomment.attach      = attach

    const json = await createComment(universialcomment);

    dispatch(createCommentSuccess(json));
  } catch (error) {
    dispatch(createCommentError(error));
  }
};


export const CREATE_REPLY_REQUEST = 'CREATE_REPLY_REQUEST';
export const CREATE_REPLY_SUCCESS = 'CREATE_REPLY_SUCCESS';
export const CREATE_REPLY_ERROR   = 'CREATE_REPLY_ERROR';

const createReplyRequest =             { type: CREATE_REPLY_REQUEST          };
const createReplySuccess = comment => ({ type: CREATE_REPLY_SUCCESS, comment });
const createReplyError   = error   => ({ type: CREATE_REPLY_ERROR,   error   });

export const attemptCreateReply = (text, pid, cid, attach) => async (dispatch, getState) => {
  dispatch(createReplyRequest);
  try {
    const { token } = getState().auth;
  
    var universialcomment = {
      pid:      null,
      parentid: null,
      text:     null, 
      attach:   null, 
    }
    
    universialcomment.pid         = pid
    universialcomment.parentid    = cid
    universialcomment.text        = text.reply
    universialcomment.attach      = attach

    const json = await createReply(universialcomment, token);
    dispatch(createReplySuccess(json));
  } catch (error) {
    dispatch(createReplyError(error));
  }
};


export const SAVED_COMMENT_REQUEST = 'SAVED_COMMENT_REQUEST';
export const SAVED_COMMENT_SUCCESS = 'SAVED_COMMENT_SUCCESS';
export const SAVED_COMMENT_ERROR   = 'SAVED_COMMENT_ERROR';

const savedCommentRequest =           { type: SAVED_COMMENT_REQUEST        };
const savedCommentSuccess = saved => ({ type: SAVED_COMMENT_SUCCESS, saved });
const savedCommentError   = error => ({ type: SAVED_COMMENT_ERROR,   error });

export const attemptSavedComment = (pid, cid) => async (dispatch, getState) => {
  dispatch(savedCommentRequest);
  try {
    const { token } = getState().auth;
    
    const json = await savedComment(pid, cid, token);
    dispatch(savedCommentSuccess(json));
  } catch (error) {
    dispatch(savedCommentError(error));
  }
};


export const SAVE_COMMENT_REQUEST = 'SAVE_COMMENT_REQUEST';
export const SAVE_COMMENT_SUCCESS = 'SAVE_COMMENT_SUCCESS';
export const SAVE_COMMENT_ERROR   = 'SAVE_COMMENT_ERROR';

const saveCommentRequest =           { type: SAVE_COMMENT_REQUEST        };
const saveCommentSuccess =          ({ type: SAVE_COMMENT_SUCCESS        });
const saveCommentError   = error => ({ type: SAVE_COMMENT_ERROR,   error });

export const attemptSaveComment = (pid, cid) => async (dispatch, getState) => {
  dispatch(saveCommentRequest);
  try {
    const { token } = getState().auth;
    
    const json = await saveComment(pid, cid, token);
    dispatch(saveCommentSuccess(json));
  } catch (error) {
    dispatch(saveCommentError(error));
  }
};


export const UNSAVE_COMMENT_REQUEST = 'UNSAVE_COMMENT_REQUEST';
export const UNSAVE_COMMENT_SUCCESS = 'UNSAVE_COMMENT_SUCCESS';
export const UNSAVE_COMMENT_ERROR   = 'UNSAVE_COMMENT_ERROR';

const unsaveCommentRequest =           { type: UNSAVE_COMMENT_REQUEST        };
const unsaveCommentSuccess =          ({ type: UNSAVE_COMMENT_SUCCESS        });
const unsaveCommentError   = error => ({ type: UNSAVE_COMMENT_ERROR,   error });

export const attemptUnsaveComment = (pid, cid) => async (dispatch, getState) => {
  dispatch(unsaveCommentRequest);
  try {
    const { token } = getState().auth;
    
    const json = await unsaveComment(pid, cid, token);
    dispatch(unsaveCommentSuccess(json));
  } catch (error) {
    dispatch(unsaveCommentError(error));
  }
};


export const VOTE_COMMENT_REQUEST = 'VOTE_COMMENT_REQUEST';
export const VOTE_COMMENT_SUCCESS = 'VOTE_COMMENT_SUCCESS';
export const VOTE_COMMENT_ERROR   = 'VOTE_COMMENT_ERROR';

const voteCommentRequest =             { type: VOTE_COMMENT_REQUEST          };
const voteCommentSuccess = comment => ({ type: VOTE_COMMENT_SUCCESS, comment });
const voteCommentError   = error   => ({ type: VOTE_COMMENT_ERROR,   error   });

export const attemptVoteComment = (pid, cid, vote) => async (dispatch, getState) => {
  dispatch(voteCommentRequest);
  try {
    const { token } = getState().auth;
    
    const comment = await voteComment(pid, cid, vote, token);
    dispatch(voteCommentSuccess(comment));
  } catch (error) {
    dispatch(voteCommentError(error));
  }
};



// TO DO
export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_ERROR   = 'DELETE_COMMENT_ERROR';

const deleteCommentRequest =             { type: DELETE_COMMENT_REQUEST          };
const deleteCommentSuccess = comment => ({ type: DELETE_COMMENT_SUCCESS, comment });
const deleteCommentError   = error   => ({ type: DELETE_COMMENT_ERROR,   error   });

export const attemptDeleteComment = (pid, cid) => async (dispatch, getState) => {
  dispatch(deleteCommentRequest);
  try {
    const { token } = getState().auth;

    const json = await deleteComment(pid, cid, token);
    dispatch(deleteCommentSuccess(json));
  } catch (error) {
    dispatch(deleteCommentError(error));
  }
};



export const REPORT_COMMENT_REQUEST = 'REPORT_COMMENT_REQUEST';
export const REPORT_COMMENT_SUCCESS = 'REPORT_COMMENT_SUCCESS';
export const REPORT_COMMENT_ERROR   = 'REPORT_COMMENT_ERROR';

const reportCommentRequest =             { type: REPORT_COMMENT_REQUEST        };
const reportCommentSuccess =            ({ type: REPORT_COMMENT_SUCCESS        });
const reportCommentError   = error   => ({ type: REPORT_COMMENT_ERROR,   error });

export const attemptReportComment = (pid, cid) => async (dispatch, getState) => {
  dispatch(reportCommentRequest);
  try {
    
    const { token } = getState().auth;

    const json = await reportComment(pid, cid, token);
    dispatch(reportCommentSuccess(json));
  } catch (error) {
    dispatch(reportCommentError(error));
  }
};


//?
export const FETCH_COMMENT_REQUEST = 'FETCH_COMMENT_REQUEST';
export const FETCH_COMMENT_SUCCESS = 'FETCH_COMMENT_SUCCESS';
export const FETCH_COMMENT_ERROR   = 'FETCH_COMMENT_ERROR';

const fetchCommentRequest =             { type: FETCH_COMMENT_REQUEST          };
const fetchCommentSuccess = comment => ({ type: FETCH_COMMENT_SUCCESS, comment });
const fetchCommentError   = error   => ({ type: FETCH_COMMENT_ERROR,   error   });

export const attemptFetchComment = (pid, cid) => async (dispatch, getState) => {
  dispatch(fetchCommentRequest);
  try {
    const { token } = getState().auth;

    const comment = await fetchComment(pid, cid, token);
    dispatch(fetchCommentSuccess(comment));
  } catch (error) {
    dispatch(fetchCommentError(error));
  }
};


