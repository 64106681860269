import { connect } from 'react-redux';
import { compose } from 'redux';
import withAuth from '../../util/withAuth';

import { attemptFetchProfile }    from '../../actions/profiles';

import ProfilePage from './Component';

export const mapStateToProps = state => {
  return({
    isFetchingProfile: state.profiles.isFetchingProfile,
    profile:           state.profiles.fetchProfile,

    errorUnkownProfile: state.profiles.UnkownProfile,
  })
};

const mapDispatchToProps = { attemptFetchProfile
                            };

const ProfilePageContainer = compose(

  withAuth,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProfilePage);

//const ProfilePageContainer = enhance(ProfilePage);

export default ProfilePageContainer;