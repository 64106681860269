import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { link, unlink } from './helpers';

const StyledLink = styled(Link)`
  ${link};

  text-underline-position: under;
  text-decoration: none;
  outline: 0;

  font-weight: 500;
  color: ${props => props.theme.normalText};
`;


const StyledUnLink = styled.span`
  ${unlink};

  text-underline-position: under;
  text-decoration: none;
  outline: 0;
  
  font-weight: 500;
  color: ${props => props.theme.normalText};
`;


const Author = ({ username }) => { 
  if (username === 'anonymous') {
    return (
      <StyledUnLink>{username}</StyledUnLink>
    )
  }
 
  return (
    <StyledLink to={`/u/${username}`}>{username}</StyledLink>
  );
}

export default Author;
