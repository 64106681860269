import styled from 'styled-components/macro';

const InputWrapper = styled.div`
  position: relative;
  
  //border-color: red;

  display: 'flex';
  flex-direction: 'column';
  
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 15px;
  height: 33px;
  //resize: vertical;
  
  //width: 100%;
  min-width: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
`;

export default InputWrapper;
