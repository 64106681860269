import React from 'react';
import { withRouter } from "react-router";
import styled from 'styled-components/macro';
import HeaderLogo from './Logo';
import HeaderDarkButtonContainer from './DarkButton/Container';
import HeaderUsername from './Username';
import HeaderNavLink from './NavLink';

import { withTheme } from 'styled-components';

import Media from 'react-media';

import SearchBarContainer from './Search/Container';

const Wrapper = styled.header`
  position: sticky;
  z-index: 1;
  //top: 0;
  display: flex;
  align-items: stretch;
  margin-bottom: 24px;
  box-shadow: 0 4px 12px ${props => props.theme.shadow};
  border-bottom: 1px solid ${props => props.theme.border};
  height: auto;
  z-index:

  padding: 0 4vw;
  background-color: ${props => props.theme.foreground};
  user-select: none;

  @media (max-width: ${props => props.theme.w2}) {
    margin-bottom: 0px;
    border: 0px;
    box-shadow: null;
    z-index: 1;
    padding: 0;
    box-shadow: 0 0px 0px;
  }

  @media (max-width: ${props => props.theme.w1}) {
    margin-top: -6px;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
  }
`;

const Wrapper2 = styled.header`
  z-index: 1;
  display: flex;
  align-items: stretch;
  margin-bottom: 24px;

  user-select: none;
`;


const Wrapper3 = styled.header`
  @media (max-width: ${props => props.theme.w1}) {
    display: flex;
    align-items: center; 
    flex-flow: column;
    flex-grow: 1;
    width: auto;
  }
`;

class ParentSearchBarContainer extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() { 
    this.props.checkToken()
  }
  render() {
   return(
     <SearchBarContainer pathname={this.props.pathname} />
   )
  }
};

const Header = ({ user, logout, checkToken, pathname, theme, searchRequest, pageRequest }) => (
 <div styles={"zIndex: null; marginBottom: 0;"}>

  <Wrapper>
    <HeaderLogo reset={searchRequest} pagereset={pageRequest}/>

    {
    <Media queries={{ large: "(min-width: "+theme.w2+")" }}>
      <ParentSearchBarContainer pathname={pathname} checkToken={checkToken}/>
    </Media>
    }

    <Wrapper3/>
  
    <HeaderDarkButtonContainer />

    {user ? (
      <>
        <HeaderUsername username={user.username} reset={searchRequest} pagereset={pageRequest}/>
        <HeaderNavLink as='span' onClick={logout}>log out</HeaderNavLink>
      </>
    ) : (
      <>
        <HeaderNavLink to='/login'>log in</HeaderNavLink>
        <HeaderNavLink to='/signup'>sign up</HeaderNavLink>
      </>
    )}

  </Wrapper>

  <Media queries={{ small: "(max-width: "+theme.w2p1+")" }}>
    <Wrapper2>
      <ParentSearchBarContainer pathname={pathname} checkToken={checkToken}/>
    </Wrapper2>
  </Media>

 </div>

);

export default withRouter(withTheme(Header));
