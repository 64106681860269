import React from 'react';
import styled from 'styled-components/macro';
import PostVoteUpvote from './Upvote';
import PostVoteDownvote from './Downvote';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px;
  padding: 4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.normalText};
`;

class PostVote extends React.Component {
  constructor(props) {
    super(props);
    const didVote = PostVote.existingVote(props);
    this.state = {
      score: props.score,
      didVote,
      didUpvote:   didVote === 1,
      didDownvote: didVote === -1
    };
  }

  static existingVote({ user, vote }) {
    const existingVote = user && vote; // user && votes && votes.find(vote => vote.user === user.id);
    return existingVote ? vote : 0; //existingVote.vote : 0;
  }

  componentDidUpdate(nextProps, nextState, nextContext) {
    if (this.props.score !== nextProps.score) {
      const didVote = PostVote.existingVote(nextProps);
      this.setState({
        score: nextProps.score,
        didVote,
        didUpvote:   didVote === 1,
        didDownvote: didVote === -1
      });
    } else if (this.props.token !== nextProps.token && !nextProps.token) {
      this.setState({
        didVote: false,
        didUpvote: false,
        didDownvote: false
      });
    }
  }

  castVote(vote) {
    const { attemptVotePost, pid, token, emailVerified, history, posts } = this.props;
    if (token) {
      if (!emailVerified) history.push('/verifyemail');

      attemptVotePost(pid, vote, posts);

      this.setState({
        score: this.state.score + vote - this.state.didVote,
        didVote:     vote,
        didUpvote:   vote === 1,
        didDownvote: vote === -1
      });
    }
  }

  upvote   = () => this.castVote(this.state.didUpvote   ? 0 : 1);

  downvote = () => this.castVote(this.state.didDownvote ? 0 : -1);

  render() {
    return (
      <Wrapper>
        <PostVoteUpvote
          canVote={!!this.props.token && (this.props.author!==null)}
          didVote={this.state.didUpvote}
          onClick={this.upvote}
        />
        <span styles={'paddingTop: -3px'}>{Math.max(0, this.state.score)}</span>
        <PostVoteDownvote
          canVote={!!this.props.token && (this.props.author!==null)}
          didVote={this.state.didDownvote}
          onClick={this.downvote}
        />
      </Wrapper>
    );
  }
}

export default PostVote;
