import styled from 'styled-components/macro';

const InputWrapper = styled.div`
  position: relative;

  flex-direction: 'column';
  //border-color: red;

  display: 'flex';
  //flex-grow: 1;
  //flex-shrink: 1;
  font-size: '15px';
  float: 'left';
  //height: '33px';
  //resize: vertical;
  
  //width: 100%;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-right: 8px;
`;

export default InputWrapper;
