import styled from 'styled-components/macro';
import Button from '../../shared/SmallButton';

const SearchButton = styled(Button)`

  margin-top: 8px;
  margin-right: 0px;
  margin-bottom: 8px;
  
  height:  38px;
  width: 68px;

  align-items: center;
  justify-content: center;
  text-align: center;
 
`;

export default SearchButton;
