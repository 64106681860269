export const keywordSchoolOptions = [
{ value: 'Culture', label: 'Culture'},
{ value: 'Courses', label: 'Courses'},
{ value: 'Philosophy', label: 'Philosophy'},
{ value: 'Extracurricular', label: 'Extracurricular'},
{ value: 'Network', label: 'Network'},
{ value: 'Location', label: 'Location'},
{ value: 'Nightlife', label: 'Nightlife'},
{ value: 'Coursework', label: 'Coursework'},
{ value: 'Employers', label: 'Employers'},
{ value: 'Curriculum', label: 'Curriculum'},
{ value: 'Departments', label: 'Departments'},
{ value: 'Work Life Balance', label: 'Work Life Balance'}
];