export const keywordOptions = [
{ value: 'Accruals Effect', label: 'Accruals Effect'},
{ value: 'Acquisitions', label: 'Acquisitions'},
{ value: 'Actuarial Studies', label: 'Actuarial Studies'},
{ value: 'Alternative Data', label: 'Alternative Data'},
{ value: 'Analysis', label: 'Analysis'},
{ value: 'Applications', label: 'Applications'},
{ value: 'Arbitrage', label: 'Arbitrage'},
{ value: 'Artificial Intelligence', label: 'Artificial Intelligence'},
{ value: 'Asset Class Picking', label: 'Asset Class Picking'},
{ value: 'Asset Markets', label: 'Asset Markets'},
{ value: 'Asset Pricing', label: 'Asset Pricing'},
{ value: 'Asymmetric', label: 'Asymmetric'},
{ value: 'Bankruptcy', label: 'Bankruptcy'},
{ value: 'Banks', label: 'Banks'},
{ value: 'Bargaining Theory', label: 'Bargaining Theory'},
{ value: 'Bayesian Analysis', label: 'Bayesian Analysis'},
{ value: 'Behavioral Finance', label: 'Behavioral Finance'},
{ value: 'Belief', label: 'Belief'},
{ value: 'Beta Factor', label: 'Beta Factor'},
{ value: 'Big Data', label: 'Big Data'},
{ value: 'Bond Timing', label: 'Bond Timing'},
{ value: 'Brokerage', label: 'Brokerage'},
{ value: 'Capacity', label: 'Capacity'},
{ value: 'Capital', label: 'Capital'},
{ value: 'Capital Budgeting', label: 'Capital Budgeting'},
{ value: 'Carry Trade', label: 'Carry Trade'},
{ value: 'Central Banks', label: 'Central Banks'},
{ value: 'Cognitive Factors On Decision Making', label: 'Cognitive Factors On Decision Making'},
{ value: 'Cognitive Factors On Decision Making In Financial Markets', label: 'Cognitive Factors On Decision Making In Financial Markets'},
{ value: 'Communication', label: 'Communication'},
{ value: 'Computation', label: 'Computation'},
{ value: 'Computational Engineering', label: 'Computational Engineering'},
{ value: 'Computational Finance', label: 'Computational Finance'},
{ value: 'Computational Techniques', label: 'Computational Techniques'},
{ value: 'Computer Applications', label: 'Computer Applications'},
{ value: 'Computers Society', label: 'Computers Society'},
{ value: 'Computing Methodologies', label: 'Computing Methodologies'},
{ value: 'Contingent Pricing', label: 'Contingent Pricing'},
{ value: 'Corporate Finance', label: 'Corporate Finance'},
{ value: 'Corporate Governance', label: 'Corporate Governance'},
{ value: 'Credit', label: 'Credit'},
{ value: 'Credit Risk', label: 'Credit Risk'},
{ value: 'Criteria For Decision-Making Under Risk', label: 'Criteria For Decision-Making Under Risk'},
{ value: 'Cryptocurrencies', label: 'Cryptocurrencies'},
{ value: 'Cycles', label: 'Cycles'},
{ value: 'Depository Institutions', label: 'Depository Institutions'},
{ value: 'Derivative Securities', label: 'Derivative Securities'},
{ value: 'Derivatives', label: 'Derivatives'},
{ value: 'Design Of Experiments', label: 'Design Of Experiments'},
{ value: 'Diffusion Processes', label: 'Diffusion Processes'},
{ value: 'Dynamic Analysis', label: 'Dynamic Analysis'},
{ value: 'Dynamic Games', label: 'Dynamic Games'},
{ value: 'Dynamic Quantile Regressions', label: 'Dynamic Quantile Regressions'},
{ value: 'Dynamic Treatment Effect Models', label: 'Dynamic Treatment Effect Models'},
{ value: 'Earnings Announcement', label: 'Earnings Announcement'},
{ value: 'Econometric', label: 'Econometric'},
{ value: 'Econometric Modeling', label: 'Econometric Modeling'},
{ value: 'Econometrics', label: 'Econometrics'},
{ value: 'Economics', label: 'Economics'},
{ value: 'Emerging Markets', label: 'Emerging Markets'},
{ value: 'Equities', label: 'Equities'},
{ value: 'Equity Long Short', label: 'Equity Long Short'},
{ value: 'Esg', label: 'Esg'},
{ value: 'Estimation', label: 'Estimation'},
{ value: 'Event Studies', label: 'Event Studies'},
{ value: 'Evolutionary Games', label: 'Evolutionary Games'},
{ value: 'Factor Allocation', label: 'Factor Allocation'},
{ value: 'Factor Investing', label: 'Factor Investing'},
{ value: 'Factor Models', label: 'Factor Models'},
{ value: 'Finance', label: 'Finance'},
{ value: 'Financial Crises', label: 'Financial Crises'},
{ value: 'Financial Econometrics', label: 'Financial Econometrics'},
{ value: 'Financial Economics', label: 'Financial Economics'},
{ value: 'Financial Forecasting', label: 'Financial Forecasting'},
{ value: 'Financial Institutions', label: 'Financial Institutions'},
{ value: 'Financial Instruments', label: 'Financial Instruments'},
{ value: 'Financial Markets', label: 'Financial Markets'},
{ value: 'Financial Risk', label: 'Financial Risk'},
{ value: 'Financing Policy', label: 'Financing Policy'},
{ value: 'Fixed Income Securities', label: 'Fixed Income Securities'},
{ value: 'Fixed Investment', label: 'Fixed Investment'},
{ value: 'Forecasting', label: 'Forecasting'},
{ value: 'Forecasting Models', label: 'Forecasting Models'},
{ value: 'Forex System', label: 'Forex System'},
{ value: 'Fund Picking', label: 'Fund Picking'},
{ value: 'Fundamental Analysis', label: 'Fundamental Analysis'},
{ value: 'Futures Pricing', label: 'Futures Pricing'},
{ value: 'Fx Anomaly', label: 'Fx Anomaly'},
{ value: 'Governance', label: 'Governance'},
{ value: 'Government Policy', label: 'Government Policy'},
{ value: 'Growth Stocks', label: 'Growth Stocks'},
{ value: 'Hidden Markov Models', label: 'Hidden Markov Models'},
{ value: 'Household Finance', label: 'Household Finance'},
{ value: 'Hypothesis Testing', label: 'Hypothesis Testing'},
{ value: 'Information', label: 'Information'},
{ value: 'Information Theory', label: 'Information Theory'},
{ value: 'Insider Trading', label: 'Insider Trading'},
{ value: 'Insiders Trading Effect', label: 'Insiders Trading Effect'},
{ value: 'Institutional Investors', label: 'Institutional Investors'},
{ value: 'Insurance', label: 'Insurance'},
{ value: 'Insurance Companies', label: 'Insurance Companies'},
{ value: 'Interest Rates', label: 'Interest Rates'},
{ value: 'International Financial Markets', label: 'International Financial Markets'},
{ value: 'Inventory Studies', label: 'Inventory Studies'},
{ value: 'Investment Banking', label: 'Investment Banking'},
{ value: 'Investment Decisions', label: 'Investment Decisions'},
{ value: 'Knowledge', label: 'Knowledge'},
{ value: 'Large Data Sets', label: 'Large Data Sets'},
{ value: 'Learning', label: 'Learning'},
{ value: 'Liquidation', label: 'Liquidation'},
{ value: 'Liquidity Risk Premium', label: 'Liquidity Risk Premium'},
{ value: 'Machine Learning', label: 'Machine Learning'},
{ value: 'Macroeconomics', label: 'Macroeconomics'},
{ value: 'Market Efficiency', label: 'Market Efficiency'},
{ value: 'Market Timing', label: 'Market Timing'},
{ value: 'Markov Chain', label: 'Markov Chain'},
{ value: 'Markov Chain Monte Carlo', label: 'Markov Chain Monte Carlo'},
{ value: 'Mathematical', label: 'Mathematical'},
{ value: 'Mathematical Finance', label: 'Mathematical Finance'},
{ value: 'Mathematical Methods', label: 'Mathematical Methods'},
{ value: 'Mathematics Computing', label: 'Mathematics Computing'},
{ value: 'Mechanism Design', label: 'Mechanism Design'},
{ value: 'Mergers', label: 'Mergers'},
{ value: 'Methodology', label: 'Methodology'},
{ value: 'Micro Finance Institutions', label: 'Micro Finance Institutions'},
{ value: 'Micro-Based Behavioral Economics', label: 'Micro-Based Behavioral Economics'},
{ value: 'Model Construction', label: 'Model Construction'},
{ value: 'Modeling', label: 'Modeling'},
{ value: 'Momentum', label: 'Momentum'},
{ value: 'Monetary Policy', label: 'Monetary Policy'},
{ value: 'Monte Carlo Method', label: 'Monte Carlo Method'},
{ value: 'Multiple Or Simultaneous Equation Models', label: 'Multiple Or Simultaneous Equation Models'},
{ value: 'Neural Networks', label: 'Neural Networks'},
{ value: 'Non-Bank Financial Institutions', label: 'Non-Bank Financial Institutions'},
{ value: 'Noncooperative Games', label: 'Noncooperative Games'},
{ value: 'Nonparametric Methods', label: 'Nonparametric Methods'},
{ value: 'Numerical Analysis', label: 'Numerical Analysis'},
{ value: 'Numerical Methods', label: 'Numerical Methods'},
{ value: 'Optimization Control', label: 'Optimization Control'},
{ value: 'Optimization Techniques', label: 'Optimization Techniques'},
{ value: 'Option Pricing', label: 'Option Pricing'},
{ value: 'Options', label: 'Options'},
{ value: 'Original Research', label: 'Original Research'},
{ value: 'Other Statistics', label: 'Other Statistics'},
{ value: 'Ownership Structure', label: 'Ownership Structure'},
{ value: 'Pairs Trading', label: 'Pairs Trading'},
{ value: 'Panel Data Models', label: 'Panel Data Models'},
{ value: 'Payout Policy', label: 'Payout Policy'},
{ value: 'Pension Funds', label: 'Pension Funds'},
{ value: 'Performance Measurement', label: 'Performance Measurement'},
{ value: 'Physics Society', label: 'Physics Society'},
{ value: 'Portfolio Choice', label: 'Portfolio Choice'},
{ value: 'Portfolio Construction', label: 'Portfolio Construction'},
{ value: 'Portfolio Management', label: 'Portfolio Management'},
{ value: 'Portfolio Theory', label: 'Portfolio Theory'},
{ value: 'Premium', label: 'Premium'},
{ value: 'Pricing', label: 'Pricing'},
{ value: 'Pricing Securities', label: 'Pricing Securities'},
{ value: 'Private Information', label: 'Private Information'},
{ value: 'Probability', label: 'Probability'},
{ value: 'Programming Models', label: 'Programming Models'},
{ value: 'Proxy Contests', label: 'Proxy Contests'},
{ value: 'Quantitative Methods', label: 'Quantitative Methods'},
{ value: 'Ratings', label: 'Ratings'},
{ value: 'Ratings Agencies', label: 'Ratings Agencies'},
{ value: 'Regulation', label: 'Regulation'},
{ value: 'Related Topics', label: 'Related Topics'},
{ value: 'Repeated Games', label: 'Repeated Games'},
{ value: 'Restructuring', label: 'Restructuring'},
{ value: 'Reversal', label: 'Reversal'},
{ value: 'Risk Management', label: 'Risk Management'},
{ value: 'Rotational System', label: 'Rotational System'},
{ value: 'Science', label: 'Science'},
{ value: 'Search', label: 'Search'},
{ value: 'Seasonality', label: 'Seasonality'},
{ value: 'Sector Picking', label: 'Sector Picking'},
{ value: 'Security Analysis And Valuation', label: 'Security Analysis And Valuation'},
{ value: 'Selection', label: 'Selection'},
{ value: 'Semiparametric', label: 'Semiparametric'},
{ value: 'Sentiment Trading', label: 'Sentiment Trading'},
{ value: 'Services', label: 'Services'},
{ value: 'Short Selling', label: 'Short Selling'},
{ value: 'Simulation', label: 'Simulation'},
{ value: 'Simulation Methods', label: 'Simulation Methods'},
{ value: 'Simulation Modeling', label: 'Simulation Modeling'},
{ value: 'Single Equation Models', label: 'Single Equation Models'},
{ value: 'Single Variables', label: 'Single Variables'},
{ value: 'Small Cap', label: 'Small Cap'},
{ value: 'Smart Beta', label: 'Smart Beta'},
{ value: 'Social', label: 'Social'},
{ value: 'Social Information Networks', label: 'Social Information Networks'},
{ value: 'Spatio-Temporal Models', label: 'Spatio-Temporal Models'},
{ value: 'Spread Trading', label: 'Spread Trading'},
{ value: 'State Space Models', label: 'State Space Models'},
{ value: 'Statistical Finance', label: 'Statistical Finance'},
{ value: 'Statistical Methods', label: 'Statistical Methods'},
{ value: 'Statistical Simulation Methods', label: 'Statistical Simulation Methods'},
{ value: 'Statistics Theory', label: 'Statistics Theory'},
{ value: 'Stochastic', label: 'Stochastic'},
{ value: 'Stock Picking', label: 'Stock Picking'},
{ value: 'Term Spread', label: 'Term Spread'},
{ value: 'Their Policies', label: 'Their Policies'},
{ value: 'Theoretical Economics', label: 'Theoretical Economics'},
{ value: 'Time-Series Models', label: 'Time-Series Models'},
{ value: 'Trading Earnings', label: 'Trading Earnings'},
{ value: 'Trading Market Microstructure', label: 'Trading Market Microstructure'},
{ value: 'Trend Following', label: 'Trend Following'},
{ value: 'Unawareness', label: 'Unawareness'},
{ value: 'Uncertainty', label: 'Uncertainty'},
{ value: 'Underlying Principles', label: 'Underlying Principles'},
{ value: 'Value', label: 'Value'},
{ value: 'Value Of Firms', label: 'Value Of Firms'},
{ value: 'Venture Capital', label: 'Venture Capital'},
{ value: 'Volatility', label: 'Volatility'},
{ value: 'Volume', label: 'Volume'},
{ value: 'Voting', label: 'Voting'},
{ value: 'Wealth', label: 'Wealth'},
];