import React from 'react';
import InputWrapper from './AdvancedWrapper';
import ButtonWrapper from './ButtonWrapper';
import ButtonWrapper2 from './ButtonWrapper2';
import QueryWrapper from './QueryWrapper';
//import LeftWrapper from './LeftWrapper';
//import TopWrapper from './TopWrapper';
//import BotWrapper from './BotWrapper';
import Input from './Advanced';
import moment from "moment";

//import ReactSelect from "./ReactSelect";
import MenuList from "./MenuList";
import Option from "./Option";

import "./ReactSelect.css";

//import Label from './Label';
//import Error from './Error';
//import SelectWrapper from './SelectWrapper';
import DateWrapper from './DateWrapper';
import ToggleWrapper from './ToggleKeywordWrapper';
//import RadioGroup from './RadioGroup';
import AdvancedRadioGroup from './AdvancedRadioGroup';
import AdvancedRadioGroup2 from './AdvancedRadioGroupKey';

import Select, { createFilter } from 'react-select';
//import makeAnimated from 'react-select/animated';

//import { keywordOptions } from '../../../lists/keywords';
//import { journalOptions } from '../../../lists/journals';
import { authorOptions } from '../../../lists/authors';
import { locationOptions } from '../../../lists/locations';
import { jobTypeOptions } from '../../../lists/jobtypes';
import { indTypeOptions } from '../../../lists/industries';
import { firmTypeOptions } from '../../../lists/firmtypes';
import { dataOptions } from '../../../lists/datas';
import { priceOptions } from '../../../lists/prices';

//import SelectField from "material-ui/SelectField";
//import MenuItem from 'material-ui/MenuItem';
//import { Autocomplete }   from 'material-ui';

import Toggle from 'react-toggle'
//import "../searchtogglestyle.css"
//import AddAuthorButton from './AddAuthorButton';

import loadable from '@loadable/component'

const DatePicker = loadable(() => import('react-datepicker'))

//const animatedComponents = makeAnimated();

//const borderRadiusStyle = { borderRadius: 2 }

//const onChange = (currentNode, selectedNodes) => {
  //console.log("path::", currentNode.path);
//};
     
const customStylesSingle = (field) => {
  return ({
      option: (styles, {isDisabled, isFocused, isSelected }) => {
       return {
        color: isFocused ? field.theme.normalText : field.theme.mutedText,
        padding: '0px',
        margin: '0px',
        alignItems: 'center',
        
        paddingTop: '2px',
        paddingBottom: '2px',
        paddingLeft: '8px',
        
        fontSize: '15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        backgroundColor: isDisabled
         ? null
         : isSelected
         ? field.theme.inputBackground
         : isFocused
         ? field.theme.inputBackground
         : null,
        height: 'auto',
        cursor: isDisabled ? 'not-allowed' : 'default',
       };
      },

      input: () => ({
       //display: 'inline-block',
       fontSize: '15px',
       color: field.theme.normalText,
       display: 'flex',
       flexGrow: 1,
       flexShrink: 1,
       overflow: 'hidden',
       textOverflow: 'ellipsis',
       whiteSpace: 'nowrap',
       height: 'auto',
      }),

      singleValue: (provided, state) => {
       return { fontSize: '15px', color: field.theme.normalText, 
       height: 'auto',

       marginTop: '0px',
       overflow: 'hidden',
       textOverflow: 'ellipsis',
       whiteSpace: 'normal',
      };
      },

      control: (styles) => ({ 
       ...styles, 
       backgroundColor: field.theme.inputBackground,
       padding: 0,
      
       display: 'flex',
       fontSize: '15px',
       margin: '0px',
       position: 'relative',
       borderRadius: '2px',
       minWidth: '125px',
       minHeight: '38px',
       height: '38px',
       borderColor: field.theme.border,
       ':hover': {
        border: "1px solid",
        borderColor: field.theme.accent
       },
      }),

      menuList: (provided, state) => ({
       ...provided,
       
       paddingTop: 0,
       paddingBottom: 0,
       zIndex: 2,
       fontSize: '15px',
       maxHeight: '200px',
       height: 'auto',
       backgroundColor: field.theme.activeBackground,
       color: field.theme.normalText,
      }),

      multiValue: (styles) => {
       return {
        ...styles,
        backgroundColor: field.theme.foreground,
        fontSize: '15px',
        border: '1px solid gray',
        padding: '0px',
       };
      },

      multiValueLabel: (styles) => ({
       ...styles,
       color: field.theme.normalText,
      }),

      multiValueRemove: (styles) => ({
       ...styles,
       color: field.theme.normalText,
       ':hover': {
        color: 'red',
       },
      }),

  })
}
     
const customStylesMulti = (field) => {
  return ({
   option: (styles, {isDisabled, isFocused, isSelected }) => {
    return {
    color: isFocused ? field.theme.normalText : field.theme.mutedText,
    padding: '0px',
    margin: '0px',
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '8px',
    fontSize: '15px',
    backgroundColor: isDisabled
        ? null
        : isSelected
        ? field.theme.inputBackground
        : isFocused
        ? field.theme.inputBackground
        : null,
     height: 'auto',
     cursor: isDisabled ? 'not-allowed' : 'default',
    };
   },

   input: () => ({
    //display: 'inline-block',
    fontSize: '15px',
    color: field.theme.normalText,
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: 'auto',

   }),

   control: (styles) => ({ 
    ...styles, 
    backgroundColor: field.theme.inputBackground,
    display: 'flex',
    fontSize: '15px',
    margin: '0px',
    position: 'relative',
    borderRadius: '2px',
    minHeight: '38px',
    minWidth: '125px',

       overflow: 'hidden',
       textOverflow: 'ellipsis',
       whiteSpace: 'nowrap',

    borderColor: field.theme.border,
    ':hover': {
      border: "1px solid",
      borderColor: field.theme.accent
    },
   }),

   menuList: (provided, state) => ({
    ...provided,
    fontSize: '15px',
    maxHeight: '200px',
    height: 'auto',
    marginTop: '0px',
    paddingTop: '0px',

    backgroundColor: field.theme.activeBackground,
    color: field.theme.normalText,
    zIndex: 2,
   }),

   multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: field.theme.foreground,
      fontSize: '15px',
      border: '1px solid',
      borderColor: field.theme.border,
      margin: '0px',   
      marginRight: '4px',  
      marginBottom: '1px', 
      marginTop: '1px',   

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
    };
   },

   multiValueLabel: (styles) => ({
    ...styles,
    color: field.theme.normalText,
    fontSize: '15px',
    backgroundColor: field.theme.activeBackground,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
   }),

   multiValueRemove: (styles) => ({
    ...styles,
    color: field.theme.mutedText,
    ':hover': {
      color: 'red',
    },
   }),

  })
}

/*
const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  limit: 500,
};
*/

const VariableField = field => {
  
  switch (field.type) {

    case 'query':
      return (
         <QueryWrapper>
          <Input
            {...field.input}
            type={field.type}
            placeholder={field.placeholder}
            autoComplete='off'
          />
         </QueryWrapper>
      );

    case 'radiogroup2':
      //console.log("RADIO GROUP:", field)
      return (
        <ButtonWrapper2>
          <AdvancedRadioGroup2 field={field} />
        </ButtonWrapper2>
      );

    case 'radiogroup':
      //console.log("RADIO GROUP 22222222222222222222222222");
      return (
        <ButtonWrapper>
          <AdvancedRadioGroup field={field} />
        </ButtonWrapper>
      );

    case 'radiogroup3':
      //console.log("RADIO GROUP 22222222222222222222222222");
      return (
        <ButtonWrapper2>
          <AdvancedRadioGroup field={field} />
        </ButtonWrapper2>
      );

    case 'startcreatedate':
      return (
       <DateWrapper>
        <DatePicker
          showPopperArrow={false}
          selected={ field.date ? field.date : null }
          onChange={ date => 
           {
             var today = moment((new Date()).toDateString());
             
             if (date > today) {
              field.handleStartCreateDateChange(today); 
              field.input.onChange(today)
            } else if (date > field.date2) {
              field.handleStartCreateDateChange(moment(field.date2)); 
              field.input.onChange(moment(field.date2))
            } else if ((date === null) || (date === undefined)) {
              field.handleStartCreateDateChange(null); 
              field.input.onChange(null)
            } else {
              field.handleStartCreateDateChange(moment(date)); 
              field.input.onChange(moment(date))
            }

            /*
            if (date > today) {
              field.handleStartCreateDateChange(moment(today).format("MM/DD/YYYY")); 
              field.input.onChange(moment(today).format("MM/DD/YYYY"))
            } else if (date > field.date2) {
              field.handleStartCreateDateChange(moment(field.date2).format("MM/DD/YYYY")); 
              field.input.onChange(moment(field.date2).format("MM/DD/YYYY"))
            } else if ((date === null) || (date === undefined)) {
              field.handleStartCreateDateChange(null); 
              field.input.onChange(null)
            } else {
              field.handleStartCreateDateChange(moment(date).format("MM/DD/YYYY")); 
              field.input.onChange(moment(date).format("MM/DD/YYYY"))
            }
            */
           } 
          }
          name="formdates.date1"
          dateFormat="MM/dd/yyyy"
          showMonthDropdown
          showYearDropdown
          popperPlacement={ field.placement }
          placeholderText={ field.placeholder }
        />
       </DateWrapper>
      );

    case 'endcreatedate':
      return (
       <DateWrapper>
        <DatePicker
          showPopperArrow={false}
          selected={ field.date ? field.date : null }
          onChange={ date => 
           {
             var today = moment((new Date()).toDateString());
             if (date > today) {
               field.handleEndCreateDateChange(today); 
               field.input.onChange(today)
             } else if (date < field.date1) {
               field.handleEndCreateDateChange(moment(field.date1)); 
               field.input.onChange(moment(field.date1))
             } else if ((date === null) || (date === undefined)) {
               field.handleEndCreateDateChange(today); 
               field.input.onChange(today)
             } else {
               field.handleEndCreateDateChange(moment(date)); 
               field.input.onChange(moment(date))
             }

             /*
             if (date > today) {
              field.handleEndCreateDateChange(moment(today).format("MM/DD/YYYY")); 
              field.input.onChange(moment(today).format("MM/DD/YYYY"))
            } else if (date < field.date1) {
              field.handleEndCreateDateChange(moment(field.date1).format("MM/DD/YYYY")); 
              field.input.onChange(moment(field.date1).format("MM/DD/YYYY"))
            } else if ((date === null) || (date === undefined)) {
              field.handleEndCreateDateChange(moment(today).format("MM/DD/YYYY")); 
              field.input.onChange(moment(today).format("MM/DD/YYYY"))
            } else {
              field.handleEndCreateDateChange(moment(date).format("MM/DD/YYYY")); 
              field.input.onChange(moment(date).format("MM/DD/YYYY"))
            }
            */
           } 
          }
          name="formdates.date2"
          dateFormat="MM/dd/yyyy"
          showMonthDropdown
          showYearDropdown
          popperPlacement={ field.placement }
          placeholderText={ field.placeholder }
        /> 
       </DateWrapper>
      );

    case 'startpublishdate':
      return (
       <DateWrapper>
        <DatePicker
         showPopperArrow={false}
         selected={ field.input.value }
         onChange={ field.handleStartPublishDateChange }
         dateFormat="MM/dd/yyyy"
         showMonthDropdown
         showYearDropdown
         popperPlacement={ field.placement }
         placeholderText={ field.placeholder }
        />
       </DateWrapper>
      );

    case 'endpublishdate':
      //console.log("PUBLISH : ", field)
      return (
       <DateWrapper>
        <DatePicker
         showPopperArrow={false}
         selected={ field.input.value }
         onChange={ field.handleEndPublishDateChange }
         dateFormat="MM/dd/yyyy"
         showMonthDropdown
         showYearDropdown
         popperPlacement={ field.placement }
         placeholderText={ field.placeholder }
        /> 
       </DateWrapper>
      );

    case 'type':
     //console.log("TYPE : ", field)
     return (
      <Select
       isClearable={true}
       value={field.typeOptions.find(op => {return op.value === field.input.value})}
       closeMenuOnSelect={true}
       placeholder={field.placeholder}
       onChange={value => field.input.onChange(value)} 
       options={field.typeOptions}
       styles={customStylesSingle(field)}
       defaultValue={field.defaultValue}
       isSearchable={false}
       components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
      />  
     );    

    case 'subtype':
     return (
      <Select
       isClearable={true}
       value={field.typeOptions.find(op => {return op.value === field.input.value.value}) || null}
       closeMenuOnSelect={true}
       placeholder={field.placeholder}
       //onChange={value => field.input.onChange(value)} 
       onChange={ value => {
          field.input.onChange(value);
          field.handleSubtypeChange();
        }
       }
       options={field.typeOptions}
       styles={customStylesSingle(field)}
       defaultValue={field.defaultValue}
       isSearchable={false}
       components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
      />  
     );    

    case 'keywords':
      return (
        <Select
         multiple={false}
         closeMenuOnSelect={false}
         placeholder={field.placeholder}
         isMulti
         //value={field.options.find(op => {return op.value === field.input.value.value})}
         defaultValue={field.defaultValue}
         onChange={value => field.input.onChange(value)} 
         options={field.options}
         styles={customStylesMulti(field)}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );    

    case 'journals':
     //console.log("THEME: ", field.theme)
      return (
         <Select
           multiple={false}
           closeMenuOnSelect={false}
           placeholder={field.placeholder}
           isMulti
           defaultValue={field.defaultValue}
           onChange={value => field.input.onChange(value)} 
           options={field.options}
           styles={customStylesMulti(field)}
           components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
         />  
     );    

    case 'author':
     //console.log("AUTHOR: ", field)   
      return (
        <Select
         isClearable={true}
         value={authorOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleAuthorChange}
         options={authorOptions}
         styles={customStylesSingle(field)}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        />  
      );   


    case 'location':
     //console.log("LOCATION: ", field)   
      return (
        <Select
         isClearable={true}
         value={locationOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleLocationChange}
         options={locationOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        />  
      );    

    case 'jobtype':
     //console.log("JOBTYPE: ", field)   
      return (
        <Select
         isClearable={true}
         value={jobTypeOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleJobTypeChange}
         options={jobTypeOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );    

    case 'industry':
     //console.log("INDTYPE: ", field)   
      return (
        <Select
         isClearable={true}
         value={indTypeOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleJobTypeChange}
         options={indTypeOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );    

    case 'firmtype':
     //console.log("FIRMTYPE: ", field)   
      return (
        <Select
         isClearable={true}
         value={firmTypeOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleFirmTypeChange}
         options={firmTypeOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );    

    case 'datatype':
     //console.log("DATATYPE: ", field)   
      return (
        <Select
         isClearable={true}
         value={dataOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleFirmTypeChange}
         options={dataOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      ); 

    case 'freemium':
     //console.log("PRICETYPE: ", field)   
      return (
        <Select
         isClearable={true}
         value={priceOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleFirmTypeChange}
         options={priceOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );    

    case 'market':
     //console.log("MARKETTYPE: ", field)   
      return (
        <Select
         isClearable={true}
         value={priceOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleFirmTypeChange}
         options={priceOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );     

    case 'instrument':
     //console.log("INSTRUMENTTYPE: ", field)   
      return (
        <Select
         isClearable={true}
         value={priceOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleFirmTypeChange}
         options={priceOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );   

    case 'period':
     //console.log("PERIODTYPE: ", field)   
      return (
        <Select
         isClearable={true}
         value={priceOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleFirmTypeChange}
         options={priceOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );    

    case 'backtest':
     //console.log("BACKTESTTYPE: ", field)   
      return (
        <Select
         isClearable={true}
         value={priceOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleFirmTypeChange}
         options={priceOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );  

    case 'numinst':
     //console.log("NUMINST: ", field)   
      return (
        <Select
         isClearable={true}
         value={priceOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleFirmTypeChange}
         options={priceOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );   

    case 'coded':
     //console.log("CODED: ", field)   
      return (
        <Select
         isClearable={true}
         value={priceOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleFirmTypeChange}
         options={priceOptions}
         styles={customStylesSingle(field)}
         isSearchable={false}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );    


    case 'firm':
     //console.log("CODED: ", field)   
      return (
        <Select
         isClearable={true}
         value={priceOptions.find(op => {return op.value === field.input.value})}
         closeMenuOnSelect={true}
         placeholder={field.placeholder}
         onChange={field.handleFirmTypeChange}
         options={priceOptions}
         styles={customStylesSingle(field)}
         components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        /> 
      );    

    case 'search_select':
     //console.log("CODED: ", field)   
      return (
           <Select
             isClearable={true}
             closeMenuOnSelect={true}
             onChange={value => field.input.onChange(value)} 
             options={field.options}
             placeholder={field.placeholder}
             value={field.options.find(op => {return op.value === field.input.value})}
             defaultValue={field.defaultValue}
             styles={customStylesSingle(field)}
             filterOption={createFilter({ ignoreAccents: false })}
             //components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
             components={{
              DropdownIndicator:() => null, 
              IndicatorSeparator:() => null,
              MenuList,
              Option
            }}
           />  
      );    

    case 'toggle1':
      return (
       <ToggleWrapper>
        <InputWrapper>
        <Toggle
	  icons={{
	      checked: <div style={{
			  display: "flex",
			  justifyContent: "center",
			  alignItems: "center",
			  height: "100%",
			  fontSize: 15,
			  fontWeight: 500,
			  backgroundColor: `${field.theme.inputBackground}`,
			  color: `${field.theme.normalText}`,
			  paddingBottom: 2,
			  paddingLeft: 6
			}}>OR</div>,
	      unchecked: <div style={{
			  display: "flex",
			  justifyContent: "center",
			  alignItems: "center",
			  height: "100%",
			  fontSize: 15,
			  fontWeight: 500,
			  backgroundColor: `${field.theme.inputBackground}`,
			  color: `${field.theme.normalText}`,
			  paddingBottom: 2
			}}>AND</div>,
	    }}
          className='custom-classname'
          //checked={field.input.value}
          //onChange={field.input.onChange}
        />
        </InputWrapper>
       </ToggleWrapper>
      );

    default:
      return (
         <ButtonWrapper>
          <Input
            {...field.input}
            type={field.type}
            placeholder={field.placeholder}
            autoComplete='off'
          />
         </ButtonWrapper>
      );

  }
};

const renderField = field => {
  return <VariableField {...field} />;
};

export default renderField;
