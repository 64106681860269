export const dataOptions = [
  { value: 'TAQ'            , label: 'TAQ'},  
  { value: 'Order Book'     , label: 'Order Book'}, 
  { value: 'OHLC'           , label: 'OHLC'},  
  { value: 'Extended Hours' , label: 'Extended Hours'},  
  { value: 'Historical'     , label: 'Historical'},    
  { value: 'Volume'         , label: 'Volume'},  
  { value: 'Insider Transactions'   , label: 'Insider Transactions'},
  { value: 'Company'   , label: 'Company'},  
  { value: 'Peers'   , label: 'Peers'},  
  { value: 'Fundamentals'   , label: 'Fundamentals'}, 
  { value: 'Balance Sheet'   , label: 'Balance Sheet'}, 
  { value: 'Income Statement'   , label: 'Income Statement'}, 
  { value: 'Cash Flow Statement'   , label: 'Cash Flow Statement'}, 
  { value: 'Dividends'   , label: 'Dividends'}, 
  { value: 'Adjustments'   , label: 'Adjustments'}, 
  { value: 'Earnings'   , label: 'Earnings'}, 
  { value: 'Sell Side Recommendations'   , label: 'Sell Side Recommendations'}, 
  { value: 'Reports'   , label: 'Reports'}, 
  { value: 'Splits'   , label: 'Splits'}, 
  { value: 'Mergers and Acquisitions'   , label: 'Mergers and Acquisitions'}, 
  { value: 'Ownership'   , label: 'Ownership'}, 
  { value: 'Price Targets'   , label: 'Price Targets'}, 
  { value: 'Technicals'   , label: 'Technicals'},  
  { value: 'Rights Issue'   , label: 'Rights Issue'}, 
  { value: 'Spinoffs'   , label: 'Spinoffs'}, 
  { value: 'Calendar'   , label: 'Calendar'}, 
  { value: 'Marcoeconomics'   , label: 'Marcoeconomics'}, 
  { value: 'Events'   , label: 'Events'}, 
  { value: 'Currencies'   , label: 'Currencies'}, 
  { value: 'Options'               , label: 'Options'},  
  { value: 'Futures'               , label: 'Futures'},  
  { value: 'Commodities'    , label: 'Commodities'},  
  { value: 'Exchange'       , label: 'Exchange'},  
  { value: 'Spread'         , label: 'Spread'},  
  { value: 'Sentiment'      , label: 'Sentiment'}, 
  { value: 'Short Interest' , label: 'Short Interest'},  
  { value: 'Alternative'    , label: 'Alternative'},  
]; 

