import React from 'react';
import styled from 'styled-components/macro';
import Post from '../../Post';

const Item = styled.li`
  :not(:first-child) {
    border-top: 1px solid ${props => props.theme.border};
  }
`;

const UserPageItem = props => {
  return(
    <Item>
      <Post {...props} />
    </Item>
  );
}

export default UserPageItem;