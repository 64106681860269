import { createStore, combineReducers, applyMiddleware, compose } from 'redux';

import { routerMiddleware, connectRouter } from "connected-react-router";

import thunk from 'redux-thunk';
import form from './reducers/form';
import error from './reducers/error';
import auth from './reducers/auth';
import posts from './reducers/posts';
import comments from './reducers/comments';
import profiles from './reducers/profiles';
import theme from './reducers/theme';
import authMiddleware from './middleware/auth';
import errorMiddleware from './middleware/error';
import themeMiddleware from './middleware/theme';

import searchrequest from './reducers/searchrequest';
import pagerequest from './reducers/searchrequest';

import history from './util/history';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const routerMiddlewaree = routerMiddleware(history);

const middlewares = [routerMiddlewaree];

export default createStore(
  combineReducers({ form, error, auth, posts, comments, profiles, theme, searchrequest, pagerequest, router: connectRouter(history), }),
  composeEnhancers(
    applyMiddleware(...middlewares, thunk, authMiddleware, errorMiddleware, themeMiddleware)
  )
);

