import moment from "moment";
import {attemptClearPosts} from "./posts"

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_RESET   = 'SEARCH_RESET';
export const SEARCH_ERROR   = 'SEARCH_ERROR';

export const PAGE_REQUEST = 'PAGE_REQUEST';
export const PAGE_SUCCESS = 'PAGE_SUCCESS';
export const PAGE_RESET   = 'PAGE_RESET';
export const PAGE_ERROR   = 'PAGE_ERROR';

const fetchSearchSuccess = searchrequest => ({ type: SEARCH_SUCCESS, searchrequest });
const fetchSearchReset   = searchrequest => ({ type: SEARCH_RESET,   searchrequest });

const fetchPageSuccess = pagerequest => ({ type: PAGE_SUCCESS, pagerequest });
const fetchPageReset   = pagerequest => ({ type: PAGE_RESET,   pagerequest });

export const searchRequest = (props, source) => async dispatch => {

	var resetpage = { currentpage: 1,
		sortby: 'Best',
		typeby: 'Posted'
		}

  if ( (props === 'reset') ) {
    var resetsearch = { pathname: '/', query: null,  table1: 'posts', table2: null, 
					 formdates: {date1: null, date2: moment((new Date()).toDateString())}, // include time to force a reset
					 type: null, 
					 formsubtype: { subtype: null },
					 formvalue1: { value1: null },
					 formvalue2: { value2: null },
					 formvalue3: { value3: null },
					 formvalue4: { value4: null },
					 formvalue5: { value5: null },
					 formvalue6: { value6: null },
		        	 array1: null, array2: null, 
					 bool1: 'OR',  
					 formbool2: { bool2: false },
					 bool3: false,
		             keywords: null
		           }

    dispatch(fetchSearchReset(resetsearch),fetchPageReset(resetpage));

	} else if ("query" in props) {
	// & (AND), | (OR), ! (NOT), and <-> (FOLLOWED BY)
	// "abc cde" > (abd <-> cde)
	// abc cde > abd & cde
	// abc AND cde > abd & cde
	// abc OR cde > abd | cde
	// abc NOT cde > abd ! cde
	// to lowercase
    dispatch(fetchSearchSuccess(props),fetchPageReset(resetpage));
	
  }
  attemptClearPosts()
};

export const pageRequest = (props, source) => async dispatch => {

	var resetpage
	if ( (props === 'reset') ) {

		resetpage = { currentpage: 1,
						  sortby: 'Best',
						  typeby: 'Posted'
						}
	
		dispatch(fetchPageReset(resetpage));
	
	} else if ( (props === 'search') ) {

		resetpage = { currentpage: 1,
						  sortby: 'Best',
						  typeby: 'Posted'
						}
	
		dispatch(fetchPageReset(resetpage));
	
	} else if ("currentpage" in props) {

    	dispatch(fetchPageSuccess(props));

  	}

};

/*
export const SAVE_REQUEST = 'SAVE_REQUEST';
export const SAVE_SUCCESS = 'SAVE_SUCCESS';
export const SAVE_RESET   = 'SAVE_RESET';
export const SAVE_ERROR   = 'SAVE_ERROR';

const fetchSaveSuccess = saverequest => ({ type: SAVE_SUCCESS, saverequest });

export const saveRequest = (props) => async dispatch => {

 if ("query" in props) {
    dispatch(fetchSaveSuccess(props));

  }

};
*/
