import React from 'react';
import styled from 'styled-components/macro';

import dayjs from 'dayjs';
import * as isLeapYear from 'dayjs/plugin/isLeapYear' // import plugin
import 'dayjs/locale/en' // import locale

import loadable from '@loadable/component'

import PostContentTitle from './Title';
import PostContentDetail from './Detail';

//import PostDeleted from './Deleted';

import PostNavLink from './PostNavLink';
import PostNavLinkNew from './PostNavLinkNew';

import './styles.css'
import 'katex/dist/katex.min.css';

const PostDiscussion = loadable(() => import('./Discussion'))
const PostURL = loadable(() => import('./URL'))
const PostArticle = loadable(() => import('./Article'))
const PostTextbook = loadable(() => import('./Textbook'))
const PostSchool = loadable(() => import('./School'))
const PostInterview = loadable(() => import('./Interview'))
const PostFirm = loadable(() => import('./Firm'))
const PostStrategy = loadable(() => import('./Strategy'))
const PostData = loadable(() => import('./Data'))


dayjs.extend(isLeapYear) // use plugin
dayjs.locale('en') // use locale

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

var md = require('markdown-it')();
var mk = require('markdown-it-katex');

md.use(mk);

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-left: 1px solid ${props => props.theme.border};
  padding: 8px;
  min-width: 0;
`;

const PostContent = ({
  token,
  showFullPost,
  pid,
  type,
  subtype,
  commentCount,
  author,

  title,     // title
  url,       // URL

  uid, 
  created, 

  ...details
}) => {

if (showFullPost) {
  return (

    <Wrapper>
           
      <PostContentTitle
        title={title}
        url={url}
        pid={pid}
        type={type}
        subtype={subtype}
        full={showFullPost}
        {...details}
      />

      {(type==='general') && (subtype==='discussion') && (
        <PostDiscussion token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
      )}

      {(type==='general') && (subtype==='link') && (
        <PostURL token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
      )}

      {(type==='library') && (subtype==='article') && (
        <PostArticle token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
      )}

      {(type==='library') && (subtype==='textbook') && (
        <PostTextbook token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
      )}

      {(type==='career') && (subtype==='school') && (
        <PostSchool token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
      )}

      {(type==='career') && (subtype==='interview') && (
        <PostInterview token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
      )}

      {(type==='career') && (subtype==='firm') && (
        <PostFirm token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
      )}

      {(type==='alpha') && (subtype==='strategy') && (
        <PostStrategy token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
      )}

      {(type==='alpha') && (subtype==='data') && (
        <PostData token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
      )}

      {(type !== 'deleted') && (subtype !== 'deleted') && (
        <PostContentDetail pid={pid} type={type} subtype={subtype} commentCount={commentCount} author={author} created={created} newpost={0} {...details} />
      )}

    </Wrapper>
  );

  } else {

    if (token) {

      if ((uid === null) && (dayjs().diff( created, 'days') < 7)) {
      
        return (

          <PostNavLinkNew to={`/q/${type}/${subtype}/${pid}`}>
            
            <PostContentTitle
              title={title}
              url={url}
              pid={pid}
              type={type}
              subtype={subtype}
              full={showFullPost}
              {...details}
            />
      
            {(type==='general') && (subtype==='discussion') && (
              <PostDiscussion token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}

            {(type==='general') && (subtype==='link') && (
              <PostURL token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}

            {(type==='library') && (subtype==='article') && (
              <PostArticle token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}
      
            {(type==='library') && (subtype==='textbook') && (
              <PostTextbook token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}
      
            {(type==='career') && (subtype==='school') && (
              <PostSchool token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}
      
            {(type==='career') && (subtype==='interview') && (
              <PostInterview token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}
      
            {(type==='career') && (subtype==='firm') && (
              <PostFirm token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}
      
            {(type==='alpha') && (subtype==='strategy') && (
              <PostStrategy token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}
      
            {(type==='alpha') && (subtype==='data') && (
              <PostData token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}
      
            <PostContentDetail pid={pid} type={type} subtype={subtype} commentCount={commentCount} author={author} created={created} newpost={1} {...details} />
          
          </PostNavLinkNew>
        )
        
      } else {
        
        return (
          
          <PostNavLink to={`/q/${type}/${subtype}/${pid}`}>
              
            <PostContentTitle
              title={title}
              url={url}
              pid={pid}
              type={type}
              subtype={subtype}
              full={showFullPost}
              {...details}
            />
              

            {(type==='general') && (subtype==='discussion') && (
              <PostDiscussion token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}

            {(type==='general') && (subtype==='link') && (
              <PostURL token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}

            {(type==='library') && (subtype==='article') && (
              <PostArticle token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}

            {(type==='library') && (subtype==='textbook') && (
              <PostTextbook token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}

            {(type==='career') && (subtype==='school') && (
              <PostSchool token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}

            {(type==='career') && (subtype==='interview') && (
              <PostInterview token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}

            {(type==='career') && (subtype==='firm') && (
              <PostFirm token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}

            {(type==='alpha') && (subtype==='strategy') && (
              <PostStrategy token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}

            {(type==='alpha') && (subtype==='data') && (
              <PostData token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
            )}

            <PostContentDetail pid={pid} type={type} subtype={subtype} commentCount={commentCount} author={author} created={created} newpost={0} {...details} />
          
          </PostNavLink>
        );
      
      }

    } else {
      
      return (
        <PostNavLink to={`/q/${type}/${subtype}/${pid}`}>
            
          <PostContentTitle
            title={title}
            url={url}
            pid={pid}
            type={type}
            subtype={subtype}
            full={showFullPost}
            {...details}
          />

          {(type==='general') && (subtype==='discussion') && (
            <PostDiscussion token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
          )}

          {(type==='general') && (subtype==='link') && (
            <PostURL token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
          )}


          {(type==='library') && (subtype==='article') && (
            <PostArticle token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
          )}

          {(type==='library') && (subtype==='textbook') && (
            <PostTextbook token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
          )}

          {(type==='career') && (subtype==='school') && (
            <PostSchool token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
          )}

          {(type==='career') && (subtype==='interview') && (
            <PostInterview token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
          )}

          {(type==='career') && (subtype==='firm') && (
            <PostFirm token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
          )}

          {(type==='alpha') && (subtype==='strategy') && (
            <PostStrategy token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
          )}

          {(type==='alpha') && (subtype==='data') && (
            <PostData token={token} type={type} subtype={subtype} title={title} full={showFullPost} url={url} {...details} />
          )}

          <PostContentDetail pid={pid} type={type} subtype={subtype} commentCount={commentCount} author={author} created={created} newpost={0} {...details} />
        
        </PostNavLink>
      );
    }
  }
}

export default PostContent;
