import { connect } from 'react-redux';
import { compose } from 'redux';
import withAuth from '../../util/withAuth';
import { logout, checkToken } from '../../actions/auth';
import Header from './Component';
import { searchRequest, pageRequest } from '../../actions/searchrequest';

const mapDispatchToProps = { logout, checkToken, searchRequest, pageRequest };

const enhance = compose(
  withAuth,
  connect(null, mapDispatchToProps)
);

const HeaderContainer = enhance(Header);

export default HeaderContainer;
