import React from 'react';
import { Field } from 'redux-form';

import renderAdvanced from '../../../shared/form/renderAdvanced';

import { subtypeGeneralOptions, subtypeLibraryOptions, subtypeCareerOptions, subtypeAlphaOptions } from '../../../../lists/subtypes';

class SelectSubtype extends React.Component {
    

    render() {

        if (this.props.type === 'general') {
            if ((this.props.subtype === null) || (this.props.subtype === undefined)) {
                return(
                    <Field 
                        name='subtype'
                        type='subtype'
                        placeholder='by Subcategory'
                        component={renderAdvanced}
                        theme={this.props.theme}
                        typeOptions={subtypeGeneralOptions}
                        defaultValue={null}
                        handleSubtypeChange={this.props.handleChange.bind(this)}
                    />
                )
            } else {
                return(
                    <Field 
                        name='subtype'
                        type='subtype'
                        placeholder='by Subcategory'
                        component={renderAdvanced}
                        theme={this.props.theme}
                        typeOptions={subtypeGeneralOptions}
                        defaultValue={subtypeGeneralOptions.some(item => item.value === this.props.subtype.value) ? this.props.subtype : null}
                        handleSubtypeChange={this.props.handleChange.bind(this)}
                    />
                )
            }
        }
        
        if (this.props.type === 'library') {
            if ((this.props.subtype === null) || (this.props.subtype === undefined)) {
                return(
                    <Field 
                        name='subtype'
                        type='subtype'
                        placeholder='by Subcategory'
                        component={renderAdvanced}
                        theme={this.props.theme}
                        typeOptions={subtypeLibraryOptions}
                        defaultValue={null}
                        handleSubtypeChange={this.props.handleChange.bind(this)}
                    />
                )
            } else {
                return(
                    <Field 
                        name='subtype'
                        type='subtype'
                        placeholder='by Subcategory'
                        component={renderAdvanced}
                        theme={this.props.theme}
                        typeOptions={subtypeLibraryOptions}
                        defaultValue={subtypeLibraryOptions.some(item => item.value === this.props.subtype.value) ? this.props.subtype : null}
                        handleSubtypeChange={this.props.handleChange.bind(this)}
                    />
                )                
            }
        }
        
        if (this.props.type === 'career') {
            if ((this.props.subtype === null) || (this.props.subtype === undefined)) {
                return(
                    <Field 
                        name='subtype'
                        type='subtype'
                        placeholder='by Subcategory'
                        component={renderAdvanced}
                        theme={this.props.theme}
                        typeOptions={subtypeCareerOptions}
                        defaultValue={null}
                        handleSubtypeChange={this.props.handleChange.bind(this)}
                    />
                )
            } else {
                return(
                    <Field 
                        name='subtype'
                        type='subtype'
                        placeholder='by Subcategory'
                        component={renderAdvanced}
                        theme={this.props.theme}
                        typeOptions={subtypeCareerOptions}
                        defaultValue={subtypeCareerOptions.some(item => item.value === this.props.subtype.value) ? this.props.subtype.value : null}
                        handleSubtypeChange={this.props.handleChange.bind(this)}
                    />
                )
            }
        }
        
        if (this.props.type === 'alpha') {
            if ((this.props.subtype === null) || (this.props.subtype === undefined)) {
                return(
                    <Field 
                        name='subtype'
                        type='subtype'
                        placeholder='by Subcategory'
                        component={renderAdvanced}
                        theme={this.props.theme}
                        typeOptions={subtypeAlphaOptions}
                        defaultValue={null}
                        handleSubtypeChange={this.props.handleChange.bind(this)}
                    />
                )
            } else {
                return(
                    <Field 
                        name='subtype'
                        type='subtype'
                        placeholder='by Subcategory'
                        component={renderAdvanced}
                        theme={this.props.theme}
                        typeOptions={subtypeAlphaOptions}
                        defaultValue={subtypeAlphaOptions.some(item => item.value === this.props.subtype.value) ? this.props.subtype : null}
                        handleSubtypeChange={this.props.handleChange.bind(this)}
                    />
                )
            }
        }

    };
};

export default SelectSubtype;