import styled from 'styled-components/macro';
import { transition, wideFont } from './helpers';

const Button = styled.button`
  ${transition('border', 'box-shadow')};
  --border: ${props => props.error ? props.theme.error : props.theme.accent};
  --shadow: ${props => props.error ? props.theme.error + '4d' : props.theme.accent + '4d'};
  
  ${wideFont};

  ${props => props.error ? `
    border: 1px solid var(--border)
    ` : `
    border: 1px solid ${props.theme.border}
  `};

  border-radius: 3px;
  padding: 8px 8px;
  background-color: ${props => props.theme.accent};
  cursor: pointer;
  color: #ffffff;    
  border: none;
  outline: none;
  
  :hover {
    filter: brightness(110%);
  }
  
  :active {
    filter: brightness(90%);
  }
  
  :focus {
    box-shadow: 0 0 0 0px ${props => props.theme.accent + '4d'};
    outline: none;
  }
  
`;

export default Button;
