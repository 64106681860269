import React from 'react';
import styled from 'styled-components/macro';
import { wideFont } from '../../shared/helpers';

import HeaderNavLink from '../NavLink';

const Wrapper = styled(HeaderNavLink)`
  ${wideFont};
  
  flex-shrink: 1;
  min-width: 0;
  color: ${props => props.theme.mutedText};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`;

const HeaderUsername = props => (
  <Wrapper to={`/u/${props.username}`} onClick={() => props.reset('reset')}>
    {props.username}
  </Wrapper>
);

export default HeaderUsername;
