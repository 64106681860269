import React from 'react';
import { withRouter } from "react-router";
import { Field, FormSection, change } from 'redux-form';
//import {reset} from 'redux-form';

import SearchForm from '../../shared/form/SearchForm';

import renderAdvanced from '../../shared/form/renderAdvanced';

import SearchButton from './SearchButton';
import ResetButton from './ResetButton';

//import renderField from '../../shared/form/renderField';

import PostVoteDownvote from './Downvote';
import PostVoteUpvote from './Upvote';

import moment from "moment";

import Media from 'react-media';

import Form from '../../shared/form/AdvancedForm';

import { withTheme } from 'styled-components';

import SelectSubtype from './Options/SubType.js'
import SelectDates from './Options/Dates.js'
import SelectValue1 from './Options/Value1.js'
import SelectValue2 from './Options/Value2.js'
import SelectValue3 from './Options/Value3.js'
import SelectValue4 from './Options/Value4.js'
import SelectValue5 from './Options/Value5.js'
import SelectValue6 from './Options/Value6.js'
import SelectBool2 from './Options/Bool2.js'

import { typeOptions } from '../../../lists/types';
import { subtypeGeneralOptions, subtypeLibraryOptions, subtypeCareerOptions, subtypeAlphaOptions } from '../../../lists/subtypes';

//import { numbers     } from '../../../lists/numbers';
import { regionOptions     } from '../../../lists/regions';
//import { schools     } from '../../../lists/schools';
//import { periods     } from '../../../lists/periods';
//import { prices      } from '../../../lists/prices';
//import { markets     } from '../../../lists/markets';

import { instrumentOptions }  from '../../../lists/instruments';

import { keywordOptions  } from '../../../lists/keywords';
import { keywordDataOptions } from '../../../lists/keywords_data';
import { keywordStrategyOptions } from '../../../lists/keywords_strategy';
import { keywordFirmOptions  } from '../../../lists/keywords_firm';
import { keywordSchoolOptions  } from '../../../lists/keywords_school';
import { journalOptions  } from '../../../lists/journals';
import { authorOptions   } from '../../../lists/authors';
import { schoolOptions   } from '../../../lists/schools';
import { liquidOptions   } from '../../../lists/liquids';
//import { locationOptions } from '../../../lists/locations';
//import { jobTypeOptions  } from '../../../lists/jobtypes';
//import { indTypeOptions  } from '../../../lists/industries';
//import { firmTypeOptions } from '../../../lists/firmtypes';
//import { dataOptions     } from '../../../lists/datas';
import { priceOptions    } from '../../../lists/prices';
import { positionOptions } from '../../../lists/positions';
import { firmOptions     } from '../../../lists/firms';
import { marketOptions   } from '../../../lists/markets';
import { periodOptions   } from '../../../lists/periods';
import { numberOptions   } from '../../../lists/numbers';
import { experienceOptions } from '../../../lists/experiences';
//import DeviceBatteryStd from 'material-ui/svg-icons/device/battery-std';

//var deepEqual = require('deep-equal')

const postTable1  = [ { label: 'posts', value: 'posts' } ];
const postTable2  = [ { label: 'comments', value: 'comments' } ];
const postAttach1 = [ { label: 'coded', value: 'coded' } ];
const postAttach3 = [ { label: 'sample', value: 'sample' } ];
const postKeys    = [ { label: 'OR', value: 'OR' }, { label: 'AND', value: 'AND' } ];

/*
const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
*/

function moment2date(date) {
  if (moment.isMoment(date)) {
    return(Date.parse(date))
  } else {
    return(date)
  }
}

function date2moment(date) {
  if (moment.isMoment(date)) {
    return(moment(date))
  } else {
    return(date)
  }
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

class searchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { popupopen: true, 
                   sharepopupopen: false, 
                   value: true, 
                   type: null, 
                   subtype: null, 
                   pathnamecheck: 'new',
                   typeDyOptions: typeOptions, 
                   subtypeDyOptions: null,
                   reset: false, 
                   queryplaceholder: "Search",
                   dateChange: false,
                   date1: null,
                   date2: Date.parse(moment((new Date()).toDateString())), //#moment((new Date()).toDateString()),
                   autosubmit: false,
                   pathname: ''};
    this.clickModal = this.clickModal.bind(this);
  }

  componentDidMount() {
    this.setState({pathname: this.props.pathname})
    this.updatePathnamecheck()
  }

  updatePathnamecheck() {

    var formdates;

    if ( (this.props.form !== null) && (this.props.form !== undefined) ) {
      if ((this.props.form.formdates === null) || (this.props.form.formdates === undefined)) {
        formdates = {date1: null, date2: Date.parse(moment((new Date()).toDateString()))} //((new Date()).toDateString())}
      } else {
        moment2date(this.props.form.formdates.date1)
        formdates = {date1: moment2date(this.props.form.formdates.date1), date2: moment2date(this.props.form.formdates.date2)}
      }
    } else {
      formdates = {date1: null, date2: Date.parse(moment((new Date()).toDateString()))} //((new Date()).toDateString())}
    }

    if (this.props.pathname.includes('/search')) {
      
      this.setState({ pathnamecheck: '/search', date1: formdates.date1, date2: formdates.date2 });   

    } else if (this.props.pathname === '/') {
      
      this.setState({ pathnamecheck: '/', date1: formdates.date1, date2: formdates.date2 });      
    
    } else if (this.props.pathname.includes('/u/')) {
      
      this.setState({ pathnamecheck: 'u', date1: formdates.date1, date2: formdates.date2 });   
    
    } else if (this.props.pathname.includes('/c/')) {
    
      this.setState({ pathnamecheck: 'c', date1: formdates.date1, date2: formdates.date2 });   

    } else if (this.props.pathname.includes('/f/')) {
  
      this.setState({ pathnamecheck: 'f', date1: formdates.date1, date2: formdates.date2 });   
  
    } else if (this.props.pathname.includes('/d/')) {

      this.setState({ pathnamecheck: 'd', date1: formdates.date1, date2: formdates.date2 });   

    } else if (this.props.pathname.includes('/s/')) {

      this.setState({ pathnamecheck: 's', date1: formdates.date1, date2: formdates.date2 });   

    } else if (this.props.pathname.includes('/k/')) {

      this.setState({ pathnamecheck: 'k', date1: formdates.date1, date2: formdates.date2 });   

    } else if (this.props.pathname.toLowerCase().match(/^\/q\/[a-zA-Z]+\/[a-zA-Z]+\/[0-9]+$/)) {

      this.setState({ pathnamecheck: null, date1: formdates.date1, date2: formdates.date2 });      

    } else if (this.props.pathname.toLowerCase().match(/^\/s=[a-zA-Z]+$/)) {

      this.setState({ pathnamecheck: '/', date1: formdates.date1, date2: formdates.date2 });   

    } else if (this.props.pathname.includes('/q/')) {

      this.setState({ pathnamecheck: 'q', date1: formdates.date1, date2: formdates.date2 });   

    } else if (this.props.pathname.includes('/j/')) {
  
      this.setState({ pathnamecheck: 'j', date1: formdates.date1, date2: formdates.date2 });   
  
    } else if (this.props.pathname.includes('/p/')) {
  
      this.setState({ pathnamecheck: 'p', date1: formdates.date1, date2: formdates.date2 });   
  
    } else if (this.props.pathname.includes('/a/')) {
  
      this.setState({ pathnamecheck: 'a', date1: formdates.date1, date2: formdates.date2 });   
  
    } else {
  
      this.setState({ pathnamecheck: null, date1: formdates.date1, date2: formdates.date2} );   
  
    }

  }

  updateState(formdates) {
    if ((formdates === null) || (formdates === undefined)) {
      formdates = {date1: null, date2: Date.parse(moment((new Date()).toDateString()))} //((new Date()).toDateString())}
    } else {
      formdates = {date1: moment2date(formdates.date1), date2: moment2date(formdates.date2)}
    }

    if (this.props.pathname.includes('/search')) {
      
      this.setState({ autosubmit: false, pathnamecheck: '/search', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});   

    } else if (this.props.pathname === '/') {
      
      this.setState({ autosubmit: false, pathnamecheck: '/', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});      
    
    } else if (this.props.pathname.includes('/u/')) {
      
      var pageusername;
      pageusername = this.props.pathname.toLowerCase().match(/^\/u\/[a-zA-Z0-9]+/)
      pageusername = pageusername[0].split('/').pop().replaceAll('_',' ');

      this.setState({ autosubmit: false, pathnamecheck: 'u', date1: formdates.date1, date2: formdates.date2,
      typeDyOptions: typeOptions, queryplaceholder: "Search by " + JSON.stringify(pageusername).replaceAll(/"/g, "")});   
    
    } else if (this.props.pathname.includes('/c/')) {
    
      this.setState({ autosubmit: false, pathnamecheck: 'c', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});   

    } else if (this.props.pathname.includes('/f/')) {
  
      this.setState({ autosubmit: false, pathnamecheck: 'f', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});   
  
    } else if (this.props.pathname.includes('/d/')) {

      this.setState({ autosubmit: false, pathnamecheck: 'd', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});   

    } else if (this.props.pathname.includes('/s/')) {

      this.setState({ autosubmit: false, pathnamecheck: 's', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});   

    } else if (this.props.pathname.includes('/k/')) {

      this.setState({ autosubmit: false, pathnamecheck: 'k', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});   

    } else if (this.props.pathname.toLowerCase().match(/^\/q\/[a-zA-Z]+\/[a-zA-Z]+\/[0-9]+$/)) {

      this.setState({ autosubmit: false, pathnamecheck: null, date1: formdates.date1, date2: formdates.date2});      

    } else if (this.props.pathname.toLowerCase().match(/^\/s=[a-zA-Z]+$/)) {

      this.setState({ autosubmit: false, pathnamecheck: '/', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});   

    } else if (this.props.pathname.includes('/q/')) {

      this.setState({ autosubmit: false, pathnamecheck: 'q', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});   

    } else if (this.props.pathname.includes('/j/')) {
  
      this.setState({ autosubmit: false, pathnamecheck: 'j', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});   
  
    } else if (this.props.pathname.includes('/p/')) {
  
      this.setState({ autosubmit: false, pathnamecheck: 'p', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});   
  
    } else if (this.props.pathname.includes('/a/')) {
  
      this.setState({ autosubmit: false, pathnamecheck: 'a', typeDyOptions: typeOptions, date1: formdates.date1, date2: formdates.date2});   
  
    } else {
  
      this.setState({ autosubmit: false, pathnamecheck: null, date1: formdates.date1, date2: formdates.date2});   
  
    }
    
  }

  initialSearch = (prvsearch123) => {
    var key;
    var id;
    var id2;

    if (this.props.pathname.includes('/search')) {
      // SEARCH

    } else if (this.props.pathname === '/') {
      // HOME PAGE

    } else if (this.props.pathname.includes('/u/')) {
      // USER
      
    } else if (this.props.pathname.includes('/c/')) {
      // 
    
    } else if (this.props.pathname.includes('/f/')) {
      // FIRM
  
    } else if (this.props.pathname.includes('/d/')) {
      // 

    } else if (this.props.pathname.includes('/s/')) {
      // SCHOOL

    } else if (this.props.pathname.includes('/k/')) {
      // KEYWORD

      key = this.props.pathname.split('/').pop().replaceAll('_',' ').toLowerCase();
      if ( key.match(/.*\/s=[a-zA-Z]+$/) ) {
        key = key.split('/s=').shift()
      }

      for (id in keywordOptions) {
        if (key === keywordOptions[id].value.toLowerCase()) {
          prvsearch123.keywords = [keywordOptions[id]]
        }
      }
      for (id in keywordDataOptions) {
        if (key === keywordDataOptions[id].value.toLowerCase()) {
          prvsearch123.keywords = [keywordDataOptions[id]]
        }
      }
      for (id in keywordStrategyOptions) {
        if (key === keywordStrategyOptions[id].value.toLowerCase()) {
          prvsearch123.keywords = [keywordStrategyOptions[id]]
        }
      }
      for (id in keywordFirmOptions) {
        if (key === keywordFirmOptions[id].value.toLowerCase()) {
          prvsearch123.keywords = [keywordFirmOptions[id]]
        }
      }
      for (id in keywordSchoolOptions) {
        if (key === keywordSchoolOptions[id].value.toLowerCase()) {
          prvsearch123.keywords = [keywordSchoolOptions[id]]
        }
      }

    } else if (this.props.pathname.toLowerCase().match(/^\/q\/[a-zA-Z]+\/[a-zA-Z]+\/[0-9]+$/)) {

    } else if (this.props.pathname.toLowerCase().match(/^\/s=[a-zA-Z]+$/)) {

    } else if (this.props.pathname.includes('/q/')) {
      // QUERY

      key = this.props.pathname.split('/q/').pop()
      if ( key.match(/.*\/s=[a-zA-Z]+$/) ) {
        key = key.split('/s=').shift()
      }
      key = key.split('/')

      prvsearch123.type = null
      prvsearch123.formsubtype = {subtype: null}

      prvsearch123.keywords = null

      if (key[0]==='q') {
        for (id in typeOptions) {
          if (key[1].toLowerCase() === typeOptions[id].value.toLowerCase()) {
            prvsearch123.type = typeOptions[id]
          }
        }
      } else {
        for (id in typeOptions) {
          if (key[0].toLowerCase() === typeOptions[id].value.toLowerCase()) {
            prvsearch123.type = typeOptions[id]
            if (key[0].toLowerCase() === 'general' && key[1]) {
              for (id2 in subtypeGeneralOptions) {
                if (key[1].toLowerCase() === subtypeGeneralOptions[id2].value.toLowerCase()) {
                  prvsearch123.formsubtype = {subtype: subtypeGeneralOptions[id2]}
                }
              }
            }
            if (key[0].toLowerCase() === 'library' && key[1]) {
              for (id2 in subtypeLibraryOptions) {
                if (key[1].toLowerCase() === subtypeLibraryOptions[id2].value.toLowerCase()) {
                  prvsearch123.formsubtype = {subtype: subtypeLibraryOptions[id2]}
                }
              }
            }
            if (key[0].toLowerCase() === 'career' && key[1]) {
              for (id2 in subtypeCareerOptions) {
                if (key[1].toLowerCase() === subtypeCareerOptions[id2].value.toLowerCase()) {
                  prvsearch123.formsubtype = {subtype: subtypeCareerOptions[id2]}
                }
              }
            }
            if (key[0].toLowerCase() === 'alpha' && key[1]) {
              for (id2 in subtypeAlphaOptions) {
                if (key[1].toLowerCase()=== subtypeAlphaOptions[id2].value.toLowerCase()) {
                  prvsearch123.formsubtype = {subtype: subtypeAlphaOptions[id2]}
                }
              }
            }
          }
        }
      }
      

    } else if (this.props.pathname.includes('/j/')) {
      // JOURNAL

      key = this.props.pathname.split('/j/').pop().replaceAll('_',' ').toLowerCase();
      if ( key.match(/.*\/s=[a-zA-Z]+$/) ) {
        key = key.split('/s=').shift()
      }

      for (id in journalOptions) {
        if (key === journalOptions[id].value.toLowerCase()) {
          prvsearch123.array1 = [journalOptions[id]]
        }
      }

      prvsearch123.type = null
      prvsearch123.formsubtype = {subtype: null}

      prvsearch123.type = typeOptions[3]
      prvsearch123.formsubtype = {subtype: subtypeLibraryOptions[0]}

    } else if (this.props.pathname.includes('/p/')) {
      // PUBLISHER

      key = this.props.pathname.split('/p/').pop().replaceAll('_',' ').toLowerCase();
      if ( key.match(/.*\/s=[a-zA-Z]+$/) ) {
        key = key.split('/s=').shift()
      }

      prvsearch123.type = null
      prvsearch123.formsubtype = {subtype: null}

      prvsearch123.type = { value: 'library', label: 'Library'};
      prvsearch123.formsubtype = {subtype: subtypeLibraryOptions[0]}

    } else if (this.props.pathname.includes('/a/')) {
      // AUTHOR

      key = this.props.pathname.split('/a/').pop()
      if ( key.match(/.*\/s=[a-zA-Z]+$/) ) {
        key = key.split('/s=').shift()
      }

      prvsearch123.type = null
      prvsearch123.formsubtype = {subtype: null}

      var fname = key.split('_').slice(0, -1).join(' ').toLowerCase()
      var lname = key.split('_').slice(-1).join(' ').replaceAll("'", "''").toLowerCase();
      fname = toTitleCase(fname)
      lname = toTitleCase(lname)
      var author = fname + ' ' + lname
      prvsearch123.type = {value: 'library', label: 'Library'}
      prvsearch123.formvalue1 = {value1: {label: author, value: {firstname: fname, lastname: lname}}}

    }

    prvsearch123.pathname = this.props.pathname;

    this.props.initialize(prvsearch123)
    this.updateState(prvsearch123.formdates)
    this.props.searchRequest(prvsearch123)
  }
   
  componentDidUpdate(prevProps, prevState, snapshot) {

    var path1
    var path2

    var initialValues = {
      pathname: '/', 
      query: null,  
      table1: 'posts', 
      table2: null, 
      formdates: {date1: null, date2: Date.parse(moment((new Date()).toDateString()))}, //moment((new Date()).toDateString())},
      type: null,
      formsubtype: { subtype: null },
      formvalue1: { value1: null },
      formvalue2: { value2: null },
      formvalue3: { value3: null },
      formvalue4: { value4: null },
      formvalue5: { value5: null },
      formvalue6: { value6: null },
      array1: null, 
      array2: null, 
      bool1: 'OR',  
      formbool2: { bool2: false },
      bool3: false,
      keywords: null
    }

    var update = true;

    // DO WE HAVE A FORM TO SUBMIT?
    if ( (this.props.form === null) || (this.props.form === undefined) )  {
      var prvsearchMount;

      if ( (this.props.searchvalues === undefined) || (this.props.searchvalues === null) ) {
        prvsearchMount = initialValues
      } else {
        prvsearchMount = JSON.parse(JSON.stringify(this.props.searchvalues))
      }

      this.initialSearch(prvsearchMount)
      this.props.pageRequest('reset')
      update = false

    } else {
      
      // DID THE PATH CHANGE?

      path1 = this.props.form.pathname
      path2 = this.props.pathname

      //  DROP SORTBY
      path1 = path1.replace(/\/s=[a-zA-Z]+$/,'')
      path2 = path2.replace(/\/s=[a-zA-Z]+$/,'')

      if ( path1 !== path2 ) {
        // MANUAL SEARCH

        // IF PATH ONLY DIFF BY SORT BY THEN DONT DO ANYTHING

        if ( (this.props.pathname === '/' ) ||
        this.props.pathname.includes('/k/') ||
        this.props.pathname.includes('/u/') ||
        this.props.pathname.includes('/j/') ||
        this.props.pathname.includes('/a/') ||
        this.props.pathname.toLowerCase().match(/^\/q\/[a-zA-Z]+\/s=[a-zA-Z]+$/) ||
        this.props.pathname.toLowerCase().match(/^\/q\/[a-zA-Z]+$/)  ||
        this.props.pathname.toLowerCase().match(/^\/q\/[a-zA-Z]+\/[a-zA-Z]+\/s=[a-zA-Z]+$/) ||
        this.props.pathname.toLowerCase().match(/^\/q\/[a-zA-Z]+\/[a-zA-Z]+$/) ) {
    
          this.initialSearch(initialValues)
          update = false
          //this.props.pageRequest('reset')

        } else {
          this.props.form.pathname = this.props.pathname;
          this.props.initialize(this.props.form)
          this.updateState(this.props.form.formdates)
          update = false
        }
      } else {
        
        if ( this.props.form.pathname !== this.props.pathname ) {
          this.props.form.pathname = this.props.pathname;
          this.props.initialize(this.props.form)
          this.updateState(this.props.form.formdates)
          update = false
        }
      }
    }

    // KEEP FORM CONSISTENT
    if ( (this.props.form !== null) && (this.props.form !== undefined) && (update === true)) {

      if ( (this.props.form.table1 !== 'posts') && (this.props.form.table2 !== 'comments')) {
        this.props.dispatch(change('searchFields', 'table1', 'posts'));
      }
    
      if ( (prevProps.form !== null) && (prevProps.form !== undefined) ) {
        
        if (this.props.form.pathname === prevProps.form.pathname) {
          // PATHNAME SHOULD REMAIN THE SAME
        
          if ( this.props.form.formsubtype.subtype !== prevProps.form.formsubtype.subtype ) {
            this.props.dispatch(change('searchFields', 'keywords', null));
          }
          if ( this.props.form.type !== prevProps.form.type ) {
            this.props.dispatch(change('searchFields', 'keywords', null));
            
            path1 = this.props.form.pathname
            path2 = prevProps.form.pathname

            //  DROP SORTBY
            path1 = path1.replace(/\/s=[a-zA-Z]+$/,'')
            path2 = path2.replace(/\/s=[a-zA-Z]+$/,'')
            
            if ( (path1 === path2) ) {
              this.props.dispatch(change('searchFields', 'formsubtype', {subtype: null}));
            }
          } 
        }
      }
    }
  }

  handleStartCreateDateChange = (date) => {
    // MOMENT TO DATE
    this.setState({ date1: moment2date(date) });

    var formdates = {date1: date2moment(date), date2: this.props.form.formdates.date2}
    this.props.dispatch(change('searchFields', 'formdates', formdates));
  };

  handleSubtypeChange = (event) => {
    this.props.dispatch(change('searchFields', 'keywords', null));
  };

  handleEndCreateDateChange = (date) => {
    this.setState({ date2: moment2date(date) });
    
    var formdates = {date1: this.props.form.formdates.date1, date2: date2moment(date)}
    this.props.dispatch(change('searchFields', 'formdates', formdates));
  };

  clickModal(e) { 
    this.setState({ popupopen: (this.state.popupopen === true ? false : true) }); 
  }

  onReset = (e) => {
    e.preventDefault();

    var initialValues = {
        pathname: '/', 
        query: null,  
        table1: 'posts', 
        table2: null, 
        formdates: {date1: null, date2: Date.parse(moment((new Date()).toDateString()))}, //((new Date()).toDateString())},
        type: null,
        formsubtype: { subtype: null },
        formvalue1: { value1: null },
        formvalue2: { value2: null },
        formvalue3: { value3: null },
        formvalue4: { value4: null },
        formvalue5: { value5: null },
        formvalue6: { value6: null },
        array1: null, 
        array2: null, 
        bool1: 'OR',  
        formbool2: { bool2: false },
        bool3: false,
        keywords: null
      }

    this.setState({ popupopen: true })

    this.props.initialize(initialValues)
    this.props.searchRequest(initialValues)
    this.props.pageRequest('reset')
    
    if (!this.props.pathname.includes('/u/')) {
      this.props.history.push('/')
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    // IF 
    // /u/:username      THEN USER         === username
    // /q/:???           THEN ???          === ???
    // /k/:keyword       THEN KEYWORD      === keyword
    // /a/:author        THEN AUTHOR       === author

    // /g/:discussion    THEN DISCUSSION   === general (discussion)       = GENERAL
    // /g/:url           THEN URL          === url                        = GENERAL

    // /r/:journal       THEN JOURNAL      === journal                    = RESEARCH
    // /r/:textbook      THEN TEXTBOOK     === textbook                   = RESEARCH
  
    // /s/:school        THEN SCHOOL       === school                     = CAREER (MANUAL)
    // /s/:interview     THEN INTERVIEW    === interview (resume)         = CAREER 
    // /s/:firm          THEN FIRM         === firm                       = CAREER (MANUAL)
    // /s/:certificates  THEN CERTIFICATES === certificates               = CAREER

    // /c/:career        THEN CAREER       === career (ads)               = AD (MANUAL)
    // /m/:market        THEN MARKET       === market (ads)               = AD (MANUAL)
    // /e/:events        THEN EVENTS       === events (ads)               = AD (MANUAL)

    // /a/:strategy      THEN STRATEGY     === strategy                   = STRATEGY
    // /a/:data          THEN DATA         === data                       = STRATEGY
    // /a/:execution     THEN EXECUTION    === execution                  = STRATEGY
    // /a/:infastructure THEN INFASTRUCUTE === infastructure              = STRATEGY

    // b, h, l, o, p, x, y
    // a, c, d, e, f, g, i, j, k, m, n, q, r, s, t, u, v, w, z

    this.props.form.pathname = this.props.pathname
    this.props.searchRequest(this.props.form)
    
    if (!this.props.pathname.includes('/u/')) {
      this.props.history.push('/search')
    }
  }

  render() {
    if (this.state.pathnamecheck === null) return(null);
    if ( (this.props.form === null) || (this.props.form === undefined) ) return(null);
    
    if (this.state.popupopen === true) {
      return(
        <SearchForm
          loading={this.props.isFetching}
          onSubmit={this.onSubmit}
          onReset={this.onReset}
          wide
        >
          
          {/* QUERY */}
          <div className="row" style={{margin: '0px', width: '100%'}}> 
            <Field name='query' type='query' placeholder={this.state.queryplaceholder} component={renderAdvanced} />
            <div className="row" style={{margin: '0px', padding: '0px', width: '100px'}}> 
              {this.state.popupopen === true  && (<PostVoteDownvote type="button" onClick={() => { this.clickModal() } }/>)}
              {this.state.popupopen !== true  && (<PostVoteUpvote type="button" onClick={() => { this.clickModal() } }/>)}
              <SearchButton type='submit'>Search</SearchButton>
            </div>
          </div>
          
        </SearchForm>

      )
    
    } else {

      return (
        <Form 
          loading={this.props.isFetching}
          onSubmit={this.onSubmit}
          onReset={this.onReset}
          wide
        >

          {/* QUERY */}
          <div className="row" style={{margin: '0px', width: '100%', minWidth: '0px'}}> 
            <Field name='query' type='query' placeholder={this.state.queryplaceholder} component={renderAdvanced} />
            <div className="row" style={{margin: '0px', padding: '0px', width: '100px'}}> 
              {this.state.popupopen === true  && (<PostVoteDownvote type="button" sonClick={() => { this.clickModal() } }/>)}
              {this.state.popupopen !== true  && (<PostVoteUpvote type="button" onClick={() => { this.clickModal() } }/>)}
              <SearchButton type='submit'>Search</SearchButton>
            </div>
          </div>

          {/* RESET & OPTION */}
          <div className="row" style={{margin: '0px', width: '100%', marginBottom: '8px'}}> 
            <Field name='table1' type='radiogroup' component={renderAdvanced} options={postTable1} />
            <Field name='table2' type='radiogroup' component={renderAdvanced} options={postTable2} />
            <div style={{flex: '1', float: 'right'}}/>
            <ResetButton type='reset' style={{float: 'right'}}>Reset</ResetButton>
          </div>

          {/* TYPE & SUBTYPE */}
          <Media queries={{ large: "(min-width: "+this.props.theme.w1p1+")" }}>
            <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}> 
              <div style={{flex: '1', flexGrow: '1', flexShrink: '1', marginRight: '8px'}}> 
                <Field 
                  name='type'
                  type='type'
                  placeholder='by Category'
                  component={renderAdvanced}
                  theme={this.props.theme}
                  typeOptions={this.state.typeDyOptions}
                  defaultValue={this.props.form.type}
                />
              </div>
              <div style={{flex: '1', flexGrow: '1', flexShrink: '1', marginRight: '100px'}}> 
                {(this.props.form.type !== null) && (
                  <FormSection name="formsubtype">
                    <SelectSubtype type={ this.props.form.type.value } subtype={ this.props.form.formsubtype.subtype } theme={ this.props.theme } handleChange={this.handleSubtypeChange.bind(this)}/>
                  </FormSection>
                )}
              </div> 
            </div> 
          </Media>
          <Media queries={{ large: "(max-width: "+this.props.theme.w1+")" }}>
            <>
              <div style={{flex: '1', flexGrow: '1', flexShrink: '1', marginRight: '100px', marginBottom: '8px'}}> 
                <Field 
                  name='type'
                  type='type'
                  placeholder='by Category'
                  component={renderAdvanced}
                  theme={this.props.theme}
                  typeOptions={this.state.typeDyOptions}
                  defaultValue={this.props.form.type}
                />
              </div> 
              {(this.props.form.type !== null) && (
                <div style={{flex: '1', flexGrow: '1', flexShrink: '1', marginRight: '100px', marginBottom: '8px'}}> 
                  <FormSection name="formsubtype">
                    <SelectSubtype type={ this.props.form.type.value } subtype={ this.props.form.formsubtype.subtype } theme={ this.props.theme } handleChange={this.handleSubtypeChange.bind(this)}/>
                  </FormSection>
                </div>
              )}
            </>
          </Media>

          {/* KEYWORDS */}
          <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}> 
            <div style={{flex: '1', flexGrow: '1', flexShrink: '1', marginBottom: '0px', marginRight: '21px'}}> 
            {(this.props.form.type === null) && (
                <Field 
                  name='keywords'
                  type='keywords'
                  placeholder='by Keyword(s)'
                  component={renderAdvanced}
                  theme={this.props.theme}
                  options={keywordOptions}
                  defaultValue={this.props.form.keywords}
                />
              )}

              {(this.props.form.type !== null) && (
                <>
                  {(this.props.form.formsubtype.subtype === null) && (
                    <Field 
                      name='keywords'
                      type='keywords'
                      placeholder='by Keyword(s)'
                      component={renderAdvanced}
                      theme={this.props.theme}
                      options={keywordOptions}
                      defaultValue={this.props.form.keywords}
                    />
                  )}

                  {(this.props.form.formsubtype.subtype !== null) && ( 

                    <>
                      
                      {(this.props.form.formsubtype.subtype.value === 'school') && (
                        <Field 
                          name='keywords'
                          type='keywords'
                          placeholder='by Keyword(s)'
                          component={renderAdvanced}
                          theme={this.props.theme}
                          options={keywordSchoolOptions}
                          defaultValue={this.props.form.keywords}
                        />
                      )}

                      {(this.props.form.formsubtype.subtype.value === 'firm') && (
                        <Field 
                          name='keywords'
                          type='keywords'
                          placeholder='by Keyword(s)'
                          component={renderAdvanced}
                          theme={this.props.theme}
                          options={keywordFirmOptions}
                          defaultValue={this.props.form.keywords}
                        />
                      )}

                      {(this.props.form.formsubtype.subtype.value === 'data') && (
                        <Field 
                          name='keywords'
                          type='keywords'
                          placeholder='by Keyword(s)'
                          component={renderAdvanced}
                          theme={this.props.theme}
                          options={keywordDataOptions}
                          defaultValue={this.props.form.keywords}
                        />
                      )}

                      {(this.props.form.formsubtype.subtype.value === 'strategy') && (
                        <Field 
                          name='keywords'
                          type='keywords'
                          placeholder='by Keyword(s)'
                          component={renderAdvanced}
                          theme={this.props.theme}
                          options={keywordStrategyOptions}
                          defaultValue={this.props.form.keywords}
                        />
                      )}

                      {(this.props.form.formsubtype.subtype.value !== 'firm') 
                       && (this.props.form.formsubtype.subtype.value !== 'school') 
                       && (this.props.form.formsubtype.subtype.value !== 'strategy') 
                       && (this.props.form.formsubtype.subtype.value !== 'data') && (
                        <Field 
                          name='keywords'
                          type='keywords'
                          placeholder='by Keyword(s)'
                          component={renderAdvanced}
                          theme={this.props.theme}
                          options={keywordOptions}
                          defaultValue={this.props.form.keywords}
                        />
                      )}

                    </>
                  )}

                </>
              )}


{/*
                {(this.props.form.formsubtype !== null) && (
                
                  <>

                    {(this.props.form.formsubtype.subtype.value === "school") && (
                      <Field 
                        name='keywords'
                        type='keywords'
                        placeholder='by Keyword(s)'
                        component={renderAdvanced}
                        theme={this.props.theme}
                        options={keywordSchoolOptions}
                        defaultValue={this.props.form.keywords}
                      />
                    )}
                    
                    {(this.props.form.formsubtype.subtype.value === "firm") && (
                      <Field 
                        name='keywords'
                        type='keywords'
                        placeholder='by Keyword(s)'
                        component={renderAdvanced}
                        theme={this.props.theme}
                        options={keywordFirmOptions}
                        defaultValue={this.props.form.keywords}
                      />
                    )}
                    
                    {(this.props.form.formsubtype.subtype.value !== "school") && (this.props.form.formsubtype.subtype.value !== "firm") && (
                      <Field 
                        name='keywords'
                        type='keywords'
                        placeholder='by Keyword(s)'
                        component={renderAdvanced}
                        theme={this.props.theme}
                        options={keywordOptions}
                        defaultValue={this.props.form.keywords}
                      />
                    )}
                  </>
                )}
                    */}
            </div>
              <Field 
                name='bool1'
                type='radiogroup2'
                component={renderAdvanced}
                options={postKeys}
              />
          </div>

          {/* TYPE: NULL */}
          {(this.props.form.type === null) && (
            <FormSection name="formdates">
              <SelectDates version="two" theme={this.props.theme}
                date1={this.state.date1}
                date2={this.state.date2}
                handleStartCreateDateChange={ this.handleStartCreateDateChange.bind(this) } 
                handleEndCreateDateChange={ this.handleEndCreateDateChange.bind(this) } 
              />
            </FormSection>
          )}

          {/* TYPE: NOT NULL */}
          {(this.props.form.type !== null) && (
            <>
          
              {/* TYPE: GENERAL */}
              {this.props.form.type.value === "general" && (
                <FormSection name="formdates">
                  <SelectDates version="two" theme={this.props.theme}
                    date1={this.state.date1}
                    date2={this.state.date2}
                    handleStartCreateDateChange={ this.handleStartCreateDateChange.bind(this) } 
                    handleEndCreateDateChange={this.handleEndCreateDateChange.bind(this) } 
                  />
                </FormSection>
              )}

              {/* TYPE: LIBRARY */}
              {this.props.form.type.value === "library" && (
                <>
                  {(this.props.form.formsubtype.subtype !== null) && (
                    <>
                      
                      {/* SUBTYPE: ARTICLE */}
                      {(this.props.form.formsubtype.subtype.value === "article") && (
                        <>
                          <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}> 
                            <div style={{flex: '1', flexGrow: '1', flexShrink: '1', marginBottom: '0px', marginRight: '21px'}}> 
                              <Field 
                                name='array1'
                                type='journals'
                                placeholder='by Journal(s)'
                                component={renderAdvanced}
                                theme={this.props.theme}
                                options={journalOptions}
                                defaultValue={this.props.form.array1}
                              />
                            </div>
                            <FormSection name="formbool2">
                              <SelectBool2 postAttach={postAttach1}/>
                            </FormSection>
                          </div>
                        </>
                      )} 

                    </>
                  )}

                  {/* AUTHOR & DATE */}
                  <Media queries={{ large: "(min-width: "+this.props.theme.w1p1+")" }}>
                    <>
                      <FormSection name="formvalue1">
                        <SelectValue1 placeholder='by Author' version='col1' options={authorOptions} theme={this.props.theme} value={ this.props.form.formvalue1.value1 } />
                      </FormSection> 
                      <FormSection name="formdates">
                        <SelectDates version="two" theme={this.props.theme}
                          date1={this.state.date1}
                          date2={this.state.date2}
                          handleStartCreateDateChange={ this.handleStartCreateDateChange.bind(this) } 
                          handleEndCreateDateChange={this.handleEndCreateDateChange.bind(this) } 
                        />
                      </FormSection>
                    </>
                  </Media>
                  <Media queries={{ large: "(max-width: "+this.props.theme.w1+")" }}>
                    <>
                      <FormSection name="formvalue1">
                        <SelectValue1 placeholder='by Author' version='col1' options={authorOptions} theme={this.props.theme} value={ this.props.form.formvalue1.value1 } />
                      </FormSection>
                      <FormSection name="formdates">
                        <SelectDates version="two" theme={this.props.theme}
                          date1={this.state.date1}
                          date2={this.state.date2}
                          handleStartCreateDateChange={ this.handleStartCreateDateChange.bind(this) } 
                          handleEndCreateDateChange={this.handleEndCreateDateChange.bind(this) } 
                        />
                      </FormSection>
                    </>
                  </Media>
                </>
              )}

              {/* TYPE: CAREER */}
              {(this.props.form.type.value === "career") && (
                <>
                  {(this.props.form.formsubtype.subtype !== null) && (
                    <>

                      {/* SUBTYPE: SCHOOL */}
                      {(this.props.form.formsubtype.subtype.value === "school") && (
                        <>
                          <Media queries={{ large: "(min-width: "+this.props.theme.w1p1+")" }}>
                            {/* Keywords (Job Function) = Quantitative Researcher, Quantitative Analyst, PM, ... */}
                            <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginBottom: '8px', marginRight: '100px'}}> 
                              <FormSection name="formvalue1">
                                <SelectValue1 placeholder='by School' version='col2L' options={schoolOptions} theme={this.props.theme} value={ this.props.form.formvalue1.value1 } />
                              </FormSection>
                              <FormSection name="formdates">
                                <SelectDates version="one" theme={this.props.theme}
                                  date1={this.state.date1}
                                  date2={this.state.date2}
                                  handleStartCreateDateChange={ this.handleStartCreateDateChange.bind(this) } 
                                  handleEndCreateDateChange={this.handleEndCreateDateChange.bind(this) } 
                                />
                              </FormSection>
                            </div>  
                          </Media>
                          <Media queries={{ large: "(max-width: "+this.props.theme.w1+")" }}>
                            <>
                              {/* Keywords (Job Function) = Quantitative Researcher, Quantitative Analyst, PM, ... */}
                              <FormSection name="formvalue1">
                                <SelectValue1 placeholder='by School' version='col1' options={schoolOptions} theme={this.props.theme} value={ this.props.form.formvalue1.value1 } />
                              </FormSection>
                              <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginBottom: '8px', marginRight: '100px'}}> 
                                <FormSection name="formdates">
                                  <SelectDates version="one" theme={this.props.theme}
                                    date1={this.state.date1}
                                    date2={this.state.date2}
                                    handleStartCreateDateChange={ this.handleStartCreateDateChange.bind(this) } 
                                    handleEndCreateDateChange={this.handleEndCreateDateChange.bind(this) } 
                                  />
                                </FormSection>
                              </div>  
                            </>
                          </Media>
                        </>
                      )} 

                      {/* SUBTYPE: INTERVIEW */}
                      {(this.props.form.formsubtype.subtype.value === "interview") && (
                        <>
                          <Media queries={{ large: "(min-width: "+this.props.theme.w1p1+")" }}>
                            <>
                              {/* Keywords (Job Function) = Quantitative Researcher, Quantitative Analyst, PM, ... */}
                              <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginBottom: '8px', marginRight: '100px'}}> 
                                <FormSection name="formvalue1">
                                  <SelectValue1 placeholder='by Firm' version='col2L' options={firmOptions} theme={this.props.theme} value={ this.props.form.formvalue1.value1 } />
                                </FormSection>
                                <FormSection name="formvalue2">
                                  <SelectValue2 placeholder='by Position' version='col2R' options={positionOptions} theme={this.props.theme} value={ this.props.form.formvalue2.value2 } />
                                </FormSection>
                              </div>  
                              <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginBottom: '8px', marginRight: '100px'}}> 
                                <FormSection name="formvalue3">
                                  <SelectValue2 placeholder='by Experience' version='col2L' options={experienceOptions} theme={this.props.theme} value={ this.props.form.formvalue2.value2 } />
                                </FormSection>
                                <FormSection name="formdates">
                                  <SelectDates version="one" theme={this.props.theme}
                                    date1={this.state.date1}
                                    date2={this.state.date2}
                                    handleStartCreateDateChange={ this.handleStartCreateDateChange.bind(this) } 
                                    handleEndCreateDateChange={this.handleEndCreateDateChange.bind(this) } 
                                  />
                                </FormSection>
                              </div>
                            </>  
                          </Media>
                          <Media queries={{ large: "(max-width: "+this.props.theme.w1+")" }}>
                            <>
                              {/* Keywords (Job Function) = Quantitative Researcher, Quantitative Analyst, PM, ... */}
                              <FormSection name="formvalue1">
                                <SelectValue1 placeholder='by Firm' version='col1' options={firmOptions} theme={this.props.theme} value={ this.props.form.formvalue1.value1 } />
                              </FormSection>
                              <FormSection name="formvalue2">
                                  <SelectValue2 placeholder='by Position' version='col1' options={positionOptions} theme={this.props.theme} value={ this.props.form.formvalue2.value2 } />
                              </FormSection>
                              <FormSection name="formvalue3">
                                <SelectValue2 placeholder='by Experience' version='col1' options={experienceOptions} theme={this.props.theme} value={ this.props.form.formvalue2.value2 } />
                              </FormSection>
                              <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginBottom: '8px', marginRight: '100px'}}> 
                                <FormSection name="formdates">
                                  <SelectDates version="one" theme={this.props.theme}
                                    date1={this.state.date1}
                                    date2={this.state.date2}
                                    handleStartCreateDateChange={ this.handleStartCreateDateChange.bind(this) } 
                                    handleEndCreateDateChange={this.handleEndCreateDateChange.bind(this) } 
                                  />
                                </FormSection>
                              </div>
                            </>
                          </Media>
                        </>
                      )} 

                      {/* FIRM */}
                      {(this.props.form.formsubtype.subtype.value === "firm") && (
                        <>
                          <Media queries={{ large: "(min-width: "+this.props.theme.w1p1+")" }}>
                            <>
                              {/* Keywords (Job Function) = Quantitative Researcher, Quantitative Analyst, PM, ... */}
                              <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginBottom: '8px', marginRight: '100px'}}> 
                                <FormSection name="formvalue1">
                                  <SelectValue1 placeholder='by Firm' version='col2L' options={firmOptions} theme={this.props.theme} value={ this.props.form.formvalue1.value1 } />
                                </FormSection>
                                <FormSection name="formdates">
                                  <SelectDates version="one" theme={this.props.theme}
                                    date1={this.state.date1}
                                    date2={this.state.date2}
                                    handleStartCreateDateChange={ this.handleStartCreateDateChange.bind(this) } 
                                    handleEndCreateDateChange={this.handleEndCreateDateChange.bind(this) } 
                                  />
                                </FormSection>
                              </div>
                            </>
                          </Media>
                          <Media queries={{ large: "(max-width: "+this.props.theme.w1+")" }}>
                            <>
                              {/* Keywords (Job Function) = Quantitative Researcher, Quantitative Analyst, PM, ... */}
                              <FormSection name="formvalue1">
                                <SelectValue1 placeholder='by Firm' version='col1' options={firmOptions} theme={this.props.theme} value={ this.props.form.formvalue1.value1 } />
                              </FormSection>
                              <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginBottom: '8px', marginRight: '100px'}}> 
                                <FormSection name="formdates">
                                  <SelectDates version="one" theme={this.props.theme}
                                    date1={this.state.date1}
                                    date2={this.state.date2}
                                    handleStartCreateDateChange={ this.handleStartCreateDateChange.bind(this) } 
                                    handleEndCreateDateChange={this.handleEndCreateDateChange.bind(this) } 
                                  />
                                </FormSection>
                              </div>  
                            </>
                          </Media>
                        </>
                      )}

                    </> 
                  )} 
                </>
              )}

              {/* SUBTYPE: ALPHA */}
              {(this.props.form.type.value === "alpha") && (
                <>
                  {(this.props.form.formsubtype.subtype === null) && (
                    <>
                      <Media queries={{ large: "(min-width: "+this.props.theme.w1p1+")" }}>
                        <>
                          <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginBottom: '8px', marginRight: '100px'}}> 
                            <FormSection name="formvalue1">
                              <SelectValue1 placeholder='by Market' version='col2L' options={marketOptions} theme={this.props.theme} value={ this.props.form.formvalue1.value1 } />
                            </FormSection>
                            <FormSection name="formvalue2">
                              <SelectValue2 placeholder='by Instrument' version='col2R' options={instrumentOptions} theme={this.props.theme} value={ this.props.form.formvalue2.value2 } />
                            </FormSection>
                          </div>
                          <FormSection name="formvalue3">
                            <SelectValue2 placeholder='by Region' version='col1' options={regionOptions} theme={this.props.theme} value={ this.props.form.formvalue2.value2 } />
                          </FormSection>
                        </>
                      </Media>
                      <Media queries={{ large: "(max-width: "+this.props.theme.w1+")" }}>
                        <>
                          <FormSection name="formvalue1">
                            <SelectValue1 placeholder='by Market' version='col1' options={marketOptions} theme={this.props.theme} value={ this.props.form.formvalue1.value1 } />
                          </FormSection>
                          <FormSection name="formvalue2">
                            <SelectValue2 placeholder='by Instrument' version='col1' options={instrumentOptions} theme={this.props.theme} value={ this.props.form.formvalue2.value2 } />
                          </FormSection>
                          <FormSection name="formvalue3">
                            <SelectValue2 placeholder='by Region' version='col1' options={regionOptions} theme={this.props.theme} value={ this.props.form.formvalue2.value2 } />
                          </FormSection>
                        </>
                      </Media>
                      </>
                    )}

                    {(this.props.form.formsubtype.subtype !== null) && (
                      <>

                        {/* SUBTYPE: STRATEGY */}
                        {(this.props.form.formsubtype.subtype.value === "strategy") && (
                          <>
                            <Media queries={{ large: "(min-width: "+this.props.theme.w1p1+")" }}>
                              <>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '21px'}}>
                                    <FormSection name="formvalue1">
                                      <SelectValue1 placeholder='by Market' version='col2L' options={marketOptions} theme={this.props.theme} value={ this.props.form.formvalue1.value1 } />
                                    </FormSection>
                                    <FormSection name="formvalue2">
                                      <SelectValue2 placeholder='by Instrument' version='col2R' options={instrumentOptions} theme={this.props.theme} value={ this.props.form.formvalue2.value2 } />
                                    </FormSection>
                                  </div>
                                  <FormSection name="formbool2">
                                    <SelectBool2 postAttach={postAttach1}/>
                                  </FormSection>
                                </div>               
                                
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '21px'}}>
                                    <FormSection name="formvalue3">
                                      <SelectValue3 placeholder='by Region' version='col2L' options={regionOptions} theme={this.props.theme} value={ this.props.form.formvalue3.value3 } />
                                    </FormSection>
                                    <FormSection name="formvalue4">
                                      <SelectValue4 placeholder='by Liquidity' version='col2R' options={liquidOptions} theme={this.props.theme} value={ this.props.form.formvalue4.value4 } />
                                    </FormSection>
                                  </div>
                                  <div style={{margin: '0%', float: 'right'}}> 
                                      <Field name='bool3' type='radiogroup3' component={renderAdvanced} options={postAttach3} />
                                  </div>
                                </div>

                                <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginBottom: '8px', marginRight: '100px'}}>
                                  <FormSection name="formvalue5">
                                    <SelectValue4 placeholder='by Number' version='col2L' options={numberOptions} theme={this.props.theme} value={ this.props.form.formvalue5.value5 } />
                                  </FormSection>
                                  <FormSection name="formvalue6">
                                    <SelectValue4 placeholder='by Period' version='col2R' options={periodOptions} theme={this.props.theme} value={ this.props.form.formvalue6.value6 } />
                                  </FormSection>
                                </div>

                              </>
                            </Media>
                            <Media queries={{ large: "(max-width: "+this.props.theme.w1+")" }}>
                              <>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '21px'}}>
                                    <FormSection name="formvalue1">
                                      <SelectValue1 placeholder='by Market' version='col2R' options={marketOptions} theme={this.props.theme} value1={ this.props.form.formvalue1.value1 } />
                                    </FormSection>
                                  </div>
                                  <FormSection name="formbool2">
                                    <SelectBool2 postAttach={postAttach1}/>
                                  </FormSection>
                                </div>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '21px'}}>
                                    <FormSection name="formvalue2">
                                      <SelectValue2 placeholder='by Instrument' version='col2R' options={instrumentOptions} theme={this.props.theme} value2={ this.props.form.formvalue2.value2 } />
                                    </FormSection>
                                  </div>
                                  <FormSection name="formbool2">
                                    <SelectBool2 postAttach={postAttach3}/>
                                  </FormSection>
                                </div>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '0px'}}>
                                    <FormSection name="formvalue3">
                                      <SelectValue3 placeholder='by Region' version='col2L' options={regionOptions} theme={this.props.theme} value={ this.props.form.formvalue3.value3 } />
                                    </FormSection>
                                    <FormSection name="formvalue4">
                                      <SelectValue4 placeholder='by Liquidity' version='col2R' options={liquidOptions} theme={this.props.theme} value={ this.props.form.formvalue4.value4 } />
                                    </FormSection>
                                  </div>
                                </div>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '0px'}}>
                                    <FormSection name="formvalue5">
                                      <SelectValue5 placeholder='by Number' version='col2L' options={numberOptions} theme={this.props.theme} value={ this.props.form.formvalue5.value5 } />
                                    </FormSection>
                                    <FormSection name="formvalue6">
                                      <SelectValue6 placeholder='by Period' version='col2R' options={periodOptions} theme={this.props.theme} value={ this.props.form.formvalue6.value6 } />
                                    </FormSection>
                                  </div>
                                </div>
                              </>
                            </Media>
                          </>
                        )}

                        {/* SUBTYPE: DATA */}
                        {(this.props.form.formsubtype.subtype.value === "data") && (
                          <>
                            <Media queries={{ large: "(min-width: "+this.props.theme.w1p1+")" }}>
                              <>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '21px'}}>
                                    <FormSection name="formvalue1">
                                      <SelectValue1 placeholder='by Asset' version='col2R' options={marketOptions} theme={this.props.theme} value1={ this.props.form.formvalue1.value1 } />
                                    </FormSection>
                                  </div>
                                  <div style={{margin: '0%', float: 'right'}}> 
                                    <Field name='bool3' type='radiogroup3' component={renderAdvanced} options={postAttach3} />
                                  </div>
                                </div>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '100px'}}> 
                                    <FormSection name="formvalue3">
                                      <SelectValue3 placeholder='by Region' version='col2L' options={regionOptions} theme={this.props.theme} value={ this.props.form.formvalue3.value3 } />
                                    </FormSection>
                                    <FormSection name="formvalue4">
                                      <SelectValue4 placeholder='by Price' version='col2R' options={priceOptions} theme={this.props.theme} value={ this.props.form.formvalue4.value4 } />
                                    </FormSection>
                                  </div>
                                </div>
                              </>
                            </Media>
                            <Media queries={{ large: "(max-width: "+this.props.theme.w1+")" }}>
                              <>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '21px'}}>
                                    <FormSection name="formvalue1">
                                      <SelectValue1 placeholder='by Asset' version='col2R' options={marketOptions} theme={this.props.theme} value1={ this.props.form.formvalue1.value1 } />
                                    </FormSection>
                                  </div>
                                  <div style={{margin: '0%', float: 'right'}}> 
                                    <Field name='bool3' type='radiogroup3' component={renderAdvanced} options={postAttach3} />
                                  </div>
                                </div>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '0px'}}>
                                    <FormSection name="formvalue3">
                                      <SelectValue3 placeholder='by Region' version='col2L' options={regionOptions} theme={this.props.theme} value={ this.props.form.formvalue3.value3 } />
                                    </FormSection>
                                    <FormSection name="formvalue4">
                                      <SelectValue4 placeholder='by Price' version='col2R' options={liquidOptions} theme={this.props.theme} value={ this.props.form.formvalue4.value4 } />
                                    </FormSection>
                                  </div>
                                </div>
                              </>
                            </Media>
                          </>
                        )}

                        {/* SUBTYPE: OPERATION */}
                        {(this.props.form.formsubtype.subtype.value === "operation") && (
                          <>
                            <Media queries={{ large: "(min-width: "+this.props.theme.w1p1+")" }}>
                              <>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '21px'}}>
                                    <FormSection name="formvalue1">
                                      <SelectValue1 placeholder='by Market' version='col2L' options={marketOptions} theme={this.props.theme} value1={ this.props.form.formvalue1.value1 } />
                                    </FormSection>
                                    <FormSection name="formvalue2">
                                      <SelectValue2 placeholder='by Instrument' version='col2R' options={instrumentOptions} theme={this.props.theme} value2={ this.props.form.formvalue2.value2 } />
                                    </FormSection>
                                  </div>
                                  <FormSection name="formbool2">
                                    <SelectBool2 postAttach={postAttach1}/>
                                  </FormSection>
                                </div>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '21px'}}> 
                                    <FormSection name="formvalue3">
                                      <SelectValue3 placeholder='by Region' version='col2L' options={regionOptions} theme={this.props.theme} value={ this.props.form.formvalue3.value3 } />
                                    </FormSection>
                                    <FormSection name="formvalue4">
                                      <SelectValue4 placeholder='by Price' version='col2R' options={priceOptions} theme={this.props.theme} value={ this.props.form.formvalue4.value4 } />
                                    </FormSection>
                                  </div>
                                  <div style={{margin: '0%', float: 'right'}}> 
                                    <Field name='bool3' type='radiogroup3' component={renderAdvanced} options={postAttach3} />
                                  </div>
                                </div>
                                <FormSection name="formvalue5">
                                  <SelectValue5 placeholder='by Provider' version='col1' options={numberOptions} theme={this.props.theme} value={ this.props.form.formvalue5.value5 } />
                                </FormSection>
                              </>
                            </Media>
                            <Media queries={{ large: "(max-width: "+this.props.theme.w1+")" }}>
                              <>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '21px'}}>
                                    <FormSection name="formvalue1">
                                      <SelectValue1 placeholder='by Market' version='col2R' options={marketOptions} theme={this.props.theme} value1={ this.props.form.formvalue1.value1 } />
                                    </FormSection>
                                  </div>
                                  <FormSection name="formbool2">
                                    <SelectBool2 postAttach={postAttach1}/>
                                  </FormSection>
                                </div>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '21px'}}>
                                    <FormSection name="formvalue2">
                                      <SelectValue2 placeholder='by Instrument' version='col2R' options={instrumentOptions} theme={this.props.theme} value2={ this.props.form.formvalue2.value2 } />
                                    </FormSection>
                                  </div>
                                  <div style={{margin: '0%', float: 'right'}}> 
                                    <Field name='bool3' type='radiogroup3' component={renderAdvanced} options={postAttach3} />
                                  </div>
                                </div>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px'}}>                  
                                  <div className="row" style={{flex: '1', flexGrow: '1', flexShrink: '1', marginLeft: '0px', marginRight: '0px'}}>
                                    <FormSection name="formvalue3">
                                      <SelectValue3 placeholder='by Region' version='col2L' options={regionOptions} theme={this.props.theme} value={ this.props.form.formvalue3.value3 } />
                                    </FormSection>
                                    <FormSection name="formvalue4">
                                      <SelectValue4 placeholder='by Liquidity' version='col2R' options={liquidOptions} theme={this.props.theme} value={ this.props.form.formvalue4.value4 } />
                                    </FormSection>
                                  </div>
                                </div>
                                <div className="row" style={{marginLeft: '0px', width: '100%', marginBottom: '8px', marginRight: '0px'}}> 
                                  <FormSection name="formvalue4">
                                    <SelectValue4 placeholder='by Provider' version='col2R' options={numberOptions} theme={this.props.theme} value={ this.props.form.formvalue4.value4 } />
                                  </FormSection>
                                </div>
                              </>
                            </Media>
                          </>
                        )}

                      </>
                    )} 
                </>
              )}

            </>
          )}
        </Form>
                 
      )
    }
  }
}

export default withRouter(withTheme(searchBar))