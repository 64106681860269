import React from 'react';
import styled from 'styled-components/macro';

import * as dayjs from 'dayjs'

import { Link } from 'react-router-dom';
import { link } from '../../shared/helpers';
import Author from '../../shared/Author';
import Media from 'react-media';

import * as isLeapYear from 'dayjs/plugin/isLeapYear' // import plugin
import 'dayjs/locale/en' // import locale

dayjs.extend(isLeapYear) // use plugin
dayjs.locale('en') // use locale

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

const Wrapper = styled.div`
  font-size: 14px;

  display: flex;
  flex: 1;
  flex-direction: row;
  width: auto;

  margin-top: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;

  & > * {
    margin-right: 4px;
  }

  & > a {
    ${link};
    :hover {
      background-color: ${props => props.theme.voteButtonHover};
      border-radius: 2px;
      padding-right: 3px;
      padding-left: 3px;
    } 
  }
  
  & > span {
    color: ${props => props.theme.mutedText};
  }
`;

const WrapperNew = styled.div`
  font-size: 14px;

  display: flex;
  flex: 1;
  flex-direction: row;
  width: auto;

  margin-top: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.5;

  & > * {
    margin-right: 4px;
  }

  & > a {
    ${link};
    :hover {
      background-color: ${props => props.theme.foregroundHover};
      border-radius: 2px;
      padding-right: 3px;
      padding-left: 3px;
    } 
  }
  
  & > span {
    color: ${props => props.theme.mutedText};
  }
`;

const PostContentDetail = props => {

  
    if (props.newpost === 1) {
      return (  
        <>
          <Media queries={{ large: "(max-width: 450px)" }}>
            <>
              <WrapperNew>
                <Link componentclass={Link} to={`/q/${props.type}/${props.subtype}/${props.pid}`}>
                  {props.commentCount} comment{props.commentCount !== 1 ? 's' : null}
                </Link>
                <Link to={`/q/${props.type}/${props.subtype}`}>{props.subtype}</Link>
                <span>by</span>
                <Author username={props.author} />
                <span>{dayjs(props.created).fromNow()}</span>
              </WrapperNew>
            </>
          </Media>
          
          <Media queries={{ large: "(min-width: 451px)" }}>
            <>
              <WrapperNew>
                <Link componentclass={Link} to={`/q/${props.type}/${props.subtype}/${props.pid}`}>
                  {props.commentCount} comment{props.commentCount !== 1 ? 's' : null}
                </Link>
                <Link to={`/q/${props.type}`}>/q/{props.type}</Link>
                <span>in</span>
                <Link to={`/q/${props.type}/${props.subtype}`}>{props.subtype}</Link>
                <span>by</span>
                <Author username={props.author} />
                <span>{dayjs(props.created).fromNow()}</span>
              </WrapperNew>
            </>
          </Media>
        </>
      )
    } else {
      return (  
        <>
          <Media queries={{ large: "(max-width: 450px)" }}>
            <>
              <Wrapper>
                <Link componentclass={Link} to={`/q/${props.type}/${props.subtype}/${props.pid}`}>
                  {props.commentCount} comment{props.commentCount !== 1 ? 's' : null}
                </Link>
                <Link to={`/q/${props.type}/${props.subtype}`}>{props.subtype}</Link>
                <span>by</span>
                <Author username={props.author} />
                <span>{dayjs(props.created).fromNow()}</span>
              </Wrapper>
            </>
          </Media>
          
          <Media queries={{ large: "(min-width: 451px)" }}>
            <>
              <Wrapper>
                <Link componentclass={Link} to={`/q/${props.type}/${props.subtype}/${props.pid}`}>
                  {props.commentCount} comment{props.commentCount !== 1 ? 's' : null}
                </Link>
                <Link to={`/q/${props.type}`}>/q/{props.type}</Link>
                <span>in</span>
                <Link to={`/q/${props.type}/${props.subtype}`}>{props.subtype}</Link>
                <span>by</span>
                <Author username={props.author} />
                <span>{dayjs(props.created).fromNow()}</span>
              </Wrapper>
            </>
          </Media>
        </>
      )
    }

};

export default PostContentDetail;
