import {

  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_ERROR,

  FETCH_COMMENT_REQUEST,
  FETCH_COMMENT_SUCCESS,
  FETCH_COMMENT_ERROR,

  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_ERROR,

  CREATE_REPLY_REQUEST,
  CREATE_REPLY_SUCCESS,
  CREATE_REPLY_ERROR,

  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_ERROR,

  VOTE_COMMENT_REQUEST,
  VOTE_COMMENT_SUCCESS,
  VOTE_COMMENT_ERROR,

  SAVED_COMMENT_REQUEST,
  SAVED_COMMENT_SUCCESS,
  SAVED_COMMENT_ERROR,

  SAVE_COMMENT_REQUEST,
  SAVE_COMMENT_SUCCESS,
  SAVE_COMMENT_ERROR,
  
  UNSAVE_COMMENT_REQUEST,
  UNSAVE_COMMENT_SUCCESS,
  UNSAVE_COMMENT_ERROR,

} from '../actions/comments';

const initialState = { isFetching: false, items: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMMENTS_REQUEST:
      return { ...state, isFetching: true, comment: null, newComment: null, newReply: null };
    case FETCH_COMMENTS_SUCCESS:    
      return { ...state, isFetching: false, items: action.comment };
    case FETCH_COMMENTS_ERROR:
      return { ...state, isFetching: false };

    case FETCH_COMMENT_REQUEST:
      return { ...state, isFetching: true, comment: null, newComment: null, newReply: null };
    case FETCH_COMMENT_SUCCESS:
      const itemsLink = action.comment;
      return { ...state, isFetching: false, items: itemsLink };
    case FETCH_COMMENT_ERROR:
      return { ...state, isFetching: false };

    case CREATE_COMMENT_REQUEST:
      return { ...state, isCommentFetching: true };
    case CREATE_COMMENT_SUCCESS:
      return { ...state, isCommentFetching: false, newComment: "update" };
    case CREATE_COMMENT_ERROR:
      return { ...state, isCommentFetching: false, error: action.error };

    case CREATE_REPLY_REQUEST:
      return { ...state, isReplyFetching: true };
    case CREATE_REPLY_SUCCESS:
      return { ...state, isReplyFetching: false, newReply: "update" };
    case CREATE_REPLY_ERROR:
      return { ...state, isReplyFetching: false, error: action.error };

    case DELETE_COMMENT_REQUEST:
      return { ...state, isDeleting: true };
    case DELETE_COMMENT_SUCCESS:
      var itemsDelete = state.items.filter(i => i.cid !== action.comment);
      return { ...state, isDeleting: false, items: itemsDelete, comment: null, newComment: "update", newReply: "update" };
    case DELETE_COMMENT_ERROR:
      return { ...state, isDeleting: false };

    case SAVED_COMMENT_REQUEST:
      return { ...state, isCommentFetching: true };
    case SAVED_COMMENT_SUCCESS:
      return { ...state, isCommentFetching: false, commentsaved: action.saved };
    case SAVED_COMMENT_ERROR:
      return { ...state, isCommentFetching: false, error: action.error };

    case SAVE_COMMENT_REQUEST:
      return { ...state, isCommentSaving: true };
    case SAVE_COMMENT_SUCCESS:
      return { ...state, isCommentSaving: false };
    case SAVE_COMMENT_ERROR:
      return { ...state, isCommentSaving: false, error: action.error };

    case UNSAVE_COMMENT_REQUEST:
      return { ...state, isCommentDeleting: true };
    case UNSAVE_COMMENT_SUCCESS:
      return { ...state, isCommentDeleting: false };
    case UNSAVE_COMMENT_ERROR:
      return { ...state, isCommentDeleting: false, error: action.error };

    case VOTE_COMMENT_REQUEST:
      return { ...state, isCommentVoting: true };
    case VOTE_COMMENT_SUCCESS:
      return { ...state, isCommentVoting: false, upvotePercentage: action.comment.upvotePercentage };
    case VOTE_COMMENT_ERROR:
      return { ...state, isCommentVoting: false };

    default:
      return state;
  }
};
